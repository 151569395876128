import { createSelector } from '@ngrx/store';
import { State } from '../index';

export const authStateSelector = (state: State) => state.auth;

export const userSelector = createSelector(
  authStateSelector,
  (state) => state?.user
);

export const userIdSelector = createSelector(
  authStateSelector,
  (state) => state?.user?.uid
);

export const idTokenSelector = createSelector(
  authStateSelector,
  (state) => state?.idToken
);