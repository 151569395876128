<div fxFlex="100" fxLayout="row wrap">
    <label fxFlex="100" *ngIf="label">{{label}}</label>
    <div [fxFlex]="fxFlexSize" fxFlex.lt-sm="100" >

        <mat-form-field 
            [ngClass]="{'mat-form-field-invalid': !!error}"
            class="date-input" appearance="outline">
            <mat-label>{{ 'common.year' | translate }}</mat-label>
            <mat-select [(value)]="year" matInput [disabled]="disabled" (selectionChange)="valueChanged()">
                <mat-option *ngIf="includeNoneOption" [value]="null">
                    {{ 'common.year' | translate}}
                </mat-option>
                <mat-option *ngFor="let availableYear of availableYears" [value]="availableYear.value">
                {{availableYear.text}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="showMonthInput" [fxFlex]="fxFlexSize" fxFlex.lt-sm="100" >
        <mat-form-field 
            class="date-input" 
            appearance="outline"
            [ngClass]="{'mat-form-field-invalid': !!error}"
        >
            <mat-label>{{ 'common.month' | translate }}</mat-label>
            <mat-select [(value)]="month" matInput [disabled]="disabled" (selectionChange)="valueChanged()">
                <mat-option *ngIf="includeNoneOption" [value]="null">
                    {{ 'common.month' | translate}}
                </mat-option>
                <mat-option *ngFor="let availableMonth of availableMonths" [value]="availableMonth.value">
                {{availableMonth.text}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div [fxFlex]="fxFlexSize" fxFlex.lt-sm="100" >
        <mat-form-field 
            class="date-input" 
            appearance="outline"
            *ngIf="showMonthInput && showDayInput"
            [ngClass]="{'mat-form-field-invalid': !!error}"
        >
            <mat-label>{{ 'common.day' | translate }}</mat-label>
            <mat-select [(value)]="day" matInput [disabled]="disabled" (selectionChange)="valueChanged()">
                <mat-option *ngIf="includeNoneOption" [value]="null">
                    {{ 'common.day' | translate}}
                </mat-option>
                <mat-option *ngFor="let availableday of availabledays" [value]="availableday.value">
                {{availableday.text}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    

    

    <mat-error style="margin-top:-15px;" fxFlex="100" *ngIf="!!error">{{error}}</mat-error>
</div>