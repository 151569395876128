<vex-section
  icon="contact_page"
  title="{{ 'common.contact' | translate }}"
  customCardClass="no-border-radius"
  [hasEditRights]="hasEditRights"
  (clicked)="editContact()"
>
  <p class="contact-main-info">{{ name }}</p>
  <p class="contact-main-info">
    <a [href]="'tel:' + phoneNumber">{{ phoneNumber }}</a>
  </p>
  <p class="contact-section-secondary-info">
    <a [href]="'mailto:' + email">{{ email }}</a>
  </p>

  <p class="contact-section-title">{{ 'common.address' | translate }}</p>
  <p class="contact-section-info">{{ streetName }}</p>
  <p class="contact-section-info" *ngIf="!!city">{{ city }}, {{ state }}</p>
  <p class="contact-section-info">{{ postalCode | mask: POSTAL_CODE_MASK }}</p>
  <p class="contact-section-info">{{ country }}</p>

  <div class="section-title-container">
    <span class="contact-section-title">{{ 'common.website' | translate }} </span>
    &nbsp;<mat-icon class="contact-section-title-icon">launch</mat-icon>
  </div>
  <p class="contact-section-info">
    <a [href]="website">{{ website }}</a>
  </p>
</vex-section>
