import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'vex-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SectionComponent implements OnInit {
  @Input() title: string = '';
  @Input() subtitle: string | null = null;
  @Input() icon: string = '';
  @Input() hideTitle = false;
  @Input() customCardClass: string = '';
  @Input() customTitleClass: string = '';
  @Input() customIconClass: string = '';
  @Input() hideContent = false;
  @Input() hasEditRights = false;
  @Input() actionButtonIcon = 'create';
  @Input() actionButtonClass = 'purple';
  @Output() clicked: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  actionClicked() {
    if (this.hasEditRights) {
      this.clicked.emit();
    }
  }
}
