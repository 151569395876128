export const environment = {
  production: false,
  instructions: 'https://storage.googleapis.com/piicco-documents/',
  pusher: {
    key: '23ad7bf51e27010f7248',
    cluster: 'us2',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDw-2mSsHd1welipd26ZOdBA178rSMVvyo',
    authDomain: 'piicco-dev.firebaseapp.com',
    databaseURL: 'https://piicco-dev.firebaseio.com',
    projectId: 'piicco-dev',
    storageBucket: 'piicco-dev.appspot.com',
    messagingSenderId: '282994484280',
    appId: '1:282994484280:web:0d3ac70d2b69420b1ab2cd',
    measurementId: 'G-3W9BS2DP7M',
  },
  // apiGateway: 'https://profiles-5efbm2a2ha-ue.a.run.app/api/v1' // 'https://piicco-api-gateway-3m07lxko.uc.gateway.dev',
  apiGateway: 'https://piicco-api-gateway-3m07lxko.uc.gateway.dev/',
  apiProfileGateway: 'https://profiles-5efbm2a2ha-ue.a.run.app/api/v1/',

  apiDocumentGateway: 'https://documents-5efbm2a2ha-ue.a.run.app/api/v1',
  apiEvaluationGateway: 'https://evaluations-5efbm2a2ha-ue.a.run.app/api/v1',
  apiConfigurationGateway: 'https://configurations-5efbm2a2ha-ue.a.run.app/api/v1',
  apiOrganizationGateway: 'https://organizations-5efbm2a2ha-ue.a.run.app/api/v1',
  apiNotificationGateway: 'https://notifications-5efbm2a2ha-ue.a.run.app/api/v1',

  terms: {
    fr: 'https://piicco.com/conditions/',
    en: 'https://piicco.com/en/conditions/',
    pt: 'https://piicco.com/pt-pt/condicoes-de-utilizacao/',
  },
  privacy: {
    fr: 'https://piicco.com/confidentialite/',
    en: 'https://piicco.com/en/privacy',
    pt: 'https://piicco.com/pt-pt/politica-de-privacidade/',
  },
  consent: {
    fr: 'https://piicco.com/consentement/',
    en: 'https://piicco.com/en/consent/',
    pt: 'https://piicco.com/pt-pt/consentimento/',
  },
  platform_instructions: {
    fr: 'https://piicco.com/evaluation-360-entraineurs/',
    en: 'https://piicco.com/en/360-evaluation-coaches/',
    pt: 'https://piicco.com/pt-pt/avaliacao-360-treinadores/ ',
  },
  athlete_platform_instructions: {
    fr: 'https://piicco.com/auto-evaluation-athlete/',
    en: 'https://piicco.com/en/360-evaluation-coaches/',
    pt: 'https://piicco.com/pt-pt/avaliacao-360-treinadores/',
  },
  giphyApiKey: 'L6PWi3y3qVPJPZe8UHSsGJZOGi1Sps9c',
  version: '1.8.0',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
