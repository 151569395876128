import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PopoverService } from 'src/@vex/components/popover/popover.service';
import { HelpDropdownComponent } from '../help-dropdown/help-dropdown.component';

@Component({
  selector: 'vex-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;

  constructor(
    public translate: TranslateService,
    private popover: PopoverService
  ) { }

  async ngOnInit() {
  }

  @HostListener('document:click', ['$event'])
  clickout() {
    this.dropdownOpen = false;
  }

  showPopover() {
    this.dropdownOpen = true;

    const popoverRef = this.popover.open({
      content: HelpDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      //this.cd.markForCheck();
    });
  }

}
