<vex-dialog-header title="{{ 'components.contact.edit' | translate }}" titleIcon="contact_page"></vex-dialog-header>
<mat-dialog-content>
  <form [formGroup]="form" (keyup.enter)="save()" fxLayout="row wrap">
    <mat-form-field fxFlex="70%" appearance="outline">
      <mat-label>{{ 'common.name' | translate }}</mat-label>
      <input id="name" formControlName="name" matInput required />
      <mat-error *ngIf="name.hasError('required') && name.touched">{{
        'components.about.about-text.required' | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="70%" appearance="outline">
      <mat-label>{{ 'common.phoneNumber' | translate }}</mat-label>
      <input id="phoneNumber" formControlName="phoneNumber" matInput />
      <mat-error *ngIf="form.get('phoneNumber').hasError('pattern')">{{ 'validations.phone-number' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="70%" appearance="outline">
      <mat-label>{{ 'common.email' | translate }}</mat-label>
      <input id="contactemail" formControlName="email" matInput />
      <mat-error *ngIf="form.get('email').hasError('pattern')">{{ 'validations.email' | translate }}</mat-error>
    </mat-form-field>

    <div fxFlex="70%" fxLayout="row wrap">
      <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label>{{ 'common.street-name' | translate }}</mat-label>
        <input id="streetName" formControlName="streetName" matInput />
      </mat-form-field>

      <mat-form-field fxFlexOffset="5%" fxFlex="45%" appearance="outline">
        <mat-label>{{ 'common.city' | translate }}</mat-label>
        <input id="contactCity" formControlName="city" matInput />
      </mat-form-field>
    </div>

    <div fxFlex="70%" fxLayout="row wrap">
      <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label>{{ 'common.postal-code' | translate }}</mat-label>
        <input id="postalCode" formControlName="postalCode" matInput [mask]="POSTAL_CODE_MASK" />
        <mat-error *ngIf="postalCode.hasError('pattern') && postalCode.touched">{{
          'validations.postal-code-pattern' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field fxFlexOffset="5%" fxFlex="45%" appearance="outline">
        <mat-label>{{ 'common.state' | translate }}</mat-label>
        <input id="state" formControlName="state" matInput />
      </mat-form-field>
    </div>

    <mat-form-field fxFlex="70%" appearance="outline">
      <mat-label>{{ 'common.country' | translate }}</mat-label>
      <input id="country" formControlName="country" matInput />
      <mat-error *ngIf="country.hasError('pattern') && country.touched">{{
        'validations.country-pattern' | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="70%" appearance="outline">
      <mat-label>{{ 'common.website' | translate }}</mat-label>
      <input id="website" formControlName="website" matInput />
      <mat-error *ngIf="website.hasError('pattern') && website.touched">{{
        'validations.website-pattern' | translate
      }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<vex-dialog-actions
  #actions
  [acceptDisabled]="submitDisabled"
  [cancelDisabled]="isSaving"
  acceptButtonText="{{ 'common.save-changes' | translate }}"
  cancelButtonText="{{ 'common.cancel' | translate }}"
  (accept)="save()"
  (cancel)="close()"
  [saving]="isSaving"
>
</vex-dialog-actions>
