<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content>
    <mat-tab-group>
      <mat-tab label="{{ 'calendar.details' | translate }}">
        <div class="m-3" fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'calendar.title' | translate }}</mat-label>
            <input id="title" formControlName="title" matInput />
            <mat-error *ngIf="form.get('title').hasError('required')">{{ 'calendar.errors.required' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'calendar.desc' | translate }}</mat-label>
            <input id="description" formControlName="description" matInput />
            <mat-error *ngIf="form.get('description').hasError('required')">{{
              'calendar.errors.required' | translate
            }}</mat-error>
          </mat-form-field>

          <div fxLayout="row" fxLayoutGap="16px">
            <mat-form-field fxFlex="50" appearance="outline">
              <mat-label>{{ 'calendar.organisation' | translate }}</mat-label>
              <mat-select formControlName="organisationId">
                <mat-option *ngFor="let org of data.organisations" [value]="org.id">
                  {{ org.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="50" appearance="outline">
              <mat-label>{{ 'components.calendar.eventType' | translate }}</mat-label>
              <mat-select formControlName="eventType">
                <mat-option *ngFor="let et of data.eventTypes" [value]="et.code">
                  {{ eventTypesLabel(et) }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('eventType').hasError('required')">{{
                'calendar.errors.required' | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'calendar.location' | translate }}</mat-label>
            <input id="location" formControlName="location" matInput />
            <mat-error *ngIf="form.get('location').hasError('required')">{{ 'calendar.errors.required' | translate }}</mat-error>
          </mat-form-field>

          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="flex-start center">
            <div class="pb-3">
              <mat-icon>schedule</mat-icon>
            </div>
            <div fxLayout="column" fxFlex="100">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'calendar.startDate' | translate }}</mat-label>
                <input
                  matInput
                  [ngxMatDatetimePicker]="pickerStartDate"
                  placeholder="Choose a date"
                  formControlName="startDate"
                  (dateChange)="calcEndDateFromStart($event)"
                />
                <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #pickerStartDate
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [stepHour]="1"
                  [stepMinute]="1"
                  [touchUi]="true"
                  [hideTime]="false"
                >
                  <!-- Custom icon or text of Apply icon -->
                  <ng-template>
                    <!-- <mat-icon>star</mat-icon> -->
                    <span>OK</span>
                  </ng-template>
                </ngx-mat-datetime-picker>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{ 'calendar.duration' | translate }}</mat-label>
                <mat-select formControlName="duration">
                  <mat-option
                    *ngFor="let duration of durations"
                    [value]="duration.value"
                    (onSelectionChange)="calcEndDate($event)"
                  >
                    {{ duration.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <ng-container *ngIf="!data.selectedEvent">
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="flex-start center">
              <div class="pb-3">
                <mat-icon>autorenew</mat-icon>
              </div>

              <mat-form-field appearance="outline">
                <mat-label>{{ 'calendar.reccurence' | translate }}</mat-label>
                <mat-select formControlName="recurrence">
                  <mat-option *ngFor="let rec of recurrenceTypes" [value]="rec.code">
                    {{ reccurenceLabel(rec) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div *ngIf="form.get('recurrence').value === 'Weekly'">
              {{ 'calendar.repeatWeekly' | translate }} {{ dayOfWeek(form.get('startDate').value) }}
            </div>

            <div *ngIf="form.get('recurrence').value === 'Monthly'">
              {{ 'calendar.repeatMonthly' | translate: { day: form.get('startDate').value.getDate() } }}
            </div>

            <mat-form-field appearance="outline" *ngIf="form.get('recurrence').value !== 'None'">
              <mat-label>{{ 'calendar.repeatUntil' | translate }}</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="repeatUntil" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <!--              <mat-label>{{ 'calendar.repeatUntil' | translate }}</mat-label>-->
              <!--              <input matInput [ngxMatDatetimePicker]="pickerUntilDate" placeholder="Choose a date" formControlName="repeatUntil"-->
              <!--              >-->
              <!--              <mat-datepicker-toggle matSuffix [for]="pickerUntilDate"></mat-datepicker-toggle>-->
              <!--              <ngx-mat-datetime-picker #pickerUntilDate [showSpinners]="true" [showSeconds]="false" [stepHour]="1"-->
              <!--                                       [stepMinute]="1"  [touchUi]="true"-->
              <!--                                       [hideTime]="true">-->
              <!--                &lt;!&ndash; Custom icon or text of Apply icon &ndash;&gt;-->
              <!--                <ng-template>-->
              <!--                  &lt;!&ndash; <mat-icon>star</mat-icon> &ndash;&gt;-->
              <!--                  <span>OK</span>-->
              <!--                </ng-template>-->
              <!--              </ngx-mat-datetime-picker>-->
              <mat-error *ngIf="form.get('repeatUntil').hasError('required')">{{
                'calendar.errors.required' | translate
              }}</mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </mat-tab>

      <mat-tab label="{{ 'calendar.attendees' | translate }}">
        <vex-attendees
          [organisationId]="form.get('organisationId').value"
          [selectedAttendees]="attendees"
          (changed)="invitationsChanged($event)"
        ></vex-attendees>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="end start">
    <button mat-stroked-button class="ml-2 mr-2" mat-button type="button" (click)="cancel()">
      {{ 'calendar.cancel' | translate }}
    </button>
    <button mat-stroked-button class="ml-2 mr-2" mat-button type="submit" [disabled]="form.invalid">
      {{ 'calendar.save' | translate }}
    </button>
  </mat-dialog-actions>
</form>

<mat-progress-spinner *ngIf="saving" class="spinner" mode="indeterminate"></mat-progress-spinner>
