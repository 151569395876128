<vex-dialog-header title="{{ 'common.my-header' | translate }}" titleIcon="view_headline"></vex-dialog-header>
<mat-dialog-content>
  <form [formGroup]="form" (keyup.enter)="save()" fxLayout="row wrap">
    <mat-form-field fxFlex="70" fxFlex.lt-sm="100" appearance="outline">
      <mat-label>{{ 'common.first-name' | translate }}</mat-label>
      <input id="firstName" formControlName="firstName" matInput required />
    </mat-form-field>

    <mat-form-field fxFlex="70" fxFlex.lt-sm="100" appearance="outline">
      <mat-label>{{ 'common.last-name' | translate }}</mat-label>
      <input id="lastName" formControlName="lastName" matInput required />
    </mat-form-field>

    <mat-form-field fxFlex="70" fxFlex.lt-sm="100" appearance="outline">
      <mat-label>{{ 'common.club-or-organization' | translate }}</mat-label>
      <input id="organizationId" type="text" matInput formControlName="organizationId" [matAutocomplete]="auto" required />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let organization of filteredAvailableOrganizations$ | async" [value]="organization">
          <div class="search-result" fxLayout="row">
            <img
              class="avatar"
              [src]="getProfileImage(organization.name, TargetedEntity.Organization, organization.logo)"
              (error)="getDefaultImage($event, organization.name, TargetedEntity.Organization)"
            />
            <div class="ml-1">{{ organization.name }}</div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field fxFlex="70" fxFlex.lt-sm="100" appearance="outline">
      <mat-label>{{ 'common.category' | translate }}</mat-label>
      <mat-select formControlName="category" matInput>
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let category of availableCategories$ | async" [value]="toStr(category)">
          {{ category[userLang] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="70" fxFlex.lt-sm="100" class="date-input" appearance="outline">
      <mat-label>{{ 'common.gender' | translate }}</mat-label>
      <mat-select formControlName="gender" matInput>
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let gender of availableGenders" [value]="gender.value">
          {{ translate.instant(gender.text) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="70" fxFlex.lt-sm="100" appearance="outline">
      <mat-label>{{ 'common.level-or-league' | translate }}</mat-label>
      <mat-select formControlName="class" matInput>
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let class of availableClasses$ | async" [value]="toStr(class)">
          {{ class[userLang] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="70" fxFlex.lt-sm="100" appearance="outline">
      <mat-label>{{ 'common.position' | translate }}</mat-label>
      <mat-select formControlName="position" matInput>
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let position of availablePositions$ | async" [value]="toStr(position)">
          {{ position[userLang] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="70" fxFlex.lt-sm="100" appearance="outline">
      <mat-label>{{ 'common.jersey-number' | translate }}</mat-label>
      <input id="jerseyNumber" type="number" formControlName="jerseyNumber" matInput />
    </mat-form-field>

    <mat-form-field fxFlex="70" fxFlex.lt-sm="100" appearance="outline">
      <mat-label>{{ 'common.title-or-league' | translate }}</mat-label>
      <input id="title" formControlName="title" matInput />
    </mat-form-field>

    <mat-form-field fxFlex="70" fxFlex.lt-sm="100" appearance="outline">
      <mat-label>{{ 'common.city-region-country' | translate }}</mat-label>
      <input id="currentCity" formControlName="city" matInput />
    </mat-form-field>
  </form>
</mat-dialog-content>
<vex-dialog-actions
  #actions
  [acceptDisabled]="submitDisabled"
  [cancelDisabled]="isSaving"
  acceptButtonText="{{ 'common.save-changes' | translate }}"
  cancelButtonText="{{ 'common.cancel' | translate }}"
  (accept)="save()"
  (cancel)="close()"
  [saving]="isSaving"
>
</vex-dialog-actions>
