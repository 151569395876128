import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from 'src/app/shared/models/dialog.model';

@Injectable({
  providedIn: 'root',
})
export class InformationDialogService {
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;

  constructor(public translate: TranslateService, private dialog: MatDialog) {}

  open(data: ConfirmationDialogData, autoCloseOnAcceptEvent = false) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: data,
    });

    if (autoCloseOnAcceptEvent) {
      this.dialogRef.componentInstance.accepted.subscribe(() => {
        this.dialogRef.close();
      });
    }

    return this.dialogRef;
  }

  close() {
    if (!!this.dialogRef) {
      this.dialogRef.close();
    }
  }

  getDialog() {
    if (!!this.dialogRef) {
      return this.dialogRef;
    }
  }
}
