

<div class="m-1" >
    <mat-card matRipple id="piicco-select-box" (click)="clickEvent()" class="piicco-select-box-card" style="position: relative;">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill>
            <mat-form-field style="display:none;" class="test" fxFlex="100" appearance="fill">
                <mat-label>a</mat-label>
                <input [min]="minDate" matInput [matDatepicker]="picker" [(ngModel)]="value" (dateChange)="dateChanged($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker  touchUi="true" #picker></mat-datepicker>
            </mat-form-field>


            <h1 fxFlex="100" class="piicco-datepicker-date">{{date ? (date | date: getDateFormat() : '' : translate.currentLang ) : ('piicco-date-picker.label' | translate)}}</h1>

            <mat-icon color="primary" class="piicco-datepicker-chevron">
                today
            </mat-icon>
        </div>
    </mat-card>
</div>
