import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { PiiccoSelectBoxItem } from '../../models/piicco-selectbox.model';

@Component({
  selector: 'vex-piicco-date-picker',
  templateUrl: './piicco-date-picker.component.html',
  styleUrls: ['./piicco-date-picker.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class PiiccoDatePickerComponent implements OnInit {
  @Input() minDate: Date;
  @Input() date: Date|null;
  @Output() dateChange = new EventEmitter<Date>();

  @ViewChild('picker') input: any;

  value: Date|null = null;

  constructor(
    private profilesService: ProfilesService,
    public translate: TranslateService 
  ) { }

  ngOnInit(): void {
  }

  clickEvent() {
    this.input.open();
  }

  dateChanged(event) {
    this.dateChange.emit(this.value);
  }

  ngDoCheck(){
    var widthButton=document.getElementById("piicco-select-box").offsetWidth;
    var elems = document.getElementsByClassName('mat-menu-panel') as HTMLCollectionOf<HTMLElement>;
    for(var i = 0; i < elems.length; i++) {
      elems[i].style.width = widthButton+"px";
    }
  }

  getDateFormat() {
    if(this.profilesService.getUserLang() === "fr") {
      return 'EEE dd MMM yyyy';
    }

    return 'EEE MMM dd yyyy';
  }

}
