import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {PiiccoChat, PiiccoChatMessage, PiiccoChatResult, PiiccoChats, SendChatGifMessage, SendChatImageMessage} from '../models/chats';
import {Evaluation} from '../../evaluation/models/evaluation.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  idReceived: EventEmitter<{ friend: string, chat: PiiccoChat }> = new EventEmitter<{ friend: string, chat: PiiccoChat }>();

  private _unreadPersonal: number;
  private _unreadOrg: number;
  public chatCountsUpdated: EventEmitter<any> = new EventEmitter();

  public chats: PiiccoChats;

  public selectedChatId: string|undefined;

  constructor(
    private http: HttpClient,
  ) {
  }

  set unreadPersonal(count) {
    this._unreadPersonal = count;
    this.chatCountsUpdated.emit();
  }

  get unreadPersonal() {
    return isNaN(this._unreadPersonal) ? 0 : this._unreadPersonal;
  }

  set unreadOrg(count) {
    this._unreadOrg = count;
    this.chatCountsUpdated.emit();
  }

  get unreadOrg() {
    return isNaN(this._unreadOrg) ? 0 : this._unreadOrg;
  }

  async fetchChats(): Promise<PiiccoChats> {
    return new Promise<PiiccoChats>((res, rej) => {
      this.http.get<PiiccoChats>(
        `${environment.apiGateway}chats/`
      ).take(1).subscribe(chats => {
          this.chats = chats;
          this._unreadPersonal = isNaN(chats.unreadProfileChats) ? 0 : chats.unreadProfileChats;
          this._unreadOrg = isNaN(chats.unreadOrganizationChats) ? 0 : chats.unreadOrganizationChats;;
          this.chatCountsUpdated.emit();
          res(chats);
        },
        (err) => {
          res(null);
        });
    });

  }

  async fetchChat(chatId: string): Promise<PiiccoChatResult> {
    return new Promise<PiiccoChatResult>((res, rej) => {
      this.http.get<PiiccoChatResult>(
        `${environment.apiGateway}chats/oneonone/${chatId}?page=1&pageSize=200`
      ).take(1).subscribe(chat => {
          res(chat);
        },
        (err) => {
          res(null);
        });
    });
  }

  async fetchOrgChat(chatId: string): Promise<PiiccoChatResult> {
    return new Promise<PiiccoChatResult>((res, rej) => {
      this.http.get<PiiccoChatResult>(
        `${environment.apiGateway}chats/organization/${chatId}?page=1&pageSize=200`
      ).take(1).subscribe(chat => {
          res(chat);
        },
        (err) => {
          res(null);
        });
    });
  }

  async sendMessage(message: string, destination: string) {
    return new Promise<any>((res, rej) => {
      return this.http.put(`${environment.apiGateway}chats/oneonone/send/${destination}`, {message}).take(1).subscribe(result => {
        this.idReceived.emit({friend: destination, chat: result});
        res(result);
      });
    });
  }

  async sendImageMessage(destination: string, image: SendChatImageMessage) {
    return new Promise<any>((res, rej) => {
      return this.http.put(
        `${environment.apiGateway}chats/oneonone/image/send/${destination}`,
        image
      ).take(1).subscribe(result => {
        this.idReceived.emit({friend: destination, chat: result});
        res(result);
      });
    });
  }

  async sendGifMessage(destination: string, gif: SendChatGifMessage) {
    return new Promise<any>((res, rej) => {
      return this.http.put(
        `${environment.apiGateway}chats/oneonone/image/send/${destination}`,
        gif
      ).take(1).subscribe(result => {
        this.idReceived.emit({friend: destination, chat: result});
        res(result);
      });
    });
  }

  async sendOrgMessage(message: string, destination: string) {
    return new Promise<any>((res, rej) => {
      return this.http.put(`${environment.apiGateway}chats/organization/send/${destination}`, {message}).take(1).subscribe(result => {
        this.idReceived.emit({friend: destination, chat: result});
        res(result);
      });
    });
  }

  async sendOrgImageMessage(destination: string, image: SendChatImageMessage) {
    return new Promise<any>((res, rej) => {
      return this.http.put(
        `${environment.apiGateway}chats/organization/image/send/${destination}`,
        image
      ).take(1).subscribe(result => {
        this.idReceived.emit({friend: destination, chat: result});
        res(result);
      });
    });
  }

  async sendOrgGifMessage(destination: string, gif: SendChatGifMessage) {
    return new Promise<any>((res, rej) => {
      return this.http.put(
        `${environment.apiGateway}chats/organization/image/send/${destination}`,
        gif
      ).take(1).subscribe(result => {
        this.idReceived.emit({friend: destination, chat: result});
        res(result);
      });
    });
  }

  deleteChat(id: string) {
    this.http.put(`${environment.apiGateway}chats/oneonone/delete/${id}`, {}).take(1).subscribe();
  }

  deleteOrgChat(id: string) {
    this.http.put(`${environment.apiGateway}chats/organization/delete/${id}`, {}).take(1).subscribe();
  }

  markAsReadProfile(id: string) {
    this.http.put(`${environment.apiGateway}chats/oneonone/views/${id}`, {}).take(1).subscribe();
    const chat = this.chats.profileChats.filter(c => c.id === id);
    console.log('markAsReadProfile', chat);
    if (chat && chat.length > 0) {
      this._unreadPersonal = this._unreadPersonal - chat[0].unreadMessages;
      chat[0].unreadMessages = 0 ;
      this.chatCountsUpdated.emit();
    }
  }

  markAsReadOrg(id: string) {
    // /chats/organization/views/6047f2d92a6524d763393773
    this.http.put(`${environment.apiGateway}chats/organization/views/${id}`, {}).take(1).subscribe();
    const chat = this.chats.organizationChats.filter(c => c.id === id);
    console.log('markAsReadOrg', chat);
    if (chat && chat.length > 0) {
      this._unreadOrg = this._unreadOrg - chat[0].unreadMessages;
      chat[0].unreadMessages = 0 ;
      this.chatCountsUpdated.emit();
    }
  }

}
