import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsDialogComponent } from './components/terms/terms-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@visurel/iconify-angular';



@NgModule({
  declarations: [TermsDialogComponent],
  imports: [
    CommonModule,
    MatIconModule,
    IconModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild()
  ]
})
export class GlobalModule { }
