import { Inject, Injectable } from '@angular/core';
import { Effect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  ChangeLanguageAction,
  SetGlobalErrorMessageAction,
  SetGlobalSuccessMessageAction,
  SetLanguageAction,
} from './global.actions';
import { State } from '..';
import notify from 'devextreme/ui/notify';
import { lang } from 'moment';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { APP_LOCAL_STORAGE_LANG_KEY } from 'src/app/core/config/application.config';

@Injectable()
export class GlobalEffects {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private translateService: TranslateService,
    @Inject('Window') private window: Window,
    private translate: TranslateService,
    private profilesService: ProfilesService
  ) {}

  @Effect()
  initLang$ = this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    map(() => {
      let language;

      try {
        language = this.window.localStorage.getItem(APP_LOCAL_STORAGE_LANG_KEY);
      } catch {}

      if (language && language !== 'null') {
        return [SetLanguageAction({ language })];
      }
      language = 'fr';
      return [SetLanguageAction({ language })];
    }),
    switchMap((actions) => actions)
  );

  @Effect({ dispatch: false })
  setLanguage$ = this.actions$.pipe(
    ofType(SetLanguageAction),
    filter(({ language }) => this.translateService.currentLang !== language && !!language),
    tap(({ language }) => this.window.localStorage.setItem(APP_LOCAL_STORAGE_LANG_KEY, language)),
    switchMap(({ language }) => this.translateService.use(language))
  );

  @Effect({ dispatch: false })
  globalError$ = this.actions$.pipe(
    ofType(SetGlobalErrorMessageAction),
    tap(({ translationKey }) => {
      notify(this.translate.instant(translationKey), 'error');
    })
  );

  @Effect({ dispatch: false })
  globalSuccess$ = this.actions$.pipe(
    ofType(SetGlobalSuccessMessageAction),
    tap(({ translationKey }) => {
      notify(this.translate.instant(translationKey), 'success');
    })
  );

  @Effect()
  changeLanguage$ = this.actions$.pipe(
    ofType(ChangeLanguageAction),
    switchMap(({ preferences, userId }) =>
      this.profilesService
        .updateProfilePreferences(userId, preferences)
        .pipe(switchMap((response) => [SetLanguageAction({ language: preferences.language })]))
    )
  );
}
