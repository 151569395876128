<vex-dialog-header
    title="{{'components.about.edit' | translate}}"
    titleIcon="article"
></vex-dialog-header>
<mat-dialog-content >
    <form [formGroup]="form" fxLayout="row wrap">
        <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>{{ 'common.about' | translate }}</mat-label>
            <textarea rows="5" formControlName="about" matInput></textarea>
        </mat-form-field>
        <vex-list-field
            *ngIf="data.targetedEntity === TargetedEntity.Profile"
            fxFlex="70"
            [(data)]="nationalitiesData"
            [availableData]="availableNationalities"
            title="{{'common.nationalities' | translate}}"
            imagePath="assets\img\flags"
        ></vex-list-field>
    </form>
</mat-dialog-content>
<vex-dialog-actions
    #actions
    [acceptDisabled]="submitDisabled"
    [cancelDisabled]="saving"
    acceptButtonText="{{'common.save-changes' | translate}}"
    cancelButtonText="{{'common.cancel' | translate}}"
    (accept)="save()"
    (cancel)="close()"
    [saving]="saving"
>

</vex-dialog-actions>