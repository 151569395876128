import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditChildRightsDialogData } from '../../models/dialog.model';

@Component({
    selector: 'vex-edit-child-rights-dialog',
    templateUrl: './edit-child-rights-dialog.component.html',
    styleUrls: ['./edit-child-rights-dialog.component.scss'],
})
export class EditChildRightsDialogComponent implements OnInit {
    private destroy$ = new Subject();

    isLoading = false;

    enableNetworkPost = true;
    enableNetworkRelations = true;
    enableChat = true;
    enableEventRSVP = true;

    constructor(
        public profilesService: ProfilesService,
        public translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: EditChildRightsDialogData,
        public dialogRef: MatDialogRef<EditChildRightsDialogComponent>
    ) {}

    ngOnInit() {
        if (this.data.profile.userPreferences?.userRights) {
            const rights = this.data.profile.userPreferences.userRights;

            this.enableChat = rights.enableChat;
            this.enableNetworkRelations = rights.enableNetworkRelations;
            this.enableNetworkPost = rights.enableNetworkPost;
            this.enableEventRSVP = rights.enableEventRSVP;
        }
    }

    async save(event?) {
        if (event) {
            event.preventDefault();
        }
        if (!this.isLoading) {
            this.isLoading = true;
            try {
                const user = await this.profilesService.updateChildRights(this.data.profile.id, {
                    enableChat: this.enableChat,
                    enableEventRSVP: this.enableEventRSVP,
                    enableNetworkPost: this.enableNetworkPost,
                    enableNetworkRelations: this.enableNetworkRelations,
                });

                this.isLoading = false;
                this.dialogRef.close();
            } catch (error) {
                this.isLoading = false;
            }
        }
    }
}
