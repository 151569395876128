import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { ObserverComponent } from 'src/app/core/components/observer.component';
import { GifData } from 'src/app/core/models/giphy.model';
import { GiphyService } from 'src/app/core/services/giphy.service';
import { GiphySelectedGif } from '../../models/dialog.model';

@Component({
  selector: 'vex-giphy-dialog',
  templateUrl: './giphy-dialog.component.html',
  styleUrls: ['./giphy-dialog.component.scss']
})
export class GiphyDialogComponent extends ObserverComponent implements OnInit {
  @Input() show: boolean;

  @Output() gifSelected = new EventEmitter<GiphySelectedGif>();
  @Output() hideDialog = new EventEmitter<void>();

  gifPerPage = 20;
  currentPage = 1;

  results: GifData[];
  search = new FormControl();
  
  constructor(
    private giphyService: GiphyService,
    private eRef: ElementRef
  ) { 
    super();

    this.search.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(async(val) => {
        this.results = await this.giphyService.search(val, this.currentPage, this.gifPerPage);
      })
    ).subscribe();
  }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target) && this.show) {
      this.hideDialog.emit();
    }
  }

  async onScroll() {
    this.currentPage++;

    this.results.push(...await this.giphyService.search(this.search.value, this.currentPage, this.gifPerPage))
  }

  selectGif(gif: GifData) {
    console.log(gif)
    this.gifSelected.emit({
      imageUrl: gif.images.original.url,
      thumbnailUrl: gif.images.fixed_height.url
    });

    this.search.setValue("");

  }

}
