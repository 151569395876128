<div>
    <h2>{{'calendar.invitations' | translate}}</h2>
    <div fxLayout="row wrap">
        <div fxFlex="80" fxFlexOffset="10" fxFlex.lt-md="100" fxLayoutAlign="end center" fxLayoutAlign.lt-md="center center">
            <button 
                mat-stroked-button
                class="rounded-text-button ml-2 mr-2"
                mat-button 
                type="button"
                (click)="selectAll()"
            >
                {{ selectAllButtonText }}
            </button>
        </div>
        <!--<vex-loader [isLoading]="isLoading"></vex-loader>-->
        <div
          *ngFor="let profile of availableProfiles"
          class="attendees-target-card m-2"
          fxFlex="80"
          fxFlexOffset="10"
          fxFlex.lt-md="100"
        >
            <div fxLayout="row wrap" fxLayoutAlign="start center">
                <div fxFlex="60" fxFlex.lt-md="100" fxLayout="row wrap">
                    <img 
                        mat-card-avatar 
                        class="profile-card-avatar" 
                        [src]="getProfileImage(getFullName(profile), TargetedEntity.Profile, profile.photo)" 
                        (error)="getDefaultImage($event, getFullName(profile), TargetedEntity.Profile)"
                    />
                    <div class="target-title">
                        <span class="name">{{profile.firstName}} {{profile.lastName}}</span> <br/>
                    </div>
                </div>
                <div fxFlex=40 fxFlex.lt-md="100" >
                  <div fxFlex="80" fxFlexOffset="20" fxLayoutAlign="center center">
                    <div>
                        <button 
                            *ngIf="isSelected(profile.id)"
                            mat-flat-button
                            color="primary"
                            class="rounded-button ml-2 mr-2 pl-10 pr-10"
                            mat-button 
                            type="button"
                            (click)="select(profile)"
                        >
                            <mat-icon style="height: unset">
                                done
                            </mat-icon>    
                        </button>
        
                        <button 
                            *ngIf="!isSelected(profile.id)"
                            mat-stroked-button
                            class="rounded-text-button ml-2 mr-2"
                            mat-button 
                            type="button"
                            (click)="select(profile)"
                        >
                            {{ 'common.select' | translate }}
        
                        </button>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div fxFlex="100" fxLayoutAlign="end center">
            <label>{{'common.selected' | translate}} ({{selectedProfiles.length}})</label>
        </div>
    </div>
</div>


