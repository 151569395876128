<div fxLayout="row wrap">
  <div fxFlex="100" fxLayoutAlign="end center" fxLayoutAlign.lt-md="center center">
    <button mat-stroked-button class="rounded-text-button mt-2 ml-2 mr-2" mat-button type="button" (click)="selectAll()">
      {{ selectAllButtonText }}
    </button>
  </div>
  <h3 class="mb-2 mt-2" fxFlex="100" *ngIf="!!sectionTitle">{{ sectionTitle }}</h3>
  <vex-loader fxFlex="100" [isLoading]="isLoading"></vex-loader>
  <div class="mb-5" *ngIf="availableProfileList.length === 0 && !isLoading" fxLayout="row wrap">
    <p class="ml-2">{{ noProfileAvailableMsg }}</p>
  </div>
  <vex-profile-action-card
    fxFlex="50"
    fxFlex.lt-sm="100"
    *ngFor="let profile of availableProfileList"
    [name]="profile.firstName + ' ' + profile.lastName"
    [profilePicture]="profile.photo"
    [id]="profile.id"
    [clickable]="false"
  >
    <div fxFlex="80" fxFlexOffset="20" fxLayoutAlign="center center">
      <div>
        <button
          *ngIf="isSelected(profile.id)"
          mat-flat-button
          color="primary"
          class="rounded-button ml-2 mr-2 pl-10 pr-10"
          mat-button
          type="button"
          (click)="select($event, profile.id)"
        >
          <mat-icon style="height: unset"> done </mat-icon>
        </button>

        <button
          *ngIf="!isSelected(profile.id)"
          mat-stroked-button
          class="rounded-text-button ml-2 mr-2"
          mat-button
          type="button"
          (click)="select($event, profile.id)"
        >
          {{ 'common.select' | translate }}
        </button>
      </div>
    </div>
  </vex-profile-action-card>

  <div fxFlex="100" fxLayoutAlign="end center">
    <label>{{ 'common.selected' | translate }} ({{ selectedProfilesId.length }})</label>
  </div>
</div>
