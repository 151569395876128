import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import emojiEn from 'src/assets/i18n/emoji.en.json';
import emojiFr from 'src/assets/i18n/emoji.fr.json';


@Component({
  selector: 'vex-emoji-dialog',
  templateUrl: './emoji-dialog.component.html',
  styleUrls: ['./emoji-dialog.component.scss']
})
export class EmojiDialogComponent implements OnInit {
  @Input() show: boolean;

  @Output() hideDialog = new EventEmitter<void>();
  @Output() emojiSelected = new EventEmitter<string>();


  constructor(
    private eRef: ElementRef,
    private profilesService: ProfilesService
  ) { }

  ngOnInit(): void {
  }

  addEmoji(e){
    this.emojiSelected.emit(e.emoji.native + ' ');
    this.hideDialog.emit();
  }
  
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target) && this.show) {
      this.hideDialog.emit();
    }
  }

  getTranslations() {
    if(this.profilesService.getUserLang() === "fr") {
      return emojiFr;
    }

    return emojiEn;
  }
}
