<vex-dialog-header
    title="{{'force-password-change-dialog.title' | translate}}"
    [hideIcon]="true"
></vex-dialog-header>
    <div [formGroup]="form" (keyup.enter)="submit()" fxLayout="column" >
        <div fxFlex="auto" fxLayout="column">
          <mat-form-field [ngClass]="{'mb-10': password.hasError('pattern')}" fxFlex="grow">
            <mat-label>{{ 'pages.auth.register.form.password.label' | translate }}</mat-label>
            <input id="password" [type]="passwordVisibile ? 'text' : 'password'" formControlName="password" autocomplete="off" matInput>
            <button (click)="passwordVisibile = !passwordVisibile" mat-icon-button matSuffix type="button">
              <mat-icon [icIcon]="passwordVisibile ? icVisibility : icVisibilityOff"></mat-icon>
            </button>
            <mat-error *ngIf="password.hasError('pattern')">{{ 'validations.password' | translate }}</mat-error>

          </mat-form-field>
  
          <mat-form-field fxFlex="grow">
            <mat-label>{{ 'pages.auth.register.form.confirmPassword.label' | translate }}</mat-label>
            <input id="passwordConfirmation" [type]="confirmPasswordVisibile ? 'text' : 'password'" formControlName="passwordConfirm" autocomplete="off" matInput>
            <button (click)="confirmPasswordVisibile = !confirmPasswordVisibile" mat-icon-button matSuffix type="button">
              <mat-icon [icIcon]="confirmPasswordVisibile ? icVisibility : icVisibilityOff"></mat-icon>
            </button>
            
            <mat-error *ngIf="passwordConfirm.hasError('passwordNotMatch')" >{{ 'pages.auth.password-doesnt-match' | translate}}</mat-error>

          </mat-form-field>
        </div>

        <vex-loader [isLoading]="isLoading"></vex-loader>
  
    </div>

<vex-dialog-actions
    #actions
    [acceptButtonText]="translate.instant('force-password-change-dialog.save-button-label')"
    [cancelButtonText]="translate.instant('force-password-change-dialog.logout-button-label')"
    (accept)="submit()"
    (cancel)="logoutAndCloseDialog()"
    [saving]="isSaving"
>
</vex-dialog-actions>