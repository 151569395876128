<div class="container py-gutter">
  <div fxLayout="row">
    <div fxLayout="row" *ngIf="!!navigationService.currentNavigationOrg">
      <img
        class="avatar border-3 border-white"
        [src]="navigationService.currentNavigationOrg.logo ? navigationService.currentNavigationOrg.logo :'/assets/img/avatars/generic_logo_placeholde.png'"
      >
      <h1>{{navigationService.currentNavigationOrg.name}}</h1>
    </div>

  </div>
  <h1 class="mt-8">{{'components.organization-selection.search' | translate }}</h1>
  <div fxLayout="column">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap="2%"
         fxLayoutGap.lt-md="0%">
      <mat-form-field fxFlex="100" fxFlex.lt-md="100" appearance="outline" class="mt-2">
        <input
          id="search" 
          #searchInput
          [formControl]="searchCtrl"
          placeholder="{{'common.search' | translate}}"
          matInput
        />
      </mat-form-field>
    </div>
    <vex-organization-card
      *ngFor="let org of organizations"
      [id]="org.id"
      [name]="org.name"
      [image]="org.logo"
      [city]="org.city"
      [lowerCaseName]="true"
      (selected)="selectOrg(org.id)"
      [showNetworkButtons]="false"
    ></vex-organization-card>
  </div>
</div>
