import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { base64ToFile, CropperPosition, Dimensions, ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { PROFILE_IMAGES_FORMAT } from 'src/app/core/config/file-format.config';
import { ImageCropperDialogData } from '../../models/dialog.model';
import { ImageCropperConfiguration } from '../../models/image-cropper.model';

@Component({
  selector: 'vex-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  cropperConfiguration: ImageCropperConfiguration = {
    aspectRatio: 'portrait',
    width: 600,
    format: 'png' 
  };

  private readonly IMAGE_SIZE = 400;

    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};
    isLoaded = false;

  ready: boolean;
  readyToLoad = false;
  loadResizedPhoto = false;
  img;

  constructor(
    public dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageCropperDialogData,
    public translate: TranslateService
  ) {
  }

  async ngOnInit() {
    await this.resizeImageIfNeeded();

    if(this.data.cropperConfig) {
      this.cropperConfiguration = {
        ...this.cropperConfiguration,
        format: this.data.cropperConfig.format || this.cropperConfiguration.format,
        aspectRatio: 'landscape' || this.cropperConfiguration.aspectRatio,
        width: this.data.cropperConfig.width || this.cropperConfiguration.width,
      }
       }
  }

  async resizeImageIfNeeded() {
    return new Promise(res =>{

      const reader = new FileReader();

      reader.onload = (e) => {
        image.src = reader.result as string;
      };

      if(this.data.event.target.files[0]) {
        reader.readAsDataURL(this.data.event.target.files[0])
      }

      const image = new Image;

      image.onload = () => {
        const elem = document.createElement('canvas');
        const ctx = elem.getContext('2d');

        if(image.width < this.IMAGE_SIZE) {
          elem.width = 800;
          elem.height = 400;
  
          ctx.drawImage(image,(400 - (image.width /2)),(200 - (image.height /2)), image.width,  image.height  );
          const data = ctx.canvas.toDataURL();
          this.img = data;
          this.loadResizedPhoto = true;
        } else {
          elem.width = 800;
          elem.height = 400;

          if(image.width > image.height) {
            const scale = image.width / image.height;

            const imgW =  this.IMAGE_SIZE;
            const imgH =  this.IMAGE_SIZE / scale;

            ctx.drawImage(image,(400 - (imgW /2)),(200 - (imgH /2)), imgW, imgH);
          } else {
            const scale = image.height / image.width;
            const imgW =  this.IMAGE_SIZE / scale;
            const imgH =  this.IMAGE_SIZE;

            ctx.drawImage(image,(400 - (imgW /2)),(200 - (imgH /2)), imgW,  imgH );
          }
  
          const data = ctx.canvas.toDataURL();
          this.img = data;
          this.loadResizedPhoto = true;
        }
        console.log(this.loadResizedPhoto, this.readyToLoad)
        this.readyToLoad = true;
        res(undefined);
      };

    });
  }


  fitImage() {
    this.scale = 1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    }
  }

  get formatConfig() {
    return this.cropperConfiguration.format;
  }

  get aspectRatio() {
    if(this.cropperConfiguration.aspectRatio === "landscape") {
      return 3/2;
    }
    return 1/1;
  }

  get width() {
    return this.cropperConfiguration.width;
  }

  get selectedImage() {
    return this.data.event.target.files[0];
  }

  save() {
     this.imageCropper.crop();
  }

  close() {
    this.dialogRef.close();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.dialogRef.close(event);
  }

  imageLoaded() {
      this.showCropper = true;
      console.log('Image loaded');
  }

    cropperReady(sourceImageDimensions: Dimensions) {
      this.isLoaded = true;
        console.log('Cropper ready', sourceImageDimensions);
    }

    loadImageFailed() {
        console.log('Load failed');
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }
}
