<div fxLayout="row">
    <img
        (click)="goToEntity()"
        mat-card-avatar 
        [ngClass]="{'clickable-flat' : clickable}"
        class="profile-card-avatar" 
        [src]="getProfileImage(name, targetedEntity, photo)" 
        (error)="getDefaultImage($event, name, targetedEntity)"
    />
    <div class="title mt-2" >
        <span (click)="goToEntity()" [ngClass]="{'clickable-flat link' : clickable}">{{ name }}</span> <br/>
        <div class="profile-card-subtitle">
            <div>{{infoTextOne}}</div>
            <div>{{infoTextTwo}}</div>
            <div><ng-content></ng-content></div>
        </div>
    </div>
</div>