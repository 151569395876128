import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PopoverRef } from 'src/@vex/components/popover/popover-ref';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { trackById } from 'src/@vex/utils/track-by';
import { NotificationType, PiiccoNotification, PiiccoNotificationItem } from 'src/app/core/models/notification.model';
import { UserRights } from 'src/app/core/models/user-rights.model';
import { NotificationBellService } from 'src/app/core/services/notification-bell.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { PiiccoEvent } from 'src/app/models/calendar';
import { CalendarService } from 'src/app/shared/calendar/services/calendar.service';

@Component({
  selector: 'vex-notification-bell-dropdown',
  templateUrl: './notification-bell-dropdown.component.html',
  styleUrls: ['./notification-bell-dropdown.component.scss'],
})
export class NotificationBellDropdownComponent implements OnInit {
  trackById = trackById;

  @Output() showChange = new EventEmitter();
  @Output() markAsRead = new EventEmitter<PiiccoNotificationItem>();
  @Output() accept = new EventEmitter<PiiccoNotificationItem>();

  UserRights = UserRights;

  constructor(
    public notificationBellService: NotificationBellService,
    public translate: TranslateService,
    private notificationsService: NotificationsService,
    private profilesService: ProfilesService,
    private navigationService: NavigationService,
    private calendarService: CalendarService,
    private popoverRef: PopoverRef<NotificationBellDropdownComponent>
  ) {}

  ngOnInit(): void {}

  get notifications() {
    return this.notificationBellService.notifications;
  }

  async markNotificationAsRead(notification: PiiccoNotificationItem) {
    this.notificationBellService.removeNotification(notification);

    await this.notificationsService.markAsRead(notification.id);
  }

  async refuseRSVPInvite(notification: PiiccoNotificationItem) {
    await this.calendarService.rsvp(notification.inviterOrganization.id, (notification.reference as PiiccoEvent).id, 'no');
    await this.markNotificationAsRead(notification);
  }

  async acceptNotification(notification: PiiccoNotificationItem) {
    this.notificationBellService.removeNotification(notification);

    switch (notification.type) {
      case NotificationType.OrganizationInvite:
        await this.notificationsService.acceptOrganizationInvite(notification.id);

        const organizations = await this.profilesService.refreshOrganizations();
        this.navigationService.initMenu(organizations);

        break;
      case NotificationType.ProfileInvite:
        await this.notificationsService.acceptProfileInvite(notification.id);
        break;
      case NotificationType.OrgToOrgInvite:
        await this.notificationsService.acceptOrganizationToOrganizationInvite(notification.id);
        break;
      case NotificationType.EventInvite:
        await this.calendarService.rsvp(notification.inviterOrganization.id, (notification.reference as PiiccoEvent).id, 'yes');
        await this.markNotificationAsRead(notification);
        break;
      case NotificationType.ChildShareInvite:
        await this.notificationsService.acceptChildShareInvite(notification.id);
        this.profilesService.clearCache();
        await this.profilesService.getProfile();
        break;
      default:
        break;
    }
  }

  hidePopUp() {
    this.popoverRef.close();
  }
}
