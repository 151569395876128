import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AttendeesComponentData} from './attendees-component-data';
import {PiiccoProfile, PublicProfile} from '../../../../models/piiccoProfile';
import {TranslateService} from '@ngx-translate/core';
import {getDefaultImage, getProfileImage} from 'src/app/core/utils/profile-photo.util';
import {TargetedEntity} from 'src/app/shared/models/dialog.model';
import {Store} from '@ngrx/store';
import {Actions, ofType} from '@ngrx/effects';
import {takeUntil, tap} from 'rxjs/operators';
import {ObserverComponent} from 'src/app/core/components/observer.component';
import {LogoutAction} from 'src/app/store/auth/auth.actions';
import {OrganizationsService} from '../../../../organization/services/organizations.service';

class CheckList {
  profile: PublicProfile;
  disabled: boolean;
  checked: boolean;
  labelPosition: string;
}

@Component({
  selector: 'vex-attendees',
  templateUrl: './attendees.component.html',
  styleUrls: ['./attendees.component.scss']
})
export class AttendeesComponent extends ObserverComponent implements OnInit, OnChanges {

  @Input() organisationId: string;
  @Input() selectedAttendees: any[];
  @Output() changed: EventEmitter<PublicProfile[]> = new EventEmitter<PublicProfile[]>();

  data: AttendeesComponentData;

  masterChecked = false;
  masterIndeterminate = false;
  selectedProfiles: PublicProfile[] = [];

  getDefaultImage = getDefaultImage;
  getProfileImage = getProfileImage;
  TargetedEntity = TargetedEntity;

  constructor(
    private organizationsService: OrganizationsService,
    public translate: TranslateService,
  ) {
    super();
  }

  get availableProfiles() {
    return this.data?.profiles;
  }

  get selectAllButtonText() {
    if (this.selectedProfiles?.length !== this.data?.profiles.length) {
      return this.translate.instant('common.select-all');
    }
    return this.translate.instant('common.unselect-all');

  }

  get submitDisabled() {
    return this.selectedProfiles.length === 0;
  }

  ngOnInit(): void {
    // this.data.selected.forEach((i) => {
    //   const t = this.data.profiles.find((j) => j.id === i);
    //
    //   if (t) {
    //     this.selectedProfiles.push(t);
    //   }
    // })

  }

  selectAll() {
    if (this.selectedProfiles.length === this.data.profiles.length) {
      this.selectedProfiles = [];
      this.changed.emit(this.selectedProfiles);
    } else {
      this.selectedProfiles = (this.data.profiles.map((i) => i));
      this.changed.emit(this.selectedProfiles);
    }
  }

  getFullName(profile: PiiccoProfile) {
    return `${profile.firstName} ${profile.lastName}`;
  }

  isSelected(id: string) {
    return !!(this.selectedProfiles.find((i) => i.id === id));
  }

  select(profile: PublicProfile) {

    const userAlreadySelected = !!this.selectedProfiles.find((i) => i.id === profile.id);

    if (userAlreadySelected) {
      this.selectedProfiles = (this.selectedProfiles.filter((i) => i.id !== profile.id));
    } else {
      this.selectedProfiles = [...this.selectedProfiles, profile];
    }
    this.changed.emit(this.selectedProfiles);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.organisationId) {
      this.reload();
    }
  }

  private reload() {
    console.log('reload', this.selectedAttendees);
    this.organizationsService.fetchOrganizationPeople(this.organisationId).take(1)
      .subscribe((people) => {
        const sortedPeople = people.sort((a, b) => {
          const aFullName = `${a.firstName.toLocaleLowerCase()} ${b.lastName.toLocaleLowerCase()}`;
          const bFullName = `${b.firstName.toLocaleLowerCase()} ${b.lastName.toLocaleLowerCase()}`;

          if (aFullName > bFullName) {
            return 1;
          }

          if (aFullName < bFullName) {
            return -1;
          }
          return 0;
        });

        this.data = {profiles: sortedPeople, selected: []}
        if (this.selectedAttendees) {
          for (const att of this.selectedAttendees) {
            const t = this.data?.profiles.find((j) => j.id === att.attendee.id);
            if (t) {
              this.selectedProfiles.push(t);
            }
          }
        }

      });
  }
}
