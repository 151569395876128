<div fxLayout="row wrap">
  <div fxFlex="100" *ngIf="imageUrls.length > 0">
    <div fxFlex="100" fxLayout="row wrap">
      <div fxFlex="100" style="position: relative">
        <img class="main-image" [src]="mainPhoto" />
        <button class="image-remove-button" mat-mini-fab color="accent" (click)="removeImage($event, 0)">
          <mat-icon>clear</mat-icon>
        </button>
      </div>

      <div fxFlex="100" *ngIf="imageUrls.length > 1" fxLayout="row wrap">
        <div
          fxFlex="50"
          *ngFor="let photo of secondaryPhotoList; index as i"
          class="pl-1"
          [ngClass]="{ 'mt-1': i > 0 }"
          style="position: relative"
          fxLayoutAlign="center start"
        >
          <img class="secondary-images-container" [src]="photo" />
          <button class="image-remove-button" mat-mini-fab color="accent" (click)="removeImage($event, 0)">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="100" class="mt-4">
    <div class="file-uploader-container" (click)="openFileDialog()">
      <div class="file-uploader-label" fxLayout="row">
        <span fxFlex="60">{{ inputLabel }}</span>
        <div fxFlex="30">
          <span style="float: right"> {{ this.imageUrls.length }}/{{ this.maxFile }} </span>
        </div>
        <div fxFlex="10">
          <mat-icon color="accent" style="font-size: 25px; height: 25px; width: 25px; line-height: 1.3rem">insert_photo</mat-icon>
        </div>
      </div>
      <input
        type="file"
        #input
        name="avatar"
        accept="image/png, image/jpeg, image/gif"
        class="no-select"
        style="display: none"
        (change)="handleFileInput($event)"
      />
    </div>
  </div>
</div>
