import { Action, createReducer, on } from "@ngrx/store";
import { ClearAuthInfoAction, SetAuthInfoAction } from "./auth.actions";
import { AuthState } from "./auth.model";
import { initialState } from './auth.state';

const reducer = createReducer(
  initialState,
  on(ClearAuthInfoAction, (state: AuthState) => ({...initialState})),
  on(SetAuthInfoAction, (state: AuthState, { authInfo }) => { 
    return ({
    ...state,
    user: authInfo.user,
    idToken: authInfo.idToken
  }); })
);

export function AuthReducer(
  state: AuthState,
  action: Action
): AuthState {
  return reducer(state, action);
}