import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DxSchedulerComponent } from 'devextreme-angular';
import moment from 'moment';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { APP_EVENTS_COLORS } from 'src/app/core/config/application.config';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { CalendarIdNamePair, PiiccoEvent } from 'src/app/models/calendar';
import { CalendarService } from '../../calendar/services/calendar.service';

@Component({
  selector: 'vex-calendar-upcoming-events',
  templateUrl: './calendar-upcoming-events.component.html',
  styleUrls: ['./calendar-upcoming-events.component.scss']
})
export class CalendarUpcomingEventsComponent implements OnInit {
  @Input() organizationId: string;
  @Input() single = false;

  isLoading = false;

  @ViewChild('scheduler') scheduler: DxSchedulerComponent;

  appointments: PiiccoEvent[] = [];
  organisations: CalendarIdNamePair[] = [];
  colors = APP_EVENTS_COLORS;
  private source: PiiccoEvent[] = [];

  constructor(
    private navigationService: NavigationService,
    private calendarService: CalendarService,
    private profilesService: ProfilesService
  ) { }

  async ngOnInit() {
    await this.getCalendar(new Date().getMonth(), new Date().getFullYear(), true);
  }

  async getCalendar(month, year, loadOrgs = true) {
    this.isLoading = true;
    const calendars = await this.calendarService.getOrganisationCalendar(month, year, this.single);
    this.appointments = [];

    for (const cal of calendars) {
      for (const appt of cal.events) {
        appt['organisationId'] = cal.ownerId;
      }
      if (loadOrgs) {
        this.organisations.push({
          id: cal.owner.id, name: cal.owner.name,
          color: this.colors[this.organisations.length],
          checked: true
        });
      }
      this.appointments = this.appointments.concat(cal.events);
    }
    // Add current org as default in case it doesn't have a calendar
    if (this.organisations.filter(o => o.id === this.navigationService.currentNavigationOrg.id).length === 0) {
      this.organisations.push({
        id: this.navigationService.currentNavigationOrg.id,
        name: this.navigationService.currentNavigationOrg.name,
        color: this.colors[this.organisations.length],
        checked: true
      });
    }

    this.source = JSON.parse(JSON.stringify(this.appointments));

    this.filter();
    this.filterExpiredEvents();
    this.orderByDate();
    this.isLoading = false;
  }

  filter() {
    const selection = this.organisations.filter(appt => appt.checked).map(appt => appt.id);
    this.appointments = this.source.filter(appt => selection.includes(appt['organisationId']));
  }

  filterExpiredEvents() {
    this.appointments = this.appointments.slice(0, 6);
    this.appointments = this.appointments.filter((i) => new Date(i.startDate) > new Date());
  }

  orderByDate() {
    this.appointments = this.appointments.sort((a,b) => {
        if(a.startDate > b.startDate) {
          return 1;
        }

        if(a.startDate < b.startDate) {
          return -1;
        }

        return 0;
    });
  }

  getDateDay(date: string) {
    return new Date(date).getDate();
  }

  get userLang() {
    return this.profilesService.getUserLang();
  }

  getDateMonth(date: string) {
    moment.locale(this.userLang);

    return moment(date).format('MMM');
  }

}
