import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { PROFILE_IMAGES_FORMAT } from 'src/app/core/config/file-format.config';
import { getDefaultImage, getProfileImage } from 'src/app/core/utils/profile-photo.util';
import { UpdatePiiccoProfile, UpdatePiiccoProfilePhoto } from 'src/app/models/piiccoProfile';
import { DialogAction, ProfilePictureDialogData } from '../../models/dialog.model';
import { ImageCropperDialogComponent } from '../image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'vex-profile-picture-dialog',
  templateUrl: './profile-picture-dialog.component.html',
  styleUrls: ['./profile-picture-dialog.component.scss']
})
export class ProfilePictureDialogComponent implements OnInit, DialogAction {
  @Output() updatePhoto = new EventEmitter<string>();
  
  @ViewChild('input') myInputVariable: ElementRef;

  selectedImage: string|null = null;

  getProfileImage = getProfileImage;
  getDefaultImage = getDefaultImage;

  constructor(
    public dialogRef: MatDialogRef<ProfilePictureDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ProfilePictureDialogData
  ) { }

  ngOnInit() {}

  get submitDisabled(): boolean {
    return !this.selectedImage;
  }

  handleFileInput(file) {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent,
      {
        disableClose: true,
        width: '600px',
        data: {
          event: file
        }
      });

    dialogRef.afterClosed().subscribe((base64: ImageCroppedEvent) => {
      console.log(base64);
      this.myInputVariable.nativeElement.value = "";
      if (base64) {
        this.selectedImage = base64.base64;
        
      }
    });
  }

  get isSaving() {
    return this.data.isSaving;
  }

  get profilePicture() {
    if(!!this.selectedImage) {
      return this.selectedImage;
    }

    if(!!this.data.image && this.data.image !== "") {
      return this.data.image;
    }

    return getProfileImage(this.data.name, this.data.targetedEntity, null);
  }

  profilePictureError($event) {
    getDefaultImage(
      $event,
      this.data.name, 
      this.data.targetedEntity
    );
  }
  
  save(): void {
    if(!!this.selectedImage) {
      this.updatePhoto.emit(this.selectedImage);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
