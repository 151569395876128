import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EMAIL_REGEX, PASSWORD_REGEX } from 'src/app/core/regexs/string.regex';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { environment } from 'src/environments/environment';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'vex-create-child-dialog',
  templateUrl: './create-child-dialog.component.html',
  styleUrls: ['./create-child-dialog.component.scss'],
})
export class CreateChildDialogComponent implements OnInit {
  private destroy$ = new Subject();

  form: FormGroup;

  inputType = 'password';
  visible = false;
  isLoading = false;

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  returnUrl: string;

  constructor(
    private fb: FormBuilder,
    public profilesService: ProfilesService,
    public translate: TranslateService,
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CreateChildDialogComponent>
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
    });
  }

  get pasword() {
    return this.form.get('password');
  }
  get firstName() {
    return this.form.get('firstname');
  }
  get lastName() {
    return this.form.get('lastname');
  }
  get email() {
    return this.form.get('email');
  }

  async send(event?) {
    if (event) {
      event.preventDefault();
    }
    if (this.form.valid && !this.isLoading) {
      this.isLoading = true;
      try {
        const user = await this.profilesService.createFamilyMember({
          email: this.email.value,
          firstName: this.firstName.value,
          lastName: this.lastName.value,
        });

        await this.profilesService.getProfile(undefined, true);

        this.isLoading = false;
        this.dialogRef.close();
      } catch (error) {
        this.isLoading = false;
      }
    }
  }

  get submitDisabled() {
    return this.form.invalid || this.isLoading;
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
