<div class="w-full" [ngClass]="classes" fxLayout="column">
    <ng-content ></ng-content>
   <div >
        <div 
            [ngStyle]="{
                'color': iconColor
            }" 
            fxLayoutAlign="space-around"
            style="margin-top:-5px"
        >
            <div style="position: relative">
                <div 
                    *ngIf="requiredPercentage(5)" 
                    class="background-color top-left-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="lineHeight/4"
                ></div>
                <div 
                    *ngIf="requiredPercentage(10)" 
                    class="background-color bottom-left-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="getYPosition()"
                ></div>
                <div 
                    *ngIf="requiredPercentage(15)" 
                    class="background-color bottom-right-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="getYPosition()"
                    [style.left.px]="getXPosition()"
                ></div>
                <div 
                    *ngIf="requiredPercentage(20)" 
                    class="background-color top-right-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.left.px]="getXPosition()"
                    [style.top.px]="lineHeight/4"

                ></div>
                <mat-icon 
                    [style.font-size.px]="lineHeight"
                    [style.width.px]="lineHeight"
                    style="height: auto !important;"
                >sports_soccer</mat-icon>

            </div>

            <div style="position: relative">
                <div 
                    *ngIf="requiredPercentage(25)" 
                    class="background-color top-left-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="lineHeight/4"
                ></div>
                <div 
                    *ngIf="requiredPercentage(30)" 
                    class="background-color bottom-left-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="getYPosition()"
                ></div>
                <div 
                    *ngIf="requiredPercentage(35)" 
                    class="background-color bottom-right-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="getYPosition()"
                    [style.left.px]="getXPosition()"
                ></div>
                <div 
                    *ngIf="requiredPercentage(40)" 
                    class="background-color top-right-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.left.px]="getXPosition()"
                    [style.top.px]="lineHeight/4"

                ></div>
                <mat-icon 
                    [style.font-size.px]="lineHeight"
                    [style.width.px]="lineHeight"
                    style="height: auto !important;"
                >sports_soccer</mat-icon>

            </div>

            <div style="position: relative">
                <div 
                    *ngIf="requiredPercentage(45)" 
                    class="background-color top-left-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="lineHeight/4"
                ></div>
                <div 
                    *ngIf="requiredPercentage(50)" 
                    class="background-color bottom-left-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="getYPosition()"
                ></div>
                <div 
                    *ngIf="requiredPercentage(55)" 
                    class="background-color bottom-right-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="getYPosition()"
                    [style.left.px]="getXPosition()"
                ></div>
                <div 
                    *ngIf="requiredPercentage(60)" 
                    class="background-color top-right-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.left.px]="getXPosition()"
                    [style.top.px]="lineHeight/4"

                ></div>
                <mat-icon 
                    [style.font-size.px]="lineHeight"
                    [style.width.px]="lineHeight"
                    style="height: auto !important;"
                >sports_soccer</mat-icon>

            </div>

            <div style="position: relative">
                <div 
                    *ngIf="requiredPercentage(65)" 
                    class="background-color top-left-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="lineHeight/4"
                ></div>
                <div 
                    *ngIf="requiredPercentage(70)" 
                    class="background-color bottom-left-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="getYPosition()"
                ></div>
                <div 
                    *ngIf="requiredPercentage(75)" 
                    class="background-color bottom-right-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="getYPosition()"
                    [style.left.px]="getXPosition()"
                ></div>
                <div 
                    *ngIf="requiredPercentage(80)" 
                    class="background-color top-right-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.left.px]="getXPosition()"
                    [style.top.px]="lineHeight/4"

                ></div>
                <mat-icon 
                    [style.font-size.px]="lineHeight"
                    [style.width.px]="lineHeight"
                    style="height: auto !important;"
                >sports_soccer</mat-icon>

            </div>
            <div style="position: relative">
                <div 
                    *ngIf="requiredPercentage(85)" 
                    class="background-color top-left-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="lineHeight/4"
                ></div>
                <div 
                    *ngIf="requiredPercentage(90)" 
                    class="background-color bottom-left-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="getYPosition()"
                ></div>
                <div 
                    *ngIf="requiredPercentage(95)" 
                    class="background-color bottom-right-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.top.px]="getYPosition()"
                    [style.left.px]="getXPosition()"
                ></div>
                <div 
                    *ngIf="requiredPercentage(100)" 
                    class="background-color top-right-corner"
                    [style.width.px]="getCornersSize()"
                    [style.height.px]="getCornersSize()"
                    [style.left.px]="getXPosition()"
                    [style.top.px]="lineHeight/4"

                ></div>
                <mat-icon 
                    [style.font-size.px]="lineHeight"
                    [style.width.px]="lineHeight"
                    style="height: auto !important;"
                >sports_soccer</mat-icon>

            </div>
        </div>
    </div>
</div>