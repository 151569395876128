<mat-card [ngClass]="{ 'clickable-card': clickable }" class="target-card" color="transparent" (click)="goProfile()">
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <div [fxFlex]="noNgContent ? '100' : '60'" fxFlex.lt-md="100" fxLayout="row wrap">
      <img mat-card-avatar class="profile-card-avatar" [src]="getImage()" (error)="getDefaultLogo($event)" />
      <div [ngClass]="{ 'target-title': !noNgContent }">
        <span class="name">{{ name }}</span> <br />
        <div class="profile-card-subtitle">
          <div>{{ getTeamName() }}</div>
          <div>{{ getTeamClassAndPosition() }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="!noNgContent" fxFlex="40" fxFlex.lt-md="100">
      <ng-content></ng-content>
    </div>
  </div>
</mat-card>
