import { AuthenticationService } from 'src/app/auth/services/authentication-service/authentication.service';
import { Actions, createEffect, Effect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../index';
import { Injectable, NgZone } from '@angular/core';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  ClearAuthInfoAction,
  LogoutAction,
  RegisterAction,
  RegisterErrorAction,
  ProfileInitAction,
  SetAuthInfoAction,
  ProfileInitSuccessAction,
  LoginSuccessAction,
  RegisterSuccessAction,
} from './auth.actions';
import { combineLatest, from, of } from 'rxjs';
import { Router } from '@angular/router';
import { userSelector } from './auth.selectors';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { SetGlobalErrorMessageAction, SetGlobalSuccessMessageAction } from '../global/global.actions';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { APP_LOCAL_STORAGE_CURRENT_ORG_KEY } from 'src/app/core/config/application.config';
import { PushNotificationsService } from '../../core/services/push-notifications.service';

@Injectable()
export class AuthEffects {
  constructor(
    private translate: TranslateService,
    private router: Router,
    private actions$: Actions,
    private authService: AuthenticationService,
    private profileService: ProfilesService,
    private navigationService: NavigationService,
    private store: Store<State>,
    private ngZone: NgZone,
    private pushNotificationsService: PushNotificationsService
  ) {}

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        switchMap(() => of())
      ),
    { dispatch: false }
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType(LogoutAction),
    tap(async () => {
      localStorage.removeItem('parentToken');
      localStorage.removeItem('isImpersonating');
      localStorage.removeItem('isImpersonating');
      localStorage.removeItem('parentToken');
      localStorage.removeItem('parentId');
      localStorage.removeItem('parentSession');
      localStorage.removeItem('parentSessionKey');
      localStorage.removeItem('parentFamily');
      await this.authService.logout();
      this.ngZone.run(() => this.router.navigateByUrl('/login'));
      localStorage.removeItem(APP_LOCAL_STORAGE_CURRENT_ORG_KEY);
      this.profileService.clearCache();
      this.navigationService.items = [];
      this.navigationService.currentNavigationOrg = undefined;
      await this.pushNotificationsService.unsubscribeFromAllTopics();
    }),
    map(() => [ClearAuthInfoAction()]),
    switchMap((actions) => actions)
  );

  register$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RegisterAction),
      mergeMap((data) => {
        return this.authService
          .createUser(
            data.registrationInfo.email,
            data.registrationInfo.password,
            data.registrationInfo.lastname,
            data.registrationInfo.firstname,
            data.redirectUrl
          )
          .pipe(
            map((credentialInfo) => ProfileInitSuccessAction({ redirectUrl: data.redirectUrl })),
            catchError((err, caught$) => {
              return of(
                SetGlobalErrorMessageAction({ translationKey: 'pages.auth.register.notify.error' }),
                RegisterErrorAction()
              );
            })
          );
      })
    );
  });

  profileInit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileInitAction),
        map((action) => action.credentialInfo),
        mergeMap((credentialInfo) => {
          return this.profileService
            .initProfile({
              email: credentialInfo.userCredentials.user.email,
              firstName: credentialInfo.firstname,
              lastName: credentialInfo.lastname,
              profileId: credentialInfo.userCredentials.user.uid,
            })
            .pipe(
              map((c) => ProfileInitSuccessAction({ redirectUrl: credentialInfo.redirectUrl })),
              catchError((err, caught$) => {
                notify(this.translate.instant('pages.auth.register.notify.error') + ': ' + err['message'], 'error');
                return caught$;
              })
            );
        })
      ),
    { useEffectsErrorHandler: false }
  );

  profileInitSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileInitSuccessAction),
        tap((redirectUrl) => {
          notify(this.translate.instant('pages.auth.register.notify.success'), 'success');
          if (redirectUrl.redirectUrl) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: redirectUrl.redirectUrl } });
          } else {
            this.router.navigateByUrl('/login');
          }
        })
      ),
    { dispatch: false }
  );
}
