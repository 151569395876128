import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, tap } from 'rxjs/operators';
import {
  UpdateOrganizationContactAction,
  UpdateOrganizationErrorAction,
  UpdateOrganizationSuccessAction,
} from 'src/app/organization/store/organization.actions';
import { SetGlobalErrorMessageAction } from 'src/app/store/global/global.actions';
import { TargetedEntity, ContactDialogData, DialogAction } from '../../models/dialog.model';
import { TranslateService } from '@ngx-translate/core';
import { CAN_POSTAL_CODE_REGEX, EMAIL_REGEX, PHONE_NUMBER_REGEX, URL_REGEX } from 'src/app/core/regexs/string.regex';
import { POSTAL_CODE_MASK } from 'src/app/core/masks/postal-code.mask';
import { PHONE_NUMBER_MASK } from 'src/app/core/masks/phone-number.mask';

@Component({
  selector: 'vex-contact-edit-dialog',
  templateUrl: './contact-edit-dialog.component.html',
  styleUrls: ['./contact-edit-dialog.component.scss'],
})
export class ContactEditDialogComponent implements OnInit, OnDestroy, DialogAction {
  private destroy$ = new Subject();
  @ViewChild('actions') submitAction;

  isSaving = false;

  POSTAL_CODE_MASK = POSTAL_CODE_MASK;
  PHONE_NUMBER_MASK = PHONE_NUMBER_MASK;

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', []),
    email: new FormControl('', [Validators.email, Validators.pattern(EMAIL_REGEX)]),
    streetName: new FormControl('', []),
    city: new FormControl('', []),
    state: new FormControl('', []),
    postalCode: new FormControl('', [Validators.pattern(CAN_POSTAL_CODE_REGEX)]),
    country: new FormControl('', [Validators.maxLength(56)]),
    website: new FormControl('', [Validators.pattern(URL_REGEX)]),
  });

  constructor(
    public dialogRef: MatDialogRef<ContactEditDialogComponent>,
    public store: Store,
    private actions$: Actions,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ContactDialogData
  ) {
    this.form.controls.name.setValue(this.data.contactInfos.name);
    this.form.controls.phoneNumber.setValue(this.data.contactInfos.phoneNumber);
    this.form.controls.email.setValue(this.data.contactInfos.email);
    this.form.controls.streetName.setValue(this.data.contactInfos.streetName);
    this.form.controls.city.setValue(this.data.contactInfos.city);
    this.form.controls.state.setValue(this.data.contactInfos.state);
    this.form.controls.postalCode.setValue(this.data.contactInfos.postalCode);
    this.form.controls.website.setValue(this.data.contactInfos.website);
    this.form.controls.country.setValue(this.data.contactInfos.country);
  }

  get name() {
    return this.form.get('name');
  }
  get phoneNumber() {
    return this.form.get('phoneNumber');
  }
  get email() {
    return this.form.get('email');
  }
  get streetName() {
    return this.form.get('streetName');
  }
  get city() {
    return this.form.get('city');
  }
  get state() {
    return this.form.get('state');
  }
  get postalCode() {
    return this.form.get('postalCode');
  }
  get country() {
    return this.form.get('country');
  }
  get website() {
    return this.form.get('website');
  }

  ngOnInit(): void {
    this.actions$
      .pipe(
        takeUntil(this.destroy$),
        ofType(UpdateOrganizationSuccessAction),
        tap(() => (this.isSaving = false)),
        tap(() => this.dialogRef.close())
      )
      .subscribe();

    this.actions$
      .pipe(
        takeUntil(this.destroy$),
        ofType(UpdateOrganizationErrorAction),
        tap(() => (this.isSaving = false))
      )
      .subscribe();
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.isSaving = true;
    if (!this.data.entityId) {
      //TODO: Programming issue - We should catch it in an external monitoring tool.
      this.store.dispatch(SetGlobalErrorMessageAction({ translationKey: 'common.error-occured' }));
      this.isSaving = false;
      return;
    }

    switch (this.data.targetedEntity) {
      case TargetedEntity.Organization:
        this.store.dispatch(
          UpdateOrganizationContactAction({
            organizationId: this.data.entityId,
            contactInfo: {
              city: this.city.value,
              civicNumber: null,
              country: this.country.value,
              email: this.email.value,
              name: this.name.value,
              phoneNumber: this.phoneNumber.value,
              postalCode: this.postalCode.value,
              state: this.state.value,
              streetName: this.streetName.value,
              website: this.website.value,
            },
          })
        );

        break;
      case TargetedEntity.Profile:
        // TODO: support profile.
        break;
      default:
        //TODO: Programming issue - We should catch it in an external monitoring tool.
        this.store.dispatch(SetGlobalErrorMessageAction({ translationKey: 'common.error-occured' }));
        this.isSaving = false;
        break;
    }
  }

  get submitDisabled() {
    return this.form.invalid || this.isSaving;
  }

  updateOrganizationAbout() {
    /*this.organizationsService.updateOrganization({ id: this.data.entityId, bio: this.about.value}).subscribe((success) => {
      this.isSaving = false;
      
      if(success) {
        this.dialogRef.close();
      }
    });*/
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
