<div class="profile-cover">
  <div 
    class="profile-cover-content" 
    fxLayoutAlign.lt-md="center center" 
    fxLayoutAlign="start center" 
    fxLayout="row wrap"
    fxLayout.lt-md="column"
  >
    <div fxFlex="25" fxFlex.lt-md="0">
      <div class="profile-picture-container"  >

        <img
        mat-card-avatar
        class="profile-cover-avatar"
        [src]="profilePicture"
        alt=""
        (error)="profilePictureError($event)"
        >
      </div>
    </div>

    <div class="profile-cover-texts-margins" *ngIf="!!title" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="center center" fxFlex="75" fxFlex.lt-md="0">
      <h1 fxLayoutAlign.lt-md="center center">{{title}}</h1>
      <h2>{{subtitle || " "}}</h2>
      <h3 *ngIf="!!textOne && textOne !== ''" class="profile-h3">{{textOne}}</h3>
      <h4 [class.profile-h4]="!textOne || textOne == ''">{{textTwo || " "}}</h4>
    </div>
  </div>
</div>
