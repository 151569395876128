import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { audit, auditTime, concatMap, debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ObserverComponent } from 'src/app/core/components/observer.component';
import { InformationDialogService } from 'src/app/core/services/information-dialog.service';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { FamilyMember, PiiccoProfile, PiiccoUserPreferences, UpdatePiiccoProfile } from 'src/app/models/piiccoProfile';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from 'src/app/shared/models/dialog.model';
import { LogoutAction } from 'src/app/store/auth/auth.actions';
import { AddParentToChildDialogData, EditChildRightsDialogData } from '../../models/dialog.model';
import { AddChildParentDialogComponent } from '../add-child-parent-dialog/add-child-parent-dialog.component';
import { CreateChildDialogComponent } from '../create-child-dialog/create-child-dialog.component';
import { EditChildRightsDialogComponent } from '../edit-child-rights-dialog/edit-child-rights-dialog.component';

@Component({
  selector: 'vex-application-settings-dialog',
  templateUrl: './application-settings-dialog.component.html',
  styleUrls: ['./application-settings-dialog.component.scss'],
})
export class ApplicationSettingsDialogComponent extends ObserverComponent implements OnInit {
  isLoading = false;
  familyFeatureActive = false;
  user: PiiccoProfile;
  userFamily: FamilyMember[] = [];

  notificationsForm: FormGroup;

  constructor(
    public translate: TranslateService,
    private profilesService: ProfilesService,
    public dialogRef: MatDialogRef<ApplicationSettingsDialogComponent>,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private store: Store,
    private informationDialogService: InformationDialogService
  ) {
    super();

    this.notificationsForm = this.formBuilder.group({
      emailNotificationsEnabled: [false, Validators.required],
      pushNotificationsEnabled: [false, Validators.required],
    });
  }

  async ngOnInit() {
    this.user = await this.profilesService.getProfile();

    this.notificationsForm.get('emailNotificationsEnabled').setValue(this.user.userPreferences?.enableEmailNotification || false);
    this.notificationsForm.get('pushNotificationsEnabled').setValue(this.user.userPreferences?.enablePushNotification || false);
    this.familyFeatureActive = this.user.userPreferences?.enableFamilyManagement || false;
    this.isLoading = true;
    this.userFamily = await this.profilesService.getProfileFamily(undefined, true);
    this.isLoading = false;
    this.cd.markForCheck();

    this.profilesService.familyUpdated.pipe(takeUntil(this.destroy$)).subscribe((r) => {
      this.userFamily = r;
    });

    this.notificationsForm.valueChanges
      .pipe(
        debounceTime(300),
        switchMap((formValue) => this.savePreferences()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getSwitchLabel() {
    if (this.familyFeatureActive) {
      return this.translate.instant('application-settings-dialog.enabled');
    }

    return this.translate.instant('application-settings-dialog.disabled');
  }

  getDisableSwitch() {
    return this.userFamily.length > 0;
  }

  addParent(member: PiiccoProfile) {
    const dialogData: AddParentToChildDialogData = {
      profile: member,
    };

    const dialog = this.dialog.open(AddChildParentDialogComponent, {
      width: '600px',
      data: dialogData,
    });
  }

  deleteAccount() {
    const dialogData: ConfirmationDialogData = {
      title: this.translate.instant('application-settings-dialog.delete-my-account.title'),
      text: this.translate.instant('application-settings-dialog.delete-my-account.text'),
      icon: '',
    };

    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
      maxWidth: 400,
    });

    dialog.componentInstance.accepted.pipe(takeUntil(this.destroy$)).subscribe(() => {
      dialog.close();

      this.profilesService
        .deleteAccount()
        .pipe(
          tap(() => (this.isLoading = true)),
          tap(() => this.store.dispatch(LogoutAction())),
          tap(() =>
            this.informationDialogService.open(
              {
                title: this.translate.instant('application-settings-dialog.delete-my-account.account-deleted'),
                text: '',
                hideCancelButton: true,
                okButtonText: this.translate.instant('common.ok'),
              },
              true
            )
          ),
          tap(() => this.dialogRef.close())
        )
        .subscribe();
    });
  }

  deleteFamilyMember(id: string) {
    const dialogData: ConfirmationDialogData = {
      title: this.translate.instant('application-settings-dialog.delete-family-member-title'),
      text: this.translate.instant('application-settings-dialog.delete-family-member-text'),
      icon: 'person_remove',
    };

    const disconnectDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    disconnectDialogRef.componentInstance.okCaption = 'common.yes';
    disconnectDialogRef.componentInstance.cancelCaption = 'common.no';
    disconnectDialogRef.componentInstance.accepted.subscribe(async () => {
      try {
        const updatedUser = await this.profilesService.deleteFamilyMember(id);
        this.userFamily = await this.profilesService.getProfileFamily();

        disconnectDialogRef.close();
      } catch (error) {
        disconnectDialogRef.close();
      }
    });

    disconnectDialogRef.componentInstance.refused.subscribe(() => {
      disconnectDialogRef.close();
    });
  }

  emancipateFamilyMember(id: string) {
    const dialogData: ConfirmationDialogData = {
      title: this.translate.instant('application-settings-dialog.emancipate-child-title'),
      text: this.translate.instant('application-settings-dialog.emancipate-child-text'),
      icon: 'link_off',
    };

    const disconnectDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    disconnectDialogRef.componentInstance.okCaption = 'common.ok';
    disconnectDialogRef.componentInstance.cancelCaption = 'common.cancel';
    disconnectDialogRef.componentInstance.accepted.subscribe(async () => {
      try {
        const updatedUser = await this.profilesService.emancipateFamilyMember(id);
        this.userFamily = await this.profilesService.getProfileFamily();

        disconnectDialogRef.close();
      } catch (error) {
        disconnectDialogRef.close();
      }
    });

    disconnectDialogRef.componentInstance.refused.subscribe(() => {
      disconnectDialogRef.close();
    });
  }

  editFamilyMemberRights(member: PiiccoProfile) {
    const dialogData: EditChildRightsDialogData = {
      profile: member,
    };

    const dialog = this.dialog.open(EditChildRightsDialogComponent, {
      width: '600px',
      data: dialogData,
    });
  }

  getFamilyMemberCount() {
    return this.userFamily.length || 0;
  }

  getProfileCurrentOrganization(profile: PiiccoProfile) {
    return this.profilesService.getProfileCurrentOrganization(profile);
  }

  openCreateFamilyMemberDialog() {
    const dialog = this.dialog.open(CreateChildDialogComponent, {
      width: '600px',
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (r) => {
        this.userFamily = await this.profilesService.getProfileFamily();
      });
  }

  async saveSettings() {
    this.isLoading = true;

    try {
      if (this.familyFeatureActive) {
        await this.profilesService.activateFamilyFeature();
      } else {
        await this.profilesService.deactivateFamilyFeature();
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  async savePreferences() {
    this.isLoading = true;
    this.profilesService
      .updateProfilePreferences(this.user.id, {
        ...this.user.userPreferences,
        enableEmailNotification: this.notificationsForm.get('emailNotificationsEnabled').value,
        enablePushNotification: this.notificationsForm.get('pushNotificationsEnabled').value,
      })
      .pipe(debounceTime(800), takeUntil(this.destroy$))
      .subscribe(() => (this.isLoading = false));
  }
}
