<mat-card
  class="giphy-dialog mat-elevation-z12"
  *ngIf="show"
  (keydown.escape)="hideDialog.emit()"
  infiniteScroll
  [infiniteScrollDistance]="1"
  [infiniteScrollThrottle]="0"
  (scrolled)="onScroll()"
  [scrollWindow]="true"
>
  <div fxLayout="row wrap" class="pb-2 mb-2">
    <mat-form-field fxFlex="100" class="search-input search" appearance="outline">
      <mat-label>{{ 'common.search' | translate }}</mat-label>
      <input id="search" placeholder="{{ 'common.search' | translate }}" #input type="text" matInput [formControl]="search" />
    </mat-form-field>
  </div>

  <div *ngIf="!results || results.length === 0">
    <span>{{ 'giphy-dialog.no-gif-found' | translate }}</span>
  </div>

  <mat-grid-list cols="2" rowHeight="100px">
    <mat-grid-tile class="clickable-flat" *ngFor="let tile of results" [colspan]="1" (click)="selectGif(tile)">
      <img style="min-width: 100%; height: 100%" [src]="tile.images.fixed_height_small.url" alt="" />
    </mat-grid-tile>
  </mat-grid-list>
</mat-card>
