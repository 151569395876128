<vex-dialog-header
    title="{{'components.profile.edit-photo' | translate}}"
    titleIcon="add_a_photo"
></vex-dialog-header>
<mat-dialog-content >
    <div fxLayout="column" fxLayoutAlign="center center " class="mb-4">
        <div>
            <img 
                class="profile-cover-avatar"
                style="background-size: cover;"
                [src]="profilePicture"
                (error)="profilePictureError($event)"
            />
        </div>
        <div class="xxs-m-t">
            <button 
                mat-stroked-button
                #submitButton
                class="dialog-save-button rounded-button"
                [disabled]="isSaving"
                mat-button 
                (click)="hiddenfileinput.click()"
                [class.spinner]="isSaving"
            >
                {{'common.upload' | translate}}
            </button>
        </div>
        
    </div>
    <input 
        #input
        style="display: none" type="file"
        id="file"
        accept="image/png, image/jpeg"
        (change)="handleFileInput($event)"
        #hiddenfileinput
    >
</mat-dialog-content>
<vex-dialog-actions
    #actions
    [acceptDisabled]="submitDisabled"
    [cancelDisabled]="isSaving"
    acceptButtonText="{{'common.save-changes' | translate}}"
    cancelButtonText="{{'common.cancel' | translate}}"
    (accept)="save()"
    (cancel)="close()"
    [saving]="isSaving"
>

</vex-dialog-actions>
