import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { NetworkSearchResult } from '../models/network-search.model';
import { NetworkProfileFriend, NetworkProfileOrganizationFollowed } from '../models/network.model';
import { PiiccoProfile } from 'src/app/models/piiccoProfile';
import { Store } from '@ngrx/store';
import { SetGlobalErrorMessageAction, SetGlobalSuccessMessageAction } from 'src/app/store/global/global.actions';
import { CreatePost, Post, PostComment, PostReply, SharePost, Timeline, UpdateComment, UpdatePost } from '../models/timeline.model';
import HTTP_STATUS_CODES from 'http-status-enum';

@Injectable({
  providedIn: 'root'
})
export class NetworksService {
  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  searchNetwork(search: string|null, pageNumber = 1, pageSize = 5) {
    return new Promise<NetworkSearchResult|undefined>((res, rej) => {
      if(!search || search === "") {
        return res(undefined);
      }

      const formattedSearch = search.replace(' ', '%20');

      return this.http.get<NetworkSearchResult>(
        `${environment.apiGateway}networks/list/${formattedSearch}?page=${pageNumber}&pageSize=${pageSize}`
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        () => res(undefined)
      );
    });   
  }

  getProfileNetwork(pageNumber = 1, pageSize = 5) {
    return new Promise<NetworkProfileFriend>((res, rej) => {

      return this.http.get<NetworkProfileFriend>(
        `${environment.apiGateway}networks/friends?page=${pageNumber}&pageSize=${pageSize}`
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        () => res(undefined)
      );
    });   
  }

  getProfilePosts(pageNumber = 1, pageSize = 5) {
    return new Promise<NetworkProfileFriend>((res, rej) => {

      return this.http.get<NetworkProfileFriend>(
        `${environment.apiGateway}networks/friends?page=${pageNumber}&pageSize=${pageSize}`
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        () => res(undefined)
      );
    });   
  }

  getOrganizationNetwork(pageNumber = 1, pageSize = 5) {
    return new Promise<NetworkProfileOrganizationFollowed>((res, rej) => {

      return this.http.get<NetworkProfileOrganizationFollowed>(
        `${environment.apiGateway}networks/organizations?page=${pageNumber}&pageSize=${pageSize}`
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        () => res(undefined)
      );
    });   
  }

  followOrganization(organizationId: string) {
    return new Promise<PiiccoProfile>((res, rej) => {
      return this.http.put<PiiccoProfile>(
        `${environment.apiGateway}networks/organizations/follow/${organizationId}`,
        {}
      ).take(1).subscribe(
        (result) => { 
          this.store.dispatch(SetGlobalSuccessMessageAction({translationKey: 'components.profile-card.followed-success'}));
          res(result);
        },
        (error) => {
          switch(error.status) {
            case HTTP_STATUS_CODES.FORBIDDEN:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.forbidden"}));
              break;
            default: 
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.error-occured"}));
              break;
          }
          rej();
        }
      );
    }); 
  }
  
  updateComment(postId: string, commentId: string, comment: UpdateComment) {
    return new Promise<PostComment>((res, rej) => {
      return this.http.put<PostComment>(
        `${environment.apiGateway}networks/timeline/post/${postId}/comment/${commentId}`,
        comment
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        (error) => {
          switch(error.status) {
            case HTTP_STATUS_CODES.FORBIDDEN:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.forbidden"}));
              break;
            default: 
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.error-occured"}));
              break;
          }
          rej();
        }
      );
    }); 
  }

  updateReply(postId: string, commentId: string, replyId: string, comment: UpdateComment) {
    return new Promise<PostReply>((res, rej) => {
      return this.http.put<PostReply>(
        `${environment.apiGateway}networks/timeline/post/${postId}/comment/${commentId}/reply/${replyId}`,
        comment
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        (error) => {
          switch(error.status) {
            case HTTP_STATUS_CODES.FORBIDDEN:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.forbidden"}));
              break;
            default: 
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.error-occured"}));
              break;
          }
          rej();
        }
      );
    }); 
  }

  deleteComment(postId: string, commentId: string) {
    return new Promise<void>((res, rej) => {
      return this.http.delete<void>(
        `${environment.apiGateway}networks/timeline/post/${postId}/comment/${commentId}`,
        {}
      ).take(1).subscribe(
        (result) => { 
          res();
        },
        (error) => {
          switch(error.status) {
            case HTTP_STATUS_CODES.FORBIDDEN:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.forbidden"}));
              break;
            default: 
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.error-occured"}));
              break;
          }
          rej();
        }
      );
    }); 
  }

  deleteReply(postId: string, commentId: string, replyId: string) {
    return new Promise<void>((res, rej) => {
      return this.http.delete<void>(
        `${environment.apiGateway}networks/timeline/post/${postId}/comment/${commentId}/reply/${replyId}`,
        {}
      ).take(1).subscribe(
        (result) => { 
          res();
        },
        (error) => {
          switch(error.status) {
            case HTTP_STATUS_CODES.FORBIDDEN:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.forbidden"}));
              break;
            default: 
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.error-occured"}));
              break;
          }
          rej();
        }
      );
    }); 
  }

  unfollowOrganization(organizationId: string) {
    return new Promise<PiiccoProfile>((res, rej) => {
      return this.http.put<PiiccoProfile>(
        `${environment.apiGateway}networks/organizations/unfollow/${organizationId}`,
        {}
      ).take(1).subscribe(
        (result) => { 
          this.store.dispatch(SetGlobalSuccessMessageAction({translationKey: 'components.profile-card.unfollowed-success'}));
          res(result);
        },
        (error) => {
          this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'components.profile-card.unfollowed-error'}));
          rej();
        }
      );
    }); 
  }

  friendDisconnect(friendId: string) {
    return new Promise<PiiccoProfile>((res, rej) => {
      return this.http.put<PiiccoProfile>(
        `${environment.apiGateway}networks/friends/disconnect/${friendId}`,
        {}
      ).take(1).subscribe(
        (result) => { 
          this.store.dispatch(SetGlobalSuccessMessageAction({translationKey: 'components.profile-card.connected-success'}));
          res(result);
        },
        (error) => {
          this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'components.profile-card.connected-error'}));
          rej();
        }
      );
    }); 
  }
    
  getMyTimeline(pageNumber = 1, pageSize = 5) {
    return new Promise<Timeline>((res, rej) => {

      return this.http.get<Timeline>(
        `${environment.apiGateway}networks/timeline?page=${pageNumber}&pageSize=${pageSize}`
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        () => res({totalPageCount: 0, posts: []})
      );
    });   
  }

  getOrganizationTimeline(organizationId: string, pageNumber = 1, pageSize = 5) {
    return new Promise<Timeline>((res, rej) => {

      return this.http.get<Timeline>(
        `${environment.apiGateway}networks/timeline/organization/${organizationId}?page=${pageNumber}&pageSize=${pageSize}`
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        () => res({totalPageCount: 0, posts: []})
      );
    });   
  }

  getProfileTimeline(profileId: string, pageNumber = 1, pageSize = 5) {
    return new Promise<Timeline>((res, rej) => {

      return this.http.get<Timeline>(
        `${environment.apiGateway}networks/timeline/profile/${profileId}?page=${pageNumber}&pageSize=${pageSize}`
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        () => res({totalPageCount: 0, posts: []})
      );
    });   
  }

  createPost(createPost: CreatePost) {
    return new Promise<Post>((res, rej) => {
      
      return this.http.post<Post>(
        `${environment.apiGateway}/networks/timeline/post`,
        createPost
      ).take(1).subscribe(
        (result) => { 
          this.store.dispatch(SetGlobalSuccessMessageAction({translationKey: 'pages.timeline.create-post-success'}));

          res(result);
        },
        (error) => {
          this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'pages.timeline.create-post-error'}));
          rej();
        }
      );
    });
  }

  sharePost(postId: string, sharePost: SharePost) {
    return new Promise<Post>((res, rej) => {
      
      return this.http.post<Post>(
        `${environment.apiGateway}networks/timeline/post/${postId}/share`,
        sharePost
      ).take(1).subscribe(
        (result) => { 
          this.store.dispatch(SetGlobalSuccessMessageAction({translationKey: 'pages.timeline.share-post-success'}));

          res(result);
        },
        (error) => {
          this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'pages.timeline.share-post-error'}));
          rej();
        }
      );
    });
  }
  
  updateShare(postId: string, updatePost: UpdatePost) {
    return new Promise<Post>((res, rej) => {
      
      return this.http.put<Post>(
        `${environment.apiGateway}networks/timeline/share/${postId}`,
        updatePost
      ).take(1).subscribe(
        (result) => { 
          this.store.dispatch(SetGlobalSuccessMessageAction({translationKey: 'pages.timeline.share-post-success'}));

          res(result);
        },
        (error) => {
          this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'pages.timeline.share-post-error'}));
          rej();
        }
      );
    });
  }

  updatePost(id: string, updatePost: UpdatePost) {
    return new Promise<Post>((res, rej) => {
      
      return this.http.put<Post>(
        `${environment.apiGateway}/networks/timeline/post/${id}`,
        updatePost
      ).take(1).subscribe(
        (result) => { 
          this.store.dispatch(SetGlobalSuccessMessageAction({translationKey: 'organization.network.post-updated-success'}));

          res(result);
        },
        (error) => {
          switch(error.status) {
            case HTTP_STATUS_CODES.FORBIDDEN:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "organization.network.post-update-unautorized"}));
              break;
            default: 
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.error-occured"}));
              break;
          }
          rej();
        }
      );
    });
  }

  deletePost(id: string) {
    return new Promise<void>((res, rej) => {
      
      return this.http.delete<void>(
        `${environment.apiGateway}/networks/timeline/post/${id}`
      ).take(1).subscribe(
        (result) => { 
          this.store.dispatch(SetGlobalSuccessMessageAction({translationKey: 'organization.network.post-deleted-success'}));

          res();
        },
        (error) => {
          switch(error.status) {
            case HTTP_STATUS_CODES.FORBIDDEN:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "organization.network.post-delete-unautorized"}));
              break;
            default: 
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.error-occured"}));
              break;
          }
          rej();
        }
      );
    });
  }


  createOrganizationPost(orgId: string, createPost: CreatePost, sendNotification = false) {
    return new Promise<Post>((res, rej) => {
    
      return this.http.post<Post>(
        `${environment.apiGateway}/networks/timeline/post/organization/${orgId}?sendNotification=${sendNotification}`,
        createPost
      ).take(1).subscribe(
        (result) => { 
          this.store.dispatch(SetGlobalSuccessMessageAction({translationKey: 'pages.timeline.create-post-success'}));

          res(result);
        },
        (error) => {
          this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'pages.timeline.create-post-error'}));
          rej();
        }
      );
    });
  }

  commentPost(id: string, comment: string) {
    return new Promise<PostComment>((res, rej) => {
      
      return this.http.put<PostComment>(
        `${environment.apiGateway}networks/timeline/post/${id}/comment`,
        {
          comment: comment
        }
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        (error) => {
          rej();
          //this.store.dispatch(SetGlobalErrorMessageAction({translationKey: ''}));
        }
      );
    });
  }

  replyComment(postId: string, commentId: string, replyText: string) {
    return new Promise<PostReply>((res, rej) => {
      
      return this.http.put<PostReply>(
        `${environment.apiGateway}networks/timeline/post/${postId}/comment/${commentId}/reply`,
        {
          comment: replyText
        }
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        (error) => {
          rej();
        }
      );
    });
  }

  likePost(id: string, like: boolean) {
    return new Promise<Post>((res, rej) => {
      

      return this.http.put<Post>(
        `${environment.apiGateway}networks/timeline/post/${id}/likeunlike/like`,
        null
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        (error) => {
          rej(error);
          //this.store.dispatch(SetGlobalErrorMessageAction({translationKey: ''}));
        }
      );
    });   
  }

  getPostById(id: string, page = 1, pageSize = 10) {
    return new Promise<Post>((res, rej) => {
      return this.http.get<Post>(
        `${environment.apiGateway}networks/timeline/post/${id}?commentPage=${page}&commentPageSize=${pageSize}`
      ).take(1).subscribe(
        (result) => { 
          res(result);
        },
        (error: HttpErrorResponse) => {
          if(error.status === HTTP_STATUS_CODES.NOT_FOUND) {
            this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'pages.view-post.not-found'}));
          } else {
            this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'common.error-occured'}));
          }
          rej();
        }
      );
    }); 
  }
}
