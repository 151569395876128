
<vex-loader [isLoading]="isLoading"></vex-loader>
<mat-card 
    *ngFor="let event of appointments; index as i"
    class="calendar-upcoming-event"
    [ngClass]="{'mt-2': i !== 0}"
>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
            <div class="event-date-day">{{getDateDay(event.startDate)}}</div>
            <div class="event-date-month">{{getDateMonth(event.startDate)}}</div>
        </div>
        <div fxFlex="70" fxFlexOffset="10" >
            <div class="event-date-title">{{event.text}}</div>
        </div>
    </div>
</mat-card>

<div *ngIf="appointments.length === 0 && !isLoading" fxLayout="row wrap">
    <p class="ml-2">{{'organization.locker-room.no-events' | translate}}</p>
</div>