import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TermsAndConditions } from 'src/app/models/terms';
import { environment } from 'src/environments/environment';
import { GET_TERMS_MOCK } from './terms.mock';

export interface HasAcceptedTermsResponse {
  accepted: boolean;
  terms: TermsAndConditions;
}

@Injectable({
  providedIn: 'root'
})
export class TermsService {
  baseUrl = environment.apiGateway;

  constructor(
    private http: HttpClient) { }

  getTerms(): Observable<TermsAndConditions> {
    return this.http.get(
      `${this.baseUrl}documents/terms`
    ).pipe(map(r => r as any));
  }

  putTerms(profileId: string, documentId: string): Observable<any> {
    return this.http.put(
      `${this.baseUrl}documents/terms/accept/${profileId}/${documentId}`,
      {} // body?
    ).pipe(map(r => r as any));  }
}
