import { MultiLangItem } from "src/app/core/models/configuration.model";
import { Roles } from "src/app/core/models/roles.enum";
import { PiiccoCurrentOrganization } from "src/app/models/piiccoProfile";
import { Organization, OrganizationContactInfos } from "src/app/organization/models/organization";
import { FileFormatConfig } from "src/app/core/models/file-format-config.model";

export enum TargetedEntity {
    Profile = "Profile",
    Organization = "Organization",
    Outsider = "Outsider"
}
export interface AboutDialogData extends MultiEntityDialog {
    aboutText: string;
    nationalities?: MultiLangItem[]|null;
    isSaving: boolean;
}

export interface ContactDialogData extends MultiEntityDialog {
    contactInfos: OrganizationContactInfos;
}

export interface ProfilePictureDialogData extends MultiEntityDialog {
    image: string;
    name: string;
    isSaving: boolean;
}

export interface ConfirmationDialogData {
    title: string;
    text: string;
    icon?: string;
    cancelButtonText?: string;
    okButtonText?: string;
    alignCenter?: boolean;
    hideCancelButton?: boolean;
    isSaving?: boolean;
}

export interface MultiEntityDialog {
    entityId: string;
    targetedEntity: TargetedEntity;
}

export interface ProfileRightsDialogData {
    firstName: string;
    lastName: string;
    profileId: string;
    photo: string|null;
    currentRoles: Roles[];
    selectedOrganization: Organization;
    clubName?: string;
    editorRights: Roles[];
}

export interface ProfileCoverCurrentDialogData {
    currentOrganization: PiiccoCurrentOrganization|null;
    firstName: string;
    lastName: string;
    userId?: string;
    isSaving?: boolean;
}

export interface DialogAction {
    isSaving: boolean;
    submitDisabled : boolean;
    save(): void;
    close(): void
}

export interface FileUploaderFileInfo {
    mimeType: string;
    originalName: string;
    height: number;
    width: number;
}


export interface ImageCropperDialogData {
    event: any;
    cropperConfig: FileFormatConfig;
}

export interface ResourceViewerDialogData {
    imageUrl: string;
}

export interface GiphySelectedGif {
    imageUrl: string;
    thumbnailUrl: string;
}