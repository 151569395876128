import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PASSWORD_REGEX } from 'src/app/core/regexs/string.regex';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Store } from '@ngrx/store';
import {
  SetGlobalErrorMessageAction,
  SetGlobalSuccessMessageAction,
  SetLanguageAction,
} from 'src/app/store/global/global.actions';
import { takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/auth/services/authentication-service/authentication.service';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { LogoutAction } from 'src/app/store/auth/auth.actions';
@Component({
  selector: 'vex-force-password-change-dialog',
  templateUrl: './force-password-change-dialog.component.html',
  styleUrls: ['./force-password-change-dialog.component.scss'],
})
export class ForcePasswordChangeDialogComponent implements OnInit {
  isSaving = false;

  private destroy$ = new Subject();

  private resetCode: string = '';

  passwordVisibile = false;
  confirmPasswordVisibile = false;
  isLoading = false;

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  form = new FormGroup({
    //oldPassword: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.pattern(PASSWORD_REGEX)]),
    passwordConfirm: new FormControl('', [Validators.required]),
  });

  get password() {
    return this.form.get('password');
  }
  get passwordConfirm() {
    return this.form.get('passwordConfirm');
  }
  //get oldPassword() {return this.form.get('oldPassword')}

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    private store: Store,
    public dialogRef: MatDialogRef<ForcePasswordChangeDialogComponent>,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private profilesService: ProfilesService
  ) {}

  ngOnInit(): void {
    this.subscribeToRouteParam();

    this.form.valueChanges.subscribe((field) => {
      if (field.password !== field.passwordConfirm) {
        this.passwordConfirm.setErrors({ passwordNotMatch: true });
      } else {
        this.passwordConfirm.setErrors(null);
      }
    });
  }

  async submit() {
    if (this.form.invalid || this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;
      const currentUser = await this.angularFireAuth.currentUser;
      await currentUser.updatePassword(this.password.value);
      this.profilesService.setForcePasswordChange(false);
      await this.authenticationService.login();
      this.profilesService.clearCache();
      await this.profilesService.getProfile();
      this.dialogRef.close(true);
      this.store.dispatch(SetGlobalSuccessMessageAction({ translationKey: 'force-password-change-dialog.success' }));

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.store.dispatch(SetGlobalErrorMessageAction({ translationKey: 'common.error-occured' }));
    }
  }

  get submitDisabled() {
    return this.form.invalid || this.passwordConfirm.pristine || this.password.pristine;
  }

  logoutAndCloseDialog() {
    this.dialogRef.close(false);
    this.store.dispatch(LogoutAction());
  }

  setLanguage(language: string) {
    this.store.dispatch(SetLanguageAction({ language }));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async subscribeToRouteParam() {
    this.route.queryParams
      .pipe(
        takeUntil(this.destroy$),
        tap(async (params) => {
          this.resetCode = params['oobCode'];

          if (!this.resetCode || this.resetCode === '') {
            this.router.navigate(['/login']);
          }
        })
      )
      .subscribe();
  }
}
