<vex-dialog-header
    title="{{'components.profile-card.rights-administration' | translate}}"
    titleIcon="admin_panel_settings"
></vex-dialog-header>

<mat-dialog-content>
    <div fxLayout="row wrap">
        <mat-card 
            fxFlex="70"
            fxFlex.lt-md="100"
            class="mat-elevation-z8 mb-3"
            fxLayoutAlign="start center"
            style="position:relative" 
        >

            <div fxLayout="row">
                <img 
                    mat-card-avatar 
                    class="profile-card-avatar" 
                    [src]="data.photo" 
                    (error)="getDefaultLogo($event)"
                />
                <div class="title">
                    <span>{{data.firstName}} {{data.lastName}}</span> <br/>
                    <div class="profile-card-subtitle">
                        <div *ngIf="data.clubName">{{data.clubName}}</div>
                    </div>
                </div>
            </div>
        </mat-card>
        <vex-loader fxFlex="100" [isLoading]="isLoading"></vex-loader>

        <form [formGroup]="form" fxLayout="row wrap" *ngIf="!isLoading">
            <span fxFlex="100" class="label">{{'components.profile-card.title' | translate}}</span>

            <mat-form-field fxFlex="70" appearance="outline">
                <mat-label>{{ 'components.profile-card.category' | translate }}</mat-label>
    
                <mat-select formControlName="category" matInput>
                    <mat-option *ngFor="let availableDataItem of organizationAvailableTitles" [value]="toStr(availableDataItem)">
                        {{ availableDataItem[userLang] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="70" appearance="outline">
                <mat-label>{{ 'components.profile-card.title' | translate }}</mat-label>
                <input id="title" formControlName="title" matInput />
            </mat-form-field>

            <span class="mt-3" fxFlex="100" class="label">{{'common.roles'| translate}}</span>

            <div formArrayName="roles" fxLayout="row wrap">
                <div fxFlex="100" fxLayout="row" [hidden]="!availableRoles[i].grantable" *ngFor="let order of rolesFormArray.controls; let i = index" >
                    <mat-checkbox  [hidden]="!availableRoles[i].grantable"  [formControlName]="i" fxFlex="60">
                        <span style="color:white">{{availableRoles[i].name}}</span>
                    </mat-checkbox>
                    <mat-icon 
                        class="clickable-flat"
                        *ngIf="availableRoles[i].grantable"
                        fxFlex="10"
                        #tooltip="matTooltip"
                        [matTooltip]="availableRoles[i].description || ''" 
                        style="float:right"
                        matTooltipPosition="above"
                        matTooltipClass="testtttt"
                        color="accent"
                        >info</mat-icon>
                </div>
                <div *ngIf="roles.hasError('required')"><mat-error>{{'validations.must-select-at-least-one-role' | translate}}</mat-error> </div>
            </div>
        </form>

    </div>
</mat-dialog-content>


<vex-dialog-actions
    #actions
    (cancel)="closeDialog()"
    (accept)="updateRoles()"
    [acceptDisabled]="saveDisabled"
    [saving]="isSaving"
>

</vex-dialog-actions>

<!--
(accept)="accepted.emit()"
    (cancel)="refuseEvent()"
    [saving]="false"
-->