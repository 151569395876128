import { FileFormatConfig } from "../models/file-format-config.model";

export const PROFILE_IMAGES_FORMAT: FileFormatConfig = {
    format: "jpeg",
    width: 600,
    height: 600
}

export const LANDSCAPE_IMAGES_FORMAT: FileFormatConfig = {
    format: "jpeg",
    width: 800,
    height: 400
}

export const PORTRAIT_IMAGES_FORMAT: FileFormatConfig = {
    format: "jpeg",
    width: 400,
    height: 600
}

export const POST_PORTRAIT_IMAGE_HEIGHT = 400;
export const POST_LANDSCAPE_IMAGE_WIDTH = 600;

export const CHAT_THUMBNAIL_WIDTH = 150;
export const CHAT_THUMBNAIL_HEIGHT = 200;
export const CHAT_PORTRAIT_IMAGE_HEIGHT = 400;
export const CHAT_LANDSCAPE_IMAGE_WIDTH = 600;
