import { CalendarIdNamePair, PiiccoEvent } from '../../../../models/calendar';
import { EventTypes } from '../../../../core/models/eventtypes.model';

export class CalendarEntryData {
  calendarId: string;
  organizerId: string;
  events: PiiccoEvent[];
  selectedEvent: PiiccoEvent;
  startDate: Date;
  endDate: Date;
  organisations: CalendarIdNamePair[] = [];
  eventTypes: EventTypes[] = [];
}

export interface RecurrenceTypes {
  code: string;
  fr: string;
  pt: string;
  en: string;
}
