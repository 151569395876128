import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-dialog-actions',
  templateUrl: './dialog-actions.component.html',
  styleUrls: ['./dialog-actions.component.scss']
})
export class DialogActionsComponent implements OnInit {
  @Input() acceptButtonText = "";
  @Input() cancelButtonText = "";
  @Input() cancelDisabled = false;
  @Input() acceptDisabled = false;
  @Input() saving = false;
  @Input() alignCenter = false;
  @Input() hideCancelButton= false;
  @Input() hideSaveButton= false;
  @ViewChild('submitButton') submitButton: ElementRef;
  @Output() cancel = new EventEmitter<any>();
  @Output() accept = new EventEmitter<any>();

  constructor(public translate: TranslateService) { 
    this.acceptButtonText = this.translate.instant("common.save-changes");
    this.cancelButtonText = this.translate.instant("common.cancel");
  }

  ngOnInit(): void {
  }

  submit() {
    this.submitButton.nativeElement.click();
  }

  acceptClick() {
    this.accept.emit();
  }

  cancelClick() {
    this.cancel.emit();
  }
}
