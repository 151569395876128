import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Evaluation } from 'src/app/evaluation/models/evaluation.model';
import { PiiccoNotification, PiiccoNotificationItem } from '../models/notification.model';
import { Roles } from '../models/roles.enum';
import { SetGlobalErrorMessageAction, SetGlobalSuccessMessageAction } from 'src/app/store/global/global.actions';
import { OutsiderInvitation } from 'src/app/organization/models/organization-invite.model';
import { CreateFeedback } from 'src/app/layout/models/feedback.model';
import { shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import HTTP_STATUS_CODES from 'http-status-enum';
import notify from 'devextreme/ui/notify';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private invitedProfilesSession: { cachedDate: Date; id: string }[] = [];
  private readonly MAX_CACHE_SEC = 120;
  pendingNotifications = new BehaviorSubject<PiiccoNotification<any>[]>([]);

  constructor(private http: HttpClient, private store: Store, private translate: TranslateService) {}

  sendFeedback(createFeedback: CreateFeedback) {
    return new Promise<void>((res, rej) => {
      return this.http
        .post<void>(`${environment.apiGateway}notifications/support/email`, createFeedback)
        .take(1)
        .subscribe(
          (result) => res(),
          (err) => {
            rej();
          }
        );
    });
  }

  inviteUserToOrganization(organizationId: string, userId: string, rights: Roles[]) {
    return new Promise<void>((res, rej) => {
      return this.http
        .post<void>(`${environment.apiGateway}notifications/organizationinvitewithroles/${organizationId}/${userId}`, {
          roles: rights,
        })
        .take(1)
        .subscribe(
          (result) => res(),
          (err) => {
            res();
          }
        );
    });
  }

  childShareInvite(parentId: string, childId: string) {
    return new Promise<void>((res, rej) => {
      return this.http
        .post<void>(`${environment.apiGateway}notifications/childshareinvite/${parentId}/${childId}`, {})
        .take(1)
        .subscribe(
          (result) => res(),
          (err) => {
            switch (err.status) {
              case HTTP_STATUS_CODES.BAD_GATEWAY:
                notify(this.translate.instant('add-child-parent-dialog.invite-bad-request'), 'error');
                break;
              case HTTP_STATUS_CODES.NOT_FOUND:
                notify(this.translate.instant('add-child-parent-dialog.not-found'), 'error');
                break;
              case HTTP_STATUS_CODES.FORBIDDEN:
                notify(this.translate.instant('common.forbidden'), 'error');
                break;
              default:
                notify(this.translate.instant('common.error-occured'), 'error');
                break;
            }
            rej();
          }
        );
    });
  }

  acceptChildShareInvite(notificationId: string) {
    return new Promise<void>((res, rej) => {
      return this.http
        .put<void>(`${environment.apiGateway}notifications/childshareinvite/accept/${notificationId}`, {})
        .take(1)
        .subscribe(
          (result) => res(),
          (err) => {
            console.log(err);
            rej();
          }
        );
    });
  }

  inviteOutsiderToOrganization(organizationId: string, invite: OutsiderInvitation) {
    return new Promise<void>((res, rej) => {
      return this.http
        .post<void>(`${environment.apiGateway}notifications/platforminvite/${organizationId}`, invite)
        .take(1)
        .subscribe(
          (result) => res(),
          (err) => {
            res();
          }
        );
    });
  }

  inviteOrganizationToOrganization(organizationId: string, userId: string) {
    return new Promise<void>((res, rej) => {
      return this.http
        .post<void>(`${environment.apiGateway}notifications/orgtoorginvite/${organizationId}/${userId}`, {})
        .take(1)
        .subscribe(
          (result) => res(),
          (err) => res()
        );
    });
  }

  inviteProfile(profileId: string, friendId: string) {
    return new Promise<void>((res, rej) => {
      return this.http
        .post<void>(`${environment.apiGateway}notifications/profileinvite/${profileId}/${friendId}`, {})
        .take(1)
        .subscribe(
          (result) => {
            this.invitedProfilesSession.push({ cachedDate: new Date(), id: friendId });
            this.store.dispatch(SetGlobalSuccessMessageAction({ translationKey: 'components.profile-card.connected-success' }));
            res();
          },
          (err) => {
            this.store.dispatch(SetGlobalErrorMessageAction({ translationKey: 'components.profile-card.connected-error' }));
            rej();
          }
        );
    });
  }

  acceptPlatformInvite(notificationId: string) {
    return new Promise<any>((res, rej) => {
      return this.http
        .put<void>(`${environment.apiGateway}/notifications/platforminvite/accept/${notificationId}`, {})
        .take(1)
        .subscribe(
          (result) => res(result),
          (err) => {
            rej();
          }
        );
    });
  }

  acceptProfileInvite(notificationId: string) {
    return new Promise<void>((res, rej) => {
      return this.http
        .put<void>(`${environment.apiGateway}/notifications/profileinvite/accept/${notificationId}`, {})
        .take(1)
        .subscribe(
          (result) => res(),
          (err) => {
            rej();
          }
        );
    });
  }

  acceptOrganizationInvite(notificationId: string) {
    return new Promise<void>((res, rej) => {
      return this.http
        .put<void>(`${environment.apiGateway}/notifications/organizationinvite/accept/${notificationId}`, {})
        .take(1)
        .subscribe(
          (result) => res(),
          (err) => {
            rej();
          }
        );
    });
  }

  acceptOrganizationToOrganizationInvite(notificationId: string) {
    return new Promise<void>((res, rej) => {
      return this.http
        .put<void>(`${environment.apiGateway}/notifications/orgtoorginvite/accept/${notificationId}`, {})
        .take(1)
        .subscribe(
          (result) => res(),
          (err) => {
            rej();
          }
        );
    });
  }

  getOrganizationPendingInvite(organizationId: string) {
    return new Promise<PiiccoNotification<any>[]>((res, rej) => {
      return this.http
        .get<PiiccoNotification<any>[]>(`${environment.apiGateway}/notifications/pending/${organizationId}`, {})
        .take(1)
        .subscribe(
          (result) => res(result),
          (err) => {
            res([]);
          }
        );
    });
  }

  getById<RefType>(notificationId: string) {
    return new Promise<PiiccoNotification<RefType>>((res, rej) => {
      return this.http
        .get<PiiccoNotification<RefType>>(`${environment.apiGateway}/notifications/${notificationId}`, {})
        .take(1)
        .subscribe(
          (result) => res(result),
          (err) => {
            rej();
          }
        );
    });
  }

  markAsRead(notificationId: string) {
    return new Promise((res, rej) => {
      return this.http
        .put(`${environment.apiGateway}/notifications/process/${notificationId}`, {})
        .take(1)
        .subscribe(
          (result) => res(result),
          (err) => {
            rej();
          }
        );
    });
  }

  getNotificationList() {
    return new Promise<PiiccoNotificationItem[]>((res, rej) => {
      return this.http
        .get<PiiccoNotificationItem[]>(`${environment.apiGateway}/notifications`, {})
        .take(1)
        .subscribe(
          (result) => res(result),
          (err) => {
            res([]);
          }
        );
    });
  }

  getPendingNotificationList() {
    return new Promise<PiiccoNotificationItem[]>((res, rej) => {
      return this.http
        .get<PiiccoNotificationItem[]>(`${environment.apiGateway}/notifications/pending/me`, {})
        .take(1)
        .pipe(shareReplay())
        .subscribe(
          (result) => {
            this.pendingNotifications.next(result);
            res(result);
          },
          (err) => {
            res([]);
          }
        );
    });
  }

  userHasPendingInvite(friendId: string) {
    this.refreshInvitedProfileCache();
    return this.invitedProfilesSession.some((i) => i.id === friendId);
  }

  refreshInvitedProfileCache() {
    this.invitedProfilesSession = this.invitedProfilesSession.filter((i) => {
      const now = new Date();

      return (now.getTime() - i.cachedDate.getTime()) / 1000 < this.MAX_CACHE_SEC;
    });
  }

  private handleError(error) {
    if (error instanceof HttpErrorResponse) {
      let errorMessage = '';

      switch (error.status) {
        case HTTP_STATUS_CODES.NOT_FOUND:
          errorMessage = 'pages.profile.not-found';
          break;
        case HTTP_STATUS_CODES.FORBIDDEN:
          errorMessage = 'common.forbidden';
          break;
        default:
          errorMessage = 'common.error-occured';
          break;
      }
      this.store.dispatch(SetGlobalErrorMessageAction({ translationKey: errorMessage }));
    } else {
      this.store.dispatch(SetGlobalErrorMessageAction({ translationKey: 'common.error-occured' }));
    }
  }
}
