import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { PopoverService } from '../../components/popover/popover.service';
import icSearch from '@iconify/icons-ic/twotone-search';
import { Store } from '@ngrx/store';
import { ChangeLanguageAction } from 'src/app/store/global/global.actions';
import { TranslateService } from '@ngx-translate/core';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { Router } from '@angular/router';
import { RightsService } from 'src/app/core/services/rights.service';
import { UserRights } from 'src/app/core/models/user-rights.model';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$ = this.configService.config$.pipe(map((config) => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map((config) => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map((config) => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map((config) => config.navbar.position === 'below-toolbar'));

  icSearch = icSearch;
  icBookmarks = icBookmarks;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;

  currentLang = this.getLang(this.translate.currentLang);
  chatButtonDisabled = false;

  constructor(
    private translate: TranslateService,
    private store: Store,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private profilesService: ProfilesService,
    private navigationService: NavigationService,
    private popoverService: PopoverService,
    private router: Router,
    private rightsService: RightsService
  ) {}

  async ngOnInit() {
    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = this.getLang(event.lang);
    });

    const profile = await this.profilesService.getProfile();
    this.chatButtonDisabled =
      this.rightsService.userIsChildren(profile) &&
      !this.rightsService.hasFeatureAccess(profile, UserRights.Chat) &&
      localStorage.getItem('isImpersonating') !== 'true';
  }

  goChat() {
    this.router.navigateByUrl('/chat');
  }

  getLang(lang: string) {
    switch (lang) {
      case 'fr':
        return 'FR';
      case 'en':
        return 'EN';
      case 'pt':
        return 'PT';
      default:
        return 'FR';
    }
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  openSearch() {
    this.layoutService.openSearch();
  }

  async setLanguage(language: string) {
    const user = await this.profilesService.getProfile();
    if (user.userPreferences) {
      user.userPreferences.language = language;
      this.store.dispatch(ChangeLanguageAction({ preferences: user.userPreferences, userId: user.id }));
    } else {
      this.store.dispatch(
        ChangeLanguageAction({
          preferences: {
            language: language,
            currentOrganization: null,
            enableEmailNotification: true,
            enablePushNotification: false,
          },
          userId: user.id,
        })
      );
    }
  }
}
