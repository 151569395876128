import { Action, createReducer, on } from '@ngrx/store';
import {
  ClearOrganizationStateAction,
  FetchOrganizationByIdSuccessAction,
  FetchOrganizationChildrenSuccessAction,
  FetchOrganizationPeopleSuccessAction,
  UpdateOrganizationSuccessAction,
} from './organization.actions';
import { organizationInitialState, OrganizationState } from './organization.state';

const reducer = createReducer(
  organizationInitialState,
  on(ClearOrganizationStateAction, (state: OrganizationState) => ({ ...organizationInitialState })),
  on(FetchOrganizationByIdSuccessAction, (state: OrganizationState, { response }) => ({
    ...state,
    fetchedOrganizationList: [...state.fetchedOrganizationList, response],
  })),
  on(FetchOrganizationChildrenSuccessAction, (state: OrganizationState, { response, organizationId }) => ({
    ...state,
    fetchedOrganizationChildList: {
      ...state.fetchedOrganizationChildList,
      [organizationId]: [...response].sort((a, b) => (a.name < b.name ? -1 : 1)),
    },
  })),
  on(FetchOrganizationPeopleSuccessAction, (state: OrganizationState, { response, organizationId }) => ({
    ...state,
    fetchedOrganizationPeopleList: {
      ...state.fetchedOrganizationPeopleList,
      [organizationId]: [...response].sort((a, b) => (`${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}` ? -1 : 1)),
    },
  })),
  on(UpdateOrganizationSuccessAction, (state: OrganizationState, { response }) => ({
    ...state,
    fetchedOrganizationList: state.fetchedOrganizationList.map((i) => {
      if (i.id === response.id) {
        return response;
      }

      return i;
    }),
  }))
);

export function OrganizationReducer(state: OrganizationState, action: Action): OrganizationState {
  return reducer(state, action);
}
