<mat-dialog-content >
    <div fxLayout="row wrap" fxLayoutAlign="center center">
        <mat-icon class="confirmation-icon" color="primary" *ngIf="!!data.icon">{{data.icon}}</mat-icon>
        <div fxFlex="100">
            <h3 style="text-align: center;" class="confirmation-title">{{data.title}}</h3>
        </div>
    </div>
    <div class="pb-8">
        <p class="confirmation-text">{{data.text}}</p>
    </div>

</mat-dialog-content>

<vex-dialog-actions
    #actions
    [acceptButtonText]="acceptButtonText"
    [cancelButtonText]="cancelButtonText"
    (accept)="accepted.emit()"
    (cancel)="refuseEvent()"
    [saving]="data.isSaving || false"
    [alignCenter]="alignCenter"
    [hideCancelButton]="hideCancelButton"
>

</vex-dialog-actions>