import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { Questionnaire } from '../models/questionnaire.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private http: HttpClient
  ) {
  }

  getQuestionnaires(organizationId: string): Promise<Questionnaire[]> {
    return new Promise((res, rej)=>{
      this.http.get<Questionnaire[]>(
        `${environment.apiGateway}documents/questionnaire/${organizationId}`
      ).take(1).subscribe((response)=>{
        res(response);
      });  
    })
  }
}
