import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { dropdownAnimation } from 'src/@vex/animations/dropdown.animation';
import { popoverAnimation } from 'src/@vex/animations/popover.animation';
import { PopoverService } from 'src/@vex/components/popover/popover.service';
import { NotificationType, PiiccoNotification, PiiccoNotificationItem } from 'src/app/core/models/notification.model';
import { NotificationBellService } from 'src/app/core/services/notification-bell.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { NotificationBellDropdownComponent } from '../notification-bell-dropdown/notification-bell-dropdown.component';

@Component({
  selector: 'vex-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
  animations: [dropdownAnimation],
})
export class NotificationBellComponent implements OnInit, OnDestroy {
  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;

  constructor(
    public notificationBellService: NotificationBellService,
    public translate: TranslateService,
    private notificationsService: NotificationsService,
    private popover: PopoverService
  ) {}

  ngOnDestroy(): void {
    this.notificationBellService.unsubscribe();
  }

  async ngOnInit() {
    await this.notificationBellService.initNotification();
  }

  @HostListener('document:click', ['$event'])
  clickout() {
    this.dropdownOpen = false;
  }

  showPopover() {
    this.dropdownOpen = true;

    const popoverRef = this.popover.open({
      content: NotificationBellDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ],
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      //this.cd.markForCheck();
    });
  }
}
