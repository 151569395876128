<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 block"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <img class="w-8 select-none" src="assets/img/demo/logo.svg" />
    <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">VEX</h1>
  </a>

  <div
    *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
    [fxHide]="mobileQuery"
    class="px-gutter"
    fxFlex="none"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span fxFlex></span>

  <div class="-mx-1 flex items-center">
    <div class="px-1">
      <vex-notification-bell> </vex-notification-bell>
    </div>

    <button [disabled]="chatButtonDisabled" (click)="goChat()" mat-icon-button>
      <mat-icon [style.color]="chatButtonDisabled ? '#808080' : 'white'">chat_bubble_outline</mat-icon>
    </button>

    <div class="">
      <vex-help></vex-help>
    </div>

    <div class="px-1">
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        {{ currentLang }}
      </button>
    </div>

    <div class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button mat-menu-item (click)="setLanguage('fr')">
        <span>Français</span>
      </button>

      <button mat-menu-item (click)="setLanguage('en')">
        <span>English</span>
      </button>

      <button mat-menu-item (click)="setLanguage('pt')">
        <span>Português</span>
      </button>
    </mat-menu>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async" [fxHide]="mobileQuery"></vex-navigation>
