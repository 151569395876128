import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { getDefaultImage, getProfileImage } from 'src/app/core/utils/profile-photo.util';
import { TargetedEntity } from 'src/app/shared/models/dialog.model';

@Component({
  selector: 'vex-profile-image-infos',
  templateUrl: './profile-image-infos.component.html',
  styleUrls: ['./profile-image-infos.component.scss']
})
export class ProfileImageInfosComponent implements OnInit {
  @Input() id: string;
  @Input() name: string;
  @Input() targetedEntity: TargetedEntity = TargetedEntity.Profile;
  @Input() photo: string;
  @Input() infoTextOne: string;
  @Input() infoTextTwo: string;
  @Input() clickable = true;
  @Output() clickEvent = new EventEmitter<void>();

  getProfileImage = getProfileImage;
  getDefaultImage = getDefaultImage;
  TargetedEntity = TargetedEntity;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goToEntity() {
    if(!this.clickable) {
      return;
    }
    this.clickEvent.emit();
    if(this.targetedEntity === TargetedEntity.Profile) {
      this.router.navigateByUrl(`profile/${this.id}`);
    } else {
      this.router.navigateByUrl(`organization/${this.id}`);
    }
  }

}
