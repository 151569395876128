import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Evaluation } from 'src/app/evaluation/models/evaluation.model';
import { AgendaEvent, PiiccoEvent } from 'src/app/models/calendar';
import { Post } from 'src/app/network/models/timeline.model';
import { PusherNotificationService } from 'src/app/services/pusher-notification/services/pusher-notification.service';
import { PiiccoNotification, PiiccoNotificationItem } from '../models/notification.model';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationBellService {
  private profileNotifications: PiiccoNotificationItem[] = [];
  private destroy$ = new Subject();

  constructor(private notificationsService: NotificationsService, private pusherNotificationService: PusherNotificationService) {}

  addNotification(notification: PiiccoNotificationItem) {
    this.profileNotifications.unshift(notification);
  }

  removeNotification(notification: PiiccoNotificationItem) {
    this.profileNotifications = this.profileNotifications.filter((i) => i.id !== notification.id);
  }

  removeNotificationById(id: string) {
    this.profileNotifications = this.profileNotifications.filter((i) => i.id !== id);
  }

  removeNotificationFromCalendarEvent(event: PiiccoEvent) {
    const found = this.profileNotifications.find((i) => (i.reference as any)?.id === event.id);
    if (found) {
      this.profileNotifications = this.profileNotifications.filter((i) => i.id !== found?.id);
    } else {
      return undefined;
    }

    return found?.id;
  }

  get notifications() {
    return this.profileNotifications;
  }

  get notificationCount() {
    return this.profileNotifications.length;
  }

  unsubscribe() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async initNotification() {
    this.profileNotifications = await this.notificationsService.getNotificationList();
    this.pusherNotificationService.notificationEvaluationInviteSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<Evaluation>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationOrganizationInviteSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<Evaluation>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationOrganizationToOrganizationInviteSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<Evaluation>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationProfileInviteSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<Evaluation>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationNewOrganizationPostSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<Post>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationEventInviteSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<PiiccoEvent>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationEventDeleteSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<PiiccoEvent>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationEventModifySubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<PiiccoEvent>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationPostCommentSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<Post>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationPostLikeSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<Post>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationStartersUpdatedSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<any>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationUnreadChatMessageSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<any>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationUnprocessedChildNotificationSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<any>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationChildShareInviteSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<any>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationChildDeletedSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<any>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationChildEmancipatedSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<any>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationProfileEmancipatedSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<any>) => {
        this.profileNotifications.push(msg);
      });

    this.pusherNotificationService.notificationProfileTransferedSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: PiiccoNotification<any>) => {
        this.profileNotifications.push(msg);
      });
  }
}
