<div (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav flex flex-col mobile-notch">
  <div class="sidenav-toolbar flex-none flex items-center m2 mt-4">
      <img [src]="imageUrl$ | async" class="w-4 select-none flex-auto" style="margin: 15px;" layout-margin>
<!--    <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto">{{title$ | async}}</h2>-->
    
  </div>

  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <vex-search-bar></vex-search-bar>

      <vex-sidenav-item 
        *ngFor="let item of items; trackBy: trackByRoute"
        [item]="item"
        [level]="0"
      ></vex-sidenav-item>
    </div>
  </vex-scrollbar>
</div>
