import { AfterContentInit, AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CalendarIdNamePair, PiiccoEvent, PiiccoEventAttendee } from '../../../models/calendar';
import { CalendarService } from '../services/calendar.service';
import { DxSchedulerComponent } from 'devextreme-angular';
import { NavigationService } from '../../../../@vex/services/navigation.service';
import { OrganizationsService } from '../../../organization/services/organizations.service';
import { MatDialog } from '@angular/material/dialog';
import { AttendeesComponent } from './attendees/attendees.component';
import { PublicProfile } from '../../../models/piiccoProfile';
import notify from 'devextreme/ui/notify';
import { TranslateService } from '@ngx-translate/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ConfigurationsService } from '../../../core/services/configurations.service';
import { EventTypes } from '../../../core/models/eventtypes.model';
import { CalendarType } from '../../models/calendar.model';
import { APP_EVENTS_COLORS } from 'src/app/core/config/application.config';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '../../models/dialog.model';
import moment from 'moment';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { ObserverComponent } from 'src/app/core/components/observer.component';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { NotificationBellService } from 'src/app/core/services/notification-bell.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { CalendarEntryComponent } from './calendar-entry/calendar-entry.component';
import { CalendarEntryData } from './calendar-entry/Calendar-entry-data.model';
import { UserRights } from 'src/app/core/models/user-rights.model';
import { RightsService } from 'src/app/core/services/rights.service';

@Component({
  selector: 'vex-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent extends ObserverComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() calendarId: string;
  @Input() single = false;
  @Input() readonly = false;
  @Input() title = '';
  @Input() showTitle = true;
  @Input() showOrganizationSelectors = true;
  @Input() personal = false;
  @Input() calendarType: CalendarType = CalendarType.Full;

  eventTypeDestroy$: any;

  @ViewChild('scheduler') scheduler: DxSchedulerComponent;
  appointments: PiiccoEvent[] = [];
  organisations: CalendarIdNamePair[] = [];
  colors = APP_EVENTS_COLORS;
  formItemsSet = false;
  cancelUpdate = false;
  isSaving = false;
  eventTypes: EventTypes[] = [];
  UserRights = UserRights;
  hasEventRSVPAccess = true;
  private source: PiiccoEvent[] = [];

  constructor(
    private navigationService: NavigationService,
    private calendarService: CalendarService,
    private configurationsService: ConfigurationsService,
    public translate: TranslateService,
    private organizationsService: OrganizationsService,
    private profilesService: ProfilesService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private notificationBellService: NotificationBellService,
    private notificationsService: NotificationsService,
    private rightsService: RightsService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.eventTypeDestroy$.unsubscribe();
  }

  async ngAfterViewInit() {
    if (!this.personal) {
      this.getOrganisationCalendar();
    } else {
      this.getMyCalendar();
    }
  }

  getCalendarTypeClass() {
    return this.calendarType === CalendarType.Full ? 'full-cal' : 'small-cal';
  }

  startDate(event: PiiccoEvent) {}

  endDate(event: PiiccoEvent) {}

  async ngOnInit() {
    this.hasEventRSVPAccess = this.rightsService.hasFeatureAccess(await this.profilesService.getProfile(), UserRights.EventRSVP);

    this.eventTypeDestroy$ = this.configurationsService.getEventTypes().subscribe((et) => {
      this.eventTypes = et;
    });

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
      this.scheduler.instance._refresh();
    });
  }

  async getOrganisationCalendar(loadOrgs = true) {
    const currentDate: Date = new Date(this.scheduler.currentDate);
    await this.getCalendar(currentDate.getMonth(), currentDate.getFullYear(), loadOrgs);
  }

  onAppointmentFormOpening($event: any) {
    $event.cancel = true;
    if (this.readonly) {
      return;
    }

    if (!this.organisations || this.organisations.length === 0) {
      return;
    }

    const calendarEntryData: CalendarEntryData = new CalendarEntryData();
    calendarEntryData.calendarId = this.calendarId;

    calendarEntryData.events = this.appointments;
    calendarEntryData.startDate = $event.appointmentData.startDate;
    calendarEntryData.endDate = $event.appointmentData.endDate;
    calendarEntryData.organisations = this.organisations;
    calendarEntryData.eventTypes = this.eventTypes;

    if ($event.appointmentData.text) {
      // Selection
      calendarEntryData.selectedEvent = $event.appointmentData;
    }

    const dialogRef = this.dialog.open(CalendarEntryComponent, {
      width: '600px',
      data: calendarEntryData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((ret) => {
      if (ret) {
        this.organisations = [];
        if (!this.personal) {
          this.getOrganisationCalendar();
        } else {
          this.getMyCalendar();
        }
      }
    });
  }

  async onAppointmentDeleted($event: any) {
    await this.calendarService.deleteEvent($event.appointmentData, $event.appointmentData.organisationId);
  }

  onAppointmentAdding($event: any) {
    // if no org, use default
    if (!$event.appointmentData.organisationId) {
      $event.appointmentData.organisationId = this.navigationService.currentNavigationOrg.id;
    }
  }

  async onAppointmentAdded($event: any) {
    // save appointment
    await this.addEvent($event.appointmentData, $event.appointmentData.organisationId);
    // this.organizationsService.fetchOrganizationPeople($event.appointmentData.organisationId).take(1)
    //   .subscribe((people) => {
    //     const sortedPeople = people.sort((a, b) => {
    //       const aFullName = `${a.firstName.toLocaleLowerCase()} ${b.lastName.toLocaleLowerCase()}`;
    //       const bFullName = `${b.firstName.toLocaleLowerCase()} ${b.lastName.toLocaleLowerCase()}`;
    //
    //       if (aFullName > bFullName) {
    //         return 1;
    //       }
    //
    //       if (aFullName < bFullName) {
    //         return -1;
    //       }
    //       return 0;
    //     });
    //
    //     const dialogRef = this.dialog.open(
    //       AttendeesComponent, {
    //         width: '600px',
    //         data: {profiles: sortedPeople, selected: []}
    //       }
    //     );
    //     dialogRef.afterClosed().subscribe(profiles => {
    //       if (profiles) {
    //         this.invite($event.appointmentData, profiles);
    //       } else {
    //         this.invite($event.appointmentData, []);
    //       }
    //     });
    //   });
  }

  async addEvent(event: PiiccoEvent, organisationId: string) {
    const org = organisationId;
    delete event['organisationId']; // NotPart of the model
    const appt: PiiccoEvent = await this.calendarService.createEvent(event, org);

    event['organisationId'] = org;
    event.id = appt.id;
  }

  onAppointmentUpdating($event: any) {
    if (!$event.newData.organisationId) {
      $event.newData.organisationId = this.navigationService.currentNavigationOrg.id;
    }

    if ($event.newData.organisationId !== $event.oldData.organisationId) {
      this.cancelUpdate = true;
      this.calendarService.deleteEvent($event.oldData, $event.oldData.organisationId).then(() => {
        // clear invitations
        $event.newData.attendees = [];
        this.addEvent($event.newData, $event.newData.organisationId).then(() => {
          const currentDate = new Date(this.scheduler.currentDate);
          this.getCalendar(currentDate.getMonth(), currentDate.getFullYear());
        });
      });
    }
  }

  async onAppointmentUpdated($event: any) {
    if (this.cancelUpdate) {
      this.cancelUpdate = false;
      return;
    }
    //await this.calendarService.updateEvent($event.appointmentData);

    // this.organizationsService.fetchOrganizationPeople($event.appointmentData.organisationId).take(1)
    //   .subscribe(people => {
    //     const selected = $event.appointmentData.attendees.map(attendee => attendee.attendeeId);
    //     const dialogRef = this.dialog.open(
    //       AttendeesComponent, {
    //         width: '600px',
    //         data: {profiles: people, selected}
    //       }
    //     );
    //     dialogRef.afterClosed().subscribe(profiles => {
    //       if (profiles) {
    //         this.invite($event.appointmentData, profiles);
    //       }
    //
    //       notify(this.translate.instant('calendar.event-updated'), 'success');
    //     });
    //   });
  }

  async getCalendar(month, year, loadOrgs = true) {
    const calendars = await this.calendarService.getOrganisationCalendar(month, year, this.single);
    this.appointments = [];

    for (const cal of calendars) {
      for (const appt of cal.events) {
        appt['organisationId'] = cal.ownerId;
      }
      if (loadOrgs) {
        this.organisations.push({
          id: cal.owner.id,
          name: cal.owner.name,
          color: this.colors[this.organisations.length],
          checked: true,
        });
      }
      this.appointments = this.appointments.concat(cal.events);
    }
    // Add current org as default in case it doesn't have a calendar
    if (this.organisations.filter((o) => o.id === this.navigationService.currentNavigationOrg.id).length === 0) {
      this.organisations.push({
        id: this.navigationService.currentNavigationOrg.id,
        name: this.navigationService.currentNavigationOrg.name,
        color: this.colors[this.organisations.length],
        checked: true,
      });
    }

    this.source = JSON.parse(JSON.stringify(this.appointments));
    this.filter();
  }

  async currentDateChange($event: Date | number | string) {
    const newDate = new Date($event);
    if (!this.personal) {
      await this.getCalendar(newDate.getMonth(), newDate.getFullYear(), false);
    } else {
      await this.getPersonalCalendar(newDate.getMonth(), newDate.getFullYear(), false);
    }
  }

  async invite(appointmentData: PiiccoEvent, profiles: PublicProfile[]) {
    appointmentData.attendees = [];
    for (const profile of profiles) {
      const attendee: PiiccoEventAttendee = {
        attendee: profile,
        attendeeId: profile.id,
        answer: 'maybe',
      };
      appointmentData.attendees.push(attendee);
    }
    await this.calendarService.updateEvent(appointmentData);
  }

  deleteAppointment($event, model) {
    $event.stopPropagation();
    this.scheduler.instance.hideAppointmentTooltip();
    this.scheduler.instance.deleteAppointment(model.appointmentData);
  }

  async accept(appt: PiiccoEvent) {
    this.isSaving = true;
    await this.calendarService.rsvp(appt.calendarId, appt.id, 'yes');
    const notifId = this.notificationBellService.removeNotificationFromCalendarEvent(appt);
    if (notifId) {
      await this.notificationsService.markAsRead(notifId);
    }
    this.getMyCalendar(false);
    this.isSaving = false;
  }

  async reject(appt: PiiccoEvent) {
    this.isSaving = true;
    await this.calendarService.rsvp(appt.calendarId, appt.id, 'no');
    const notifId = this.notificationBellService.removeNotificationFromCalendarEvent(appt);
    if (notifId) {
      await this.notificationsService.markAsRead(notifId);
    }
    this.getMyCalendar(false);
    this.isSaving = false;
  }

  filter() {
    const selection = this.organisations.filter((appt) => appt.checked).map((appt) => appt.id);
    this.appointments = this.source.filter((appt) => selection.includes(appt['organisationId']));
  }

  onChangeFilter($event: MatCheckboxChange, org: CalendarIdNamePair) {
    org.checked = $event.checked;
    this.filter();
  }

  getEventType(eventType: string) {
    const currentLang = this.translate.currentLang;
    if (!this.eventTypes) {
      return '';
    }
    const found = this.eventTypes.filter((et) => et.code === eventType);
    if (found && found.length > 0) {
      return found[0][currentLang];
    } else {
      return eventType;
    }
  }

  getIcon(eventType: any) {
    if (!this.eventTypes) {
      return '';
    }

    const found = this.eventTypes.filter((et) => et.code === eventType);
    if (found && found.length > 0) {
      return found[0].icon;
    } else {
      return '';
    }
  }

  validURL(str) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  onAppointmentDeleting($event: any) {
    $event.cancel = true;

    const dialogData: ConfirmationDialogData = {
      title: this.translate.instant('common.delete'),
      text: this.translate.instant('common.confirm-delete'),
      icon: 'calendar_today',
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    dialogRef.componentInstance.accepted.subscribe(() => {
      const idx = this.appointments.indexOf($event.appointmentData);
      this.appointments.splice(idx, 1);
      this.onAppointmentDeleted($event);
      dialogRef.close();
    });
  }

  closeTooltip() {
    this.scheduler.instance.hideAppointmentTooltip();
  }

  openAddDialog() {
    this.scheduler.instance.showAppointmentPopup();
  }

  getStartDate(event: PiiccoEvent) {
    moment.locale(this.profilesService.getUserLang());

    if (this.profilesService.getUserLang() === 'en') {
      return moment(new Date(event.startDate).toUTCString()).format('MMM DD YYYY HH:mm');
    }

    return moment(new Date(event.startDate).toUTCString()).format('DD MMM YYYY HH:mm');
  }

  getEndDate(event: PiiccoEvent) {
    moment.locale(this.profilesService.getUserLang());

    if (this.profilesService.getUserLang() === 'en') {
      return moment(event.endDate).format('MMM DD YYYY HH:mm');
    }

    return moment(event.endDate).format('DD MMM YYYY HH:mm');
  }

  private async getMyCalendar(loadOrgs = true) {
    const currentDate: Date = new Date(this.scheduler.currentDate);
    await this.getPersonalCalendar(currentDate.getMonth(), currentDate.getFullYear(), loadOrgs);
  }

  private async getPersonalCalendar(month: number, year: number, loadOrgs = true) {
    const calendars = await this.calendarService.getMyCalendar(month, year);
    this.appointments = [];
    for (const cal of calendars) {
      for (const appt of cal.events) {
        appt['organisationId'] = cal.ownerId;
      }
      if (loadOrgs) {
        this.organisations.push({
          id: cal.owner.id,
          name: cal.owner.name,
          color: this.colors[this.organisations.length],
          checked: true,
        });
      }
      this.appointments = this.appointments.concat(cal.events);
    }
    this.source = JSON.parse(JSON.stringify(this.appointments));
    this.filter();
  }
}
