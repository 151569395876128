import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { CreateResource, Resource, ResourceVisibility, UpdateResource } from '../../organization/models/resources.model';
import HTTP_STATUS_CODES from 'http-status-enum';
import notify from 'devextreme/ui/notify';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
 

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {
  }

  clearCache() {
  }

  createResource(organizationId: string, data: CreateResource) {
    return new Promise<Resource>((res, rej) => {
      this.http.post<Resource>(
        `${environment.apiGateway}resources/organization/${organizationId}`,
        data
        ).take(1).subscribe(
        (response) => {
          notify(this.translate.instant("resource-management-dialog.resource-created"), "success");
          res(response);
        },
        (err: HttpErrorResponse) => {
          switch(err.status) {
            case HTTP_STATUS_CODES.BAD_REQUEST: 
              notify(this.translate.instant('resource-management-dialog.bad-request-error'), 'error');
              break;
            case HTTP_STATUS_CODES.FORBIDDEN:
              notify(this.translate.instant('resource-management.forbidden-create'), 'error');
              break;
            default:
              notify(this.translate.instant("common.error-occured"), 'error');
              break;
          }

          rej();
        }
      )
    });
  } 

  reorderResources(organizationId: string, newOrder: {id: string, order: number}[]) {
    return new Promise<Resource>((res, rej) => {
      this.http.put<Resource>(
        `${environment.apiGateway}resources/organization/${organizationId}/reorder`,
        newOrder
        ).take(1).subscribe(
        (response) => {
          res(response);
        },
        (err: HttpErrorResponse) => {
          rej();
        }
      )
    });
  } 

  updateResource(organizationId: string, resourceId: string, data: UpdateResource) {
    return new Promise<Resource>((res, rej) => {
      this.http.put<Resource>(
        `${environment.apiGateway}resources/organization/${organizationId}/resource/${resourceId}`,
        data
        ).take(1).subscribe(
        (response) => {
          notify(this.translate.instant("resource-management-dialog.resource-updated"), "success");
          res(response);
        },
        (err: HttpErrorResponse) => {

          switch(err.status) {
            case HTTP_STATUS_CODES.BAD_REQUEST: 
              notify(this.translate.instant('resource-management-dialog.bad-request-error'), 'error');
              break;
            case HTTP_STATUS_CODES.FORBIDDEN:
              notify(this.translate.instant('resource-management.forbidden-update'), 'error');
              break;
            default:
              notify(this.translate.instant("common.error-occured"), 'error');
              break;
          }

          rej();
        }
      )
    });
  } 

  deleteResource(organizationId: string, resourceId: string) {
    return new Promise<void>((res, rej) => {
      this.http.delete<void>(`${environment.apiGateway}resources/organization/${organizationId}/resource/${resourceId}`).take(1).subscribe(
        (response) => {
          notify(this.translate.instant("resource-management-dialog.resource-deleted"), "success");
          res();
        },
        (err: HttpErrorResponse) => {

          switch(err.status) {
            case HTTP_STATUS_CODES.NOT_FOUND:
              notify(this.translate.instant("resource-management.not-found"), "error");
              break;
            case HTTP_STATUS_CODES.FORBIDDEN:
              notify(this.translate.instant("resource-management.forbidden-delete"), "error");
              break;
            default:
              notify(this.translate.instant("common.error-occured"), "error");
              break;
          }

          rej();
        }
      )
    });
  }

  getOrganizationResources(organizationId: string) {
    return new Promise<Resource[]>((res, rej) => {
      this.http.get<Resource[]>(`${environment.apiGateway}resources/organization/${organizationId}`).take(1).subscribe(
        (response) => {
          res(response);
        },
        (err) => {
          res([]);
        }
      )
    });
  }
  
  getOrganizationResourcesByVisibility(organizationId: string, resourceVisibility: ResourceVisibility) {
    return new Promise<Resource[]>((res) => {
      this.http.get<Resource[]>(`${environment.apiGateway}resources/organization/${organizationId}/${resourceVisibility}`).take(1).subscribe(
        (response) => {
          res(response);
        },
        (err) => {
          res([]);
        }
      )
    });
  }

}
