import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import Pusher from 'pusher-js';
import { ProfilesService } from '../../../core/services/profiles.service';
import { Subject } from 'rxjs';
import { ChatService } from '../../../chat/services/chat.service';

enum pusherMessages {
  chatMessage = 'chat-message',
  chatViewed = 'chat-viewed',
  notificationOrganizationInvite = 'notification-OrganizationInvite',
  notificationOrganizationToOrganizationInvite = 'notification-OrganizationToOrganizationInvite',
  notificationProfileInvite = 'notification-ProfileInvite',
  notificationEvaluationInvite = 'notification-EvaluationInvite',
  notificationNewOrganizationPost = 'notification-OrganizationPost',
  notificationEventInvite = 'notification-EventInvite',
  notificationEventModify = 'notification-EventModify',
  notificationEventDelete = 'notification-EventDelete',
  notifcationPostComment = 'notification-PostComment',
  notifcationPostLike = 'notification-PostLike',
  notificationStartersUpdated = 'notification-StartersUpdate',
  notificationUnreadChat = 'notification-UnreadChatMessage',
  notificationUnprocessedChildNotification = 'notification-UnprocessedChildNotification',
  notificationChildShareInvite = 'notification-ChildShareInvite',
  notificationChildDeleted = 'notification-ChildDeleted',
  notificationChildEmancipated = 'notification-ChildEmancipated',
  notificationProfileEmancipated = 'notification-ProfileEmancipated',
  notificationProfileTransfered = 'notification-ProfileTransfered',
}

@Injectable({
  providedIn: 'root',
})
export class PusherNotificationService implements OnDestroy {
  pusher: any;
  channel: any;

  public chatMessageSubject = new Subject();
  public chatViewedSubject = new Subject();
  public notificationOrganizationInviteSubject = new Subject();
  public notificationOrganizationToOrganizationInviteSubject = new Subject();
  public notificationProfileInviteSubject = new Subject();
  public notificationNewOrganizationPostSubject = new Subject();
  public notificationEvaluationInviteSubject = new Subject();
  public notificationEventInviteSubject = new Subject();
  public notificationEventModifySubject = new Subject();
  public notificationEventDeleteSubject = new Subject();
  public notificationPostCommentSubject = new Subject();
  public notificationPostLikeSubject = new Subject();
  public notificationStartersUpdatedSubject = new Subject();
  public notificationUnreadChatMessageSubject = new Subject();
  public notificationUnprocessedChildNotificationSubject = new Subject();
  public notificationChildShareInviteSubject = new Subject();
  public notificationChildDeletedSubject = new Subject();
  public notificationChildEmancipatedSubject = new Subject();
  public notificationProfileEmancipatedSubject = new Subject();
  public notificationProfileTransferedSubject = new Subject();

  public chatMessageBinding;
  public chatViewedBinding;
  public notificationOrganizationInviteBinding;
  public notificationOrganizationToOrganizationInviteBinding;
  public notificationProfileInviteBinding;
  public notificationEvaluationInviteBinding;
  public notificationNewOrganizationPostBinding;
  public notificationEventInviteBinding;
  public notificationEventModifyBinding;
  public notificationEventDeleteBinding;
  public notificationPostCommentBinding;
  public notificationPostLikeBinding;
  public notificationStartersUpdatedBinding;
  public notificationUnreadChatMessageBinding;
  public notificationUnprocessedChildNotificationBinding;
  public notificationChildShareInviteBinding;
  public notificationChildDeletedBinding;
  public notificationChildEmancipatedBinding;
  public notificationProfileEmancipatedBinding;
  public notificationProfileTransferedBinding;

  constructor(private http: HttpClient, private profilesService: ProfilesService, private chatService: ChatService) {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
    });
  }

  ngOnDestroy(): void {
    this.chatMessageSubject.unsubscribe();
    this.chatViewedSubject.unsubscribe();
    this.notificationOrganizationInviteSubject.unsubscribe();
    this.notificationOrganizationToOrganizationInviteSubject.unsubscribe();
    this.notificationProfileInviteSubject.unsubscribe();
    this.notificationEvaluationInviteSubject.unsubscribe();
    this.notificationNewOrganizationPostSubject.unsubscribe();
    this.notificationEventInviteSubject.unsubscribe();
    this.notificationEventModifySubject.unsubscribe();
    this.notificationEventDeleteSubject.unsubscribe();
    this.notificationPostCommentSubject.unsubscribe();
    this.notificationPostLikeSubject.unsubscribe();
    this.notificationStartersUpdatedSubject.unsubscribe();
    this.notificationUnreadChatMessageSubject.unsubscribe();
    this.notificationUnprocessedChildNotificationSubject.unsubscribe();
    this.notificationChildShareInviteSubject.unsubscribe();
    this.notificationChildDeletedSubject.unsubscribe();
    this.notificationChildEmancipatedSubject.unsubscribe();
    this.notificationProfileEmancipatedSubject.unsubscribe();
    this.notificationProfileTransferedSubject.unsubscribe();

    this.chatMessageBinding.unbind();
    this.chatViewedBinding.unbind();
    this.notificationEvaluationInviteBinding.unbind();
    this.notificationOrganizationInviteBinding.unbind();
    this.notificationOrganizationToOrganizationInviteBinding.unbind();
    this.notificationProfileInviteBinding.unbind();
    this.notificationNewOrganizationPostBinding.unbind();
    this.notificationEventInviteBinding.unbind();
    this.notificationEventModifyBinding.unbind();
    this.notificationEventDeleteBinding.unbind();
    this.notificationPostCommentBinding.unbind();
    this.notificationPostLikeBinding.unbind();
    this.notificationStartersUpdatedBinding.unbind();
    this.notificationUnreadChatMessageBinding.unbind();
    this.notificationUnprocessedChildNotificationBinding.unbind();
    this.notificationChildShareInviteBinding.unbind();
    this.notificationChildDeletedBinding.unbind();
    this.notificationChildEmancipatedBinding.unbind();
    this.notificationProfileEmancipatedBinding.unbind();
    this.notificationProfileTransferedBinding.unbind();
  }

  getChannel(channelName: string): any {
    return this.pusher.subscribe(channelName);
  }

  async initDispatcher() {
    const profileId: string = await this.profilesService.getUserId();
    this.channel = this.getChannel(profileId);

    this.initChatDispatchers();
    this.initNetworkDispatchers();
    this.initCalendarDispatchers();

    this.notificationEvaluationInviteBinding = this.channel.bind(pusherMessages.notificationEvaluationInvite, (data) => {
      this.notificationEvaluationInviteSubject.next(data);
    });

    this.notificationStartersUpdatedBinding = this.channel.bind(pusherMessages.notificationStartersUpdated, (data) => {
      this.notificationStartersUpdatedSubject.next(data);
    });

    this.notificationUnprocessedChildNotificationBinding = this.channel.bind(
      pusherMessages.notificationUnprocessedChildNotification,
      (data) => {
        this.notificationUnprocessedChildNotificationSubject.next(data);
      }
    );

    this.notificationChildShareInviteBinding = this.channel.bind(pusherMessages.notificationChildShareInvite, (data) => {
      this.notificationChildShareInviteSubject.next(data);
    });

    this.notificationChildDeletedBinding = this.channel.bind(pusherMessages.notificationChildDeleted, (data) => {
      this.notificationChildDeletedSubject.next(data);
    });

    this.notificationChildEmancipatedBinding = this.channel.bind(pusherMessages.notificationChildEmancipated, (data) => {
      this.notificationChildEmancipatedSubject.next(data);
    });

    this.notificationProfileEmancipatedBinding = this.channel.bind(pusherMessages.notificationProfileEmancipated, (data) => {
      this.notificationProfileEmancipatedSubject.next(data);
    });

    this.notificationProfileTransferedBinding = this.channel.bind(pusherMessages.notificationProfileTransfered, (data) => {
      this.notificationProfileTransferedSubject.next(data);
    });
  }

  private initChatDispatchers() {
    this.chatMessageBinding = this.channel.bind(pusherMessages.chatMessage, (data) => {
      this.processChatsCounters(data);
      this.chatMessageSubject.next(data);
    });

    this.chatViewedBinding = this.channel.bind(pusherMessages.chatViewed, (data) => {
      this.chatViewedSubject.next(data);
    });

    this.notificationUnreadChatMessageBinding = this.channel.bind(pusherMessages.notificationUnreadChat, (data) => {
      this.notificationUnreadChatMessageSubject.next(data);
    });
  }

  private initNetworkDispatchers() {
    this.notificationOrganizationInviteBinding = this.channel.bind(pusherMessages.notificationOrganizationInvite, (data) => {
      this.notificationOrganizationInviteSubject.next(data);
    });

    this.notificationOrganizationToOrganizationInviteBinding = this.channel.bind(
      pusherMessages.notificationOrganizationToOrganizationInvite,
      (data) => {
        this.notificationOrganizationToOrganizationInviteSubject.next(data);
      }
    );

    this.notificationProfileInviteBinding = this.channel.bind(pusherMessages.notificationProfileInvite, (data) => {
      this.notificationProfileInviteSubject.next(data);
    });

    this.notificationNewOrganizationPostBinding = this.channel.bind(pusherMessages.notificationNewOrganizationPost, (data) => {
      this.notificationNewOrganizationPostSubject.next(data);
    });

    this.notificationPostCommentBinding = this.channel.bind(pusherMessages.notifcationPostComment, (data) => {
      this.notificationPostCommentSubject.next(data);
    });

    this.notificationPostLikeBinding = this.channel.bind(pusherMessages.notifcationPostLike, (data) => {
      this.notificationPostLikeSubject.next(data);
    });
  }

  private initCalendarDispatchers() {
    this.notificationEventInviteBinding = this.channel.bind(pusherMessages.notificationEventInvite, (data) => {
      this.notificationEventInviteSubject.next(data);
    });

    this.notificationEventModifyBinding = this.channel.bind(pusherMessages.notificationEventModify, (data) => {
      this.notificationEventModifySubject.next(data);
    });

    this.notificationEventDeleteBinding = this.channel.bind(pusherMessages.notificationEventDelete, (data) => {
      this.notificationEventDeleteSubject.next(data);
    });
  }

  private processChatsCounters(data) {
    if (this.chatService.chats) {
      const chat = this.chatService.chats.profileChats.filter((c) => c.id === data.chatId);
      if (chat && chat.length > 0) {
        this.chatService.unreadPersonal += 1;
      } else {
        if (data.sender.id !== this.profilesService.getUserId()) {
          this.chatService.unreadOrg += 1;
        }
      }
    }
  }
}
