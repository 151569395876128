import { createAction, props } from '@ngrx/store';
import { AuthInfo, LoginCredentials } from './auth.model';
import UserCredential = firebase.auth.UserCredential;


export interface RegistrationInfo {
  email: string;
  password: string;
  lastname: string;
  firstname: string;
}

export interface CredentialInfo {
  userCredentials: UserCredential;
  lastname: string;
  firstname: string;
  redirectUrl?: string;
}

export const SetAuthInfoAction = createAction(
  '[Auth] - Set Auth Info',
  props<{ authInfo: AuthInfo }>()
);

export const ClearAuthInfoAction = createAction(
  '[Auth] - Clear Auth State'
);

export const LoginAction = createAction(
  '[Auth] - Login',
  props<{ credentials: LoginCredentials }>()
);

export const LoginSuccessAction = createAction(
  '[Auth] - Login Success'
);

export const LoginErrorAction = createAction(
  '[Auth] - Login error',
  props<{ error?: any }>()
);

export const LogoutAction = createAction(
  '[Auth] - Logout'
);

export const RegisterAction = createAction(
  '[Auth] - Register',
  props<{ registrationInfo: RegistrationInfo, redirectUrl?: string }>()
);

export const RegisterSuccessAction = createAction(
  '[Auth] - Register Success'
);

export const RegisterErrorAction = createAction(
  '[Auth] - Register error');

export const ProfileInitAction = createAction(
  '[Auth] - Profile Init',
  props<{ credentialInfo: CredentialInfo, redirectUrl?: string  }>()
);

export const ProfileInitSuccessAction = createAction(
  '[Auth] - Profile Init Success',
  props<{redirectUrl?: string}>()
);


export const ForgotPasswordSuccessAction = createAction(
  '[Auth] - Forgot Password Success'
);

export const ForgotPasswordErrorAction = createAction(
  '[Auth] - Forgot Password Error',
  props<{ error?: any}>()
);
