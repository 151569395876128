<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <div class="dropdown-heading">{{ 'help-dropdown.title' | translate }}</div>
      <div class="dropdown-subheading"></div>
    </div>
  </div>
  <div class="dropdown-content">
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <!--<span  style="font-weight: bold;">{{'help-dropdown.instructions-title' | translate}}</span> <br />-->
      <p>
        <a class="link" style="color: white; text-decoration: underline" target="_blank" [href]="instructions">{{
          'help-dropdown.instructions' | translate
        }}</a>
      </p>
      <p style="padding-top: 10px">
        <a class="link" style="color: white; text-decoration: underline" target="_blank" [href]="athleteInstructions">{{
          'help-dropdown.instructions-athlete' | translate
        }}</a>
      </p>

      <p class="mt-4" style="font-weight: bold">{{ 'help-dropdown.send-us-message-title' | translate }}</p>
      <br />
      <p style="text-align: center">{{ 'help-dropdown.send-us-message-text' | translate }}</p>
      <br />
      <div class="mt-2">
        <button color="primary" mat-button mat-raised-button type="button" (click)="openFeedbackDialog()">
          {{ 'help-dropdown.send-message' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
