<vex-dialog-header
    title="{{'feedback-dialog.title' | translate}}"
    titleIcon="insert_comment"
></vex-dialog-header>

<mat-dialog-content >
    <div fxLayout="row wrap" fxLayoutAlign="center center">   
        <div fxFlex="100">
            <form [formGroup]="form" fxLayout="row wrap" *ngIf="!isSaving">
                <mat-form-field style="width: 100%" appearance="outline">
                    <textarea autocomplete="off" placeholder="{{'feedback-dialog.message' | translate}}" formControlName="text" matInput ></textarea>
                </mat-form-field>
            </form>
        </div>

        <div fxFlex="100">
            <vex-file-uploader
                (filedChanged)="files = $event"
                (filedInfosChanged)="filesInfo = $event"
                [maxFile]="1"
            ></vex-file-uploader>
        </div>
    </div>
</mat-dialog-content>

<vex-dialog-actions
    #actions
    [acceptDisabled]="submitDisabled"
    [cancelDisabled]="isSaving"
    acceptButtonText="{{'feedback-dialog.send' | translate}}"
    cancelButtonText="{{'feedback-dialog.close' | translate}}"
    (accept)="save()"
    (cancel)="close()"
    [saving]="isSaving"
>

</vex-dialog-actions>