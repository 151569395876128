import { environment } from 'src/environments/environment';

import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { OrganizationReducer } from '../organization/store/organization.reducer';
import { OrganizationState } from '../organization/store/organization.state';
import { AuthState } from './auth/auth.model';
import { AuthReducer } from './auth/auth.reducers';
import { GlobalState } from './global/global.model';
import { GlobalReducer } from './global/global.reducers';

export interface State {
  global: GlobalState;
  auth: AuthState;
  organization: OrganizationState;
}

export const reducers: ActionReducerMap<State> = {
  global: GlobalReducer,
  auth: AuthReducer,
  organization: OrganizationReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
