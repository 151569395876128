import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import icPerson from '@iconify/icons-ic/twotone-person';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import {AngularFireAuth} from '@angular/fire/auth';
import { getProfileImage } from 'src/app/core/utils/profile-photo.util';
import { TargetedEntity } from 'src/app/shared/models/dialog.model';
import { PiiccoProfile } from 'src/app/models/piiccoProfile';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

  dropdownOpen: boolean;
  icPerson = icPerson;
  userName: any;
  currentProfile: PiiccoProfile;

  constructor(private popover: PopoverService,
              private profilesService: ProfilesService,
              private afAuth: AngularFireAuth,
              private cd: ChangeDetectorRef) { }

  async ngOnInit() {
    this.afAuth.user.subscribe(async(user) => {

      if(!!user) {
        this.currentProfile = await this.profilesService.getProfile();
        this.userName = `${this.currentProfile.firstName} ${this.currentProfile.lastName}`;
      } else {
        this.userName = "";
      }
      this.cd.detectChanges();
    });
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

  getProfileImage() {
    if (this.currentProfile)
    {
      return getProfileImage(
        this.currentProfile.firstName + " " + this.currentProfile.lastName, 
        TargetedEntity.Profile, 
        this.currentProfile.photo
      );  
    }
    else
    {
      return null;
    }
  }

}
