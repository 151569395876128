import { Action, createReducer, on } from '@ngrx/store';
import { SetLanguageAction } from './global.actions';
import { GlobalState } from './global.model';
import { initalState } from './global.state';

export const reducer = createReducer(
  initalState,
  on(SetLanguageAction, (state: GlobalState, { language }) => ({
    ...state,
    language
  }))
);

export function GlobalReducer(state: GlobalState, action: Action): GlobalState {
  return reducer(state, action);
}
