import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-outdated-app-dialog',
  templateUrl: './outdated-app-dialog.component.html',
  styleUrls: ['./outdated-app-dialog.component.scss'],
})
export class OutdatedAppDialogComponent implements OnInit {
  constructor(public translate: TranslateService, private dialogRef: MatDialogRef<OutdatedAppDialogComponent>) {}

  ngOnInit(): void {}
}
