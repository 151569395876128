import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import {
  LANDSCAPE_IMAGES_FORMAT,
  PORTRAIT_IMAGES_FORMAT,
  POST_LANDSCAPE_IMAGE_WIDTH,
  POST_PORTRAIT_IMAGE_HEIGHT,
} from 'src/app/core/config/file-format.config';
import { FileUploaderFileInfo, ImageCropperDialogData } from '../../models/dialog.model';
import { ImageCropperDialogComponent } from '../image-cropper-dialog/image-cropper-dialog.component';
import { Ng2ImgMaxService } from 'ng2-img-max';
import notify from 'devextreme/ui/notify';
import { FileFormatConfig } from 'src/app/core/models/file-format-config.model';
import { ImageService } from 'src/app/core/services/image.service';

@Component({
  selector: 'vex-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploaderComponent implements OnInit {
  @ViewChild('input') input: ElementRef;
  @Input() maxFile = 5;
  @Input() imageFormat = LANDSCAPE_IMAGES_FORMAT;
  @Input() openCropper = true;

  @Output() filedChanged = new EventEmitter<any[]>();
  @Output() filedInfosChanged = new EventEmitter<FileUploaderFileInfo[]>();

  imageUrls: string[] = [];
  imageInfos: FileUploaderFileInfo[] = [];
  gridHeight = '400px';

  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
    private ng2ImgMax: Ng2ImgMaxService,
    private imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.gridHeight = window.innerWidth <= 800 ? '250px' : '300px';
  }

  openFileDialog() {
    if (this.imageUrls.length < this.maxFile) {
      this.input.nativeElement.click();
    }
  }

  get inputLabel() {
    if (this.maxFile < 2) {
      return this.translate.instant('components.file-uploader.upload-photo');
    }

    return this.translate.instant('components.file-uploader.upload-photos');
  }

  async handleFileInput(event) {
    const selectedFile = event.target.files[0];
    if (selectedFile && this.openCropper) {
      const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
        disableClose: true,
        width: '600px',
        data: {
          event: event,
          cropperConfig: {},
        } as ImageCropperDialogData,
      });

      dialogRef.afterClosed().subscribe((base64: ImageCroppedEvent) => {
        if (base64) {
          this.imageUrls.push(base64.base64);
          this.imageInfos.push({
            height: base64.height,
            mimeType: event.target.files[0].type,
            originalName: event.target.files[0].name,
            width: base64.width,
          });
          this.filedChanged.emit(this.imageUrls.map((i) => i.split(',')[1]));
          this.filedInfosChanged.emit(this.imageInfos);
        }
      });
    } else {
      try {
        if (await this.imageService.imageIsGif(selectedFile)) {
          const base64 = await this.imageService.getBase64Image(selectedFile);
          const image = await this.imageService.readImage(selectedFile);

          this.imageUrls.push(base64);
          this.imageInfos.push({
            height: image.height,
            mimeType: event.target.files[0].type,
            originalName: event.target.files[0].name,
            width: image.width,
          });

          this.filedChanged.emit(this.imageUrls.map((i) => i.split(',')[1]));
          this.filedInfosChanged.emit(this.imageInfos);
        } else {
          const base64 = await this.resize(selectedFile);
          const image = await this.imageService.readImage(selectedFile);

          this.imageUrls.push(base64);
          this.imageInfos.push({
            height: image.height,
            mimeType: event.target.files[0].type,
            originalName: event.target.files[0].name,
            width: image.width,
          });

          this.filedChanged.emit(this.imageUrls.map((i) => i.split(',')[1]));
          this.filedInfosChanged.emit(this.imageInfos);
        }
      } catch (error) {
        // TODO: Add translation
        notify('', 'error');
      }
    }
  }

  removeImage(event, index) {
    event.stopPropagation();
    this.imageUrls = this.imageUrls.filter((i, indx) => indx !== index);
    this.imageInfos = this.imageInfos.filter((i, indx) => indx !== index);
    this.filedChanged.emit(this.imageUrls.map((i) => i.split(',')[1]));
    this.filedInfosChanged.emit(this.imageInfos);
  }

  onResize(event) {
    if (event.target.innerWidth < 600) {
      this.gridHeight = '200px';
    }
  }

  get mainPhoto() {
    if (this.imageUrls.length > 0) {
      return this.imageUrls[0];
    }
    return null;
  }

  get secondaryPhotoList() {
    if (this.imageUrls.length <= 1) {
      return [];
    }

    return this.imageUrls.slice(1, this.imageUrls.length);
  }

  private async resize(originalImage: any | undefined) {
    if (this.imageService.imageIsPortrait(originalImage)) {
      return await this.imageService.resizePortraitImage(originalImage, POST_PORTRAIT_IMAGE_HEIGHT);
    } else {
      return await this.imageService.resizeLandscapeImage(originalImage, POST_LANDSCAPE_IMAGE_WIDTH);
    }
  }
}
