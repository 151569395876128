export const YEAR_ONLY_DATE = {
    fr: "YYYY",
    en: "YYYY"
};

export const YEAR_MONTH_DATE = {
    fr: "MMM YYYY",
    en: "MMM YYYY"
};

export const YEAR_MONTH_DAY_DATE = {
    fr: "DD MMM YYYY",
    en: "MMM DD YYYY"
};