<mat-dialog-actions class="sm-p-b" [fxLayoutAlign]="alignCenter ? 'center center' : 'end center'" fxLayout="row wrap">
    
    <div 
        fxFlex="100"
        [fxLayoutAlign]="alignCenter ? 'center center' : 'end center'" fxLayout="row wrap"
    >
        <button 
            *ngIf="!hideCancelButton"
            [disabled]="cancelDisabled"
            class="rounded-button cancel-button"
            disableRipple
            mat-button 
            (click)="cancelClick()"
        >
            {{cancelButtonText}}
        </button>

        <button    
            *ngIf="!hideSaveButton"
            mat-stroked-button
            #submitButton
            class="dialog-save-button rounded-button"
            [disabled]="acceptDisabled"
            mat-button 
            (click)="acceptClick()"
            [class.spinner]="saving"
        >
            {{acceptButtonText}}
        </button>
    </div>
    
</mat-dialog-actions>
