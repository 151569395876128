import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, PiiccoNotificationItem } from 'src/app/core/models/notification.model';
import { Roles } from 'src/app/core/models/roles.enum';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { RightsService } from 'src/app/core/services/rights.service';
import { PiiccoCurrentOrganization, PiiccoProfile } from 'src/app/models/piiccoProfile';
import { Organization } from 'src/app/organization/models/organization';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { ConfirmationDialogData, ProfileRightsDialogData, TargetedEntity } from '../../models/dialog.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ProfileRightsDialogComponent } from '../profile-rights-dialog/profile-rights-dialog.component';
import { NetworksService } from 'src/app/network/services/networks.service';
import { InformationDialogService } from 'src/app/core/services/information-dialog.service';
import { OrganizationsService } from 'src/app/organization/services/organizations.service';
import { getDefaultImage, getProfileImage } from 'src/app/core/utils/profile-photo.util';
import { map, takeUntil } from 'rxjs/operators';
import { ObserverComponent } from 'src/app/core/components/observer.component';
import { Observable, of } from 'rxjs';
import { UserRights } from 'src/app/core/models/user-rights.model';

@Component({
  selector: 'vex-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileCardComponent extends ObserverComponent implements OnInit {
  @Input() id: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() image: string;
  @Input() topCornerInfo = '';
  @Input() currentProfileOrganization: PiiccoCurrentOrganization = null;
  @Input() secondaryInfos: string | null = null;
  @Input() otherInfos: string | null = null;
  @Input() clickable = true;
  @Input() lowerCaseName = false;
  @Input() ngContentWidth = 70;
  @Input() ltSmNgContentWidth = 100;
  @Input() removeNgContentPadding = false;
  @Input() showAdminButtons = true;
  @Input() showNetworkButtons = false;
  @Input() hideBadge = true;
  @Input() badgeContent: string | number = '';
  @Input() profileRights: Roles[];
  @Input() currentOrganization?: Organization;
  @Output() selected = new EventEmitter<string>();
  @Output() removed = new EventEmitter<void>();
  @Output() rightsChanged = new EventEmitter<void>();

  isAdmin = false;
  isSuperAdmin = false;
  hasNetworkFeatureAccess = true;
  dialogRef: MatDialogRef<ProfileRightsDialogComponent>;
  disconnectDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  profileData: PiiccoProfile;
  pendingInvitations$: Observable<PiiccoNotificationItem[]> = of([]);
  getDefaultImage = getDefaultImage;
  getProfileImage = getProfileImage;

  UserRights = UserRights;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private dialog: MatDialog,
    private profilesService: ProfilesService,
    private notificationsService: NotificationsService,
    private rightsService: RightsService,
    private networkService: NetworksService,
    private informationDialogService: InformationDialogService,
    private organizationsService: OrganizationsService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.currentOrganization && changes.currentOrganization.currentValue) {
      await this.setup(changes.currentOrganization.currentValue);
    }
  }

  async ngOnInit() {
    if (this.currentOrganization) {
      await this.setup(this.currentOrganization);
    }

    this.hasNetworkFeatureAccess = this.rightsService.hasFeatureAccess(
      await this.profilesService.getProfile(),
      UserRights.NetworkRelations
    );
    this.profileData = await this.profilesService.getProfile();
    this.pendingInvitations$ = await this.notificationsService.pendingNotifications;
  }

  async setup(organization: Organization) {
    const userOrganizationList = await new Promise<Organization[]>((res) =>
      this.profilesService.getOrganizations().subscribe(
        (response) => res(response),
        (error) => res([])
      )
    );

    this.isAdmin = await this.rightsService.hasRightsOnOrganization(organization.id, userOrganizationList, [
      Roles.Admin,
      Roles.SuperAdmin,
    ]);
    this.isSuperAdmin = await this.rightsService.hasRightsOnOrganization(organization.id, userOrganizationList, [
      Roles.SuperAdmin,
    ]);
    this.hasNetworkFeatureAccess = this.rightsService.hasFeatureAccess(
      await this.profilesService.getProfile(),
      UserRights.NetworkRelations
    );
  }

  get profileImage() {
    return getProfileImage(this.firstName + ' ' + this.lastName, TargetedEntity.Profile, this.image);
  }

  getTeamName() {
    if (!this.currentProfileOrganization && this.secondaryInfos) {
      return this.secondaryInfos;
    }
    if (!this.currentProfileOrganization) {
      return '';
    }

    return this.currentProfileOrganization.organizationName;
  }

  getTeamClassAndPosition() {
    if (!this.currentProfileOrganization && this.otherInfos) {
      return this.otherInfos;
    }

    if (!this.currentProfileOrganization) {
      return '';
    }

    let teamClass = '';
    let teamGender = '';
    let teamCategory = '';
    let position = '';

    if (this.currentProfileOrganization?.teamClass) {
      teamClass = this.currentProfileOrganization?.teamClass[this.userLang];
    }

    if (this.currentProfileOrganization?.teamGender) {
      teamGender = this.currentProfileOrganization?.teamGender;
    }

    if (this.currentProfileOrganization?.teamCategory) {
      teamClass = this.currentProfileOrganization?.teamCategory[this.userLang];
    }

    if (this.currentProfileOrganization?.position) {
      position = this.currentProfileOrganization?.position[this.userLang];
    }

    return `${teamClass} ${teamGender} ${teamCategory} - ${position}`;
  }

  getDefaultLogo($event) {
    getDefaultImage($event, this.firstName + ' ' + this.lastName, TargetedEntity.Profile);
  }

  openDialog() {
    const dialogData: ProfileRightsDialogData = {
      profileId: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      photo: this.profileImage,
      selectedOrganization: this.currentOrganization,
      currentRoles: [],
      editorRights: this.isSuperAdmin ? [Roles.SuperAdmin] : [Roles.Admin],
    };

    this.dialogRef = this.dialog.open(ProfileRightsDialogComponent, {
      width: '600px',
      data: dialogData,
    });

    this.dialogRef.componentInstance.rightsChanged.pipe(takeUntil(this.destroy$)).subscribe(() => this.rightsChanged.emit());
  }

  openDeleteConfirmationDialog() {
    const dialogRef = this.informationDialogService.open({
      text: this.translate.instant('components.profile-card.organization-removal-text'),
      title: this.translate.instant('components.profile-card.confirm-removal'),
      alignCenter: false,
      okButtonText: this.translate.instant('common.remove'),
      icon: 'delete',
    });

    dialogRef.componentInstance.accepted.subscribe(async (r) => {
      try {
        dialogRef.componentInstance.data.isSaving = true;
        await this.organizationsService.removerUserFromOrganization(this.currentOrganization.id, this.id);

        dialogRef.componentInstance.data.isSaving = false;

        dialogRef.close();
        this.removed.emit();
      } catch (error) {}
    });
  }

  get userLang() {
    return this.profilesService.getUserLang();
  }

  topCornerButtonClick(event) {
    event.stopPropagation();
  }

  isProfileMyself() {
    // Check the current profile is not the logged in profile
    return !this.id || this.id == this.profileData?.id;
  }

  isProfileFriend() {
    // Check if the logged in profile is friend with the current profile
    return this.profileData?.friends && this.profileData?.friends.indexOf(this.id) >= 0;
  }

  isInvitationPending() {
    return this.pendingInvitations$.pipe(
      map((x) => {
        return x.some((i) => {
          return (
            (i.type === NotificationType.ProfileInvite && i.inviteeProfile.id === this.id) ||
            this.notificationsService.userHasPendingInvite(this.id)
          );
        });
      })
    );
  }

  async connectToProfile(event) {
    event.stopPropagation();
    // Create an invitation to connect and send it to the other user
    if (this.profileData && this.id) {
      await this.notificationsService.inviteProfile(this.profileData?.id, this.id);
    }
  }

  disconnectFromProfile(event) {
    event.stopPropagation();
    // Disconnect an existing relation
    const dialogData: ConfirmationDialogData = {
      text: this.translate.instant('components.profile-card.disconnect-text'),
      title: this.translate.instant('components.profile-card.disconnect-title'),
      icon: 'person_remove',
    };

    this.disconnectDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    this.disconnectDialogRef.componentInstance.okCaption = 'common.yes';
    this.disconnectDialogRef.componentInstance.cancelCaption = 'common.no';
    this.disconnectDialogRef.componentInstance.accepted.subscribe(async () => {
      try {
        const updatedUser = await this.networkService.friendDisconnect(this.id);
        this.profileData = updatedUser;
        this.profilesService.updateProfileCache(updatedUser);

        this.disconnectDialogRef.close();
      } catch (error) {
        this.disconnectDialogRef.close();
      }
    });

    this.disconnectDialogRef.componentInstance.refused.subscribe(() => {
      this.dialogRef.close();
    });

    this.disconnectDialogRef.afterClosed().subscribe(() => {
      this.dialogRef = undefined;
    });
  }

  launchChat(event) {
    event.stopPropagation();
    this.router.navigateByUrl(`/chat/${this.id}`);
  }
}
