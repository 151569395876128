import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, PiiccoNotificationItem, PiiccoNotification } from 'src/app/core/models/notification.model';
import { UserRights } from 'src/app/core/models/user-rights.model';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { RightsService } from 'src/app/core/services/rights.service';
import { Evaluation } from 'src/app/evaluation/models/evaluation.model';
import { PiiccoEvent } from 'src/app/models/calendar';
import { PiiccoProfile } from 'src/app/models/piiccoProfile';
import { Post } from 'src/app/network/models/timeline.model';
import { Organization } from 'src/app/organization/models/organization';

@Component({
  selector: 'vex-notification-bell-item',
  templateUrl: './notification-bell-item.component.html',
  styleUrls: ['./notification-bell-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationBellItemComponent implements OnInit {
  @Input() notification: PiiccoNotificationItem;
  @Output() markAsRead = new EventEmitter<PiiccoNotificationItem>();
  @Output() refuseRSVPInvite = new EventEmitter<PiiccoNotificationItem>();
  @Output() hidePopOp = new EventEmitter<undefined>();
  @Output() accept = new EventEmitter<PiiccoNotificationItem>();

  notificationText = '';
  notificationIcon = '';
  show = true;
  showAcceptButton = true;
  isRSVPEvent = false;
  hasEventRSVPAccess = true;
  UserRights = UserRights;

  NotificationType = NotificationType;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private profilesService: ProfilesService,
    private rightsService: RightsService
  ) {}

  async ngOnInit() {
    this.hasEventRSVPAccess = this.rightsService.hasFeatureAccess(await this.profilesService.getProfile(), UserRights.EventRSVP);
    switch (this.notification.type) {
      case NotificationType.EvaluationInvite:
        this.notificationText = this.translate.instant('components.notification-bell.eval-invite-text', {
          name: (this.notification as PiiccoNotification<Evaluation>).reference.title,
        });
        this.notificationIcon = 'poll';
        this.showAcceptButton = false;
        break;
      case NotificationType.OrganizationInvite:
        this.notificationText = this.translate.instant('components.notification-bell.org-invite-text', {
          name: this.notification.inviterOrganization.name,
        });
        this.notificationIcon = 'groups';
        break;
      case NotificationType.ProfileInvite:
        this.notificationText = this.translate.instant('components.notification-bell.profile-invite-text', {
          name: `${this.notification.inviterProfile.firstName} ${this.notification.inviterProfile.lastName}`,
        });
        this.notificationIcon = 'person_add';
        break;
      case NotificationType.OrgToOrgInvite:
        this.notificationText = this.translate.instant('components.notification-bell.org-to-org-invite-text', {
          inviterOrganization: this.notification.inviterOrganization.name,
          inviteeOrganization: this.notification.inviteeOrganization.name,
        });
        this.notificationIcon = 'group_add';
        break;
      case NotificationType.OrganizationPost:
        this.notificationText = this.translate.instant('components.notification-bell.post-invite-text', {
          inviterOrganization: this.notification.inviterOrganization.name,
        });
        this.notificationIcon = 'announcement';
        this.showAcceptButton = false;
        break;
      case NotificationType.EventInvite:
        this.notificationText = this.translate.instant('components.notification-bell.event-invite-text', {
          eventName: (this.notification.reference as PiiccoEvent).text,
        });
        this.notificationIcon = 'event';
        this.isRSVPEvent = true;
        break;
      case NotificationType.EventDelete:
        this.notificationText = this.translate.instant('components.notification-bell.event-delete-text', {
          eventName: (this.notification.reference as PiiccoEvent).text,
        });
        this.notificationIcon = 'event_busy';
        this.showAcceptButton = false;

        break;
      case NotificationType.EventModify:
        this.notificationText = this.translate.instant('components.notification-bell.event-modify-text', {
          eventName: (this.notification.reference as PiiccoEvent).text,
        });
        this.notificationIcon = 'event_calendar';
        this.showAcceptButton = false;
        break;
      case NotificationType.PostComment:
        this.notificationText = this.translate.instant('components.notification-bell.post-comment-text', {
          name: `${this.notification.inviterProfile.firstName} ${this.notification.inviterProfile.lastName}`,
        });
        this.notificationIcon = 'post_add';
        this.showAcceptButton = false;
        break;
      case NotificationType.PostLike:
        this.notificationText = this.translate.instant('components.notification-bell.post-like-text', {
          name: `${this.notification.inviterProfile.firstName} ${this.notification.inviterProfile.lastName}`,
        });
        this.notificationIcon = 'post_add';
        this.showAcceptButton = false;
        break;
      case NotificationType.StartersUpdate:
        this.notificationText = this.translate.instant('components.notification-bell.roster-updated-text', {
          date: this.getDateFormatted((this.notification.reference as any).startersDate),
        });
        this.notificationIcon = 'groups';
        this.showAcceptButton = false;
        break;
      case NotificationType.UnreadChatMessage:
        this.notificationText = this.translate.instant('components.notification-bell.chat-message-text');
        this.notificationIcon = 'chat';
        this.showAcceptButton = false;
        break;
      case NotificationType.UnprocessedChildNotification:
        this.notificationText = this.translate.instant('components.notification-bell.unprocessed-child-notification');
        this.notificationIcon = 'announcement';
        this.showAcceptButton = false;
        break;
      case NotificationType.ChildShareInvite:
        this.notificationText = this.translate.instant('components.notification-bell.child-share-invite', {
          firstName: (this.notification.reference as PiiccoProfile).firstName,
          lastName: (this.notification.reference as PiiccoProfile).lastName,
        });
        this.notificationIcon = 'family_restroom';
        this.showAcceptButton = true;
        break;
      case NotificationType.ChildDeleted:
        this.notificationText = this.translate.instant('components.notification-bell.child-deleted', {
          parentFirstName: (this.notification.inviterProfile as PiiccoProfile).firstName,
          parentLastName: (this.notification.inviterProfile as PiiccoProfile).lastName,
          firstName: (this.notification.reference as PiiccoProfile).firstName,
          lastName: (this.notification.reference as PiiccoProfile).lastName,
        });
        this.notificationIcon = 'family_restroom';
        this.showAcceptButton = false;
        break;
      case NotificationType.ChildEmancipated:
        this.notificationText = this.translate.instant('components.notification-bell.child-emancipated', {
          parentFirstName: (this.notification.inviterProfile as PiiccoProfile).firstName,
          parentLastName: (this.notification.inviterProfile as PiiccoProfile).lastName,
          firstName: (this.notification.reference as PiiccoProfile).firstName,
          lastName: (this.notification.reference as PiiccoProfile).lastName,
        });
        this.notificationIcon = 'family_restroom';
        this.showAcceptButton = false;
        break;
      case NotificationType.ProfileEmancipated:
        this.notificationText = this.translate.instant('components.notification-bell.profile-emancipated');
        this.notificationIcon = 'family_restroom';
        this.showAcceptButton = false;
        break;
      case NotificationType.ProfileTransfered:
        this.notificationText = this.translate.instant('components.notification-bell.profile-transfered', {
          from_organization: (this.notification.inviterOrganization as Organization).name,
          to_organization: (this.notification.inviteeOrganization as Organization).name,
        });
        this.notificationIcon = 'transfer_within_a_station';
        this.showAcceptButton = false;
        break;
      default:
        this.show = false;
        break;
    }
  }

  markAsReadEvent(e) {
    e.stopPropagation();
    if (this.isRSVPEvent) {
      this.refuseRSVPInvite.emit(this.notification);
    } else {
      this.markAsRead.emit(this.notification);
    }
  }

  acceptEvent() {
    this.accept.emit(this.notification);
  }

  getDateFormatted(date: string) {
    const rosterUTCDate = new Date(date);
    const rosterDate = new Date(rosterUTCDate.getTime() + rosterUTCDate.getTimezoneOffset() * 60 * 1000);

    if (this.profilesService.getUserLang() === 'en') {
      return `${rosterDate.getFullYear()}-${rosterDate.getMonth() + 1}-${rosterDate.getDate()}`;
    }

    return `${rosterDate.getDate()}-${rosterDate.getMonth() + 1}-${rosterDate.getFullYear()}`;
  }

  clickEvent(e) {
    if (this.notification.type === NotificationType.EvaluationInvite) {
      this.router.navigateByUrl(`/evaluation/profile`);
      this.markAsReadEvent(e);
      this.hidePopOp.emit();
    }
    if (
      this.notification.type === NotificationType.OrganizationPost ||
      this.notification.type === NotificationType.PostComment ||
      this.notification.type === NotificationType.PostLike
    ) {
      this.router.navigateByUrl(`/network/post/${(this.notification as PiiccoNotification<Post>).reference.id}`);
      this.markAsReadEvent(e);
      this.hidePopOp.emit();
    }

    if (this.notification.type === NotificationType.EventInvite) {
      this.router.navigateByUrl(`/profile/agenda`);
    }

    if (this.notification.type === NotificationType.StartersUpdate) {
      this.router.navigateByUrl('/organization/locker-room');
      this.markAsReadEvent(e);
      this.hidePopOp.emit();
    }

    if (this.notification.type === NotificationType.UnreadChatMessage) {
      this.router.navigateByUrl('/chat');
      this.markAsReadEvent(e);
      this.hidePopOp.emit();
    }
  }
}
