import { Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DynamicDate } from 'src/app/core/models/date.model';
import { buildDayList, buildDynamicDate, buildMonthList, buildYearList } from 'src/app/core/utils/date.util';
import { DatesService } from '../../../core/services/dates.service';

@Component({
  selector: 'vex-date-input-group',
  templateUrl: './date-input-group.component.html',
  styleUrls: ['./date-input-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputGroupComponent),
      multi: true,
    },
  ],
})
export class DateInputGroupComponent implements OnInit, ControlValueAccessor {
  availableYears = buildYearList();
  availableMonths = buildMonthList();
  availabledays = buildDayList();
  

  @Input() date: string|Date|DynamicDate|null|undefined;
  @Input() label = '';
  @Input() disabled: boolean;
  @Input() minYear = 1930;
  @Input() error: string|undefined;
  @Input() includeNoneOption = true;
  @Input() showMonthInput = true;
  @Input() showDayInput = true;
  @Input() returnDynamicDate = true;
  @Input() isBeforeFromDate = false;
  @Output() dateChange = new EventEmitter<DynamicDate|Date|null|string>();
  // tslint:disable-next-line:no-output-native no-output-rename
  @Output('change') changeOutput = new EventEmitter<DynamicDate|Date|null|string>();

  day: number|null = null;
  month: number|null = null;
  year: number|null = null;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor() { }


  writeValue(value: any): void {
    this.changeDate(value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  valueChanged() {
    if (this.year === null) {
      this.month = null;
      this.day = null;
    }

    if (this.month === null) {
      this.day = null;
    }
    
    if (this.returnDynamicDate) {
      this.emitChanges(buildDynamicDate(
        this.year,
        this.month,
        this.day,
      ));
    } else {
      this.emitChanges(new Date(
        this.year,
        this.month,
        this.day,
      ));
    }
  }

  get fxFlexSize() {
    if (this.showDayInput && this.showMonthInput) {
      return '33.33';
    }

    if (this.showMonthInput && !this.showDayInput) {
      return '50';
    }

    return '50';
  }

  ngOnInit(): void {
    if (!!this.date) {
      this.setDate(this.date);
    } 
  }

  private setDate(date: string|Date|DynamicDate) {
    let inputDate = date;
    if (typeof inputDate === 'string') {
      inputDate = new Date(inputDate);
    }

    if ((date as DynamicDate).month || (date as DynamicDate).year || (date as DynamicDate).day) {
      this.day = (date as DynamicDate).day;
      this.month = (date as DynamicDate).month - 1;
      this.year = (date as DynamicDate).year;
    } else {
      this.day = (date as Date).getDate();
      this.month = (date as Date).getMonth();
      this.year = (date as Date).getFullYear();
    }
  }

  private changeDate(date: string|Date|null|undefined|DynamicDate) {
    if (!!date) {
      this.setDate(date);
    } else {
      this.day = null;
      this.month = null;
      this.year = null;
    }
  }

  private emitChanges(date: Date|DynamicDate) {
    this.changeOutput.emit(date);
    this.onChange(date);
    this.dateChange.emit(date);
  }
}
