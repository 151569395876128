import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatesService {

  constructor() {}

  nowYear() {
    return new Date().getFullYear(); 
  }

  buildYearList(min: number = 1950, max?: number): {value: number, text: string}[] {
    if(!max) {
      max = new Date().getFullYear()
    }

    let years: {value: number, text: string}[] = [];


    for(let i = max; i >= min; i--) {
      years.push({
        value: i,
        text: i.toString()
      });
    }

    return years;
  }

  buildMonthList(min = 1, max = 12): {value: number, text: string}[] {
    let months: {value: number, text: string}[] = [];


    for(let i = min; i <= max; i++) {
      months.push({
        value: i - 1,
        text: (i.toString().length == 1 ? `0${i.toString()}` : i.toString()) 
      });
    }

    return months; 
  }

  buildDayList(min = 1, max = 31): {value: number, text: string}[] {
    let days: {value: number, text: string}[] = [];


    for(let i = min; i <= max; i++) {
      days.push({
        value: i,
        text: (i.toString().length == 1 ? `0${i.toString()}` : i.toString()) 
      });
    }

    return days; 
  }

  buildDate(year: number, month: number, day: number) {
    return new Date(year, month, day, 0,0,0,0);
  }
}