import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EvaluationOutputType } from 'src/app/evaluation/models/evaluation.model';
import { PiiccoScoreColor } from 'src/app/evaluation/models/piicco-score.model';

@Component({
  selector: 'vex-piicco-score-tile',
  templateUrl: './piicco-score-tile.component.html',
  styleUrls: ['./piicco-score-tile.component.scss']
})
export class PiiccoScoreTileComponent implements OnInit {
  @Input() graphColor: PiiccoScoreColor;
  @Input() title: string;
  @Input() graphBarWidth = 35;
  @Input() hideDescription = false;
  @Input() isPrincipal: boolean = false;
  @Input() description: string;
  @Input() percentage: string;
  @Input() evaluatorsScore: string;
  @Input() targetScore: string;
  @Input() athleteFirstName: string= "";
  @Input() outputType: EvaluationOutputType = EvaluationOutputType.CompleteOutput;
 
  sectionOpened = false;
  EvaluationOutputType = EvaluationOutputType;
  
  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  openDetails() {
    this.sectionOpened = !this.sectionOpened;
  }

  get lineHeight() {
    return this.isPrincipal ? 40 : 15;
  }
}
