<div [ngClass]="{ 'clickable-card': clickable }" class="card-margin">
  <mat-card
    (click)="selected.emit(id)"
    class="no-border-radius profile-card mat-elevation-z10"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    style="position: relative"
  >
    <div
      [fxFlex]="ref.children.length === 0 ? 100 : 100 - this.ngContentWidth"
      [fxFlex.lt-sm]="ref.children.length === 0 ? 100 : 100 - this.ltSmNgContentWidth"
    >
      <div fxLayout="row">
        <img mat-card-avatar class="profile-card-avatar" [src]="profileImage" (error)="getDefaultLogo($event)" />
        <div class="title">
          <span [ngClass]="{ name: !lowerCaseName }">{{ firstName }} {{ lastName }}</span> <br />
          <div class="profile-card-subtitle">
            <div>{{ getTeamName() }}</div>
            <div>{{ getTeamClassAndPosition() }}</div>
          </div>
          <div class="profile-card-subtitle-2">
            <ng-content select="[subtitle]"></ng-content>
          </div>
        </div>
      </div>
    </div>

    <div
      #ref
      [fxFlex]="ref.children.length === 0 ? 0 : this.ngContentWidth"
      [fxFlex.lt-sm]="ref.children.length === 0 ? 0 : this.ltSmNgContentWidth"
      [ngClass]="{ 'lt-sm-padding-top': !removeNgContentPadding }"
    >
      <ng-content></ng-content>
    </div>

    <div *ngIf="showNetworkButtons && hasNetworkFeatureAccess" class="profile-card-action-buttons">
      <div *ngIf="!isProfileMyself()">
        <button
          *ngIf="!isProfileFriend() && !(isInvitationPending() | async)"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="connectToProfile($event)"
          mat-button
          type="button"
        >
          <mat-icon inline="true">person_add</mat-icon>
        </button>
        <button
          disabled
          *ngIf="!isProfileFriend() && (isInvitationPending() | async)"
          mat-stroked-button
          class="rounded-text-button icon-text"
          mat-button
          type="button"
        >
          <mat-icon inline="true">person_add</mat-icon>
        </button>
        <button
          *ngIf="isProfileFriend()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="disconnectFromProfile($event)"
          mat-button
          type="button"
        >
          <mat-icon inline="true">check</mat-icon>
        </button>
        <button
          *ngIf="isProfileFriend()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="launchChat($event)"
          mat-button
          type="button"
        >
          <mat-icon inline="true">chat_bubble_outline</mat-icon>
        </button>
      </div>
    </div>

    <span class="top-corner-info" *ngIf="topCornerInfo !== ''">{{ topCornerInfo }}</span>
    <mat-menu class="edit-right-menu" #menu>
      <button (click)="openDialog()" mat-menu-item>
        <mat-icon style="display: inline">admin_panel_settings</mat-icon>
        {{ 'components.profile-card.manage-rights' | translate }}
      </button>
      <button (click)="openDeleteConfirmationDialog()" mat-menu-item>
        <mat-icon style="display: inline">delete</mat-icon> {{ 'components.profile-card.remove-from-org' | translate }}
      </button>
    </mat-menu>
    <button
      *ngIf="isAdmin && showAdminButtons"
      [matMenuTriggerFor]="menu"
      (click)="topCornerButtonClick($event)"
      class="top-corner-action-button"
      color="primary"
      mat-icon-button
      type="button"
    >
      <mat-icon color="accent">more_vert</mat-icon>
    </button>

    <div *ngIf="!hideBadge" class="profile-card-badge" fxLayout="row wrap" fxLayoutAlign="center center">
      {{ badgeContent }}
    </div>
  </mat-card>
</div>
