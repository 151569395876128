import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'vex-observer-component',
  template: '<div></div>'
})
export class ObserverComponent {
  destroy$ = new Subject();

  constructor() { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
