import { Component, Input, OnInit } from '@angular/core';
import { getDefaultImage, getProfileImage } from 'src/app/core/utils/profile-photo.util';
import { TargetedEntity } from '../../models/dialog.model';

@Component({
  selector: 'vex-public-profile-cover',
  templateUrl: './public-profile-cover.component.html',
  styleUrls: ['./public-profile-cover.component.scss']
})
export class PublicProfileCoverComponent implements OnInit {
  @Input() title: string|undefined;
  @Input() image: string|undefined|null;
  @Input() subtitle: string|undefined;
  @Input() textOne: string|undefined;
  @Input() textTwo: string|undefined;
  @Input() targetedEntity: TargetedEntity.Profile;

  getProfileImage = getProfileImage;
  getDefaultImage = getDefaultImage;

  constructor() { }

  ngOnInit(): void {
  }

  get profilePicture() {
    return getProfileImage(this.title, this.targetedEntity, this.image);
  }


  profilePictureError($event) {
    getDefaultImage(
      $event,
      this.title, 
      this.targetedEntity
      );
  }

}
