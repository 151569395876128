import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_PIICCO_SCORE_COLORS } from 'src/app/core/config/application.config';
import { PiiccoScoreColor } from 'src/app/evaluation/models/piicco-score.model';

@Component({
  selector: 'vex-piicco-icon-graph',
  templateUrl: './piicco-icon-graph.component.html',
  styleUrls: ['./piicco-icon-graph.component.scss']
})
export class PiiccoIconGraphComponent implements OnInit {
  @Input() percentage: number = 0;
  @Input() lineHeight = 100;
  @Input() iconColor: PiiccoScoreColor;
  @Input() targetScore: number;
  @Input() evaluatorsScore: number;
  @Input() athleteFirstName = "";
  @Input() classes = "mt-2";

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  requiredPercentage(required: number) {
    return required > this.percentage;
  }

  getCornersSize() {
    return this.lineHeight / 1.8;
  }

  getCornersPosition() {
    return this.lineHeight / 2;
  }

  getYPosition() {
    return this.lineHeight *0.70;
  }

  getXPosition() {
    return this.lineHeight * 0.47;
  }
}
