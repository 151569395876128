import { TargetedEntity } from "src/app/shared/models/dialog.model";
import { RemoveSpecialCharactersAndAccents } from "./special-characters.util";

export const getDefaultImage = ($event, name: string, profileType: TargetedEntity) => {
    $event.target.src = getDefaultProfileImage(
        name, 
        profileType
    );
}

export const getProfileImage = (name: string|null|undefined, profileType: TargetedEntity, image?: string|null) => {
    if(!!image) {
      return image;
    }
    
    return getDefaultProfileImage(name, profileType);
}


const getDefaultProfileImage = (name: string|null|undefined, profileType: TargetedEntity) => {
    if(!name) {
      return `https://ui-avatars.com/api/?background=8C8CB3&color=000&name=`;
    }

    let nameQuery = RemoveSpecialCharactersAndAccents(name);
    nameQuery = nameQuery.replace(" ", "+");

    if(profileType === TargetedEntity.Profile) {
      return `https://ui-avatars.com/api/?name=${nameQuery}&background=fff&color=8C8CB3`;
    }

    return `https://ui-avatars.com/api/?name=${nameQuery}&background=8C8CB3&color=fff`;
} 

