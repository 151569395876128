import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vex-background-cover',
  templateUrl: './background-cover.component.html',
  styleUrls: ['./background-cover.component.scss']
})
export class BackgroundCoverComponent implements OnInit {
  isLoading = false;
  @Input() coverImage: string|undefined = "/assets/img/bg/profile-cover-image.jpg";
  @Input() borderColorClass = "piicco-default-border";
  

  constructor() { }

  ngOnInit() {
  }
}
