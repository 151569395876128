import { Injectable } from '@angular/core';
import { PiiccoProfile, PiiccoUserPreferenceRights } from 'src/app/models/piiccoProfile';
import { Organization, OrganizationRole } from 'src/app/organization/models/organization';
import { Roles } from '../models/roles.enum';
import { UserRights } from '../models/user-rights.model';

@Injectable({
  providedIn: 'root',
})
export class RightsService {
  constructor() {}

  isImpersonatingAndChildDoesNotHaveAccess(user: PiiccoProfile, userRights: UserRights) {
    if (!localStorage.getItem('isImpersonating') || !user.userPreferences?.userRights) {
      return false;
    }

    switch (userRights) {
      case UserRights.Chat:
        return !this.childHasFeatureAccess(user.userPreferences.userRights.enableChat);
      case UserRights.EventRSVP:
        return !this.childHasFeatureAccess(user.userPreferences.userRights.enableEventRSVP);
      case UserRights.NetworkPost:
        return !this.childHasFeatureAccess(user.userPreferences.userRights.enableNetworkPost);
      case UserRights.NetworkRelations:
        return !this.childHasFeatureAccess(user.userPreferences.userRights.enableNetworkRelations);
      default:
        return true;
    }
  }

  hasFeatureAccess(user: PiiccoProfile, userRights: UserRights) {
    if (!user.userPreferences?.userRights || localStorage.getItem('isImpersonating')) {
      return true;
    }

    switch (userRights) {
      case UserRights.Chat:
        return this.childHasFeatureAccess(user.userPreferences.userRights.enableChat);
      case UserRights.EventRSVP:
        return this.childHasFeatureAccess(user.userPreferences.userRights.enableEventRSVP);
      case UserRights.NetworkPost:
        return this.childHasFeatureAccess(user.userPreferences.userRights.enableNetworkPost);
      case UserRights.NetworkRelations:
        return this.childHasFeatureAccess(user.userPreferences.userRights.enableNetworkRelations);
      default:
        return true;
    }
  }

  hasOrganizationRole(organization: Organization, roles: Roles[]) {
    if (!organization.roles && organization.roles.length > 0) {
      return false;
    }

    if (typeof organization.roles[0] === 'string') {
      return (organization.roles as Roles[]).filter((role) => roles.includes(role)).length > 0;
    }

    return (organization.roles as OrganizationRole[]).filter((role) => roles.includes(role.tag)).length > 0;
  }

  async hasRightsOnOrganization(organizationId: string, userOrganizationList: Organization[] | undefined, roles: Roles[]) {
    if (!userOrganizationList || userOrganizationList.length === 0) {
      return false;
    }

    const flattenedOrganizationList = this.getFlattenedUserOrganizations(userOrganizationList);
    const foundOrganization = flattenedOrganizationList.find((i) => i.id === organizationId);

    if (!foundOrganization) {
      return false;
    }

    const foundRole = (foundOrganization.roles as Roles[]).find((i) => roles.includes(i));
    return !!foundRole;
  }

  userIsChildren(user?: PiiccoProfile) {
    if (!user) {
      return false;
    }
    return user.familyParents && user.familyParents.length > 0;
  }

  private getFlattenedUserOrganizations(organizationList: Organization[], parentRoles: Roles[] = []) {
    return organizationList.reduce<Organization[]>((r, { childOrgs, ...rest }) => {
      r.push(rest as Organization);
      if (childOrgs && childOrgs.length) {
        r.push(
          ...this.getFlattenedUserOrganizations(
            childOrgs as Organization[],
            (rest.roles as Roles[]).filter((r) => r === Roles.Admin || r === Roles.SuperAdmin)
          )
        );
      }
      return r;
    }, []);
  }

  private childHasFeatureAccess(featureRights: boolean | null) {
    if (featureRights === null) {
      return true;
    }

    return featureRights;
  }
}
