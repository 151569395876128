<ng-container *ngIf="isOrg(item) && currentNavigationOrg && item.organizations.length">
  <div [@dropdown]="true"
       [class.active]="isActive"
       class="item organization"
       fxLayout="row"
       (click)="navigationSelection()"
       matRipple>
    <mat-icon class="item-icon" fxFlex="none"></mat-icon>
    <span class="item-label white" fxFlex="auto"  >{{currentNavigationOrg.name}}</span>
    <mat-icon (click)="navigationSelection(); $event?.stopPropagation();"
              class="item-icon"
              fxFlex="none">arrow_forward_ios</mat-icon>
  </div>
</ng-container>

<a *ngIf="isLink(item) && !isFunction(item.route) && !isDisabled"
   [fragment]="item.fragment"
   [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
   [routerLink]="item.route"
   class="item"
   fxLayout="row"
   matRipple
   routerLinkActive="active">
  <mat-icon *ngIf="level === 0" class="item-icon" fxFlex="none">
    {{item.icon}}</mat-icon>
  <span class="item-label" fxFlex="auto">{{ item.label }}</span>
  <span *ngIf="item.badge && item.badge.value !== '0'"
        [ngClass]="[item.badge.bgClass, item.badge.textClass]"
        class="item-badge"
        fxFlex="none">{{ item.badge.value }}</span>
</a>

<div (click)="item.route()"
     *ngIf="isLink(item) && isFunction(item.route)"
     class="item"
     fxLayout="row"
     matRipple
     routerLinkActive="active">
  <mat-icon *ngIf="level === 0" class="item-icon" fxFlex="none">{{item.icon}}</mat-icon>
  <i class="item-label" fxFlex="auto">{{ item.label }}</i>
  <span *ngIf="item.badge"
        [ngClass]="[item.badge.bgClass, item.badge.textClass]"
        class="item-badge"
        fxFlex="none">{{ item.badge.value }}</span>
</div>

<ng-container *ngIf="isDropdown(item) && !isDisabled">
  <div (click)="toggleOpen()"
       [class.active]="isOpen || isActive"
       [class.open]="isOpen"
       class="item"
       fxLayout="row"
       matRipple>
    <mat-icon *ngIf="level === 0" class="item-icon" fxFlex="none">{{item.icon}}</mat-icon>
    <span class="item-label" fxFlex="auto">{{ item.label }}</span>
    <span *ngIf="item.badge"
          [ngClass]="[item.badge.bgClass, item.badge.textClass]"
          class="item-badge"
          fxFlex="none">{{ item.badge.value }}</span>
    <mat-icon [icIcon]="icKeyboardArrowRight" class="item-dropdown-icon" fxFlex="none"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="item-dropdown">
    <vex-sidenav-item  *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>


<ng-container *ngIf="isSubheading(item)">
  <div class="subheading">{{ item.label }}</div>
  <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="0"></vex-sidenav-item>
</ng-container>

