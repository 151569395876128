<vex-section
    icon="article"
    title="{{ 'common.about' | translate }}"
    [hasEditRights]="hasEditRights"
    (clicked)="editAbout()"
>
<div fxLayout="row wrap">

    <!--<div [innerHTML]="sanitizedBio" class="contact-main-info"></div>-->
    <div fxFlex="100" class="pb-4">
        <label>{{'common.bio' | translate}}</label>
        <p class="text-with-line-break">{{bio}}</p>
    </div>

    <div fxFlex="100" *ngIf="targetedEntity === TargetedEntity.Profile && concatenatedNationalities">
        <label>{{'common.nationalities' | translate}}</label>
        <p class="profile-info">{{ concatenatedNationalities || "-"}}</p>
    </div>
</div>



</vex-section>