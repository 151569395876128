<vex-dialog-header
  [title]="translate.instant('edit-child-rights-dialog.title')"
  titleIcon="admin_panel_settings"
></vex-dialog-header>
<mat-dialog-content fxLayout="column">
  <div fxFlex="100" fxLayout="row wrap">
    <vex-profile-card
      class="mb-2"
      fxFlex="70"
      fxFlex.lt-sm="100"
      [lowerCaseName]="true"
      [id]="data.profile.id"
      [firstName]="data.profile.firstName"
      [lastName]="data.profile.lastName"
      [image]="data.profile.photo"
      [ngContentWidth]="20"
      [showNetworkButtons]="false"
      [hideBadge]="true"
    >
    </vex-profile-card>
    <div fxFlex="100">
      <span class="label">{{ 'edit-child-rights-dialog.child-can-text' | translate }}</span>
    </div>
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
      <mat-checkbox [(ngModel)]="enableNetworkPost" fxFlex="90">
        <span style="color: white">{{ 'children-rights-info.post-info' | translate }}</span>
      </mat-checkbox>
      <mat-icon
        class="clickable-flat"
        fxFlex="10"
        #tooltip="matTooltip"
        [matTooltip]="translate.instant('children-rights-info.post-info')"
        style="float: right; height: auto"
        matTooltipPosition="above"
        color="accent"
        >info</mat-icon
      >

      <mat-checkbox [(ngModel)]="enableChat" fxFlex="90">
        <span style="color: white">{{ 'children-rights-info.chat-info' | translate }}</span>
      </mat-checkbox>
      <mat-icon
        class="clickable-flat"
        fxFlex="10"
        #tooltip="matTooltip"
        [matTooltip]="translate.instant('children-rights-info.chat-info')"
        style="float: right; height: auto"
        matTooltipPosition="above"
        color="accent"
        >info</mat-icon
      >

      <mat-checkbox [(ngModel)]="enableNetworkRelations" fxFlex="90">
        <span style="color: white">{{ 'children-rights-info.send-accept-invite-info' | translate }}</span>
      </mat-checkbox>
      <mat-icon
        class="clickable-flat"
        fxFlex="10"
        #tooltip="matTooltip"
        [matTooltip]="translate.instant('children-rights-info.send-accept-invite-info')"
        style="float: right; height: auto"
        matTooltipPosition="above"
        color="accent"
        >info</mat-icon
      >

      <mat-checkbox [(ngModel)]="enableEventRSVP" fxFlex="90">
        <span style="color: white">{{ 'children-rights-info.answer-events-info' | translate }}</span>
      </mat-checkbox>
      <mat-icon
        class="clickable-flat"
        fxFlex="10"
        #tooltip="matTooltip"
        [matTooltip]="translate.instant('children-rights-info.answer-events-info')"
        style="float: right; height: auto"
        matTooltipPosition="above"
        color="accent"
        >info</mat-icon
      >
    </div>
  </div>

  <vex-loader [isLoading]="isLoading"></vex-loader>

  <vex-dialog-actions (cancel)="dialogRef.close()" (accept)="save()"></vex-dialog-actions>
</mat-dialog-content>
