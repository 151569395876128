<div fxLayout="row" fxLayoutAlign="center center">


    <mat-form-field fxFlex="80" (click)="$event.stopPropagation()" class="search-input search" appearance="outline" >
        <!--<mat-label>{{'common.search' | translate}}</mat-label>-->
        <input id="search" placeholder="{{'common.search' | translate}}" #input type="text" matInput [formControl]="search" [matAutocomplete]="auto">
        <mat-autocomplete class="auto-complete-height" #auto="matAutocomplete">
        <mat-option class="mt-1" *ngFor="let option of searchResults" (click)="goProfile(option)">
            <div class="search-result" fxLayout="row">
                <img 
                class="avatar" 
                [src]="getProfilePhoto(option)"
                (error)="getDefaultPhoto($event, option)"
                >
                <div class="ml-1">{{option.name}}</div>
            </div>
        </mat-option>
        <mat-option disabled *ngIf="hasNoResults">
            {{'common.no-results' | translate}}
        </mat-option>
        <div fxLayout="row wrap" fxLayoutAlign="center" *ngIf="showSeeAllResultsPage">
            <mat-divider fxFlex="100"></mat-divider>
            <div class="mt-3 mb-3" fxFlex="100" fxLayoutAlign="center">
                <button 
                    (click)="goAllResults()"
                    mat-stroked-button
                    class="dialog-save-button rounded-button purple-outline"
                    mat-button 
                >{{'components.search-bar.see-all-results' | translate}} ({{resultCount}})</button>
            </div>
        </div>
        </mat-autocomplete>
    </mat-form-field>
</div>
