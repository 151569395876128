<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <div class="dropdown-heading">{{ 'common.notifications' | translate }}</div>
      <div class="dropdown-subheading">
        <span *ngIf="notifications.length === 0">{{
          'components.notification-bell.you-have-no-notifications' | translate
        }}</span>
        <span *ngIf="notifications.length === 1">{{
          'components.notification-bell.you-have-one-notification' | translate
        }}</span>
        <span *ngIf="notifications.length > 1">{{
          'components.notification-bell.you-have-notifications' | translate: { count: notifications.length }
        }}</span>
      </div>
    </div>
  </div>
  <div class="dropdown-content">
    <div fxLayout="row wrap">
      <vex-children-rights-info
        fxFlex="100"
        [currentFeatureRight]="UserRights.EventRSVP"
        [text]="translate.instant('children-rights-info.rsvp-rights-info-text')"
      ></vex-children-rights-info>
      <div *ngFor="let notification of notifications; trackBy: trackById" fxFlex="100">
        <vex-notification-bell-item
          (markAsRead)="markNotificationAsRead($event)"
          (refuseRSVPInvite)="refuseRSVPInvite($event)"
          (accept)="acceptNotification($event)"
          [notification]="notification"
          (hidePopOp)="hidePopUp()"
        ></vex-notification-bell-item>
      </div>
    </div>
  </div>
</div>
