import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { getDefaultImage, getProfileImage } from 'src/app/core/utils/profile-photo.util';
import { PiiccoCurrentOrganization } from 'src/app/models/piiccoProfile';
import { TargetedEntity } from '../../models/dialog.model';

@Component({
  selector: 'vex-profile-action-card',
  templateUrl: './profile-action-card.component.html',
  styleUrls: ['./profile-action-card.component.scss'],
})
export class ProfileActionCardComponent implements OnInit {
  @Input() id: string;
  @Input() clickable = true;
  @Input() name: string;
  @Input() profilePicture: string | null;
  @Input() profileCurrentOrg: PiiccoCurrentOrganization | undefined;
  @Input() noNgContent = false;

  getDefaultImage = getDefaultImage;
  getProfileImage = getProfileImage;

  constructor(private router: Router, private profilesService: ProfilesService) {}

  ngOnInit(): void {}

  goProfile() {
    this.router.navigateByUrl(`/profile/${this.id}`);
  }

  getImage() {
    return getProfileImage(this.name, TargetedEntity.Profile, this.profilePicture);
  }

  getTeamName() {
    if (!this.profileCurrentOrg) {
      return '';
    }

    return this.profileCurrentOrg.organizationName;
  }

  getTeamClassAndPosition() {
    if (!this.profileCurrentOrg) {
      return '';
    }

    let teamClass = '';
    let teamGender = '';
    let teamCategory = '';
    let position = '';

    if (this.profileCurrentOrg?.teamClass) {
      teamClass = this.profileCurrentOrg?.teamClass[this.userLang];
    }

    if (this.profileCurrentOrg?.teamGender) {
      teamGender = this.profileCurrentOrg?.teamGender;
    }

    if (this.profileCurrentOrg?.teamCategory) {
      teamClass = this.profileCurrentOrg?.teamCategory[this.userLang];
    }

    if (this.profileCurrentOrg?.position) {
      position = this.profileCurrentOrg?.position[this.userLang];
    }

    return `${teamClass} ${teamGender} ${teamCategory} - ${position}`;
  }

  get userLang() {
    return this.profilesService.getUserLang();
  }

  getDefaultLogo($event) {
    getDefaultImage($event, this.name, TargetedEntity.Profile);
  }
}
