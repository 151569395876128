import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NavigationService } from '../../../../@vex/services/navigation.service';
import { Organization } from '../../../organization/models/organization';
import { environment } from '../../../../environments/environment';
import { AgendaEvent, PiiccoCalendar, PiiccoEvent } from '../../../models/calendar';
import { PublicProfile } from '../../../models/piiccoProfile';
import { AuthenticationService } from '../../../auth/services/authentication-service/authentication.service';
import { Store } from '@ngrx/store';
import { SetGlobalSuccessMessageAction } from 'src/app/store/global/global.actions';
import notify from 'devextreme/ui/notify';
import { TranslateService } from '@ngx-translate/core';
import HTTP_STATUS_CODES from 'http-status-enum';
import { SearchResult } from '../../models/search.model';
import { take } from 'rxjs-compat/operator/take';

@Injectable({
  providedIn: 'root',
})
export class CalendarService implements OnDestroy {
  constructor(
    private http: HttpClient,
    private navigationService: NavigationService,
    private auth: AuthenticationService,
    private store: Store,
    private translate: TranslateService
  ) {}

  deleteEvent(event: PiiccoEvent, organisationId: string): Promise<any> {
    return new Promise((res, rej) => {
      this.http
        .delete(`${environment.apiGateway}calendars/organization/${organisationId}/event/${event.id}`)
        .take(1)
        .subscribe(
          () => {
            notify(this.translate.instant('calendar.event-deleted'), 'success');

            res(true);
          },
          (error) => {
            rej(error);
          }
        );
    });
  }

  getAgenda(page = 1, pageSize = 15) {
    return new Promise<SearchResult<AgendaEvent>>((res, rej) => {
      return this.http
        .get<SearchResult<AgendaEvent>>(`${environment.apiGateway}calendars/agenda?page=${page}&pageSize=${pageSize}`)
        .take(1)
        .subscribe(
          (result) => res(result),
          () =>
            res({
              pageCount: 0,
              recordCount: 0,
              records: [],
            })
        );
    });
  }

  createEvent(event: PiiccoEvent, organisationId: string): Promise<PiiccoEvent> {
    return new Promise<PiiccoEvent>(async (res, rej) => {
      const currentUser = await this.auth.getUser();
      event.organizerId = currentUser['uid'];
      if (event.text === '') {
        event.text = 'N/A';
      }
      if (!event.recurrenceRule) {
        event.recurrenceRule = '';
      }
      if (!event.location) {
        event.location = '';
      }
      if (!event.eventType) {
        event.eventType = 'none';
      }

      this.http
        .post<PiiccoEvent>(`${environment.apiGateway}calendars/organization/${organisationId}/event`, event)
        .take(1)
        .subscribe(
          (r) => {
            notify(this.translate.instant('calendar.event-created'), 'success');
            res(r);
          },
          (error: HttpErrorResponse) => {
            switch (error.status) {
              case HTTP_STATUS_CODES.FORBIDDEN:
                notify(this.translate.instant('calendar.create-forbidden'), 'error');
                break;
              default:
                notify(this.translate.instant('common.error-occured'), 'error');
                break;
            }
            rej([]);
          }
        );
    });
  }

  updateEvent(event: PiiccoEvent): Promise<PiiccoEvent> {
    return new Promise<PiiccoEvent>((res, rej) => {
      this.http
        .put<PiiccoEvent>(`${environment.apiGateway}calendars/organization/${event['organisationId']}/event/${event.id}`, event)
        .take(1)
        .subscribe(
          (r) => {
            notify(this.translate.instant('calendar.event-updated'), 'success');

            res(r);
          },
          (error) => {
            rej([]);
          }
        );
    });
  }
  async getMyCalendar(month, year): Promise<PiiccoCalendar[]> {
    return new Promise<PiiccoCalendar[]>((res, rej) => {
      const url = `${environment.apiGateway}calendars/`;
      this.http
        .get<PiiccoCalendar[] | PiiccoCalendar>(`${url}?month=${month + 1}&year=${year}`)
        .take(1)
        .subscribe(
          (cals) => {
            if (Array.isArray(cals)) {
              res(cals);
            } else {
              res([cals]);
            }
          },
          (error) => {
            rej([]);
          }
        );
    });
  }

  async getOrganisationCalendar(month, year, single: boolean = false, nbMonths = 2): Promise<PiiccoCalendar[]> {
    return new Promise<PiiccoCalendar[]>((res, rej) => {
      if (!this.navigationService.currentNavigationOrg) {
        this.navigationService.currentNavigationOrg$.subscribe((c) => {
          const url = `${environment.apiGateway}calendars/organization/${c.id}${single ? '/single' : ''}`;
          this.http
            .get<PiiccoCalendar[] | PiiccoCalendar>(`${url}?month=${month + 1}&year=${year}&nbMonths=${nbMonths}`)
            .take(1)
            .subscribe(
              (cals) => {
                if (Array.isArray(cals)) {
                  res(cals);
                } else {
                  res([cals]);
                }
              },
              (error) => {
                rej([]);
              }
            );
        });
      } else {
        const url = `${environment.apiGateway}calendars/organization/${this.navigationService.currentNavigationOrg.id}${
          single ? '/single' : ''
        }`;
        this.http
          .get<PiiccoCalendar[] | PiiccoCalendar>(`${url}?month=${month + 1}&year=${year}`)
          .take(1)
          .subscribe(
            (cals) => {
              if (Array.isArray(cals)) {
                res(cals);
              } else {
                res([cals]);
              }
            },
            (error) => {
              rej([]);
            }
          );
      }
    });
  }

  ngOnDestroy(): void {
    this.navigationService.currentNavigationOrg$.unsubscribe();
  }

  rsvp(orgId: string, eventId: string, response: string) {
    return new Promise<PiiccoEvent>((res, rej) => {
      this.http
        .put<PiiccoEvent>(`${environment.apiGateway}calendars/organization/${orgId}/event/${eventId}/${response}`, {})
        .take(1)
        .subscribe(
          (r) => {
            if (response === 'yes') {
              this.store.dispatch(SetGlobalSuccessMessageAction({ translationKey: 'calendar.accepted-event' }));
            } else {
              this.store.dispatch(SetGlobalSuccessMessageAction({ translationKey: 'calendar.refused-event' }));
            }

            res(r);
          },
          (error) => {
            rej([]);
          }
        );
    });
  }

  athleteRsvp(userId: string, orgId: string, eventId: string, response: string, justification?: string) {
    return new Promise<PiiccoEvent>((res, rej) => {
      this.http
        .put<PiiccoEvent>(
          `${environment.apiGateway}calendars/organization/${orgId}/eventandjust/${eventId}/${userId}/${response}`,
          {
            justification: justification,
          }
        )
        .take(1)
        .subscribe(
          (r) => {
            this.store.dispatch(SetGlobalSuccessMessageAction({ translationKey: 'edit-attendees-response-dialog.answer-sent' }));
            res(r);
          },
          (error) => {
            rej([]);
          }
        );
    });
  }
}
