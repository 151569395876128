import { Injectable } from '@angular/core';
import { PiiccoProfile } from '../../models/piiccoProfile';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MLArrays, MLString } from '../../models/ml-string';
import { map, share, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  EvaluationCategory,
  ISelfEvaluationConfig,
  MultiLangItem,
  OrganizationRoles,
  OrganizationTitle,
  Sport,
  WebsiteMetaTags,
} from '../models/configuration.model';
import { Nationality } from '../models/nationality.model';
import { EventTypes } from '../models/eventtypes.model';
import { EvaluationOutput } from '../models/evaluationOutput.model';
import { Publicity } from '../models/publicity.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationsService {
  private nationalitiesObservableCache: Observable<Nationality[]>;
  private nationalitiesCache: Nationality[];
  private eventTypesObservableCache: Observable<EventTypes[]>;
  private eventTypesCache: EventTypes[];
  private sportsObservableCache: Observable<Sport>;
  private sportsCache: Sport;
  private orgTitlesObservableCache: Observable<OrganizationTitle[]>;
  private orgTitlesCache: OrganizationTitle[];
  private orgRoles: OrganizationRoles[];
  private orgTitles: MultiLangItem[];
  private maxFileSize: number;
  private orgEvalCategory: EvaluationCategory[];

  constructor(private http: HttpClient) {}

  clearCache() {
    this.nationalitiesObservableCache = undefined;
    this.nationalitiesCache = undefined;
    this.orgTitlesCache = undefined;
    this.orgTitlesObservableCache = undefined;
    this.sportsCache = undefined;
    this.sportsObservableCache = undefined;
  }

  getGenericPublicity() {
    return new Promise<Publicity | undefined>((res, rej) => {
      this.http
        .get<Publicity | undefined>(`${environment.apiGateway}configurations/GENERIC_PUB`)
        .take(1)
        .subscribe(
          (response) => {
            res(response);
          },
          (err) => {
            res(undefined);
          }
        );
    });
  }

  getMaxFileSize(organizationId: string) {
    if (this.maxFileSize) {
      return this.maxFileSize;
    }
    return new Promise<number>((res, rej) => {
      this.http
        .get<number>(
          `${environment.apiGateway}configurations/RESOURCES_MAX_FILE_SIZE_MB/${organizationId}?includeNullReference=true`
        )
        .take(1)
        .subscribe(
          (response) => {
            this.maxFileSize = response;
            res(response);
          },
          (err) => {
            rej();
          }
        );
    });
  }

  getWebsiteMetaTags(website: string) {
    return new Promise<WebsiteMetaTags>((res, rej) => {
      this.http
        .get<WebsiteMetaTags>(`${environment.apiGateway}configurations/metatags/opengraph?url=${website}`)
        .take(1)
        .subscribe(
          (response) => {
            res(response);
          },
          (err) => {
            rej();
          }
        );
    });
  }

  getMinimumSupportedVersion() {
    return new Promise<string | null>((res, rej) => {
      this.http
        .get<string | null>(`${environment.apiGateway}configurations/MINIMUM_SUPPORTED_VERSION`)
        .take(1)
        .subscribe(
          (response) => {
            res(response);
          },
          (err) => {
            rej();
          }
        );
    });
  }

  getOrganizationAvailableStorage(organizationId: string) {
    return new Promise<number>((res, rej) => {
      this.http
        .get<number>(
          `${environment.apiGateway}configurations/RESOURCES_MAX_POOL_SIZE_MB/${organizationId}?includeNullReference=true`
        )
        .take(1)
        .subscribe(
          (response) => {
            res(response);
          },
          (err) => {
            rej();
          }
        );
    });
  }

  getOrganizationSponsorPublicities(organizationId: string) {
    return new Promise<Publicity[]>((res, rej) => {
      this.http
        .get<Publicity[]>(`${environment.apiGateway}configurations/SPONSOR_PUB/${organizationId}?includeNullReference=false`)
        .take(1)
        .subscribe(
          (response) => {
            res(response);
          },
          (err) => {
            res([]);
          }
        );
    });
  }

  getSelfEvaluationConfig() {
    return new Promise<ISelfEvaluationConfig>((res, rej) => {
      this.http
        .get<ISelfEvaluationConfig>(`${environment.apiGateway}configurations/SELF_EVALUATION_CONFIG`)
        .take(1)
        .subscribe(
          (response) => {
            res(response);
          },
          (err) => {
            res(undefined);
          }
        );
    });
  }

  getEvaluationCategories() {
    return new Promise<EvaluationCategory[]>((res, rej) => {
      this.http
        .get<EvaluationCategory[]>(`${environment.apiGateway}configurations/EVALUATION_CATEGORIES`)
        .take(1)
        .subscribe(
          (response) => {
            this.orgEvalCategory = response;
            res(response);
          },
          (err) => {
            res([]);
          }
        );
    });
  }

  getEvaluationOutputs(organizationId: string) {
    return new Promise<EvaluationOutput[]>((res, rej) => {
      this.http
        .get<EvaluationOutput[]>(
          `${environment.apiGateway}configurations/EVALUATION_OUTPUT/${organizationId}?includeNullReference=true`
        )
        .take(1)
        .subscribe(
          (response) => {
            res(response);
          },
          (err) => {
            res([]);
          }
        );
    });
  }

  getNationalities(): Observable<Nationality[]> {
    if (!!this.nationalitiesCache) {
      return of(this.nationalitiesCache);
    }

    if (!!this.nationalitiesObservableCache) {
      return this.nationalitiesObservableCache;
    }

    this.nationalitiesObservableCache = this.http
      .get<Nationality[]>(environment.apiGateway + 'configurations/NATIONALITIES')
      .pipe(
        take(1),
        tap((nationalities) => (this.nationalitiesCache = nationalities)),
        share()
      );

    return this.nationalitiesObservableCache;
  }

  getEventTypes(): Observable<EventTypes[]> {
    if (!!this.eventTypesCache) {
      return of(this.eventTypesCache);
    }

    if (!!this.eventTypesObservableCache) {
      return this.eventTypesObservableCache;
    }

    this.eventTypesObservableCache = this.http.get<EventTypes[]>(environment.apiGateway + 'configurations/EVENTTYPES').pipe(
      tap((eventTypes) => (this.eventTypesCache = eventTypes)),
      share()
    );

    return this.eventTypesObservableCache;
  }

  getOrganizationRoles() {
    if (this.orgRoles) {
      return this.orgRoles;
    }

    return new Promise<OrganizationRoles[]>((res, rej) => {
      this.http
        .get<OrganizationRoles[]>(`${environment.apiGateway}configurations/ORG_RIGHTS`)
        .take(1)
        .subscribe(
          (response) => {
            this.orgRoles = response;
            res(response);
          },
          (err) => {
            res([]);
          }
        );
    });
  }

  getOrganizationTitles() {
    if (this.orgTitles) {
      return this.orgTitles;
    }

    return new Promise<MultiLangItem[]>((res, rej) => {
      this.http
        .get<{ name: MultiLangItem }[]>(`${environment.apiGateway}configurations/ORG_TITLES`)
        .take(1)
        .subscribe(
          (response) => {
            this.orgTitles = response.map((i) => i.name);
            res(this.orgTitles);
          },
          (err) => {
            res([]);
          }
        );
    });
  }

  getPositions(): Observable<MultiLangItem[]> {
    if (!!this.sportsCache) {
      return of(this.sportsCache.positions.map((i) => i.name));
    }

    if (!!this.sportsObservableCache) {
      return this.sportsObservableCache.pipe(map((i) => i.positions.map((p) => p.name)));
    }

    this.sportsObservableCache = this.http.get<Sport>(environment.apiGateway + 'sports/soccer').pipe(
      tap((sports) => (this.sportsCache = sports)),
      share()
    );

    return this.sportsObservableCache.pipe(map((i) => i.positions.map((p) => p.name)));
  }

  getClasses(): Observable<MultiLangItem[]> {
    if (!!this.sportsCache) {
      return of(this.sportsCache.classes);
    }

    if (!!this.sportsObservableCache) {
      return this.sportsObservableCache.pipe(map((i) => i.classes));
    }

    this.sportsObservableCache = this.http.get<Sport>(environment.apiGateway + 'sports/soccer').pipe(
      tap((sports) => (this.sportsCache = sports)),
      share()
    );

    return this.sportsObservableCache.pipe(map((i) => i.classes));
  }

  getCategories(): Observable<MultiLangItem[]> {
    if (!!this.sportsCache) {
      return of(this.sportsCache.categories);
    }

    if (!!this.sportsObservableCache) {
      return this.sportsObservableCache.pipe(map((i) => i.categories));
    }

    this.sportsObservableCache = this.http.get<Sport>(environment.apiGateway + 'sports/soccer').pipe(
      tap((sports) => (this.sportsCache = sports)),
      share()
    );

    return this.sportsObservableCache.pipe(map((i) => i.categories));
  }

  getTitles(): Observable<MultiLangItem[]> {
    if (!!this.orgTitlesCache) {
      return of(this.orgTitlesCache.map((i) => i.name));
    }

    if (!!this.orgTitlesObservableCache) {
      return this.orgTitlesObservableCache.pipe(map((i) => i.map((title) => title.name)));
    }

    this.orgTitlesObservableCache = this.http.get<OrganizationTitle[]>(environment.apiGateway + 'configurations/ORG_TITLES').pipe(
      tap((titles) => (this.orgTitlesCache = titles)),
      share()
    );

    return this.orgTitlesObservableCache.pipe(map((i) => i.map((title) => title.name)));
  }
}
