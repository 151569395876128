import { Roles } from '../core/models/roles.enum';
import { MultiLangItem } from '../core/models/configuration.model';
import { DynamicDate } from '../core/models/date.model';

export interface PiiccoProfile {
  id: string;
  firstName: string;
  lastName: string;
  gender: string;
  photo: string;
  nationalities: MultiLangItem[];
  dateOfBirth: string | null;
  yearOfBirth: number | null;
  weight: number | null;
  height: number | null;
  heightInches: number | null;
  email: string | null;
  cellPhone: string | null;
  socialMedias: string[];
  address: string;
  bio: string | null;
  educationDiplomas: PiiccoHistoryItem[] | null;
  type: string;
  sports: PicccoSportProfile;
  organizations: PiiccoProfileOrganization[];
  acceptedTerms: PiiccoAcceptedTerms[];
  userPreferences: PiiccoUserPreferences;
  contact1Name: string | null;
  contact1Link: string | null;
  contact1Email: string | null;
  contact1CellPhone: string | null;
  contact2Name: string | null;
  contact2Link: string | null;
  contact2Email: string | null;
  contact2CellPhone: string | null;
  friends: string[];
  orgsFollowed: string[];
  deleted: boolean | null;
  familyMembers?: string[] | null;
  familyParents?: PiiccoProfile[] | null;
  titles?: PiiccoProfileTitle[];
}

export interface PiiccoProfileTitle {
  category: MultiLangItem;
  organizationId: string;
  title: MultiLangItem;
}

export interface UpdatePiiccoProfile {
  id: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  nationalities?: MultiLangItem[];
  dateOfBirth?: Date | null;
  weight?: number | null;
  height?: number | null;
  heightInches?: number | null;
  email?: string;
  cellPhone?: string;
  socialMedias?: string[];
  address?: string;
  bio?: string;
  educationDiplomas?: PiiccoHistoryItem[];
  type?: string;
  sports?: PicccoSportProfile;
  organizations?: PiiccoProfileOrganization[];
  acceptedTerms?: PiiccoAcceptedTerms[];
  userPreferences?: PiiccoUserPreferences;
  friends?: string[];
  contact1Name?: string | null;
  contact1Link?: string | null;
  contact1Email?: string | null;
  contact1CellPhone?: string | null;
  contact2Name?: string | null;
  contact2Link?: string | null;
  contact2Email?: string | null;
  contact2CellPhone?: string | null;
}

export interface UpdatePiiccoProfilePhoto {
  imageData: string;
}

export interface PublicProfile {
  id: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  photo: string | null;
  deleted: boolean;
}

export enum Gender {
  male = 'M',
  female = 'F',
}

export enum Foot {
  left = 'LEFT',
  right = 'RIGHT',
  both = 'BOTH',
}
export interface PiiccoProfileOrganization {
  organizationId: string;
  roles: Roles[];
}

export interface PiiccoAcceptedTerms {
  acceptedDate: string | null;
  termsId: string;
}

export interface PiiccoHistoryItem {
  fromDate: DynamicDate | null;
  toDate: DynamicDate | null;
  location: string | null;
  organization: string | null;
  description: string | null;
}

export interface PiiccoUserPreferences {
  language: string;
  currentOrganization: string | null;
  forcePasswordChange?: boolean;
  enableFamilyManagement?: boolean;
  enablePushNotification: boolean;
  enableEmailNotification: boolean;
  userRights?: PiiccoUserPreferenceRights;
}

export interface PiiccoUserPreferenceRights {
  enableChat: boolean | null;
  enableNetworkRelations: boolean | null;
  enableNetworkPost: boolean | null;
  enableEventRSVP: boolean | null;
}

export interface PicccoSportProfile {
  soccer: PicccoSoccerProfile;
}

export interface PicccoSoccerProfile {
  passportId: string | null;
  pnce: string | null;
  foot: string | null;
  bestPosition: MultiLangItem | null;
  otherPositions: MultiLangItem[] | null;
  specializedCamps: PiiccoHistoryItem[] | null;
  certifications: PiiccoHistoryItem[] | null;
  workExperience: PiiccoHistoryItem[] | null;
  playingOrganizationHistory: PiiccoSoccerPlayHistory[] | null;
  coachingOrganizationHistory: PiiccoSoccerCoachHistory[] | null;
  current: PiiccoCurrentOrganization | null;
}

export interface PiiccoCurrentOrganization {
  title: string;
  jerseyNumber: number | null;
  position: MultiLangItem;
  organizationName: string | null;
  teamCategory: MultiLangItem | null;
  teamClass: MultiLangItem | null;
  teamGender: Gender | null;
  organizationId: string | null;
  city: string | null;
  organizationLogo: string | null;
}

export interface PiiccoSoccerPlayHistory {
  organizationId: string | null;
  name: string | null;
  city: string | null;
  coachName: string | null;
  logo: string | null;
  jerseyNumber: number | null;
  teamCategory: MultiLangItem | null;
  teamClass: MultiLangItem | null;
  teamGender: Gender | null;
  position: MultiLangItem | null;
  fromDate: DynamicDate | null;
  toDate: DynamicDate | null;
  accomplishments: string[] | null;
}

export interface PiiccoSoccerCoachHistory {
  organizationId: string | null;
  name: string | null;
  city: string | null;
  logo: string | null;
  playerCategory: MultiLangItem | null;
  playerClass: MultiLangItem | null;
  playerGender: Gender | null;
  fromDate: DynamicDate | null;
  toDate: DynamicDate | null;
  accomplishments: string[] | null;
  title: string | null;
}

export interface CreateFamilyMember {
  firstName: string;
  lastName: string;
  email: string;
}

export interface FamilyMember {
  profile: PiiccoProfile;
  unprocessedNotifications: number;
}
