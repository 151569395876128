import {CommonModule, DatePipe} from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { ProfileCoverComponent } from './components/profile-cover/profile-cover.component';
import { ClubListItemComponent } from './components/club-list-item/club-list-item.component';
import { ContactComponent } from './components/contact/contact.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { AboutComponent } from './components/about/about.component';
import { SectionComponent } from './components/section/section.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AboutEditDialogComponent } from './components/about-edit-dialog/about-edit-dialog.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { DialogActionsComponent } from './components/dialog-actions/dialog-actions.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ContactEditDialogComponent } from './components/contact-edit-dialog/contact-edit-dialog.component';
import { ProfilePictureDialogComponent } from './components/profile-picture-dialog/profile-picture-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ListFieldComponent } from './components/list-field/list-field.component';
import { MatSelectModule } from '@angular/material/select';
import { DateInputGroupComponent } from './components/date-input-group/date-input-group.component';
import { StringListFieldComponent } from './components/string-list-field/string-list-field.component';
import { BackgroundCoverComponent } from './components/background-cover/background-cover.component';
import { NgxMaskModule } from 'ngx-mask';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { StepperActionsComponent } from './components/stepper-actions/stepper-actions.component';
import { ProfileSelectorComponent } from './components/profile-selector/profile-selector.component';
import { ProfileOverviewComponent } from './components/profile-overview/profile-overview.component';
import { ProfileOverviewDialogComponent } from './components/profile-overview-dialog/profile-overview-dialog.component';
import { PiiccoLineGraphComponent } from './components/piicco-line-graph/piicco-line-graph.component';
import { PublicProfileCoverComponent } from './components/public-profile-cover/public-profile-cover.component';
import { MatMenuModule } from '@angular/material/menu';
import { ProfileRightsDialogComponent } from './components/profile-rights-dialog/profile-rights-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PiiccoScoreTileComponent } from './components/piicco-score-tile/piicco-score-tile.component';
import { ProfileImageInfosComponent } from './components/profile-image-infos/profile-image-infos.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { OrganizationCardComponent } from './components/organization-card/organization-card.component';
import { CoreModule } from '../core/core.module';
import { ProfileCoverCurrentDialogComponent } from './components/profile-cover-current-dialog/profile-cover-current-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LoaderComponent } from './components/loader/loader.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { Ng2ImgMaxModule } from 'ng2-img-max';

import {
  DxButtonModule,
  DxSchedulerModule,
  DxSpeedDialActionModule,
  DxValidationSummaryModule,
  DxValidatorModule
} from 'devextreme-angular';
import {CalendarComponent} from './calendar/components/calendar.component';
import {CalendarService} from './calendar/services/calendar.service';
import {DxiResourceModule} from 'devextreme-angular/ui/nested';
import { AttendeesComponent } from './calendar/components/attendees/attendees.component';
import {MatListModule} from '@angular/material/list';
import {MatRadioModule} from '@angular/material/radio';
import { CalendarUpcomingEventsComponent } from './components/calendar-upcoming-events/calendar-upcoming-events.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperDialogComponent } from './components/image-cropper-dialog/image-cropper-dialog.component';
import { PiiccoIconGraphComponent } from './components/piicco-icon-graph/piicco-icon-graph.component';
import { PiiccoExpansionPanelComponent } from './components/piicco-expansion-panel/piicco-expansion-panel.component';
import { GenericPublicityComponent } from './components/generic-publicity/generic-publicity.component';
import { PiiccoSelectBoxComponent } from './components/piicco-select-box/piicco-select-box.component';
import { MatRippleModule } from '@angular/material/core';
import { PiiccoDatePickerComponent } from './components/piicco-date-picker/piicco-date-picker.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ProfileActionCardComponent } from './components/profile-action-card/profile-action-card.component';
import { GiphyDialogComponent } from './components/giphy-dialog/giphy-dialog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EmojiDialogComponent } from './components/emoji-dialog/emoji-dialog.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { CalendarEntryComponent } from './calendar/components/calendar-entry/calendar-entry.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ChildrenRightsInfoComponent } from './components/children-rights-info/children-rights-info.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

const SHARED_DECLARATIONS = [
  ProfileCoverComponent, 
  ContactComponent, 
  AboutComponent, 
  SectionComponent, 
  AboutEditDialogComponent, 
  DialogHeaderComponent, 
  DialogActionsComponent, 
  ContactEditDialogComponent, 
  ListFieldComponent, 
  ProfilePictureDialogComponent, 
  DateInputGroupComponent, 
  StringListFieldComponent, 
  BackgroundCoverComponent, 
  ProfileCardComponent, 
  ConfirmationDialogComponent, 
  ClubListItemComponent, 
  StepperActionsComponent,
  ProfileOverviewComponent,
  ProfileOverviewDialogComponent,
  PiiccoLineGraphComponent,
  PublicProfileCoverComponent,
  PiiccoScoreTileComponent,
  ProfileImageInfosComponent,
  FileUploaderComponent,
  OrganizationCardComponent,
  LoaderComponent,
  CalendarComponent,
  CalendarUpcomingEventsComponent,
  PiiccoIconGraphComponent,
  PiiccoExpansionPanelComponent,
  GenericPublicityComponent,
  PiiccoSelectBoxComponent,
  PiiccoDatePickerComponent,
  ProfileSelectorComponent,
  GiphyDialogComponent,
  EmojiDialogComponent,
  ChildrenRightsInfoComponent
];

@NgModule({

    declarations: [
        ...SHARED_DECLARATIONS,
        ProfilePictureDialogComponent,
        BackgroundCoverComponent,
        ProfileRightsDialogComponent,
        ProfileCoverCurrentDialogComponent,
        AttendeesComponent,
        ImageCropperDialogComponent,
        ProfileActionCardComponent,
        CalendarEntryComponent,
    ],
  imports: [
    MatRippleModule,
    ImageCropperModule,
    CoreModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    CommonModule,
    Ng2ImgMaxModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ScrollingModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatBadgeModule,
    FormsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatTooltipModule,
    NgxMaskModule.forChild(),
    DxButtonModule,
    DxSchedulerModule,
    DxSpeedDialActionModule,
    DxiResourceModule,
    MatGridListModule,
    NgxMaskModule.forChild(),
    MatListModule,
    MatRadioModule,
    InfiniteScrollModule,
    PickerModule,
    MatTabsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule
  ],
    exports: [...SHARED_DECLARATIONS],
    providers: [
      CalendarService,
      DatePipe
    ]
})
export class SharedModule {
}
