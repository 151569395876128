<vex-dialog-header title="{{ 'outdate-app-dialog.title' | translate }}" [hideIcon]="true"></vex-dialog-header>
<mat-dialog-content>
  <div fxLayout="column">
    <div class="pb-8">
      <p class="confirmation-text">
        {{ 'outdate-app-dialog.text' | translate }}
      </p>
    </div>
  </div>
</mat-dialog-content>
