<div>

    <div class="mb-2" *ngIf="isLoaded">
      <h3>{{'components.image-cropper.title' | translate}}</h3>
      <br>
      <button (click)="zoomIn()" mat-icon-button><mat-icon color="accent">zoom_in</mat-icon></button>
      <button (click)="zoomOut()" mat-icon-button><mat-icon color="accent">zoom_out</mat-icon></button>
      <!--<button (click)="cropper.center()" mat-icon-button><mat-icon color="accent">filter_center_focus</mat-icon></button>-->
      <button (click)="rotateLeft()" mat-icon-button><mat-icon color="accent">rotate_left</mat-icon></button>
      <button (click)="rotateRight()" mat-icon-button><mat-icon color="accent">rotate_right</mat-icon></button>
      <button (click)="fitImage()" mat-icon-button><mat-icon color="accent">fit_screen</mat-icon></button>    
    </div>

    <div >
      <image-cropper
      *ngIf="loadResizedPhoto && readyToLoad"
      [imageBase64]="img"
      [maintainAspectRatio]="true"
      [aspectRatio]="aspectRatio"
      [resizeToWidth]="width"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [cropperMaxWidth]="300"
      [cropperMinWidth]="100"
      [alignImage]="'center'"
      format="png"
      [autoCrop]="false"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"
      style="max-height: 50vh;"
  ></image-cropper>

  <image-cropper
      *ngIf="!loadResizedPhoto && readyToLoad"
      [imageFile]="selectedImage"
      [containWithinAspectRatio]="false"
      [maintainAspectRatio]="true"
      [aspectRatio]="aspectRatio"
      [resizeToWidth]="width"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [autoCrop]="false"
      [cropperMinWidth]="100"
      [alignImage]="'center'"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"
      style="max-height: 50vh;"
  ></image-cropper>
    </div>


  
    <div fxLayout="row" fxLayoutAlign="end" class="mt-4">
      <button 
        class="rounded-button"
        style="margin-right:5px;"
        (click)="close()"
        mat-button 
      >{{'common.cancel' | translate}}
      </button>
      <button 
        mat-stroked-button
        class="dialog-save-button rounded-button"
        (click)="save()"
        mat-button 
      >{{'components.image-cropper.crop' | translate}}</button>
    </div>
  </div>
  