const EMPTY_DATE_VALUE = new Date(1900, 0, 1).toISOString();
const EMPTY_YEAR_OF_BIRTH = 1900;
const EMPTY_NUMBER_VALUE = -2000000;

/**
 * 
 * @param any object 
 * @returns T | null
 */
export const ConvertEmptyValuesToNull = <T>(data: T) => {
    
    if(typeof data === "object") {
        return ConvertPropertyToNull(data);
    }
    
    return null;
}

/**
 * Two supported value type - number and dates
 * @param isNumber 
 * @returns number | string
 */
export const ConvertNullToEmptyValue = (value: any, isNumber = false) => {
    if(value) {
        return value;
    }

    if(isNumber) {
        return EMPTY_NUMBER_VALUE;
    }

    return EMPTY_DATE_VALUE;
}


const ConvertPropertyToNull = <T>(data: T) => {
    if(!data) {
        return data;
    }

    const keys = Object.keys(data);

    keys.forEach((key) => {
        if(typeof data[key] === "object") {
            data[key] = ConvertPropertyToNull(data[key]);
        } else {
            if(key.toLocaleLowerCase().includes("year") && data[key] === EMPTY_YEAR_OF_BIRTH) {
                data[key] = null;
            }
            data[key] = ConvertValueToNull(data[key]);
        }
    });

    return data;
}

const ConvertValueToNull = (value: any) => {
    if(typeof value === "string") {
        try {
            if(new Date(value as string).toISOString() === EMPTY_DATE_VALUE) {
                return null;
            }
        } catch (error) {
            
        }
    }

    if(value === EMPTY_NUMBER_VALUE) {
        return null;
    }

    return value;
}