<div class="mt-5" fxFlex="100">
    <div fxLayout="row wrap">
        <div fxFlex="30" fxFlex.lt-md="100" fxLayoutAlign.lt-md="end center">
            <button 
                *ngIf="showPrevious"
                mat-stroked-button
                class="rounded-text-button rounded-button pl-3 pr-3 mt-3"
                [disabled]="allButtonDisabled"
                mat-button 
                (click)="previous.emit()"
            >
                
            {{ 'common.previous' | translate }}
            </button>
        </div>
        <div fxFlex="70" fxLayout="row" fxFlex.lt-md="100" fxLayoutAlign="end center">
            <button     
                *ngIf="showCancelButton"
                [disabled]="allButtonDisabled"
                class="rounded-button cancel-button pl-3 pr-3 mr-3 mt-3"
                disableRipple
                mat-button 
                (click)="cancel.emit()"
            >
                {{ 'common.cancel' | translate }}
            </button>
            <button 
                mat-stroked-button
                class="rounded-text-button rounded-button pl-3 pr-3 mt-3"
                [disabled]="nextButtonDisabled || allButtonDisabled"
                mat-button 
                (click)="next.emit()"
            >
                
                {{nextButton}}
            </button>
        </div>
        

    </div>
    
</div>