export const APP_DEFAULT_LANG = "fr";

export const APP_LOCAL_STORAGE_LANG_KEY = "piicco-language";

export const APP_LOCAL_STORAGE_CURRENT_ORG_KEY = "current-org"

export enum APP_PIICCO_SCORE_GRADIENTS {
    Blue="linear-gradient(90deg, rgba(151,74,255,1) 0%, rgba(0,212,255,1) 100%);",
    Pink = "linear-gradient(90deg, rgba(151,74,255,1) 0%, rgba(255,0,226,1) 100%);",
    Turquoise = "linear-gradient(90deg, rgba(151,74,255,1) 0%, rgba(0,255,194,1) 100%);",
    Orange = "linear-gradient(90deg, rgba(151,74,255,1) 0%, rgba(255,194,0,1) 100%);",
    Yellow = "linear-gradient(90deg, rgba(151,74,255,1) 0%, rgba(255,237,0,1) 100%);",
}

export enum APP_PIICCO_SCORE_COLORS {
    Blue="rgb(0, 212, 255)",
    Pink="rgb(255, 0, 226)",
    Turquoise="rgb(0, 255, 194)",
    Orange="rgb(255, 194, 0)",
    Yellow="rgb(255, 237, 0)"
}

export const APP_EVENTS_COLORS = [
    'fuchsia',
    'gold',
    'lime',
    'yellow',
    'aqua',
    'aliceblue',
    'antiquewhite',
    'aqua',
    'aquamarine',
    'azure',
    'beige',
    'bisque',
    'burlywood',
    'chartreuse',
    'darkkhaki',
    'darksalmon',
    'darkseagreen'
];