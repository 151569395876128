import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationBellComponent } from './components/notification-bell/notification-bell.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NotificationBellDropdownComponent } from './components/notification-bell-dropdown/notification-bell-dropdown.component';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatBadgeModule } from '@angular/material/badge';
import { PusherNotificationModule } from '../services/pusher-notification/pusher-notification.module';
import { NotificationBellItemComponent } from './components/notification-bell-item/notification-bell-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NetworksService } from '../network/services/networks.service';
import { MatDividerModule } from '@angular/material/divider';
import { HelpComponent } from './components/help/help.component';
import { HelpDropdownComponent } from './components/help-dropdown/help-dropdown.component';
import { FeedbackDialogComponent } from './components/feedback-dialog/feedback-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '../shared/shared.module';
import { ApplicationSettingsDialogComponent } from './components/application-settings-dialog/application-settings-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CreateChildDialogComponent } from './components/create-child-dialog/create-child-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconModule } from '@visurel/iconify-angular';
import { EditChildRightsDialogComponent } from './components/edit-child-rights-dialog/edit-child-rights-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OutdatedAppDialogComponent } from './components/outdated-app-dialog/outdated-app-dialog.component';
import { AddChildParentDialogComponent } from './components/add-child-parent-dialog/add-child-parent-dialog.component';

const EXPORTED_COMPONENETS = [
  NotificationBellComponent,
  SearchBarComponent,
  HelpComponent,
  ApplicationSettingsDialogComponent,
  OutdatedAppDialogComponent,
];

@NgModule({
  declarations: [
    ...EXPORTED_COMPONENETS,
    NotificationBellDropdownComponent,
    NotificationBellItemComponent,
    HelpDropdownComponent,
    FeedbackDialogComponent,
    CreateChildDialogComponent,
    EditChildRightsDialogComponent,
    AddChildParentDialogComponent,
  ],
  imports: [
    FormsModule,
    MatDialogModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    FlexLayoutModule,
    OverlayModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    PusherNotificationModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    IconModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTooltipModule,
    SharedModule,
    MatDividerModule,
    TranslateModule.forChild(),
  ],
  exports: [...EXPORTED_COMPONENETS],
  providers: [NetworksService],
})
export class LayoutModule {}
