<div class="footer" fxLayout="row">
  <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center" vexContainer>
    <a fxFlex="none"
       href="http://piicco.com"
       id="get-vex"
       target="_blank">
      <span>{{"components.footer.go-piicco" | translate}}</span>
    </a>
    <div class="ltr:ml-4 rtl:mr-4" >
      {{'components.footer.all-rights-reserved' | translate}}
    </div>
  </div>
</div>

