import { Component, EventEmitter, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { PiiccoProfile } from './models/piiccoProfile';
import { TranslateService } from '@ngx-translate/core';
import { idTokenSelector } from './store/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { LogoutAction } from './store/auth/auth.actions';
import { PusherNotificationService } from './services/pusher-notification/services/pusher-notification.service';
import { NotificationBellService } from './core/services/notification-bell.service';
import { loadMessages, locale } from 'devextreme/localization';
import frMessages from 'src/assets/i18n/dx.fr.json';
import enMessages from 'src/assets/i18n/dx.en.json';
import ptMessages from 'src/assets/i18n/dx.pt.json';
import { Keepalive } from '@ng-idle/keepalive';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AuthenticationService } from './auth/services/authentication-service/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ForcePasswordChangeDialogComponent } from './profile/components/force-password-change-dialog/force-password-change-dialog.component';
import { StatusBar } from '@capacitor/status-bar';
import { ConfigurationsService } from './core/services/configurations.service';
import semver from 'semver';
import { OutdatedAppDialogComponent } from './layout/components/outdated-app-dialog/outdated-app-dialog.component';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import { PushNotificationsService } from './core/services/push-notifications.service';
declare var cordova: any;
@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'vex';
  profile: PiiccoProfile;
  loadProfile$: EventEmitter<any> = new EventEmitter<any>();

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  isLoadingProfile = false;

  constructor(
    private configService: ConfigService,
    private configurationsService: ConfigurationsService,
    private styleService: StyleService,
    private afAuth: AngularFireAuth,
    private auth: AuthenticationService,
    private store: Store,
    private translate: TranslateService,
    private profileService: ProfilesService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private pusherNotificationService: PusherNotificationService,
    private notificationBellService: NotificationBellService,
    private authenticationService: AuthenticationService,
    private idle: Idle,
    private keepalive: Keepalive,
    private dialog: MatDialog,
    private router: Router,
    private pushNotificationsService: PushNotificationsService
  ) {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    this.setupIdleTimeout();

    this.setUpLayout();

    try {
      if (Capacitor.isPluginAvailable('StatusBar')) {
        StatusBar.setOverlaysWebView({ overlay: false });
      }
    } catch (error) {}

    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        this.store.select(idTokenSelector).subscribe(async (idToken) => {
          if (idToken) {
            const minSupportedVersion = await this.configurationsService.getMinimumSupportedVersion();

            if (
              Device &&
              ((await Device.getInfo()).platform === 'ios' || (await Device.getInfo()).platform === 'android') &&
              minSupportedVersion &&
              semver.gt(minSupportedVersion, await cordova.getAppVersion.getVersionNumber())
            ) {
              this.dialog.open(OutdatedAppDialogComponent, {
                disableClose: true,
              });
            }
            this.loadProfile$.emit(user);
          }
        });
      }
    });

    translate.onLangChange.subscribe((lang) => {
      this.profileService.getOrganizations().subscribe((organizations) => {
        this.navigationService.initMenu(organizations);
        locale(lang.lang);
      });
    });

    this.navigationService.currentNavigationOrg$.subscribe((c) => {
      this.profileService
        .getOrganizations()
        .take(1)
        .subscribe((organizations) => {
          this.navigationService.initMenu(organizations);
        });
    });

    this.loadProfile$.subscribe((user) => {
      this.loadProfile(user);
    });

    loadMessages({ fr: frMessages });
    loadMessages({ en: enMessages });
    loadMessages({ pt: ptMessages });
    locale(translate.currentLang);
  }

  async ngOnInit() {}

  async loadProfile(user) {
    if (this.isLoadingProfile) {
      return;
    }

    this.isLoadingProfile = true;
    let profile = undefined;
    try {
      profile = await this.profileService.getProfile(undefined, true);
      await this.profileService.getProfileFamily();
    } catch (error) {}
    this.isLoadingProfile = false;

    if (localStorage.getItem('isImpersonating') !== 'true') {
      if (profile.userPreferences?.forcePasswordChange) {
        const dialogRef = this.dialog.open(ForcePasswordChangeDialogComponent, {
          width: '600px',
          disableClose: true,
        });

        dialogRef.afterClosed().subscribe(async (success) => {
          if (!success) {
            this.store.dispatch(LogoutAction());
          }
        });
      } else {
        await (await this.pushNotificationsService.init()).registerDevice(profile.id);
        await this.authenticationService.login();
      }
    }

    this.profileService
      .getOrganizations()
      .take(1)
      .subscribe((organizations) => {
        const flattenedOrganizations = this.profileService.getFlattenedUserOrganizations(organizations);
        if (profile.userPreferences && profile.userPreferences.currentOrganization) {
          this.navigationService.currentNavigationOrg =
            flattenedOrganizations.find((o) => o.id === profile.userPreferences.currentOrganization) || organizations[0];
        } else {
          this.navigationService.currentNavigationOrg = organizations[0];
        }

        this.navigationService.initMenu(flattenedOrganizations);
        this.pusherNotificationService.initDispatcher();
      });
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  private setUpLayout() {
    Settings.defaultLocale = this.localeId;

    this.styleService.setStyle(Style.dark);
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.route.queryParamMap
      .pipe(map((queryParamMap) => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))))
      .subscribe((isRtl) => {
        this.document.body.dir = isRtl ? 'rtl' : 'ltr';
        this.configService.updateConfig({
          rtl: isRtl,
        });
      });

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has('layout')))
      .subscribe((queryParamMap) => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has('style')))
      .subscribe((queryParamMap) => this.styleService.setStyle(queryParamMap.get('style') as Style));
  }

  private setupIdleTimeout() {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(15 * 60);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(10);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => (this.idleState = 'No longer idle.'));
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      const trusted = localStorage.getItem('trustBrowser') === 'true';
      if (trusted) {
        this.reloadRoute();
      } else {
        this.store.dispatch(LogoutAction());
        this.auth.logout();
        this.profileService.clearCache();
      }
    });
    this.idle.onIdleStart.subscribe(() => (this.idleState = "You've gone idle!"));
    this.idle.onTimeoutWarning.subscribe((countdown) => (this.idleState = 'You will time out in ' + countdown + ' seconds!'));

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
  }

  private reloadRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
