import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { DatesService } from './services/dates.service';
import { RightsService } from './services/rights.service';
import { MatDialogModule } from '@angular/material/dialog';
import { InformationDialogService } from './services/information-dialog.service';
import { SportsService } from './services/sports.service';
import { ConfigurationsService } from './services/configurations.service';
import { DocumentsService } from './services/documents.service';
import { NotificationsService } from './services/notifications.service';
import { NotificationBellService } from './services/notification-bell.service';
import { ProfilesService } from './services/profiles.service';
import { PageRightGuard } from './guards/page-right.guard';
import { PublicitiesService } from './services/publicities.service';
import { ResourcesService } from './services/resources.service';
import { ImageService } from './services/image.service';
import { GiphyService } from './services/giphy.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PushNotificationsService } from './services/push-notifications.service';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    ReactiveFormsModule, // TODO: See if this component should be in the CoreModule
    TranslateModule.forChild(),
    MatDialogModule,
    InfiniteScrollModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    RightsService,
    InformationDialogService,
    DatesService,
    SportsService,
    ConfigurationsService,
    DocumentsService,
    NotificationsService,
    PublicitiesService,
    ResourcesService,
    ImageService,
    GiphyService,
  ],
})
export class CoreModule {
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [NotificationBellService, ProfilesService, PageRightGuard, PushNotificationsService],
    };
  }
}
