import {Injectable} from '@angular/core';
import {PiiccoProfile} from '../../models/piiccoProfile';

@Injectable({
  providedIn: 'root'
})
export class PublicitiesService {

  constructor(
  ) {
  }

  canDisplayPublicity(profile: PiiccoProfile) {
    return this.isUserMajor(profile.dateOfBirth);
  }

  private isUserMajor(profileDOB: string|null) {
    if(!profileDOB) {
      return true;
    }


    const today = new Date();
    const birthDate = new Date(profileDOB);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age >= 18;
  }

}
