import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogData } from '../../models/dialog.model';

@Component({
  selector: 'vex-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  @Input() okCaption: string = 'common.delete';
  @Input() cancelCaption: string = 'common.cancel';
  @Output() accepted = new EventEmitter<void>();
  @Output() refused = new EventEmitter<void>();

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {}

  ngOnInit(): void {}

  get cancelButtonText() {
    if (!!this.data.cancelButtonText) {
      return this.data.cancelButtonText;
    }

    return this.translate.instant(this.cancelCaption);
  }

  get acceptButtonText() {
    if (!!this.data.okButtonText) {
      return this.data.okButtonText;
    }

    return this.translate.instant(this.okCaption);
  }

  get alignCenter() {
    if (!!this.data.alignCenter) {
      return this.data.alignCenter;
    }

    return false;
  }

  get hideCancelButton() {
    return this.data.hideCancelButton || false;
  }

  refuseEvent() {
    this.refused.emit();
    this.dialogRef.close();
  }
}
