import { Component, Input, OnInit } from '@angular/core';
import { UserRights } from 'src/app/core/models/user-rights.model';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { RightsService } from 'src/app/core/services/rights.service';

@Component({
  selector: 'vex-children-rights-info',
  templateUrl: './children-rights-info.component.html',
  styleUrls: ['./children-rights-info.component.scss']
})
export class ChildrenRightsInfoComponent implements OnInit {
  @Input() currentFeatureRight: UserRights;
  @Input() text: string;

  show = false;

  constructor(
    private profilesService: ProfilesService,
    private rightsService: RightsService
  ) { }

  async ngOnInit() {
    this.show = this.rightsService.isImpersonatingAndChildDoesNotHaveAccess(await this.profilesService.getProfile(), this.currentFeatureRight);
  }

}
