<vex-section
  fxFlex="100%"
  icon="remove_red_eye"
  [title]="translate.instant('common.description')"
  [hasEditRights]="hasEditRights"
  (clicked)="editUserInformations()"
  [hideTitle]="hideSectionTitle"
>
  <div fxLayout="row wrap">
    <div class="pb-4" fxFlex="25%" fxFlex.lt-md="50">
      <label>{{ 'common.height' | translate }}</label>
      <p class="profile-info">{{ fullHeight || '-' }}</p>
    </div>
    <div class="pb-4" fxFlex="25%" fxFlex.lt-md="50">
      <label>{{ 'common.gender' | translate }}</label>
      <p class="profile-info">{{ profileGender || '-' }}</p>
    </div>
    <div class="pb-4" fxFlex="25%" fxFlex.lt-md="50">
      <label>{{ 'common.birth-date-year' | translate }}</label>
      <p class="profile-info">{{ yearOfBirth || '-' }}</p>
    </div>
    <div class="pb-4" fxFlex="25%" fxFlex.lt-md="50">
      <label>{{ 'common.weight' | translate }}</label>
      <p class="profile-info">{{ weight || '-' }} {{ !!weight ? ('common.lbs' | translate) : '' }}</p>
    </div>
    <div class="pb-4" fxFlex="25%" fxFlex.lt-md="50">
      <label>{{ 'common.foot' | translate }}</label>
      <p class="profile-info">{{ profileFoot || '-' }}</p>
    </div>
    <div class="pb-4" fxFlex="25%" fxFlex.lt-md="50">
      <label>{{ 'common.best-position' | translate }}</label>
      <p class="profile-info">{{ getBestPosition || '-' }}</p>
    </div>
    <div class="pb-4" fxFlex="25%" fxFlex.lt-md="50">
      <label>{{ 'common.other-positions' | translate }}</label>
      <p class="profile-info">{{ concatenatedOtherPositions || '-' }}</p>
    </div>
  </div>
</vex-section>
