<div fxLayout="row wrap">
  <mat-card class="piicco-socre-details-card no-border-radius" fxFlex="100">
    <mat-card-content style="min-width: 100%" fxLayout="row wrap">
      <vex-piicco-line-graph
        *ngIf="outputType !== EvaluationOutputType.GraphicOutput"
        [fxFlex]="graphBarWidth"
        fxFlex.lt-md="100"
        [lineColor]="graphColor"
        [lineHeight]="lineHeight"
        [percentage]="percentage"
        [evaluatorsScore]="evaluatorsScore"
        [targetScore]="targetScore"
        [athleteFirstName]="athleteFirstName"
      >
        <div class="mb-3" [fxFlex]="graphBarWidth" fxFlex.lt-md="100">
          <h3 class="piicco-score-line-graph" style="font-weight: 500" *ngIf="!!title">
            <span [innerHtml]="title"></span>
            <span style="float: right">
              {{ percentage }}
            </span>
          </h3>
        </div>
      </vex-piicco-line-graph>

      <vex-piicco-icon-graph
        *ngIf="outputType === EvaluationOutputType.GraphicOutput"
        [fxFlex]="graphBarWidth"
        fxFlex.lt-md="100"
        [iconColor]="graphColor"
        [lineHeight]="lineHeight"
        [percentage]="percentage"
        classes=""
        [lineHeight]="30"
      >
        <div class="mb-3" [fxFlex]="graphBarWidth" fxFlex.lt-md="100">
          <h3 class="piicco-score-line-graph" style="font-weight: 500" *ngIf="!!title">
            <span [innerHtml]="title"></span>
          </h3>
        </div>
      </vex-piicco-icon-graph>

      <div *ngIf="!hideDescription" fxFlex="50" fxFlex.lt-md="100" [ngClass.lt-md]="'mt-4'" fxLayout="row" fxLayoutAlign="center">
        <p
          [ngClass]="{ 'fade-description': sectionOpened }"
          fxFlex="80"
          fxFlex.lt-md="100"
          class="label description-label"
          *ngIf="description"
        >
          {{ description }}
        </p>
      </div>

      <div
        class="mt-4"
        [fxFlex]="hideDescription ? 100 - graphBarWidth : 10"
        fxFlex.lt-md="100"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <div fxflex="100">
          <button
            *ngIf="!sectionOpened"
            mat-stroked-button
            class="rounded-button ml-1 ml-1 fixed-width"
            mat-button
            (click)="openDetails()"
          >
            {{ 'common.details' | translate }}
          </button>
          <button
            mat-flat-button
            *ngIf="sectionOpened"
            class="rounded-button ml-1 ml-1 fixed-width"
            mat-button
            color="accent"
            (click)="openDetails()"
          >
            {{ 'common.close' | translate }}
          </button>
        </div>
      </div>

      <div *ngIf="!hideDescription && sectionOpened" fxFlex="100" fxFlex.lt-md="100" class="mt-4" fxLayout="row">
        <p fxFlex="100" fxFlex.lt-md="100" class="label" *ngIf="description">{{ description }}</p>
      </div>
    </mat-card-content>
  </mat-card>

  <ng-content fxFlex="100" *ngIf="sectionOpened"></ng-content>
</div>
