import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { Store } from '@ngrx/store';
import { idTokenSelector, userSelector } from 'src/app/store/auth/auth.selectors';
import { APP_LOCAL_STORAGE_LANG_KEY, APP_DEFAULT_LANG } from 'src/app/core/config/application.config';
import { languageSelector } from 'src/app/store/global/global.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';
  private token = 'secrettoken';
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private store: Store, private auth: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      if ((req.url.includes('localhost') || req.url.includes('piicco')) && !req.url.includes('profiles/init')) {
        return this.store.select(idTokenSelector).pipe(
          switchMap((token) => {
            if (!token) {
              return next.handle(req);
            }

            let lang = APP_DEFAULT_LANG;
            let tokenToUse = token;

            if (req.headers.get('useParentToken') && req.headers.get('useParentToken') !== '') {
              tokenToUse = req.headers.get('useParentToken');
            }

            this.store.select(languageSelector).subscribe((r) => (lang = r));

            try {
              if (localStorage.getItem('parentToken') && !req.headers.get('useParentToken')) {
                req = req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'x-profileid': tokenToUse,
                    'x-parentprofileid': localStorage.getItem('parentToken'),
                    'Accept-Language': lang,
                  },
                });
              } else {
                req = req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${tokenToUse}`,
                    'x-profileid': tokenToUse,
                    'Accept-Language': lang,
                  },
                });
              }
              return next.handle(req);
            } catch (e) {
              console.error('intercept clone error', e);
            }
          })
        );
      } else {
        return next.handle(req);
      }
    } catch (e) {
      console.error('intercept error', e);
    }
  }
}
