import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable, NgZone } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { first, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
  constructor(private router: Router, private auth: AngularFireAuth) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.authState
      .map((authState) => !!authState)
      .do((authenticated) => {
        if (!authenticated && state.url.indexOf('/invite/platforminvite/accept') > -1) {
          this.router.navigate(['/register'], { queryParams: { returnUrl: state.url } });
        }

        if (!authenticated && state.url.indexOf('/invite/platforminvite/accept') === -1) {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        }
      });
  }
}
