import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Publicity } from 'src/app/core/models/publicity.model';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { PublicitiesService } from 'src/app/core/services/publicities.service';

@Component({
  selector: 'vex-generic-publicity',
  templateUrl: './generic-publicity.component.html',
  styleUrls: ['./generic-publicity.component.scss']
})
export class GenericPublicityComponent implements OnInit {
  @Input() title: string;
  @Input() publicity: Publicity;
  @Input() height = 250;
  @Input() width = 300;
  @Output() publicityHided = new EventEmitter<void>();

  showPub = false;

  constructor(
    private publicitiesService: PublicitiesService,
    private profilesService: ProfilesService
  ) { }

  async ngOnInit() {
    this.showPub = this.publicitiesService.canDisplayPublicity(
      await this.profilesService.getProfile()
    );
    if(!this.showPub) {
      this.publicityHided.emit();
    }
}


  get pubLink() {
    return this.publicity.publink[this.profilesService.getUserLang()];
  }

  goToPub() {
    window.open(this.publicity.target[this.profilesService.getUserLang()], '_blank');
  }
}
