import { Component, Input, OnInit } from '@angular/core';
import { LoaderSize } from '../../models/loader.model';

@Component({
  selector: 'vex-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() align = "center";
  @Input() isLoading = false;
  @Input() size: LoaderSize = LoaderSize.Medium;
  constructor() { }

  ngOnInit(): void {
  }

  get loaderSize() {
    switch(this.size){
      case LoaderSize.xSmall:
        return '20';
      case LoaderSize.Small:
        return '30';
      case LoaderSize.Medium:
        return '40';
      case LoaderSize.Large:
        return '50';
      case LoaderSize.xLarge:
        return '60';
      default:
        return '20';
    }
  }
}
