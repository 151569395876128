import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { iif, Observable, of, Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';

import { takeUntil, tap } from 'rxjs/operators';
import { OrganizationsService } from 'src/app/organization/services/organizations.service';
import { UpdateOrganizationAboutAction, UpdateOrganizationErrorAction, UpdateOrganizationSuccessAction } from 'src/app/organization/store/organization.actions';
import { SetGlobalErrorMessageAction, SetGlobalSuccessMessageAction } from 'src/app/store/global/global.actions';
import { AboutDialogData, TargetedEntity } from '../../models/dialog.model';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { UpdatePiiccoProfile } from 'src/app/models/piiccoProfile';
import { Nationality } from 'src/app/core/models/nationality.model';
import { ConfigurationsService } from 'src/app/core/services/configurations.service';
import { MultiLangItem } from 'src/app/core/models/configuration.model';

@Component({
  selector: 'vex-about-edit-dialog',
  templateUrl: './about-edit-dialog.component.html',
  styleUrls: ['./about-edit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutEditDialogComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  @ViewChild('actions') submitAction;
  @Output() updateUser = new EventEmitter<UpdatePiiccoProfile>();
  
  _availableNationalities: Nationality[] = [];
  TargetedEntity = TargetedEntity;

  isSaving = false;

  form = new FormGroup({
    about: new FormControl('', []),
    nationalities: new FormControl('', [])
  });


  constructor(
    public dialogRef: MatDialogRef<AboutEditDialogComponent>,
    public store: Store,
    private actions$: Actions,
    private configurationsService: ConfigurationsService,
    @Inject(MAT_DIALOG_DATA) public data: AboutDialogData
  ) { 
    this.form.controls.about.setValue(this.data.aboutText);



    this.configurationsService.getNationalities().subscribe((response) => {
      this._availableNationalities = response;
      if(this.data.nationalities) {
        this.form.controls.nationalities.setValue( this.data.nationalities || null);
      }
    });

  }

  get about() { return this.form.get('about'); }
  get nationalities() { return this.form.get("nationalities"); }
  
  ngOnInit(): void {

    this.actions$.pipe(
      takeUntil(this.destroy$),
      ofType(UpdateOrganizationSuccessAction),
      tap(() => this.isSaving = false),
      tap(() => this.dialogRef.close())
    ).subscribe();

    this.actions$.pipe(
      takeUntil(this.destroy$),
      ofType(UpdateOrganizationErrorAction),
      tap(() => this.isSaving = false),
    ).subscribe();
  }

  get saving() {
    return this.data.isSaving || this.isSaving;
  }

  get nationalitiesData(): MultiLangItem[] {
    if(!this.form.controls.nationalities.value) {
      return [];
    }

    return this.form.controls.nationalities.value;
  }

  set nationalitiesData(data: MultiLangItem[]) {
    this.form.controls.nationalities.setValue(data);
  }

  save() {
    
    if(!this.data.entityId) {
      //TODO: Programming issue - We should catch it in an external monitoring tool. 
      this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.error-occured"}));
      this.isSaving = false;
      return;
    }

    switch(this.data.targetedEntity) {
      case TargetedEntity.Organization:
        this.isSaving = true;
        this.store.dispatch(UpdateOrganizationAboutAction({aboutText: this.about.value, organizationId: this.data.entityId}));
        
        break;
      case TargetedEntity.Profile:
        this.updateUser.emit({id: this.data.entityId, bio: this.about.value, nationalities: this.nationalitiesData});
        break;
      default: 
        //TODO: Programming issue - We should catch it in an external monitoring tool. 
        this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "common.error-occured"}));
        this.isSaving = false;
        break;
    }
  }

  get availableNationalities() {
    return this._availableNationalities;
  }

  get submitDisabled() {
    return this.form.invalid || this.isSaving
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
