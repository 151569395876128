<button 
    class="button" 
    mat-icon-button 
    type="button"
    #originRef
    color="white"
    (click)="showPopover()"
>
    <mat-icon>
        help_outline
    </mat-icon>
</button>