import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PublicProfile } from 'src/app/models/piiccoProfile';

@Component({
  selector: 'vex-profile-selector',
  templateUrl: './profile-selector.component.html',
  styleUrls: ['./profile-selector.component.scss']
})
export class ProfileSelectorComponent implements OnInit {
  @Input() isLoading = false;
  @Input() availableProfiles: PublicProfile[] = [];
  @Input() selectedProfilesId: string[] = [];
  @Input() sectionTitle: string;
  @Input() noProfileAvailableMsg: string;
  @Input() disableSelected = false;
  @Input() alreadySelectedProfiles: string[] = [];
  
  
  @Output() selected = new EventEmitter<string[]>();

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  get availableProfileList() {
    return this.availableProfiles.filter((i) => !this.alreadySelectedProfiles.some((j) => j === i.id));
  }


  isSelected(profileId: string) {
    return !!this.selectedProfilesId.find((i) => i === profileId) || !!this.alreadySelectedProfiles.find((i) => i === profileId);
  }

  selectAll() {
    if(this.selectedProfilesId.length === this.availableProfiles.length) {
      this.selected.emit([]);
    } else {
      this.selected.emit(this.availableProfileList.map((i) => i.id));
    }
  }

  get selectAllButtonText() {
    if(this.selectedProfilesId.length !== this.availableProfiles.length) {
      return this.translate.instant("common.select-all");
    }
    return this.translate.instant("common.unselect-all");

  }

  select(event, profileId: string) {
    event.stopPropagation();

    const userAlreadySelected = !!this.alreadySelectedProfiles.find((i) => i === profileId);

    if(!userAlreadySelected) {
      if(!!this.selectedProfilesId.find((i) => i === profileId)) {
        this.selected.emit(this.selectedProfilesId.filter((i) => i !== profileId));
      } else {
        this.selected.emit([...this.selectedProfilesId, profileId]);
      }
    }
  }

}
