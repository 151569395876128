import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { PiiccoSelectBoxItem } from '../../models/piicco-selectbox.model';

@Component({
  selector: 'vex-piicco-select-box',
  templateUrl: './piicco-select-box.component.html',
  styleUrls: ['./piicco-select-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PiiccoSelectBoxComponent implements OnInit {
  @Input() items: PiiccoSelectBoxItem<any>[];
  @Output() selected = new EventEmitter<PiiccoSelectBoxItem<any>>();

  isOpen = false;

  currentIndex = 0;

  constructor() { }

  ngOnInit(): void {
    if(this.items.length > 0) {
      this.select(0);
    }
  }

  clickEvent() {
    this.isOpen = !this.isOpen;
  }

  select(idx) {
    this.currentIndex = idx;
    this.selected.emit(this.current);
  }

  get current() {
    return this.items[this.currentIndex];
  }

  ngDoCheck(){
    var widthButton=document.getElementById("piicco-select-box").offsetWidth;
    var elems = document.getElementsByClassName('mat-menu-panel') as HTMLCollectionOf<HTMLElement>;
    for(var i = 0; i < elems.length; i++) {
      elems[i].style.width = widthButton+"px";
    }
  }

  menuClosed() {
    this.isOpen = false;
  }
}
