<div *ngIf="show">
    <emoji-mart 
      class="emoji-dialog" 
      [perLine]="7" 
      [sheetSize]="32" 
      [isNative]="true" 
      [darkMode]="true"
      (emojiClick)="addEmoji($event)"
      [i18n]="getTranslations()"
      [showPreview]="false"
      ></emoji-mart>
</div>