import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AboutEditDialogComponent } from '../about-edit-dialog/about-edit-dialog.component';
import { AboutDialogData, TargetedEntity } from '../../models/dialog.model';
import { UpdatePiiccoProfile } from 'src/app/models/piiccoProfile';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MultiLangItem } from 'src/app/core/models/configuration.model';
import { ProfilesService } from 'src/app/core/services/profiles.service';

@Component({
  selector: 'vex-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  @Input() bio: string|null|undefined
  @Input() nationalities: MultiLangItem[]|null = null;
  @Input() entityId: string;
  @Input() isSaving: boolean;
  @Input() targetedEntity: TargetedEntity;
  @Input() hasEditRights: boolean = false;
  @Output() updateUser = new EventEmitter<UpdatePiiccoProfile>();
  @Input() updateSucceeded: Observable<boolean> = of(false);

  dialogRef: MatDialogRef<AboutEditDialogComponent>;
  TargetedEntity = TargetedEntity;

  constructor(
    public translate: TranslateService, 
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private profilesService: ProfilesService
  ) { }

  get sanitizedBio() {
    return this.sanitizer.bypassSecurityTrustHtml(this.bio);
  }

  editAbout() {
    const dialogData: AboutDialogData = {
      entityId: this.entityId,
      targetedEntity: this.targetedEntity, 
      aboutText: this.bio,
      isSaving: false,
      nationalities: this.nationalities
    };

    this.dialogRef = this.dialog.open(
      AboutEditDialogComponent,{
        width: '600px',
        data: dialogData
      }
    );

    this.dialogRef.componentInstance.updateUser.subscribe((data) => {
      this.dialogRef.componentInstance.data.isSaving = true;

      
      this.updateUser.emit(data);
    });

  }

  get concatenatedNationalities() {
    if(!this.nationalities) {
      return null;
    }

    return this.nationalities.map((i) => {
      return i[this.userLang];
    }).join(", ");
  }

  get userLang() { //TODO: 
    return this.profilesService.getUserLang();
  }

  ngOnInit(): void {
    this.updateSucceeded.pipe(
      takeUntil(this.destroy$),
      tap((isSuccess) => {
        if(!!this.dialogRef) {
          this.dialogRef.componentInstance.data.isSaving = false;

          if(isSuccess) {
            this.dialogRef.componentInstance.close();
          }
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
