<div fxLayout="row wrap" class="notification" matRipple *ngIf="show" (click)="clickEvent($event)">
  <div fxFlex="100" fxLayout="row">
    <div fxFlex="10" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon
        style="font-size: 35px !important; width: 35px !important; height: 35px !important; line-height: 35px !important"
        color="primary"
        >{{ notificationIcon }}</mat-icon
      >
    </div>
    <span fxFlex="65" class="pl-3 pr-1" [innerHTML]="notificationText"></span>
    <div
      fxFlex="25"
      fxLayout="row"
      fxLayoutAlign="end center"
      *ngIf="
        notification.type !== NotificationType.EventInvite ||
        (notification.type === NotificationType.EventInvite && hasEventRSVPAccess)
      "
    >
      <button color="rgba(0,0,0,0.5)" icon type="button" (click)="markAsReadEvent($event)">
        <mat-icon>clear</mat-icon>
      </button>
      <button class="ml-2" mat-mini-fab type="button" (click)="acceptEvent()" *ngIf="showAcceptButton">
        <mat-icon>check</mat-icon>
      </button>
    </div>
  </div>
</div>
