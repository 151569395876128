import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_PIICCO_SCORE_GRADIENTS } from 'src/app/core/config/application.config';
import { PiiccoScoreColor } from 'src/app/evaluation/models/piicco-score.model';

@Component({
  selector: 'vex-piicco-line-graph',
  templateUrl: './piicco-line-graph.component.html',
  styleUrls: ['./piicco-line-graph.component.scss'],
})
export class PiiccoLineGraphComponent implements OnInit {
  private readonly LINE_DEFAULT_BG_COLOR = '#8C8CB3';
  @Input() percentage: number = 0;
  @Input() lineHeight = 20;
  @Input() lineColor;
  @Input() targetScore: number;
  @Input() evaluatorsScore: number;
  @Input() athleteFirstName = '';

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}

  get darkLineWidth() {
    if (!this.percentage || this.percentage < 0) {
      return '100%';
    }

    return Math.round(100 - this.percentage).toString() + '%';
  }

  get darkLineLeftPosition() {
    if (!this.percentage || this.percentage < 0) {
      return '0%';
    }
    return Math.round(this.percentage).toString() + '%';
  }

  get linePixelHeight() {
    return Math.round(this.lineHeight - 0.5).toString() + 'px';
  }

  get containerPadding() {
    return (this.lineHeight + 10).toFixed(2) + 'px';
  }

  get evaluatorsPointerContainerWidth() {
    return `calc(${this.evaluatorsScore.toFixed(2)}% - 5px)`;
  }

  get pointerHeight() {
    return `calc(${this.lineHeight || 0}px + 5px)`;
  }

  get mePointerHeight() {
    return `calc(${this.lineHeight || 0}px - 10px)`;
  }

  get pointerContainerMarginTop() {
    return `${this.lineHeight}px`;
  }

  get targetScoreContainerWidth() {
    return `calc(${this.targetScore.toFixed(2)}% - 5px)`;
  }

  get athleteName() {
    if (!this.athleteFirstName) {
      return this.translate.instant('common.me');
    }

    return this.athleteFirstName.charAt(0);
  }

  getGraphColor() {
    return this.lineColor;
  }
}
