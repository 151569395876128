<div class="dropdown">
  <div class="dropdown-content" *ngIf="!isSwitching">
    <a
      (click)="close()"
      *ngFor="let item of items; trackBy: trackById"
      [routerLink]="item.route"
      class="notification"
      fxLayout="row"
      fxLayoutAlign="start center"
      matRipple
    >
      <mat-icon [icIcon]="item.icon" [ngClass]="item.colorClass" class="notification-icon" fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{ item.label }}</div>
        <div class="notification-description">{{ item.description }}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
    <a
      *ngIf="!userIsChildren()"
      (click)="openSettingsDialog()"
      class="notification"
      fxLayout="row"
      fxLayoutAlign="start center"
      matRipple
    >
      <mat-icon color="accent" class="notification-icon" fxFlex="none">settings</mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{ 'toolbar-user-dropdown.settings-option' | translate }}</div>
      </div>
    </a>
    <div *ngIf="(!userIsChildren() && familyFeatureActive()) || isImpersonated()">
      <mat-card
        *ngFor="let child of getFamilyChildrens()"
        (click)="switchProfile(child.id)"
        class="notification clickable-flat"
        fxLayout="row"
        fxLayoutAlign="start center"
        matRipple
      >
        <div fxLayout="row wrap" fxFlex="100">
          <div fxFlex="90" fxLayout="row" fxLayoutAlign="start center">
            <img
              mat-card-avatar
              class="child-card-avatar"
              [src]="getChildProfileImage(child)"
              (error)="getDefaultChildImage($event, child)"
            />
            <div class="subtitle ml-2">
              <span>{{ child.firstName }} {{ child.lastName }}</span> <br />
            </div>
          </div>

          <div fxFlex="10">
            <mat-icon color="accent"> sync_alt </mat-icon>
          </div>
        </div>
      </mat-card>
    </div>
  </div>

  <div *ngIf="isSwitching" fxLayout="row wrap" fxLayoutAlign="center">
    <mat-spinner class="mt-2" mat-spinner [diameter]="40"></mat-spinner>
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="space-between center">
    <a (click)="logout()" color="primary" mat-button>{{ 'vex.layout.toolbar.toolbar-user.logout' | translate }}</a>
  </div>
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon [icIcon]="icBusiness"></mat-icon>
    <span>{{ 'vex.layout.toolbar.toolbar-user.toolbar-user-dropdown.settingsMenu.changeAddress' | translate }}</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icVerifiedUser"></mat-icon>
    <span>{{ 'vex.layout.toolbar.toolbar-user.toolbar-user-dropdown.settingsMenu.changeUsername' | translate }}</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icLock"></mat-icon>
    <span>{{ 'vex.layout.toolbar.toolbar-user.toolbar-user-dropdown.settingsMenu.changePassword' | translate }}</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icNotificationsOff"></mat-icon>
    <span>{{ 'vex.layout.toolbar.toolbar-user.toolbar-user-dropdown.settingsMenu.disableNotifications' | translate }}</span>
  </button>
</mat-menu>
