<div class="m-1" >

    <mat-card matRipple id="piicco-select-box" (click)="isOpen = !isOpen" [matMenuTriggerFor]="menu" class="piicco-select-box-card" (menuClosed)="menuClosed()">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex="100" style="padding-left:10px">
                <h3 [innerHtml]="current?.name || ''" class="piicco-select-box-text"></h3>
            </div>

            <mat-icon class="piicco-select-box-chevron">
                {{isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}
            </mat-icon>

            <mat-menu class="piicco-select-box-menu" #menu="matMenu">
                <div style="width:100%">
                    <button *ngFor="let item of items; let i = index" mat-menu-item [innerHTML]="item.name" (click)="select(i)"></button>
                </div>
            </mat-menu>
        </div>
    </mat-card>
</div>
