import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { createAction, props } from '@ngrx/store';
import { PiiccoUserPreferences } from 'src/app/models/piiccoProfile';

export const SetLanguageAction = createAction('[Global] - Set language', props<{ language: string }>());

export const ChangeLanguageAction = createAction(
  '[Global] - Change language',
  props<{ preferences: PiiccoUserPreferences; userId: string }>()
);

export const SetGlobalErrorMessageAction = createAction('[Global] - Set error message', props<{ translationKey: string }>());

export const SetGlobalSuccessMessageAction = createAction('[Global] - Set success message', props<{ translationKey: string }>());

export const SetClearMessageAction = createAction('[Global] - Clear message');
