import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { PublicProfile } from 'src/app/models/piiccoProfile';
import { NormalizeSearchResultItem } from 'src/app/network/models/network-search.model';
import { NetworksService } from 'src/app/network/services/networks.service';
import { Organization } from 'src/app/organization/models/organization';
import { OrganizationSearchResult, ProfileSearchResult, SelectedSearchResult } from 'src/app/organization/models/organization-seach.model';
import { OrganizationsService } from 'src/app/organization/services/organizations.service';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { TargetedEntity } from 'src/app/shared/models/dialog.model';
import { SearchResult } from 'src/app/shared/models/search.model';
import { getDefaultImage, getProfileImage } from 'src/app/core/utils/profile-photo.util';

@Component({
  selector: 'vex-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchBarComponent implements OnInit {
  private readonly MAX_SEARCH_RESULTS = 4;
  private destroy$ = new Subject();
  @ViewChild("input") myInputField: ElementRef;

  results: NormalizeSearchResultItem[] = [];
  resultCount = 0;

  showSearch = false;

  @ViewChild('input') input: ElementRef;

  search = new FormControl();
  isLoading = false;

  getDefaultImage = getDefaultImage;
  getProfileImage = getProfileImage;

  constructor(
    public translate: TranslateService,
    private networksService: NetworksService,
    private router: Router
  ){
    this.search.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(async(val) => {
        this.isLoading = true;
        this.results = await this.searchQuery(val);
        this.isLoading = false;
      })
    ).subscribe();
  }

  ngOnInit() {}

  @HostListener('document:click', ['$event'])
  clickout() {
    this.resetSearch();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();  
  }

  get showSeeAllResultsPage() {
    return this.resultCount > this.MAX_SEARCH_RESULTS;
  }

  resetSearch() {
    this.search.setValue("");
    this.resultCount = 0;
    this.results = [];
    this.showSearch = false;
  }

  get hasNoResults() {
    return (
      this.searchResults.length === 0 &&
      !this.isLoading && 
      this.search.value &&
      this.search.value.length > 3
    );
  }

  goAllResults() {
    this.router.navigateByUrl(`/network/search/${this.search.value}`);
  }

  async searchQuery(value: string) {

    if(!value || value.length < 3) {
      return [];
    }

    const results = await this.networksService.searchNetwork(value);

    this.resultCount = results.organizations.recordCount + results.profiles.recordCount;

    return [
      ...this.normalizeOrganization(results.organizations),
      ...this.normalizeProfile(results.profiles)
    ]
  }

  showHideInput(clickEvent) {
    this.showSearch = !this.showSearch;

    if(this.showSearch) {
      setTimeout(() => this.myInputField.nativeElement.focus(), 100);
    } else {
      this.resetSearch();
    }

    clickEvent.stopPropagation();
  }

  goProfile(item: NormalizeSearchResultItem) {
    this.resetSearch();
    if(item.type === TargetedEntity.Organization) {
      this.router.navigateByUrl(`/organization/${item.id}`);
    } else {
      this.router.navigateByUrl(`/profile/${item.id}`);

    }
  }

  get searchResults() {
    return this.results.slice(0, this.MAX_SEARCH_RESULTS);
  }

  normalizeOrganization(organizationSearchResults: SearchResult<Organization>): NormalizeSearchResultItem[] {
    return organizationSearchResults.records.map((i) => {
      return {
        id:  i.id,
        name: i.name,
        photo: i.logo,
        type: TargetedEntity.Organization
      }
    });
  }

  normalizeProfile(profileSearchResults: SearchResult<PublicProfile>): NormalizeSearchResultItem[] {
    return profileSearchResults.records.map((i) => {
      return {
        id:  i.id,
        name: `${i.firstName} ${i.lastName}`,
        photo: i.photo,
        type: TargetedEntity.Profile
      }
    });
  }

  getProfilePhoto(searchResult: NormalizeSearchResultItem) {
    return getProfileImage(searchResult.name, searchResult.type, searchResult.photo);
  }

  getDefaultPhoto($event, searchResult: NormalizeSearchResultItem) {
    getDefaultImage($event, searchResult.name, searchResult.type);
  }

}
