import moment from "moment";
import { APP_LOCAL_STORAGE_LANG_KEY, APP_DEFAULT_LANG } from "../config/application.config";
import { YEAR_MONTH_DATE, YEAR_MONTH_DAY_DATE, YEAR_ONLY_DATE } from "../config/dates-format.config";
import { DynamicDate } from "../models/date.model";

export const IsFromDateAfterThenToDate = (from: DynamicDate|null, to: DynamicDate|null) => {
    if(!from || !to) {
        return false;
    }   

    return (
        (from.year && to.year) && from.year > to.year ||
        (YearIsEqualOrGreater(from.year, to.year) && (!from.month || !to.month)) ||
        (YearIsEqual(from.year, to.year) && MonthIsEqualOrGreater(from.month, to.month)) ||
        (YearIsEqual(from.year, to.year) && MonthIsEqual(from.month, to.month) && DayIsEqualOrGreater(from.day, to.day))
    );
}

export const nowYear = () => {
    return new Date().getFullYear(); 
}

export const getDateStringFromDynamicDate = (date: DynamicDate|null, forceFormat?) => {
    if(!date || !date.year) {
      return null;
    }

    if(!date.month && date.month !== 0) {
        return formatDate(new Date(date.year,0), YEAR_ONLY_DATE);
    }

    if(!date.day) {
        return formatDate(new Date(date.year, date.month - 1), YEAR_MONTH_DATE);
    }
    
    return formatDate(new Date(date.year, date.month - 1, date.day), YEAR_MONTH_DAY_DATE);
}

export const getFromToDate = (fromDate: DynamicDate|null|undefined, toDate: DynamicDate|null|undefined, presentText: string) => {
    if(!fromDate && !toDate) {
        return '';
    }
    
    if(fromDate && !toDate) {
        return getDateStringFromDynamicDate(fromDate) + " - " + presentText;
    }

    return getDateStringFromDynamicDate(fromDate) + " - " + getDateStringFromDynamicDate(toDate);
}

export const buildDynamicDate = (year?: number|null, month?: number|null, day?: number|null): DynamicDate|null => {
    if(!year && !month && !day) {
        return null;
    }

    let date: DynamicDate = {};

    if(year){
        date.year = year;
    }

    if(month || month === 0){
        date.month = month + 1;
    }

    if(day){
        date.day = day;
    }

    return date;
}

export const buildYearList = (min: number = 1950, max?: number): {value: number, text: string}[] => {
    if(!max) {
      max = new Date().getFullYear()
    }

    let years: {value: number, text: string}[] = [];


    for(let i = max; i >= min; i--) {
      years.push({
        value: i,
        text: i.toString()
      });
    }

    return years;
}

export const buildMonthList = (min = 1, max = 12): {value: number, text: string}[] => {
    let months: {value: number, text: string}[] = [];


    for(let i = min; i <= max; i++) {
        months.push({
        value: i - 1,
        text: (i.toString().length == 1 ? `0${i.toString()}` : i.toString()) 
        });
    }

    return months; 
}

export const buildDayList = (min = 1, max = 31): {value: number, text: string}[] => {
    let days: {value: number, text: string}[] = [];


    for(let i = min; i <= max; i++) {
    days.push({
        value: i,
        text: (i.toString().length == 1 ? `0${i.toString()}` : i.toString()) 
    });
    }

    return days; 
}

export const buildDate = (year: number, month: number, day: number) => {
    return new Date(year, month, day, 0,0,0,0);
}

const formatDate = (date: Date, format: any) => {
    const userLocale = localStorage.getItem(APP_LOCAL_STORAGE_LANG_KEY) || APP_DEFAULT_LANG;

    return moment(date).locale(userLocale).format(getLocaleDateFormat(userLocale, format));
}

const getLocaleDateFormat = (locale: string, dateFormat: any) => {
    return dateFormat[locale] || dateFormat[APP_DEFAULT_LANG];
}

const YearIsEqualOrGreater = (fromYear?: number|null, toYear?: number|null) => {
    return (fromYear && toYear) && fromYear >= toYear;
}

const YearIsEqual = (fromYear?: number|null, toYear?: number|null) => {
    return (fromYear && toYear) && fromYear === toYear;
}


const MonthIsEqualOrGreater = (fromMonth?: number|null, toMonth?: number|null) => {
    return ( fromMonth && toMonth ) && fromMonth >= toMonth;
}

const MonthIsEqual = (fromMonth?: number|null, toMonth?: number|null) => {
    return ( fromMonth && toMonth ) && fromMonth === toMonth;
}

const DayIsEqualOrGreater = (fromDay?: number|null, toDay?: number|null) => {
    return ( fromDay && toDay ) && fromDay >= toDay;
}