import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { MultiLangItem } from 'src/app/core/models/configuration.model';
import { ConfigurationsService } from 'src/app/core/services/configurations.service';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { getDefaultImage, getProfileImage } from 'src/app/core/utils/profile-photo.util';
import { PiiccoCurrentOrganization, PiiccoProfile } from 'src/app/models/piiccoProfile';
import { Organization } from 'src/app/organization/models/organization';
import { DialogAction, ProfileCoverCurrentDialogData, TargetedEntity } from '../../models/dialog.model';
import { GENDERS, SPORT_TYPE_GENDER } from '../../static-data/profile.data';

@Component({
  selector: 'vex-profile-cover-current-dialog',
  templateUrl: './profile-cover-current-dialog.component.html',
  styleUrls: ['./profile-cover-current-dialog.component.scss'],
})
export class ProfileCoverCurrentDialogComponent implements OnInit, DialogAction {
  @Output() updateUserCurrent = new EventEmitter<ProfileCoverCurrentDialogData | null>();
  availableOrganizations: Organization[] = [];
  availableClasses$: Observable<MultiLangItem[]> = of([]);
  availableCategories$: Observable<MultiLangItem[]> = of([]);
  availablePositions$: Observable<MultiLangItem[]> = of([]);
  availableGenders = SPORT_TYPE_GENDER;
  filteredAvailableOrganizations$: Observable<Organization[]> = of([]);

  getDefaultImage = getDefaultImage;
  getProfileImage = getProfileImage;
  TargetedEntity = TargetedEntity;

  form = new FormGroup({
    firstName: new FormControl(null, []),
    lastName: new FormControl(null, []),
    city: new FormControl(null, []),
    jerseyNumber: new FormControl(null, []),
    title: new FormControl(null, []),
    gender: new FormControl(null, []),
    organizationId: new FormControl(null, []),
    category: new FormControl(null, []),
    position: new FormControl(null, []),
    class: new FormControl(null, []),
  });

  get firstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }
  get city() {
    return this.form.get('city');
  }
  get jerseyNumber() {
    return this.form.get('jerseyNumber');
  }
  get title() {
    return this.form.get('title');
  }
  get gender() {
    return this.form.get('gender');
  }
  get category() {
    return this.form.get('category');
  }
  get position() {
    return this.form.get('position');
  }
  get class() {
    return this.form.get('class');
  }
  get organizationId() {
    return this.form.get('organizationId');
  }

  constructor(
    private profilesService: ProfilesService,
    @Inject(MAT_DIALOG_DATA) public data: ProfileCoverCurrentDialogData,
    public translate: TranslateService,
    private configurationsService: ConfigurationsService,
    public dialogRef: MatDialogRef<ProfileCoverCurrentDialogComponent>
  ) {
    this.availableClasses$ = this.configurationsService.getClasses();
    this.availablePositions$ = this.configurationsService.getPositions();
    this.availableCategories$ = this.configurationsService.getCategories();
  }

  ngOnInit(): void {
    this.profilesService
      .getOrganizations()
      .take(1)
      .pipe(
        map((list) => this.profilesService.getFlattenedUserOrganizations(list) as Organization[]),
        tap((list) => (this.availableOrganizations = list)),
        tap((list) => {
          if (this.data.currentOrganization) {
            if (this.data.currentOrganization.organizationId) {
              const org = list.find((i) => i.id === this.data.currentOrganization.organizationId);
              this.organizationId.setValue(org);
            } else {
              this.organizationId.setValue(this.data.currentOrganization.organizationName);
            }
          }
        })
      )
      .subscribe();

    this.firstName.setValue(this.data.firstName || null);
    this.lastName.setValue(this.data.lastName || null);

    if (this.data.currentOrganization) {
      this.city.setValue(this.data.currentOrganization.city || null);
      this.jerseyNumber.setValue(this.data.currentOrganization.jerseyNumber || null);
      this.title.setValue(this.data.currentOrganization.title || null);
      this.gender.setValue(this.data.currentOrganization.teamGender || null);
      this.category.setValue(this.toStr(this.data.currentOrganization.teamCategory) || null);
      this.class.setValue(this.toStr(this.data.currentOrganization.teamClass) || null);
      this.position.setValue(this.toStr(this.data.currentOrganization.position) || null);
    }

    this.filteredAvailableOrganizations$ = this.organizationId.valueChanges.pipe(
      startWith(''),
      map((i: Organization | string | undefined) => {
        if (!i) {
          return this.availableOrganizations;
        }

        if (typeof i !== 'string') {
          return this.availableOrganizations.filter((o) => !i || o.name.toLocaleLowerCase().includes(i.name.toLocaleLowerCase()));
        }

        return this.availableOrganizations.filter((o) => !i || o.name.toLocaleLowerCase().includes(i.toLocaleLowerCase()));
      })
    );
  }

  get isSaving() {
    return this.data.isSaving;
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }

    let organizationName = null;
    let organizationId = null;
    let organizationLogo = null;

    const selectedOrg: null | string | Organization = this.organizationId.value;

    if (typeof selectedOrg === 'string') {
      organizationName = this.organizationId.value;
    }

    if (typeof selectedOrg !== 'string' && selectedOrg) {
      organizationName = (selectedOrg as Organization).name;
      organizationId = (selectedOrg as Organization).id;
      organizationLogo = (selectedOrg as Organization).logo;
    }

    this.updateUserCurrent.emit({
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      currentOrganization: {
        city: this.city.value,
        jerseyNumber: this.jerseyNumber.value,
        position: JSON.parse(this.position.value),
        teamCategory: JSON.parse(this.category.value),
        teamClass: JSON.parse(this.class.value),
        teamGender: this.gender.value,
        organizationId: organizationId,
        organizationName: organizationName,
        organizationLogo: organizationLogo,
        title: this.title.value,
      },
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  get submitDisabled() {
    return this.form.invalid || this.isSaving;
  }

  get userLang() {
    return this.profilesService.getUserLang();
  }

  displayFn(entity: Organization | string): string {
    if (typeof entity === 'string') {
      return entity;
    }

    return entity && (entity as Organization).name ? (entity as Organization).name : '';
  }

  toStr(data) {
    return JSON.stringify(data);
  }
}
