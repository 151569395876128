import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PHONE_NUMBER_MASK } from 'src/app/core/masks/phone-number.mask';
import { POSTAL_CODE_MASK } from 'src/app/core/masks/postal-code.mask';
import { ContactDialogData, TargetedEntity } from '../../models/dialog.model';
import { ContactEditDialogComponent } from '../contact-edit-dialog/contact-edit-dialog.component';

@Component({
  selector: 'vex-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @Input() name: string|undefined;
  @Input() phoneNumber: string|undefined;
  @Input() email: string|undefined;
  @Input() streetName: string|undefined;
  @Input() city: string|undefined;
  @Input() state: string|undefined;
  @Input() postalCode: string|undefined;
  @Input() country: string|undefined;
  @Input() website: string|undefined;
  @Input() entityId: string;
  @Input() targetedEntity: TargetedEntity
  @Input() hasEditRights: boolean = false;

  PHONE_NUMBER_MASK= PHONE_NUMBER_MASK;
  POSTAL_CODE_MASK= POSTAL_CODE_MASK;

  constructor(
    translate: TranslateService,
    private dialog: MatDialog  
  ) { }

  ngOnInit(): void {
  }

  editContact() {
    const data: ContactDialogData = {
      contactInfos: {
        civicNumber: null,
        name: this.name,
        city: this.city,
        state: this.state,
        postalCode: this.postalCode,
        country: this.country,
        email: this.email,
        phoneNumber: this.phoneNumber,
        streetName: this.streetName,
        website: this.website
      },
      entityId: this.entityId,
      targetedEntity: this.targetedEntity
    };

    this.dialog.open(
      ContactEditDialogComponent,
      {
        width: '600px',
        data
      }
    )
  }

}
