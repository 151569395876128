import { EventEmitter, Injectable } from '@angular/core';
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
  NavigationOrg,
  NavigationSubheading,
} from '../interfaces/navigation-item.interface';
import { Subject } from 'rxjs';
import { Organization } from 'src/app/organization/models/organization';
import { RightsService } from 'src/app/core/services/rights.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationFeatures } from 'src/app/core/models/organization-features.enum';
import { Roles } from 'src/app/core/models/roles.enum';
import { ChatService } from '../../app/chat/services/chat.service';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { UserRights } from 'src/app/core/models/user-rights.model';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  isInitingMenu = false;
  items: NavigationItem[] = [];
  private _currentNavigationOrg: Organization = null;
  currentNavigationOrg$: EventEmitter<Organization> = new EventEmitter<Organization>();

  countsChanged$: EventEmitter<{ value: string; bgClass: string; textClass: string }> = new EventEmitter<{
    value: string;
    bgClass: string;
    textClass: string;
  }>();

  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  private chatUnreadCount: { value: string; bgClass: string; textClass: string } = {
    value: '0',
    bgClass: 'purple',
    textClass: 'white',
  };

  constructor(
    private rightsService: RightsService,
    private translate: TranslateService,
    private chatService: ChatService,
    private profilesService: ProfilesService
  ) {
    this.chatService.chatCountsUpdated.subscribe(() => {
      const o = isNaN(this.chatService.unreadOrg) ? 0 : this.chatService.unreadOrg;
      const p = isNaN(this.chatService.unreadPersonal) ? 0 : this.chatService.unreadPersonal;
      const count = o + p;
      this.chatUnreadCount = { value: count.toString(), bgClass: 'purple', textClass: 'white' };
      this.countsChanged$.emit(this.chatUnreadCount);
    });
  }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isOrg(item: NavigationItem): item is NavigationOrg {
    return item.type === 'organizations';
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  async initMenu(organizations: Organization[]) {
    if (this.isInitingMenu) {
      return;
    }

    this.isInitingMenu = true;
    this.items = [];
    if (!this.currentNavigationOrg && organizations.length > 0) {
      this.currentNavigationOrg = organizations[0];
    }

    this.items.push({
      type: 'subheading',
      label: this.translate.instant('menu.sidebar.myprofile'),
      children: [
        {
          type: 'link',
          label: this.translate.instant('pages.timeline.timeline'),
          route: '/network/timeline',
          icon: '',
          disabled: false,
          featureCheck: false,
          routerLinkActiveOptions: { exact: true },
        },
        {
          type: 'link',
          label: this.translate.instant('menu.sidebar.profile'),
          route: '/profile',
          icon: '',
          disabled: false,
          featureCheck: false,
          routerLinkActiveOptions: { exact: true },
        },
        {
          type: 'link',
          label: this.translate.instant('pages.evaluation.my-evaluations'),
          route: '/evaluation/profile',
          icon: '',
          disabled: false,
          featureCheck: false,
          routerLinkActiveOptions: { exact: true },
        },
        {
          type: 'link',
          label: this.translate.instant('menu.sidebar.my-calendar'),
          route: '/profile/agenda',
          icon: '',
          disabled: false,
          featureCheck: true,
          routerLinkActiveOptions: { exact: true },
        },
      ],
    });

    this.items.push({
      type: 'subheading',
      label: this.translate.instant('pages.home.organization'),
      children: [
        {
          type: 'organizations',
          organizations: organizations,
        },
      ],
    });

    if (!!this.currentNavigationOrg) {
      this.items.push({
        type: 'link',
        label: this.translate.instant('menu.sidebar.orgprofile'),
        route: '/organization/' + this.currentNavigationOrg.id,
        icon: '',
        disabled: false,
        featureCheck: false,
        routerLinkActiveOptions: { exact: true },
      });

      this.items.push({
        type: 'dropdown',
        label: this.translate.instant('menu.sidebar.office'),
        roles: [Roles.SuperAdmin, Roles.Admin, Roles.CalendarManager, Roles.ResourceManager],
        disabled:
          (!this.hasFeature(OrganizationFeatures.Calendar) &&
            !this.hasFeature(OrganizationFeatures.CoachsOffice) &&
            !this.hasFeature(OrganizationFeatures.Resource) &&
            !this.hasFeature(OrganizationFeatures.OrgTeamMigration)) ||
          !this.hasRights([Roles.SuperAdmin, Roles.Admin, Roles.CalendarManager, Roles.ResourceManager]),
        children: [
          {
            feature: OrganizationFeatures.Calendar,
            roles: [Roles.SuperAdmin, Roles.Admin, Roles.CalendarManager],
            type: 'link',
            label: this.translate.instant('menu.sidebar.calendar'),
            route: '/organization/planning',
            icon: '',
            disabled:
              !this.hasFeature(OrganizationFeatures.Calendar) ||
              !this.hasRights([Roles.SuperAdmin, Roles.Admin, Roles.CalendarManager]),
            featureCheck: true,
            routerLinkActiveOptions: { exact: true },
          },
          {
            feature: OrganizationFeatures.Resource,
            type: 'link',
            label: this.translate.instant('menu.sidebar.resource-manager'),
            route: '/organization/resource-management',
            icon: '',
            disabled: !this.hasRights([Roles.SuperAdmin, Roles.Admin, Roles.ResourceManager]),
            featureCheck: true,
            routerLinkActiveOptions: { exact: true },
          },
          {
            feature: OrganizationFeatures.CoachsOffice,
            type: 'link',
            label: this.translate.instant('menu.sidebar.roster'),
            route: '/organization/coachs-office',
            icon: '',
            disabled: !this.hasRights([Roles.SuperAdmin, Roles.Admin]),
            featureCheck: true,
            routerLinkActiveOptions: { exact: true },
          },
          {
            feature: OrganizationFeatures.OrgTeamMigration,
            type: 'link',
            label: this.translate.instant('menu.sidebar.inter-team-migration'),
            route: '/organization/member-migration',
            icon: '',
            disabled:
              !this.hasRights([Roles.SuperAdmin, Roles.Admin]) ||
              !this.currentNavigationOrg.childOrgs ||
              this.currentNavigationOrg.childOrgs.length < 2,
            featureCheck: true,
            routerLinkActiveOptions: { exact: true },
          },
        ],
        icon: '',
      });

      this.items.push({
        feature: OrganizationFeatures.LockerRoom,
        type: 'link',
        label: this.translate.instant('menu.sidebar.locker-room'),
        route: '/organization/locker-room',
        icon: '',
        disabled: !this.hasFeature(OrganizationFeatures.LockerRoom),
        featureCheck: true,
        routerLinkActiveOptions: { exact: true },
      });
    }

    this.items.push({
      feature: OrganizationFeatures.Invitation,
      roles: [Roles.SuperAdmin, Roles.Admin, Roles.Invitor],
      type: 'link',
      label: this.translate.instant('menu.sidebar.invitations'),
      route: '/organization/invite',
      icon: '',
      disabled:
        !this.hasFeature(OrganizationFeatures.Invitation) || !this.hasRights([Roles.SuperAdmin, Roles.Admin, Roles.Invitor]),
      featureCheck: true,
      routerLinkActiveOptions: { exact: true },
    });

    this.items.push({
      feature: OrganizationFeatures.Evaluations,
      roles: [Roles.SuperAdmin, Roles.Admin, Roles.Evaluator],
      type: 'link',
      label: this.translate.instant('menu.sidebar.orgevaluations'),
      route: '/evaluation/organization',
      icon: '',
      disabled:
        !this.hasFeature(OrganizationFeatures.Evaluations) || !this.hasRights([Roles.SuperAdmin, Roles.Admin, Roles.Evaluator]),
      featureCheck: true,
      routerLinkActiveOptions: { exact: true },
    });

    this.items.push({
      feature: OrganizationFeatures.Search,
      roles: [Roles.SuperAdmin, Roles.Admin, Roles.MemberSearcher],
      type: 'link',
      label: this.translate.instant('menu.sidebar.search'),
      route: '/organization/search',
      icon: '',
      disabled:
        !this.hasFeature(OrganizationFeatures.Search) || !this.hasRights([Roles.SuperAdmin, Roles.Admin, Roles.MemberSearcher]),
      featureCheck: true,
      routerLinkActiveOptions: { exact: true },
    });

    this.isInitingMenu = false;
    this.chatService.fetchChats().then((chats) => {
      const count = this.chatService.unreadOrg + this.chatService.unreadPersonal;
      this.chatUnreadCount = { value: count.toString(), bgClass: 'purple', textClass: 'white' };
      this.countsChanged$.emit(this.chatUnreadCount);
    });
  }

  set currentNavigationOrg(org: Organization) {
    this._currentNavigationOrg = org;
    this.currentNavigationOrg$.emit(this._currentNavigationOrg);
  }

  get currentNavigationOrg(): Organization {
    return this._currentNavigationOrg;
  }

  hasFeature(feature?: string): boolean {
    if (!feature) {
      return false;
    }

    if (this.currentNavigationOrg && this.currentNavigationOrg.features) {
      const foundFeatures = this.currentNavigationOrg.features.filter((f) => f.tag.toLowerCase() === feature.toLowerCase());
      return foundFeatures && foundFeatures.length > 0;
    } else {
      return false;
    }
  }

  hasRights(rights: string[]): boolean {
    if (!this.currentNavigationOrg) {
      return false;
    }

    const foundRight = rights.find((i) => (this.currentNavigationOrg.roles as string[])?.includes(i));

    return !!foundRight;
  }
}
