<vex-dialog-header [title]="translate.instant('add-child-parent-dialog.title')" titleIcon="person_add"></vex-dialog-header>
<mat-dialog-content fxLayout="column">
  <div fxFlex="100" fxLayout="row wrap">
    <vex-profile-card
      class="mb-2"
      fxFlex="70"
      fxFlex.lt-sm="100"
      [lowerCaseName]="true"
      [id]="data.profile.id"
      [firstName]="data.profile.firstName"
      [lastName]="data.profile.lastName"
      [image]="data.profile.photo"
      [ngContentWidth]="20"
      [showNetworkButtons]="false"
      [hideBadge]="true"
    >
    </vex-profile-card>

    <mat-form-field fxFlex="70" class="invitation-search-input" appearance="outline">
      <mat-label>{{ 'common.search' | translate }}</mat-label>
      <input id="search" #input type="text" matInput [formControl]="search" [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let option of searchResults" [value]="toString(option)">
          <div class="search-result" fxLayout="row">
            <img class="avatar" [src]="getProfilePhoto(option)" (error)="getDefaultPhoto($event, option)" />
            <div class="ml-1">{{ option.firstName }} {{ option.lastName }}</div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <vex-loader fxFlex="100" [isLoading]="isLoading"></vex-loader>

    <p *ngIf="selectedParent" fxFlex="100">{{ 'add-child-parent-dialog.parent-to-add' | translate }}</p>
    <vex-profile-card
      *ngIf="selectedParent"
      class="mb-2"
      fxFlex="70"
      fxFlex.lt-sm="100"
      [lowerCaseName]="true"
      [id]="selectedParent.id"
      [firstName]="selectedParent.firstName"
      [lastName]="selectedParent.lastName"
      [image]="selectedParent.photo"
      [ngContentWidth]="20"
      [showNetworkButtons]="false"
      [hideBadge]="true"
    >
    </vex-profile-card>
  </div>
</mat-dialog-content>
<vex-dialog-actions
  [acceptButtonText]="translate.instant('add-child-parent-dialog.add')"
  (cancel)="dialogRef.close()"
  (accept)="save()"
></vex-dialog-actions>
