import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icLock from '@iconify/icons-ic/twotone-lock';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { AuthenticationService } from '../../../../../app/auth/services/authentication-service/authentication.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogoutAction } from 'src/app/store/auth/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationSettingsDialogComponent } from 'src/app/layout/components/application-settings-dialog/application-settings-dialog.component';
import { RightsService } from 'src/app/core/services/rights.service';
import { FamilyMember, PiiccoProfile } from 'src/app/models/piiccoProfile';
import { TargetedEntity } from 'src/app/shared/models/dialog.model';
import { getDefaultImage, getProfileImage } from 'src/app/core/utils/profile-photo.util';
import notify from 'devextreme/ui/notify';
import firebase from 'firebase';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarUserDropdownComponent implements OnInit {
  items: MenuItem[] = [
    {
      id: '1',
      icon: icAccountCircle,
      label: this.translate.instant('vex.layout.toolbar.toolbar-user.toolbar-user-dropdown.menuItem.myProfile.label'),
      description: this.translate.instant('vex.layout.toolbar.toolbar-user.toolbar-user-dropdown.menuItem.myProfile.description'),
      colorClass: 'text-teal',
      route: '/profile',
    },
  ];

  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icArrowDropDown = icArrowDropDown;
  icBusiness = icBusiness;
  icVerifiedUser = icVerifiedUser;
  icLock = icLock;
  icNotificationsOff = icNotificationsOff;
  userName: any;
  profile: PiiccoProfile;
  userFamily: FamilyMember[] = [];
  isSwitching = false;
  getDefaultImage = getDefaultImage;
  getProfileImage = getProfileImage;

  constructor(
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private profilesService: ProfilesService,
    private afAuth: AngularFireAuth,
    private store: Store,
    public router: Router,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private rightsService: RightsService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {}

  async ngOnInit() {
    this.userFamily = await this.profilesService.getProfileFamily();

    this.afAuth.user.subscribe(async (user) => {
      if (!!user) {
        this.profile = await this.profilesService.getProfile();
        this.userName = `${this.profile.firstName} ${this.profile.lastName}`;
      }
      this.cd.detectChanges();
    });

    this.translate.onLangChange.subscribe((event) => {
      this.items = [
        {
          id: '1',
          icon: icAccountCircle,
          label: event.translations.vex.layout.toolbar['toolbar-user']['toolbar-user-dropdown'].menuItem.myProfile.label,
          description:
            event.translations.vex.layout.toolbar['toolbar-user']['toolbar-user-dropdown'].menuItem.myProfile.description,
          colorClass: 'text-teal',
          route: '/apps/social/me',
        },
      ];
    });
  }

  setParentSessionKey() {
    const trusted = localStorage.getItem('trustBrowser') === 'true';
  }

  async switchProfile(id: string) {
    try {
      this.isSwitching = true;
      if (this.isImpersonated()) {
        if (localStorage.getItem('parentId') === id) {
          await this.authenticationService.loginAsUser(localStorage.getItem('parentToken'));
          localStorage.removeItem('isImpersonating');
          localStorage.removeItem('parentToken');
          localStorage.removeItem('parentId');
          localStorage.removeItem('parentFamily');
        } else {
          await this.authenticationService.loginAsUser(localStorage.getItem('parentToken'));

          const token = await this.profilesService.impersonnateFamilyMember(id);

          await this.authenticationService.loginAsUser(token);
        }
      } else {
        const token = await this.profilesService.impersonnateFamilyMember(id);
        const parent = await this.profilesService.getProfile();
        const parentToken = await this.profilesService.impersonnateFamilyMember(parent.id);

        localStorage.setItem('parentToken', parentToken);
        localStorage.setItem('isImpersonating', 'true');
        localStorage.setItem('parentId', parent.id);

        const family: FamilyMember[] = [
          {
            profile: parent,
            unprocessedNotifications: 0,
          },
          ...this.userFamily,
        ];

        localStorage.setItem('parentFamily', JSON.stringify(family));
        await this.authenticationService.loginAsUser(token);
      }

      window.location.reload();
      this.isSwitching = false;
    } catch (error) {
      this.isSwitching = false;
      this.cd.markForCheck();
    }
  }

  getChildProfileImage(child: PiiccoProfile) {
    return getProfileImage(child.firstName + ' ' + child.lastName, TargetedEntity.Profile, child.photo);
  }

  getDefaultChildImage($event, child: PiiccoProfile) {
    getDefaultImage($event, child.firstName + ' ' + child.lastName, TargetedEntity.Profile);
  }

  familyFeatureActive() {
    return this.profile?.userPreferences?.enableFamilyManagement || false;
  }

  getFamilyChildrens() {
    if (this.isImpersonated()) {
      const family = localStorage.getItem('parentFamily');

      if (!family || !this.profile) {
        return [];
      }

      return (
        JSON.parse(family)
          .map((i) => i.profile)
          .filter((j) => j.id !== this.profile.id) || []
      );
    }
    return this.userFamily.map((i) => i.profile) || [];
  }

  isImpersonated() {
    return localStorage.getItem('isImpersonating') === 'true';
  }

  userIsChildren() {
    return this.rightsService.userIsChildren(this.profile);
  }

  openSettingsDialog() {
    this.popoverRef.close();

    this.dialog.open(ApplicationSettingsDialogComponent, {
      width: '600px',
    });
  }

  close() {
    this.popoverRef.close();
  }

  logout() {
    this.store.dispatch(LogoutAction());
    this.profilesService.clearCache();
    this.popoverRef.close();
  }
}
