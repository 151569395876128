import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map, share, tap} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MultiLangItem } from 'src/app/core/models/configuration.model';

@Injectable({
  providedIn: 'root'
})
export class SportsService {
    private nationalitiesObservableCache: Observable<MultiLangItem[]>;
    private nationalitiesCache: MultiLangItem[];


  constructor(
    private http: HttpClient,
  ) { }

  clearCache() {
    this.nationalitiesObservableCache = undefined;
    this.nationalitiesCache = undefined
  }

  getNationalities(): Observable<MultiLangItem[]> {
    if (!!this.nationalitiesCache) {
        return of(this.nationalitiesCache);
    }
  
    if(!!this.nationalitiesObservableCache) {
        return this.nationalitiesObservableCache;
    }

    this.nationalitiesObservableCache = this.http.get<MultiLangItem[]>(environment.apiGateway + 'configurations/NATIONALITIES')
    .pipe(
        tap((nationalities) => this.nationalitiesCache = nationalities), 
        share()
    );

    return this.nationalitiesObservableCache;
  }
}
