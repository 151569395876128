import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable, NgZone } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { ProfilesService } from '../services/profiles.service';
import { RightsService } from '../services/rights.service';
import { Roles } from '../models/roles.enum';
import { map, tap } from 'rxjs/operators';
import { NavigationService } from 'src/@vex/services/navigation.service';

@Injectable({
    providedIn: 'root'
})
export class PageRightGuard implements CanActivate {
  constructor(
      private router: Router,
      private profilesService: ProfilesService, 
      private rightsService: RightsService, 
      private navigationService: NavigationService
    ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const requiredRoles: Roles[] = route.data.roles || [];
    const bypassIfMyProfile: boolean = route.data.bypassIfMyProfile || false;
    const profileIdParamKey: string = route.data.profileIdParamKey || '';

    if(bypassIfMyProfile && profileIdParamKey && route.params[profileIdParamKey]) {
        const profile = await this.profilesService.getProfile();

        if(profile.id === route.params[profileIdParamKey]) {
            return true;
        }
    } 
    
    this.validateRoles(requiredRoles);

    return true;
  }

  ownsRole(requiredRoles: Roles[], userOrgRoles: Roles[]) {
      return requiredRoles.some((i) => userOrgRoles.includes(i));
  }

  private validateRoles(requiredRoles: Roles[]) {
    if(this.navigationService.currentNavigationOrg) {

        if(!this.ownsRole(requiredRoles, this.navigationService.currentNavigationOrg.roles as Roles[])) {
            this.router.navigateByUrl("/");
        }

    } else {
        this.navigationService.currentNavigationOrg$.subscribe((r) => {
            if(!this.ownsRole(requiredRoles, r.roles as Roles[])) {
                this.router.navigateByUrl("/");
            }
        });
    }
  }

}
