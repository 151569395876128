import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { FileUploaderFileInfo } from 'src/app/shared/models/dialog.model';
import { SetGlobalErrorMessageAction, SetGlobalSuccessMessageAction } from 'src/app/store/global/global.actions';
import { CreateFeedback } from '../../models/feedback.model';

@Component({
  selector: 'vex-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {

  files: string[] = [];
  filesInfo: FileUploaderFileInfo[] = [];
  isSaving = false;
  form = new FormGroup({
    text: new FormControl('', [Validators.required]),
  });

  get text() {
    return this.form.get('text');
  }

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private notificationsService: NotificationsService,
    private profilesService: ProfilesService,
    private store: Store
  ) { }

  ngOnInit(): void {
  }

  async save() {
    if(this.form.invalid) {
      return;
    }

    let screenshotName = null;
    let screenshot = null;

    if(this.files.length > 0) {
      screenshot = this.files[0];
      screenshotName = this.filesInfo[0].originalName;
    }

    const data: CreateFeedback = {
      text: this.text.value,
      screenshot: screenshot,
      screenshotFileName: screenshotName
    };

    try {
      this.dialogRef.disableClose = true;
      this.isSaving = true;
      await this.notificationsService.sendFeedback(data);
      this.store.dispatch(SetGlobalSuccessMessageAction({translationKey: "feedback-dialog.sent-success"}));      
      this.close();
      this.isSaving = false;
      this.dialogRef.disableClose = false;
    } catch (error) {
      this.isSaving = false;      
      this.dialogRef.disableClose = false;
      this.store.dispatch(SetGlobalErrorMessageAction({translationKey: "feedback-dialog.sent-error"}));      
    }

  }

  close() {
    this.dialogRef.close();
  }

  get submitDisabled() {
    return this.form.invalid || this.isSaving;
  }

}
