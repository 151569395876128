<div [class]="{'clickable' : clickable}" fxLayout="row" fxFlex="100%" (click)="onClubListItemClicked()" fxLayoutAlign.lt-md="center center">
  <div [fxFlex]="photoWidth" class="club-list-item-container">
    <img
      mat-card-avatar
      class="club-list-item-avatar"
      [src]="logo"
      (error)="getDefaultLogo($event)"
    />
  </div>
  <div [fxFlex]="'calc(100 -' + photoWidth + ' )'" class="club-list-item-texts-margins" fxLayout="column">
    <span class="title">{{title}}</span>
    <div class="flex-break"></div>
    <span class="subtitle">{{subtitle}}&nbsp;</span>
  </div>
</div>
