import { MultiLangItem } from 'src/app/core/models/configuration.model';
import { PiiccoProfile } from 'src/app/models/piiccoProfile';
import { Organization } from 'src/app/organization/models/organization';

export interface Evaluation {
  id: string;
  title: string;
  participants: EvaluationParticipant[];
  target: PiiccoProfile;
  percentageCompleted: number;
  dateCreated?: Date;
  dateFinalized?: string | Date;
  questionnaireId: string;
  owner: Organization | PiiccoProfile;
  content?: CurrentUserEvaluation[];
  mainColor: string;
  mainGradientColor: string;
}

export interface EvaluationParticipant {
  id: string;
  name: string;
  isTarget: boolean;
  percentageCompleted: number;
  invitationDate: string;
  dateFinalized: string;
  photo: string;
}

export interface CreateEvaluation {
  title: string;
  targetIds: string[];
  ownerId: string;
  ownerType: number;
  participants: string[];
  questionnaireId: string;
  outputId: string;
  includeTarget: Boolean;
}

export interface CurrentUserEvaluation {
  id: string;
  type: string;
  title: string;
  invitationDate: Date;
  evaluationStartDate: Date;
  isTarget: boolean;
  dimensions: Dimension[];
  dateFinalized?: Date;
}

export interface Question {
  id: string;
  order: number;
  type: {
    controlName: string;
    configuration?: {
      lines?: number;
      fr?: string[];
      en?: string[];
    };
  };
  element: MultiLangItem;
  scoreCalculation: string;
  text: MultiLangItem;
  answer: string;
  dateAnswered?: Date;
  choices: string[];
}

export interface Dimension {
  id: string;
  name: MultiLangItem;
  questions: Question[];
  gradientColor: string;
  color: string;
}

export enum EvaluationOutputType {
  CompleteOutput = 'COMPLETE_PIICCO_OUTPUT',
  GraphicOutput = 'GRAPHIC_PIICCO_OUTPUT',
}

export interface EvaluationFilter {
  dateBegin?: string | null;
  dateEnd?: string | null;
  categoryId: string | null;
  athleteIds?: string[] | null;
  organizations?: string[] | null;
  evaluatorIds: string[] | null;
}
