import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import * as functions from '@firebase/functions';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { NotificationType } from '../models/notification.model';
import { NotificationBellService } from './notification-bell.service';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  private isRegistered = false;
  private hasNotificationPermission = false;
  private subscribedTopics = [];

  //currentMessage = new BehaviorSubject(null);

  private webToken: null | string = null;

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private router: Router,
    private notificationBellService: NotificationBellService,
    private notificationsService: NotificationsService
  ) {}

  async init() {
    await this.unsubscribeFromAllTopics();
    localStorage.setItem('subscribedTopics', '[]');
    if (this.isMobile()) {
      this.initMobileNotificationEvents();
    } else {
      //this.initWebNotification();
    }

    return this;
  }

  async registerDevice(topic: string) {
    if (this.isMobile()) {
      const hasPermission = await this.requestMobilePermission();

      if (!hasPermission) {
        return false;
      }

      this.hasNotificationPermission = true;
      PushNotifications.register();
      const success = await this.listenToRegistrationEvents();

      if (success) {
        await this.subscribeToTopic(topic);
      }
    } else {
      /*try {
        const token = await this.requestWebPermission();
        this.angularFireMessaging.onMessage((r) => {
          alert(r);
        });

        console.log(token);
        if (token) {
          await this.subscribeToTopic(topic, token as string);
        }
        //this.angularFireMessaging.
      } catch (error) {
        console.log('NOPe', error);
      }*/
    }
  }

  async subscribeToTopic(topicName: string, token?: string) {
    if (this.isMobile()) {
      try {
        const b = await FCM.subscribeTo({ topic: topicName });
        this.isRegistered = true;
        this.subscribedTopics.push(topicName);
        localStorage.setItem('subscribedTopics', JSON.stringify(this.subscribedTopics));
      } catch (error) {
        console.log('subscribeToTopic-Error', error);
      }
    }
  }

  async unsubscribeFromSingleTopic(topicName: string) {
    if (this.isMobile()) {
      try {
        const z = await FCM.unsubscribeFrom({ topic: topicName });
      } catch (error) {}
    }
  }

  async unsubscribeFromAllTopics() {
    if (this.isMobile()) {
      const localStorageTopic = localStorage.getItem('subscribedTopics');
      const topicList = localStorageTopic ? JSON.parse(localStorageTopic) : [];

      for (const topic of topicList) {
        try {
          const z = await FCM.unsubscribeFrom({ topic: topic });
          console.log('unsubscribeFromTopic', z);
        } catch (error) {
          console.log('unsubscribeFromTopic-Error', error);
        }
      }
    }
  }

  deviceRegistered() {
    return this.isRegistered;
  }

  notificationPermissionGranted() {
    return this.hasNotificationPermission;
  }

  private async requestMobilePermission() {
    try {
      const permission = await PushNotifications.requestPermissions();

      return permission.receive === 'granted';
    } catch (error) {
      console.log('eRRROR', error);
      return false;
      // TODO:  Do something.
    }
  }

  private async requestWebPermission() {
    this.angularFireMessaging.getToken.subscribe((r) => console.log(r));

    return new Promise((res, rej) => {
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          return res(token);
        },
        (err) => {
          console.log(err);
          return rej();
        }
      );
    });
  }

  private async listenToRegistrationEvents() {
    return new Promise<boolean>((res, rej) => {
      PushNotifications.addListener('registration', (a) => {
        console.log(a);
        res(true);
      });
      PushNotifications.addListener('registrationError', (e) => {
        rej(e);
      });
    });
  }

  private initMobileNotificationEvents() {
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {});

    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      if (notification.notification.data.notificationId) {
        const notif: { notificationType: string; notificationId: string } = notification.notification.data;

        if (this.isCalendarEventNotification(notif.notificationType as NotificationType)) {
          this.router.navigateByUrl('profile/agenda');
          this.notificationsService.markAsRead(notif.notificationType);
          this.notificationBellService.removeNotificationById(notif.notificationId);
        }

        if (!this.isCalendarEventNotification(notif.notificationType as NotificationType)) {
          this.notificationsService.markAsRead(notif.notificationId);
          this.notificationBellService.removeNotificationById(notif.notificationId);
        }
      }
    });
  }

  private initWebNotification() {}

  private isMobile() {
    return Capacitor.getPlatform() !== 'web';
  }

  private isCalendarEventNotification(notificationType: NotificationType) {
    return (
      notificationType === NotificationType.EventInvite ||
      notificationType === NotificationType.EventDelete ||
      notificationType === NotificationType.EventModify
    );
  }
}
