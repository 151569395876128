import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { Store } from '@ngrx/store';
import { userSelector } from 'src/app/store/auth/auth.selectors';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class NotLoggedInGuard implements CanActivate {
  constructor(private store: Store, private router: Router, private afAuth: AngularFireAuth) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((res, rej) => {
      this.afAuth.user.subscribe((user) => {
        if (user) {
          this.router.navigateByUrl(this.router['browserUrlTree'].queryParams.returnUrl ?? '/');
          res(false);
        } else {
          res(true);
        }
      });
    });
  }
}
