import HTTP_STATUS_CODES from 'http-status-enum';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, mergeMap, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { State } from 'src/app/store';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AbstractOrganizationService } from '../services/organizations.abstract.service';
import { OrganizationsService } from '../services/organizations.service';
import {
  AddOrganizationAccomplishmentAction,
  DeleteOrganizationAccomplishmentAction,
    FetchOrganizationByIdAction, FetchOrganizationByIdErrorAction,
    FetchOrganizationByIdSuccessAction,
    FetchOrganizationChildrenAction,
    FetchOrganizationChildrenErrorAction,
    FetchOrganizationChildrenSuccessAction,
    FetchOrganizationPeopleAction,
    FetchOrganizationPeopleErrorAction,
    FetchOrganizationPeopleSuccessAction,
    UpdateOrganizationAboutAction,
    UpdateOrganizationAccomplishmentAction,
    UpdateOrganizationContactAction,
    UpdateOrganizationErrorAction,
    UpdateOrganizationLogoAction,
    UpdateOrganizationSuccessAction,
} from './organization.actions';
import { SetGlobalErrorMessageAction, SetGlobalSuccessMessageAction } from 'src/app/store/global/global.actions';

@Injectable()
export class OrganizationEffects {
  constructor(private store: Store<State>, private actions$: Actions, private organizationsService: OrganizationsService) {}

  @Effect()
  fetchOrganization$ = this.actions$.pipe(
    ofType(FetchOrganizationByIdAction),
    switchMap(({ organizationId }) =>
      this.organizationsService.getOrganization(organizationId).pipe(
        shareReplay(),
        mergeMap((response) => [FetchOrganizationByIdSuccessAction({ response })]),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          switch (error.status) {
            case HTTP_STATUS_CODES.NOT_FOUND:
              errorMessage = 'pages.organization.not-found';
              break;
            case HTTP_STATUS_CODES.FORBIDDEN:
              errorMessage = 'common.forbidden';
              break;
            default:
              errorMessage = 'common.error-occured';
              break;
          }
          return of(FetchOrganizationByIdErrorAction({ error }), SetGlobalErrorMessageAction({ translationKey: errorMessage }));
        })
      )
    )
  );

  @Effect()
  fetchOrganizationChildren$ = this.actions$.pipe(
    ofType(FetchOrganizationChildrenAction),
    switchMap(({ organizationId }) =>
      this.organizationsService.fetchOrganizationChildren(organizationId).pipe(
        mergeMap((response) => [FetchOrganizationChildrenSuccessAction({ response, organizationId })]),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          switch (error.status) {
            case HTTP_STATUS_CODES.NOT_FOUND:
              errorMessage = 'pages.organization.not-found';
              break;
            case HTTP_STATUS_CODES.FORBIDDEN:
              errorMessage = 'common.forbidden';
              break;
            default:
              errorMessage = 'common.error-occured';
              break;
          }
          return of(
            FetchOrganizationChildrenErrorAction({ error }),
            SetGlobalErrorMessageAction({ translationKey: errorMessage })
          );
        })
      )
    )
  );

  @Effect()
  fetchOrganizationPeoples$ = this.actions$.pipe(
    ofType(FetchOrganizationPeopleAction),
    switchMap(({ organizationId }) =>
      this.organizationsService.fetchOrganizationPeople(organizationId).pipe(
        mergeMap((response) => [FetchOrganizationPeopleSuccessAction({ response, organizationId })]),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          switch (error.status) {
            case HTTP_STATUS_CODES.NOT_FOUND:
              errorMessage = 'pages.organization.not-found';
              break;
            default:
              errorMessage = 'common.error-occured';
              break;
          }
          return of(FetchOrganizationPeopleErrorAction({ error }), SetGlobalErrorMessageAction({ translationKey: errorMessage }));
        })
      )
    )
  );

  @Effect()
  updateOrganizationAbout$ = this.actions$.pipe(
    ofType(UpdateOrganizationAboutAction),
    switchMap(({ organizationId, aboutText }) =>
      this.organizationsService.updateOrganization({ id: organizationId, bio: aboutText }).pipe(
        mergeMap((response) => [
          UpdateOrganizationSuccessAction({ response }),
          SetGlobalSuccessMessageAction({ translationKey: 'components.about.edit-success' }),
        ]),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          switch (error.status) {
            case HTTP_STATUS_CODES.NOT_FOUND:
              errorMessage = 'pages.organization.not-found';
              break;
            default:
              errorMessage = 'common.error-occured';
              break;
          }
          return of(UpdateOrganizationErrorAction({ error }), SetGlobalErrorMessageAction({ translationKey: errorMessage }));
        })
      )
    )
  );

  @Effect()
  updateOrganizationContact$ = this.actions$.pipe(
    ofType(UpdateOrganizationContactAction),
    switchMap(({ organizationId, contactInfo }) =>
      this.organizationsService
        .updateOrganization({
          id: organizationId,
          contactInfos: contactInfo,
        })
        .pipe(
          mergeMap((response) => [
            UpdateOrganizationSuccessAction({ response }),
            SetGlobalSuccessMessageAction({ translationKey: 'components.contact.edit-success' }),
          ]),
          catchError((error: HttpErrorResponse) => {
            let errorMessage = '';

            switch (error.status) {
              case HTTP_STATUS_CODES.NOT_FOUND:
                errorMessage = 'pages.organization.not-found';
                break;
              default:
                errorMessage = 'common.error-occured';
                break;
            }
            return of(UpdateOrganizationErrorAction({ error }), SetGlobalErrorMessageAction({ translationKey: errorMessage }));
          })
        )
    )
  );

  @Effect()
  addOrganizationAccomplishment$ = this.actions$.pipe(
    ofType(AddOrganizationAccomplishmentAction),
    switchMap(({ organizationId, accomplishmentList }) =>
      this.organizationsService
        .updateOrganization({
          id: organizationId,
          accomplishments: accomplishmentList,
        })
        .pipe(
          mergeMap((response) => [
            UpdateOrganizationSuccessAction({ response }),
            SetGlobalSuccessMessageAction({ translationKey: 'components.org-accomplishment.add-success' }),
          ]),
          catchError((error: HttpErrorResponse) => {
            let errorMessage = '';

            switch (error.status) {
              case HTTP_STATUS_CODES.NOT_FOUND:
                errorMessage = 'pages.organization.not-found';
                break;
              default:
                errorMessage = 'common.error-occured';
                break;
            }
            return of(UpdateOrganizationErrorAction({ error }), SetGlobalErrorMessageAction({ translationKey: errorMessage }));
          })
        )
    )
  );

  @Effect()
  updateOrganizationAccomplishment$ = this.actions$.pipe(
    ofType(UpdateOrganizationAccomplishmentAction),
    switchMap(({ organizationId, accomplishmentList }) =>
      this.organizationsService.updateOrganization({ id: organizationId, accomplishments: accomplishmentList }).pipe(
        mergeMap((response) => [
          UpdateOrganizationSuccessAction({ response }),
          SetGlobalSuccessMessageAction({ translationKey: 'components.org-accomplishment.edit-success' }),
        ]),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          switch (error.status) {
            case HTTP_STATUS_CODES.NOT_FOUND:
              errorMessage = 'pages.organization.not-found';
              break;
            default:
              errorMessage = 'common.error-occured';
              break;
          }
          return of(UpdateOrganizationErrorAction({ error }), SetGlobalErrorMessageAction({ translationKey: errorMessage }));
        })
      )
    )
  );

  @Effect()
  deleteOrganizationAccomplishment$ = this.actions$.pipe(
    ofType(DeleteOrganizationAccomplishmentAction),
    switchMap(({ organizationId, accomplishmentList }) =>
      this.organizationsService.updateOrganization({ id: organizationId, accomplishments: accomplishmentList }).pipe(
        mergeMap((response) => [
          UpdateOrganizationSuccessAction({ response }),
          SetGlobalSuccessMessageAction({ translationKey: 'components.org-accomplishment.delete-success' }),
        ]),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          switch (error.status) {
            case HTTP_STATUS_CODES.NOT_FOUND:
              errorMessage = 'pages.organization.not-found';
              break;
            default:
              errorMessage = 'common.error-occured';
              break;
          }
          return of(UpdateOrganizationErrorAction({ error }), SetGlobalErrorMessageAction({ translationKey: errorMessage }));
        })
      )
    )
  );

  @Effect()
  updateOrganizationLogo$ = this.actions$.pipe(
    ofType(UpdateOrganizationLogoAction),
    switchMap(({ organizationId, imageData }) =>
      this.organizationsService.updateOrganizationPhoto(organizationId, imageData).pipe(
        mergeMap((response) => [
          UpdateOrganizationSuccessAction({ response }),
          SetGlobalSuccessMessageAction({ translationKey: 'components.org-logo.edit-success' }),
        ]),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          switch (error.status) {
            case HTTP_STATUS_CODES.NOT_FOUND:
              errorMessage = 'pages.organization.not-found';
              break;
            default:
              errorMessage = 'common.error-occured';
              break;
          }
          return of(UpdateOrganizationErrorAction({ error }), SetGlobalErrorMessageAction({ translationKey: errorMessage }));
        })
      )
    )
  );
}
