import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { APP_DEFAULT_LANG } from 'src/app/core/config/application.config';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { environment } from 'src/environments/environment';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';

@Component({
  selector: 'vex-help-dropdown',
  templateUrl: './help-dropdown.component.html',
  styleUrls: ['./help-dropdown.component.scss'],
})
export class HelpDropdownComponent implements OnInit {
  constructor(public translate: TranslateService, private profilesService: ProfilesService, private dialog: MatDialog) {}

  ngOnInit(): void {}

  openFeedbackDialog() {
    this.dialog.open(FeedbackDialogComponent, {
      width: '600px',
    });
  }

  get instructions() {
    return environment.platform_instructions[this.userLang] || environment.platform_instructions[APP_DEFAULT_LANG];
  }

  get athleteInstructions() {
    return (
      environment.athlete_platform_instructions[this.userLang] || environment.athlete_platform_instructions[APP_DEFAULT_LANG]
    );
  }

  get userLang() {
    return this.profilesService.getUserLang();
  }
}
