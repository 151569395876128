import { PiiccoAcceptedTerms, PublicProfile } from "src/app/models/piiccoProfile";
import { Organization } from "../models/organization";

export interface OrganizationState {
    fetchedOrganizationList: Organization[];
    fetchedOrganizationChildList: {[id: string]: Organization[]};
    fetchedOrganizationPeopleList: {[id: string]: PublicProfile[]};
    isFetchingOrganization: boolean;
}

export const organizationInitialState: OrganizationState = {
    fetchedOrganizationList: [],
    fetchedOrganizationChildList: {},
    fetchedOrganizationPeopleList: {},
    isFetchingOrganization:  false
}