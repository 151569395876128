import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-stepper-actions',
  templateUrl: './stepper-actions.component.html',
  styleUrls: ['./stepper-actions.component.scss']
})
export class StepperActionsComponent implements OnInit {
  @Input() showPrevious = true;
  @Input() nextButtonText: string;
  @Input() allButtonDisabled: boolean = false;
  @Input() nextButtonDisabled: boolean = false;
  @Input() showCancelButton = true;
  @Output() next = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  get nextButton() {
    return this.nextButtonText || this.translate.instant("common.next");
  }

}
