<div class="w-full mt-2" fxLayout="column">
    <ng-content ></ng-content>
    <div [ngStyle]="{'height': linePixelHeight, 'padding-bottom': containerPadding}" class=" graph-container">

        <div 
            [ngStyle]="{
                'height': linePixelHeight, 
                'background': getGraphColor()
            }" 
            class="score-line" 
        ></div>
        <div 
            [ngStyle]="{
                'height': linePixelHeight,
                'width': darkLineWidth,
                'left': darkLineLeftPosition
            }" 
            class="dark-score-line" 
        ></div>

        <div
            *ngIf="!!evaluatorsScore"
            class="evaluators-pointer-container"
            [ngStyle]="{
                'height': linePixelHeight
            }" 
            
        >
            <div class="evaluator-pointer"
            [ngStyle]="{
                'left': evaluatorsPointerContainerWidth,
                'top': pointerHeight
            }" ></div>

            <div 
                *ngIf="!!targetScore"
                class="target-pointer"
                [ngStyle]="{
                    'left': targetScoreContainerWidth,
                    'top': mePointerHeight
                }"
                fxLayout="row wrap" 
            >
                <div 
                    fxFlex="100" 
                    fxLayout="row wrap"
                    fxLayoutAlign="center start"
                >
                    <div>|</div>
                    <div class="target-pointer-me">{{athleteName}}</div>
                </div>    
            </div>
        </div>
    </div>    
</div>