import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor() {}

  async resizeLandscapeImage(originalImage: Blob, newWidth: number) {
    const img = await this.readImage(originalImage);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingQuality = 'high';

    const ratio = this.getLandscapeRatio(img);
    canvas.width = newWidth;
    canvas.height = newWidth * ratio;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    return ctx.canvas.toDataURL();
  }

  async resizePortraitImage(originalImage: Blob, newHeight: number) {
    const img = await this.readImage(originalImage);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingQuality = 'high';

    const ratio = this.getPortraitRatio(img);

    canvas.width = newHeight * ratio;
    canvas.height = newHeight;

    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    return ctx.canvas.toDataURL();
  }

  async getImageExtention(originalImage: Blob | string) {
    if (typeof originalImage === 'string') {
      return originalImage.split(';')[0].split('/')[1];
    }

    const b64 = await this.getBase64Image(originalImage as Blob);
    return b64.split(';')[0].split('/')[1];
  }

  async imageIsPortrait(originalImage: Blob) {
    const img = await this.readImage(originalImage);

    return img.width / img.height < 1;
  }

  async imageIsGif(originalImage: Blob) {
    return originalImage.type === 'image/gif';
  }

  getBase64Image(originalImage: Blob) {
    if (!originalImage) {
      return null;
    }

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(originalImage);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  async readImage(file: Blob) {
    console.log(file);
    return new Promise<any>(async (res) => {
      const dataUrl = await this.getBase64Image(file);
      const img = new Image();
      img.onload = () => {
        res(img);
      };
      img.src = dataUrl;
    });
  }

  private getLandscapeRatio(img: any) {
    return Math.round((img.height / img.width) * 100) / 100;
  }

  private getPortraitRatio(img: any) {
    return Math.round((img.width / img.height) * 100) / 100;
  }
}
