import { Component, Input, OnInit } from '@angular/core';
import { PublicProfile } from 'src/app/models/piiccoProfile';

@Component({
  selector: 'vex-piicco-expansion-panel',
  templateUrl: './piicco-expansion-panel.component.html',
  styleUrls: ['./piicco-expansion-panel.component.scss']
})
export class PiiccoExpansionPanelComponent implements OnInit {
  @Input() title: string;
  opened = false;

  constructor() { }

  ngOnInit(): void {
  }

  get icon() {
    return this.opened ? 'arrow_drop_right' : 'arrow_drop_down';
  }
}
