<vex-dialog-header
    title="{{'components.description.edit' | translate}}"
    titleIcon="fingerprint"
></vex-dialog-header>
<mat-dialog-content >
    <form [formGroup]="form" (keyup.enter)="save()" fxLayout="row wrap">
        <div fxFlex="70" fxLayout="row wrap">
            <label fxFlex="100">{{'common.height' | translate }} - {{'common.weight' | translate }}</label>
            
            <div fxFlex="33.33" fxFlex.lt-sm="50" >
                <mat-form-field class="date-input"  appearance="outline">
                    <mat-label>{{ 'common.height' | translate }}</mat-label>
                    <input id="height" formControlName="height" matInput />
                    <span matSuffix>{{'common.ft' | translate}}</span>
                </mat-form-field>
            </div>

            <div fxFlex="33.33" fxFlex.lt-sm="50" >
                <mat-form-field class="date-input" appearance="outline">
                    <input id="heightInches" formControlName="heightInches" matInput />
                    <span matSuffix>{{'common.inches' | translate}}</span>
                </mat-form-field>
            </div>

            <div fxFlex="33.33" fxFlex.lt-sm="100" >
                <mat-form-field class="date-input" appearance="outline">
                    <mat-label>{{ 'common.weight' | translate }}</mat-label>
                    <input id="weight" formControlName="weight" matInput  />
                    <span matSuffix>{{'common.lbs' | translate}}</span>
                </mat-form-field>
            </div>
           
        </div>
        
        <label  fxFill="100">{{'common.birth-date' | translate}}</label>

        <div fxFlex="70" fxFlex.lt-sm="100" fxLayout="row wrap">
            <div fxFlex="33.33" fxFlex.lt-sm="100" >
                <mat-form-field class="date-input" appearance="outline">
                    <mat-label>{{ 'common.year' | translate }}</mat-label>
                    <mat-select formControlName="year" matInput>
                        <mat-option [value]="null">
                            {{ 'common.year' | translate}}
                        </mat-option>
                        <mat-option *ngFor="let availableYear of availableYears" [value]="availableYear.value">
                        {{availableYear.text}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <div fxFlex="33.33" fxFlex.lt-sm="100" >
                <mat-form-field class="date-input" appearance="outline">
                    <mat-label>{{ 'common.month' | translate }}</mat-label>
                    <mat-select formControlName="month" matInput>
                        <mat-option [value]="null">
                            {{ 'common.month' | translate}}
                        </mat-option>
                        <mat-option *ngFor="let availableMonth of availableMonths" [value]="availableMonth.value">
                        {{availableMonth.text}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="33.33" fxFlex.lt-sm="100" >
                <mat-form-field class="date-input" appearance="outline">
                    <mat-label>{{ 'common.day' | translate }}</mat-label>
                    <mat-select formControlName="day" matInput>
                        <mat-option [value]="null">
                            {{ 'common.day' | translate}}
                        </mat-option>
                        <mat-option *ngFor="let availableday of availabledays" [value]="availableday.value">
                        {{availableday.text}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>            
        </div>
        
        <mat-form-field fxFlex="70" fxFlex.lt-sm="100" class="date-input" appearance="outline">
            <mat-label>{{ 'common.gender' | translate }}</mat-label>
            <mat-select formControlName="gender" matInput>
                <mat-option [value]="">
                    {{ 'common.gender' | translate}}
                </mat-option>
                <mat-option *ngFor="let gender of genders" [value]="gender.value">
                    {{ translate.instant(gender.text)}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="70" fxFlex.lt-sm="100" class="date-input" appearance="outline">
            <mat-label>{{ 'common.foot' | translate }}</mat-label>
            <mat-select formControlName="foot" matInput>
                <mat-option [value]="null">
                    {{ 'common.foot' | translate}}
                </mat-option>
                <mat-option *ngFor="let footItem of feet" [value]="footItem.value">
                    {{ translate.instant(footItem.text)}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="70" fxFlex.lt-sm="100" appearance="outline">
            <mat-label>{{ 'common.best-position' | translate }}</mat-label>
            <mat-select formControlName="bestPosition" matInput>
                <mat-option [value]="null">
                    {{ 'common.best-position' | translate}}
                </mat-option>
                <mat-option *ngFor="let availableDataItem of availablePositions" [value]="toStr(availableDataItem)">
                    {{ availableDataItem[this.userLang] }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <vex-list-field
            fxFlex="70"
            fxFlex.lt-md="100"
            [(data)]="otherPositionsData"
            [availableData]="availablePositions"
            title="{{'common.other-positions' | translate}}"
        ></vex-list-field>
        
    </form>
</mat-dialog-content>
<vex-dialog-actions
    #actions
    [acceptDisabled]="submitDisabled"
    [cancelDisabled]="isSaving"
    acceptButtonText="{{'common.save-changes' | translate}}"
    cancelButtonText="{{'common.cancel' | translate}}"
    (accept)="save()"
    (cancel)="close()"
    [saving]="isSaving"
>

</vex-dialog-actions>