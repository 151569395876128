import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'vex-string-list-field',
  templateUrl: './string-list-field.component.html',
  styleUrls: ['./string-list-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StringListFieldComponent),
      multi: true,
    },
  ],
})
export class StringListFieldComponent implements OnInit, ControlValueAccessor {
  onChange: any = () => {};
  onTouch: any = () => {};
  @Input() data: string[]; 
  @Input() title: string ="";
  @Input() disabled: boolean;

  @Output() dataChange = new EventEmitter<string[]>();

  @ViewChild("input") inputData: ElementRef;

  _data: string[] = [];

  constructor() { }

  ngOnInit(
  ): void {  }

  addElementToArray($event) {
    $event.stopPropagation();  

    if(!!this.inputData.nativeElement.value && this.inputData.nativeElement.value !== "") {
      this._data.push(this.inputData.nativeElement.value)
      
      this.onChange(this._data);
      this.dataChange.emit(this._data);
    }

    this.inputData.nativeElement.value = "";  
  }
  
  removeElement(indexToRemove: number) {
    this._data = this._data.filter((value, index) => index !== indexToRemove);
    this.onChange(this._data);
    this.dataChange.emit(this._data);
  }

  writeValue(value: string[]): void {
    this._data = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
