import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'vex-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {
  @Input() title: string = "";
  @Input() titleIcon: string = "";
  @Input() navigationIcon: string|null = null;
  @Input() hideIcon = false;
  @Input() iconColor = "primary";

  @Output() navigationClicked = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
