import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { ObserverComponent } from 'src/app/core/components/observer.component';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { getDefaultImage, getProfileImage } from 'src/app/core/utils/profile-photo.util';
import { PiiccoProfile } from 'src/app/models/piiccoProfile';
import { TargetedEntity } from 'src/app/shared/models/dialog.model';
import { AddParentToChildDialogData } from '../../models/dialog.model';

@Component({
  selector: 'vex-add-child-parent-dialog',
  templateUrl: './add-child-parent-dialog.component.html',
  styleUrls: ['./add-child-parent-dialog.component.scss'],
})
export class AddChildParentDialogComponent extends ObserverComponent implements OnInit {
  private readonly MAX_SEARCH_RESULTS = 20;

  constructor(
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: AddParentToChildDialogData,
    public dialogRef: MatDialogRef<AddChildParentDialogComponent>,
    private profilesService: ProfilesService,
    private notificationsService: NotificationsService
  ) {
    super();
  }

  @ViewChild('input') input: ElementRef;
  searchResults: PiiccoProfile[] = [];
  search = new FormControl();

  searchedName = '';
  isLoading = false;
  selectedParent: PiiccoProfile | null = null;

  ngOnInit(): void {
    this.search.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(async (val) => {
          this.searchResults = await this.searchQuery(val);
        })
      )
      .subscribe();
  }

  async searchQuery(value: string) {
    return await this.profilesService.searchPublicProfile(value, this.MAX_SEARCH_RESULTS);
  }

  getProfilePhoto(searchResult: PiiccoProfile) {
    return getProfileImage(`${searchResult.firstName} ${searchResult.lastName}`, TargetedEntity.Profile, searchResult.photo);
  }

  getDefaultPhoto($event, searchResult: PiiccoProfile) {
    getDefaultImage($event, `${searchResult.firstName} ${searchResult.lastName}`, TargetedEntity.Profile);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedParent = JSON.parse(event.option.value);
    this.input.nativeElement.value = '';
    this.search.setValue(null);
  }

  toString(data: any) {
    return JSON.stringify(data);
  }

  async save(event?) {
    if (event) {
      event.preventDefault();
    }
    if (!this.isLoading) {
      this.isLoading = true;
      try {
        await this.notificationsService.childShareInvite(this.selectedParent.id, this.data.profile.id);
        notify(this.translate.instant('add-child-parent-dialog.invitation-sent', 'success'));
        this.isLoading = false;
        this.dialogRef.close();
      } catch (error) {
        this.isLoading = false;
      }
    }
  }
}
