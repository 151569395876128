<div fxLayout="row wrap"> 
    <label fxFill="100%" >{{title}}</label>
    <div 
        *ngFor="let dataItem of getImageDataItems(data)"
        fxFlex="100%"
    >
        <div fxFlex="100%" class="list-field-item-text">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
                <img *ngIf="buildImagePath(dataItem) != ''" class="list-field-image" [src]="buildImagePath(dataItem)">
                <span class="ml-2">{{ dataItem[this.userLang] }}</span>
            </div>                            
        </div>
        <div>
            <button
            class="list-clear-button" 
            mat-mini-fab
            (click)="removeElement(dataItem)"
        >
            <mat-icon>clear</mat-icon>
        </button>
        </div>
       
        
    </div>


    <mat-form-field fxFlex="100%" appearance="outline">
        <mat-label>{{ 'common.add' | translate }} +</mat-label>
        <mat-select [(ngModel)]="selectedValue" (selectionChange)="addElementToArray($event)" matInput>
            <mat-option *ngFor="let availableDataItem of filteredAvailableData" [value]="availableDataItem">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <img *ngIf="buildImagePath(availableDataItem) != ''" class="list-field-image" [src]="buildImagePath(availableDataItem)">
                    <span class="ml-2">{{ availableDataItem[this.userLang] }}</span>
                </div>                
            </mat-option>
        </mat-select>
    </mat-form-field>

</div>