import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AbstractOrganizationService} from './organizations.abstract.service';
import {Organization, OrganizationMigration, OrganizationPeopleType, UpdateOrganization} from '../models/organization';
import {PublicProfile} from 'src/app/models/piiccoProfile';
import HTTP_STATUS_CODES from 'http-status-enum';
import {Store} from '@ngrx/store';
import {OrganizationSearchResult} from '../models/organization-seach.model';
import {SearchResult} from 'src/app/shared/models/search.model';
import {OrganizationProfileRole, UpdateOrganizationProfileRole} from '../models/organization-profile-role.model';
import {SetGlobalErrorMessageAction, SetGlobalSuccessMessageAction} from 'src/app/store/global/global.actions';
import { shareReplay, take } from 'rxjs/operators';
import { AthleteStats, UpdateAthleteInfos, OrganizationStarters, OrganizationAthlete, OrganizationStartersSearchResult } from '../models/starting-roster.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  private observableCache: { [key: number]: Observable<Organization> } = {};

  constructor(
    private http: HttpClient,
    private store: Store
  ) {
  }

  private fetchOrganization(organizationId: string): Observable<Organization> {
    return this.http.get<Organization>(
      `${environment.apiGateway}organizations/${organizationId}`
    );
  }

  fetchOrganizationChildren(organizationId: string): Observable<Organization[]> {
    return this.http.get<Organization[]>(
      `${environment.apiGateway}organizations/${organizationId}/childs`
    ).take(1);
  }

  fetchOrganizationAthletesInfo(organizationId: string, stats: AthleteStats) {
    return new Promise<OrganizationAthlete[]>((res, rej) => {
      this.http.get<OrganizationAthlete[]>(
        `${environment.apiGateway}organizations/${organizationId}/athleteinfos?statName=${stats}`
      ).take(1).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej([]);
        }
      );
    });
  }

  migrateAthletes(organizationId : string, data: OrganizationMigration) {
    return new Promise<OrganizationStartersSearchResult>((res, rej) => {
      this.http.post<OrganizationStartersSearchResult>(
        `${environment.apiGateway}organizations/${organizationId}/transfermembers`,
        data
      ).take(1).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej(error);
        }
      );
    });
  }

  fetchOrganizationStarters(organizationId: string, stats: AthleteStats|undefined, page = 1, pageSize = 10) {
    let apiUrl = `${environment.apiGateway}organizations/${organizationId}/starters?statName=${stats}&page=${page}&pagesize=${pageSize}`;
    if(!stats) {
      apiUrl = `${environment.apiGateway}organizations/${organizationId}/starters?page=${page}&pagesize=${pageSize}`
    }

    return new Promise<OrganizationStartersSearchResult>((res, rej) => {
      this.http.get<OrganizationStartersSearchResult>(
        apiUrl
      ).take(1).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej();
        }
      );
    });
  }

  updateOrganizationStarters(organizationId: string, data: UpdateAthleteInfos[], date: string) {
    return new Promise<OrganizationStarters>((res, rej) => {
      this.http.put<OrganizationStarters>(
        `${environment.apiGateway}organizations/${organizationId}/starters?starterDate=${date}`,
        data
      ).take(1).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej([]);
        }
      );
    });
  }

  updateOrganizationAthleteInfos(organizationId: string, data: UpdateAthleteInfos[]) {
    return new Promise<Organization>((res, rej) => {
      this.http.put<Organization>(
        `${environment.apiGateway}organizations/${organizationId}/athleteinfos`,
        data
      ).take(1).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej();
        }
      );
    });
  }

  shareStartingRoster(organizationId: string, data: UpdateAthleteInfos[]) {
    return new Promise<Organization>((res, rej) => {
      this.http.post<Organization>(
        `${environment.apiGateway}organizations/${organizationId}/starters/notification`,
        data
      ).take(1).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej(error);
        }
      );
    });
  }

  // TODO: Migrate to promise +  remove function fetchOrganizationPeopleByType
  fetchOrganizationPeople(organizationId: string,
                          peopleType: OrganizationPeopleType = OrganizationPeopleType.People):
    Observable<PublicProfile[]> {
    return this.http.get<PublicProfile[]>(`${environment.apiGateway}organizations/${organizationId}/${peopleType}`).take(1);
  }

  fetchOrganizationPeopleByType(organizationId: string,
                                peopleType: OrganizationPeopleType = OrganizationPeopleType.People,
                                includeChild: boolean, includeParent: boolean): Promise<PublicProfile[]> {
    return new Promise<PublicProfile[]>((res, rej) => {
      this.http.get<PublicProfile[]>(
        `${environment.apiGateway}organizations/${organizationId}/${peopleType}?includeChild=${includeChild}&includeParent=${includeParent}`
      ).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej([]);
        }
      );
    });

  }

  fetchUserOrganizationRoles(organizationId: string, userId: string) {
    return new Promise<OrganizationProfileRole>((res, rej) => {
      this.http.get<OrganizationProfileRole>(
        `${environment.apiGateway}organizations/${organizationId}/roles/${userId}`
      ).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej([]);
        }
      );
    });
  }

  updateUserOrganizationRoles(organizationId: string, userId: string, updateUserRole: UpdateOrganizationProfileRole) {
    return new Promise<any>((res, rej) => {
      this.http.put<any>(
        `${environment.apiGateway}organizations/${organizationId}/roles/${userId}`,
        updateUserRole
      ).subscribe(
        (response) => {
          this.store.dispatch(SetGlobalSuccessMessageAction({translationKey:
              'components.profile-card.roles-updated'}));
          res(response);
        },
        (error: HttpErrorResponse) => {

          switch (error.status) {
            case HTTP_STATUS_CODES.FORBIDDEN:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'common.forbidden'}));
              break;
            case HTTP_STATUS_CODES.BAD_REQUEST:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'components.profile-card.cannot-change-admin-rights'}));
              break;
            default:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'common.error-occured'}));
              break;
          }

          rej(undefined);
        }
      );
    });
  }

  removerUserFromOrganization(organizationId: string, userId: string) {
    return new Promise<void>((res, rej) => {
      this.http.delete<void>(
        `${environment.apiGateway}organizations/${organizationId}/roles/${userId}`,
      ).subscribe(
        (response) => {
          this.store.dispatch(SetGlobalSuccessMessageAction({translationKey: 'components.profile-card.removed-from-org-success'}));
          res();
        },
        (error: HttpErrorResponse) => {

          switch (error.status) {
            case HTTP_STATUS_CODES.FORBIDDEN:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'common.forbidden'}));
              break;
            case HTTP_STATUS_CODES.BAD_REQUEST:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'components.profile-card.cannot-remove-admin'}));
              break;
            default:
              this.store.dispatch(SetGlobalErrorMessageAction({translationKey: 'common.error-occured'}));
              break;
          }

          rej();
        }
      );
    });
  }

  searchPublicOrganization(search: string | null) {
    return new Promise<OrganizationSearchResult[]>((res, rej) => {
      if (!search || search === '') {
        return res([]);
      }

      return this.http.get<SearchResult<OrganizationSearchResult>>(
        `${environment.apiGateway}organizations/public/list/${search}?page=1&pageSize=15`
      ).subscribe((result) => res(result.records));
    });
  }

  getOrganization(id: string, noCache = false): Observable<Organization> {
    if (!this.observableCache[id] || noCache) {
      this.observableCache[id] = this.fetchOrganization(id).pipe(take(1), shareReplay());
    }
    return this.observableCache[id];
  }

  updateOrganization(data: UpdateOrganization) {
    this.clearCache(data.id);
    return this.http.put<Organization>(
      `${environment.apiGateway}organizations`,
      data
    );
  }

  updateOrganizationPhoto(organizationId: string, imageData: string) {
    this.clearCache(organizationId);
    const image = imageData.slice(imageData.indexOf(',') + 1);
    return this.http.put<Organization>(
      `${environment.apiGateway}organizations/${organizationId}/logo`,
      {imageData: image}
    );
  }

  // updateCache(data: Organization) {
  //   this.organizationCache[data.id] = data;
  // }
  //
  clearCache(id: string) {
    this.observableCache[id] = null;
  }

}

export const ORGANIZATION_SERVICE_PROVIDER = {
  provide: AbstractOrganizationService,
  useClass: OrganizationsService
};
