import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { UpdatePiiccoProfile } from 'src/app/models/piiccoProfile';
import { MultiLangItem } from 'src/app/core/models/configuration.model';
import { DialogAction } from 'src/app/shared/models/dialog.model';
import { DatesService } from 'src/app/core/services/dates.service';
import { FEET, GENDERS } from 'src/app/shared/static-data/profile.data';
import { ProfileOverviewDialogData } from 'src/app/profile/models/dialog.model';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { ConfigurationsService } from 'src/app/core/services/configurations.service';
import { Nationality } from 'src/app/core/models/nationality.model';
import { ConvertNullToEmptyValue } from 'src/app/core/utils/nullable-api-values.util';

@Component({
  selector: 'vex-profile-overview-dialog',
  templateUrl: './profile-overview-dialog.component.html',
  styleUrls: ['./profile-overview-dialog.component.scss']
})
export class ProfileOverviewDialogComponent implements OnInit, DialogAction {
  @Output() updateUser = new EventEmitter<UpdatePiiccoProfile>();
  availableYears = [];
  availableMonths = [];
  availabledays = [];
  genders = GENDERS;
  feet = FEET;

  _availableNationalities: Nationality[] = [];
  _availablePositions: MultiLangItem[] = [];

  form = new FormGroup({
    height: new FormControl('', []),
    heightInches: new FormControl('', []),
    weight: new FormControl('', []),
    gender: new FormControl('', []),
    foot: new FormControl('', []),
    bestPosition: new FormControl('', []),
    otherPositions: new FormControl('', []),
    month: new FormControl('', [Validators.min(0), Validators.max(11)]),
    day: new FormControl('', [Validators.min(1), Validators.max(31)]),
    year: new FormControl('', [Validators.min(1950), Validators.max(this.datesService.nowYear())]),
  });

  get height() { return this.form.get("height"); }
  get heightInches() { return this.form.get("heightInches"); }
  get weight() { return this.form.get("weight"); }
  get gender() { return this.form.get("gender"); }
  get foot() { return this.form.get("foot"); }
  get birthDate() { return this.form.get("birthDate"); }
  get bestPosition() { return this.form.get("bestPosition"); }
  get otherPositions() { return this.form.get("otherPositions"); }
  get day() { return this.form.get("day"); }
  get month() { return this.form.get("month"); }
  get year() { return this.form.get("year"); }


  constructor(
    public dialogRef: MatDialogRef<ProfileOverviewDialogComponent>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ProfileOverviewDialogData,
    private datesService: DatesService,
    private configurationsService: ConfigurationsService,
    private profilesService: ProfilesService
  ) { 
    this.availableYears = this.datesService.buildYearList(1930);
    this.availableMonths = this.datesService.buildMonthList();
    this.availabledays = this.datesService.buildDayList();

    if(!!this.data.birthDate) {
      const date = new Date(this.data.birthDate);

      this.day.setValue(date.getDate());
      this.month.setValue(date.getMonth());
      this.year.setValue(date.getFullYear());
    }

    this.form.controls.height.setValue( this.data.height || "");
    this.form.controls.heightInches.setValue( this.data.heightInches || "");
    this.form.controls.weight.setValue( this.data.weight || "");
    this.form.controls.gender.setValue( this.data.gender || "");
    this.form.controls.foot.setValue( this.data.foot || "");

    this.form.controls.bestPosition.setValue( this.toStr(this.data.bestPosition) || null);
    this.form.controls.otherPositions.setValue( this.data.otherPositions|| null);
    
    

    this.configurationsService.getPositions().pipe(
      tap((i) => this._availablePositions = i),
    ).subscribe();
  }

  toStr(val) {
    return JSON.stringify(val);
  }

  get availablePositions() {
    return this._availablePositions;
  }

  get otherPositionsData(): MultiLangItem[] {
    if(!this.form.controls.otherPositions.value) {
      return [];
    }

    return this.form.controls.otherPositions.value;
  }

  set otherPositionsData(data: MultiLangItem[]) {
    this.form.controls.otherPositions.setValue(data);
  }
  
  get submitDisabled(): boolean {
    return this.form.invalid || this.isSaving;
  }

  get userLang() {
    return this.profilesService.getUserLang();
  }

  get isSaving() {
    return this.data.isSaving;
  }

  async save() {
    if(this.form.invalid) {
      return;
    }
    const profile = await this.profilesService.getProfile(this.data.entityId);
    
    let userSportProfile = this.profilesService.getProfileSoccerProfile(profile);

    if(!userSportProfile) {
      userSportProfile = await this.profilesService.initSoccerProfile();
    }

    userSportProfile.bestPosition = JSON.parse(this.bestPosition.value);
    userSportProfile.otherPositions = this.otherPositionsData;
    userSportProfile.foot = this.foot.value;


    this.updateUser.emit({
      id: this.data.entityId,
      gender: this.gender.value||'',
      weight: ConvertNullToEmptyValue(this.weight.value, true),
      height: ConvertNullToEmptyValue(this.height.value, true),
      heightInches: ConvertNullToEmptyValue(this.heightInches.value, true),
      dateOfBirth: this.getAgregatedDate(),
      sports: {soccer: userSportProfile}
    });
  }

  getAgregatedDate() {
    if(!this.year.value || (!this.month.value && this.month.value !== 0) || !this.day.value) {
      return ConvertNullToEmptyValue(undefined, false);
    }
    return new Date(this.year.value, this.month.value, this.day.value,0,0,0,0);
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
