<div class="wrap">
  <header class="header">
    <h1 mat-dialog-title class="title">{{ 'apps.global.terms.title' | translate }}</h1>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  
  <div mat-dialog-content class="body">
    <h3 class="version">Version: {{ data?.version }}</h3>
    <h3 class="date">Date: {{ date }}</h3>
    <div [innerHTML]="content" class="content"></div>
    <div class="form" [formGroup]="form">
      <mat-checkbox formControlName="terms" [checked]="terms">
        {{ 'apps.global.terms.label' | translate }}
      </mat-checkbox>
    </div>
  </div>

  <footer mat-dialog-actions class="footer">
    <button mat-button (click)="this.closeDialog()">{{ 'apps.global.terms.cancel' | translate }}</button>
    <button mat-raised-button
      color="primary"
      [disabled]="!checked.value || loading"
      (click)="submit()">
      <span *ngIf="!loading">{{ 'apps.global.terms.submit' | translate }}</span>
      <span *ngIf="loading">{{ 'apps.global.terms.submitting' | translate }}</span>
    </button>
  </footer>

  <div class="language">
    <button (click)="setLanguage('fr')" mat-icon-button matTooltip="Français">
      FR
    </button>
    <button (click)="setLanguage('en')" mat-icon-button matTooltip="English">
      EN
    </button>
  </div>
</div>