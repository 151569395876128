import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MultiLangItem, OrganizationRoles } from 'src/app/core/models/configuration.model';
import { Roles } from 'src/app/core/models/roles.enum';
import { ConfigurationsService } from 'src/app/core/services/configurations.service';
import { RightsService } from 'src/app/core/services/rights.service';
import { OrganizationProfileRole, UpdateOrganizationProfileRole } from 'src/app/organization/models/organization-profile-role.model';
import { OrganizationsService } from 'src/app/organization/services/organizations.service';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { ProfileRightsDialogData, TargetedEntity } from '../../models/dialog.model';
import { getDefaultImage } from 'src/app/core/utils/profile-photo.util';

@Component({
  selector: 'vex-profile-rights-dialog',
  templateUrl: './profile-rights-dialog.component.html',
  styleUrls: ['./profile-rights-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileRightsDialogComponent implements OnInit {
  form: FormGroup;
  organizationAvailableRoles: OrganizationRoles[] = [];
  organizationAvailableTitles: MultiLangItem[] = [];
  isLoading = false;
  isSaving = false;

  @Output() rightsChanged = new EventEmitter<void>();

  profileRoles: OrganizationProfileRole;

  getDefaultImage = getDefaultImage;

  constructor(
    public dialogRef: MatDialogRef<ProfileRightsDialogComponent>,
    public dialog: MatDialog,
    private configurationsService: ConfigurationsService,
    private rightsService: RightsService,
    private profilesService: ProfilesService,
    private organizationService: OrganizationsService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ProfileRightsDialogData,
    private formBuilder: FormBuilder
  ) { 
    this.form = this.formBuilder.group({
      roles: new FormArray([], [Validators.required]),
      title: new FormControl(null, []),
      category: new FormControl(null, []),
    });


  }

  get title() { return this.form.get('title'); }
  get category() { return this.form.get('category'); }
  get roles() { return this.form.get('roles'); }
  get rolesFormArray() {  return this.form.controls.roles as FormArray; }

  async ngOnInit() {
    if(this.rightsService.hasOrganizationRole(this.data.selectedOrganization, [Roles.Admin, Roles.SuperAdmin])) {
      this.isLoading = true;
      this.profileRoles = await this.organizationService.fetchUserOrganizationRoles(
        this.data.selectedOrganization.id,
        this.data.profileId
      );

      if(this.profileRoles.title) {
        if(this.profileRoles.title.category) {
          this.category.setValue(JSON.stringify(this.profileRoles.title.category));
        }

        if(this.profileRoles.title.title) {
          this.title.setValue(this.profileRoles.title.title[this.userLang]);
        }
      }

      this.organizationAvailableRoles = await this.configurationsService.getOrganizationRoles();
      this.organizationAvailableRoles.forEach((i, index) => {
        this.rolesFormArray.push(new FormControl(this.profileRoles.roles.includes(i.tag)));
      });

      this.organizationAvailableTitles = await this.configurationsService.getOrganizationTitles();
      this.isLoading = false;
      
      this.form.valueChanges.subscribe((r) => {
        if(!(r.roles as boolean[]).some((i) => i)) {
          this.roles.setErrors({required: true});
        } else {
          if(this.roles.errors) {
            delete this.roles.errors["required"];
          }
        }
      });
    }
  }
  
  get availableRoles() {
    if(this.organizationAvailableRoles.length === 0) {
      return [];
    }
    
    return this.organizationAvailableRoles.map((i) => {
      return {
        name: i.name[this.userLang],
        description: i.description[this.userLang],
        tag: i.tag,
        grantable: i.grantable  
      }
    });
  }

  roleIsAdminAndUserIsAdmin(role: Roles) {
    if(role === Roles.Admin) {
      return this.profileRoles.roles.includes(Roles.Admin) && !this.data.editorRights.includes(Roles.SuperAdmin);
    }

    return false
  }

  get saveDisabled() {
    return this.isLoading || this.form.invalid;
  }

  async updateRoles() {
    const selectedRoles: Roles[] = [];
    (this.rolesFormArray.value as boolean[]).forEach((selected, index) => {
      if(selected) {
        selectedRoles.push((this.availableRoles[index].tag));
      }
    });


    const updatedUserRoles: UpdateOrganizationProfileRole = {
      roles: selectedRoles,
      title: {
        profileId: this.data.profileId,
        category: this.category.value ? JSON.parse(this.category.value) : {fr: '', en: ''},
        title: {
          fr: this.title.value || "",
          en: this.title.value || ""
        }
      }
    };

    this.isSaving = true;
    try {
      await this.organizationService.updateUserOrganizationRoles(
        this.data.selectedOrganization.id,
        this.data.profileId,
        updatedUserRoles
      );
  
      this.isSaving = false;

      this.rightsChanged.emit();

      this.closeDialog();
    } catch (error) {
      this.isSaving = false;
    }
    
  }

  getDefaultLogo($event) {
    getDefaultImage(
      $event,
      this.data.firstName + " " + this.data.lastName, 
      TargetedEntity.Profile
    );
  }

  toStr(data) {
    return JSON.stringify(data);
  }

  get userLang(){
    return this.profilesService.getUserLang();
  }

  closeDialog() {
    if(!this.isLoading) {
      this.dialogRef.close();
    }
  }
}
