import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Organization } from '../../models/organization';
import { FormControl } from '@angular/forms';
import { ProfilesService } from '../../../core/services/profiles.service';
import { NavigationService } from '../../../../@vex/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { debounceTime, tap } from 'rxjs/operators';
import { untilDestroyed } from '@ngneat/until-destroy';
import {
  APP_DEFAULT_LANG,
  APP_LOCAL_STORAGE_CURRENT_ORG_KEY,
  APP_LOCAL_STORAGE_LANG_KEY,
} from '../../../core/config/application.config';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrganizationsService } from '../../services/organizations.service';

@Component({
  selector: 'vex-org-selector-popup',
  templateUrl: './org-selector-popup.component.html',
  styleUrls: ['./org-selector-popup.component.scss'],
})
export class OrgSelectorPopupComponent implements OnInit, OnDestroy {
  originalOrganizations: Organization[] = [];
  organizations: Organization[] = [];
  searchCtrl = new FormControl();

  constructor(
    private profileService: ProfilesService,
    public navigationService: NavigationService,
    public translate: TranslateService,
    public router: Router,
    @Inject('Window') private window: Window,
    private profilesService: ProfilesService,
    private dialogRef: MatDialogRef<OrgSelectorPopupComponent>
  ) {
    this.profileService.getOrganizations().subscribe((orgs) => {
      this.originalOrganizations = this.profileService.getFlattenedUserOrganizations(JSON.parse(JSON.stringify(orgs)));
      this.organizations = this.originalOrganizations;
    });
  }

  ngOnInit(): void {
    this.searchCtrl.valueChanges.pipe(debounceTime(300)).subscribe((value) => this.search(value));
  }

  public filterView(list: Organization[], value: string): any {
    return list.filter((i: Organization) => i.name.toLowerCase().includes(value.toLowerCase()));
  }

  search(value?) {
    if (!value || value === '') {
      this.organizations = JSON.parse(JSON.stringify(this.originalOrganizations));
      return;
    }
    this.organizations = this.filterView(JSON.parse(JSON.stringify(this.originalOrganizations)), value);
  }

  close() {}

  async selectOrg(id: string) {
    this.window.localStorage.setItem(APP_LOCAL_STORAGE_CURRENT_ORG_KEY, id);

    const user = await this.profileService.getProfile();

    if (user.userPreferences) {
      user.userPreferences.currentOrganization = id;
      await this.profilesService.updateProfilePreferences(user.id, user.userPreferences).toPromise();
    } else {
      await this.profilesService
        .updateProfilePreferences(user.id, {
          language: localStorage.getItem(APP_LOCAL_STORAGE_LANG_KEY) || APP_DEFAULT_LANG,
          currentOrganization: id,
          enableEmailNotification: true,
          enablePushNotification: false,
        })
        .toPromise();
    }

    this.navigationService.currentNavigationOrg = this.organizations.find((i) => i.id === id);
    this.router.navigateByUrl(`/organization/${this.navigationService.currentNavigationOrg.id}`);
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.searchCtrl.setValue(undefined);
  }
}
