import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { CustomLayoutModule } from './layout/custom-layout/custom-layout.module';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AuthenticationService } from './auth/services/authentication-service/authentication.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthInterceptor } from './auth/services/authentication-service/auth-interceptor.service';
import { AgePipe } from './pipes/age.pipe';
import { GlobalModule } from './pages/apps/global/global.module';
import { MatDialogModule } from '@angular/material/dialog';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/auth/auth.effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GlobalEffects } from './store/global/global.effects';
import { OrganizationEffects } from './organization/store/organization.effects';
import { NgxMaskModule } from 'ngx-mask';
import { CommonModule, registerLocaleData } from '@angular/common';
import { CoreModule } from '@angular/flex-layout';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import localeFr from '@angular/common/locales/fr';
import localePt from '@angular/common/locales/pt';
import { LoggedInGuard } from './auth/services/authentication-service/logged-in-guard/logged-in.guard';
import { NotLoggedInGuard } from './auth/services/authentication-service/not-logged-in-guard/not-logged-in.guard';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';

registerLocaleData(localeFr);
registerLocaleData(localePt);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    // Material
    MatDialogModule,

    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,

    // Idle
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),

    // Vex
    VexModule,
    CustomLayoutModule,

    // PIICCO
    GlobalModule,

    // NGRX Store
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    NgxMaskModule.forRoot({ validation: false }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([AuthEffects, GlobalEffects, OrganizationEffects]),

    // NGX-Translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthenticationService,
    LoggedInGuard,
    NotLoggedInGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: 'Window', useValue: window },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
