import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PiiccoEvent, PiiccoEventAttendee } from '../../../../models/calendar';
import { CalendarEntryData, RecurrenceTypes } from './Calendar-entry-data.model';
import { PublicProfile } from '../../../../models/piiccoProfile';
import { nanoid } from 'nanoid';
import { CalendarService } from '../../services/calendar.service';
import moment from 'moment';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { MatDatetimePickerInputEvent } from '@angular-material-components/datetime-picker';
import { EventTypes } from 'src/app/core/models/eventtypes.model';

function repeatUntilValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }

  if (formControl.parent.get('recurrence').value !== 'None') {
    return Validators.required(formControl);
  }
  return null;
}

@Component({
  selector: 'vex-calendar-entry',
  templateUrl: './calendar-entry.component.html',
  styleUrls: ['./calendar-entry.component.scss'],
})
export class CalendarEntryComponent implements OnInit {
  recurrenceTypes: RecurrenceTypes[] = [
    { code: 'None', fr: 'Aucune', en: 'None', pt: 'Nenhum' },
    { code: 'Daily', fr: 'Quotidien', en: 'Daily', pt: 'Diária' },
    { code: 'Weekly', fr: 'Hebdomadaire', en: 'Weekly', pt: 'Semanal' },
    { code: 'Monthly', fr: 'Mensuel', en: 'Monthly', pt: 'Mensal' },
  ];

  public attendees: PiiccoEventAttendee[];

  form: FormGroup;
  saving = false;
  durations = [
    { display: '00h00', value: 0 },
    { display: '00h30', value: 0.5 },
    { display: '01h00', value: 1 },
    { display: '01h30', value: 1.5 },
    { display: '02h00', value: 2 },
    { display: '02h30', value: 2.5 },
    { display: '03h00', value: 3 },
    { display: '03h30', value: 3.5 },
    { display: '04h00', value: 4 },
    { display: '04h30', value: 4.5 },
    { display: '05h00', value: 5 },
    { display: '05h30', value: 5.5 },
    { display: '06h00', value: 6 },
    { display: '06h30', value: 6.5 },
    { display: '07h00', value: 7 },
    { display: '07h30', value: 7.5 },
    { display: '08h00', value: 8 },
    { display: '08h30', value: 8.5 },
    { display: '09h00', value: 9 },
    { display: '09h30', value: 9.5 },
    { display: '10h00', value: 10 },
    { display: '10h30', value: 10.5 },
    { display: '11h00', value: 11 },
    { display: '11h30', value: 11.5 },
    { display: '12h00', value: 12 },
    { display: '12h30', value: 12.5 },
    { display: '13h00', value: 13 },
    { display: '13h30', value: 13.5 },
    { display: '14h00', value: 14 },
    { display: '14h30', value: 14.5 },
  ];

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<CalendarEntryComponent>,
    private calendarService: CalendarService,
    @Inject(MAT_DIALOG_DATA) public data: CalendarEntryData,
    public translateService: TranslateService
  ) {
    // title: any;
    // description: any;
    // location: any;
    // organisationId: any;
    // eventType: any;
    // startDate: any;
    // endDate: any;
    // recurrence: any;
    // repeatUntil: any;
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      location: ['', Validators.required],
      organisationId: ['', Validators.required],
      eventType: ['', Validators.required],
      startDate: ['', Validators.required],
      duration: [3, Validators.required],
      endDate: [''],
      recurrence: [''],
      repeatUntil: ['', repeatUntilValidator],
    });
  }

  ngOnInit(): void {
    this.form.controls['organisationId'].setValue(this.data.organisations[0].id);
    this.form.controls['startDate'].setValue(new Date(this.data.startDate));
    this.form.controls['endDate'].setValue(new Date(this.data.endDate));
    this.form.controls['recurrence'].setValue('None');
    this.form.controls['duration'].setValue(3);

    if (this.data.selectedEvent) {
      this.form.controls['title'].setValue(this.data.selectedEvent.text);
      this.form.controls['description'].setValue(this.data.selectedEvent.description);
      this.form.controls['location'].setValue(this.data.selectedEvent.location);
      this.form.controls['eventType'].setValue(this.data.selectedEvent.eventType);
      this.form.controls['startDate'].setValue(new Date(this.data.selectedEvent.startDate));
      this.form.controls['endDate'].setValue(new Date(this.data.selectedEvent.endDate));
      this.form.controls['duration'].setValue(
        this.getDuration(new Date(this.data.selectedEvent.startDate), new Date(this.data.selectedEvent.endDate))
      );
      this.attendees = this.data.selectedEvent.attendees;
    }
  }

  dayOfWeek(date: Date) {
    const day = date.getDay();
    const daysEn = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const daysFr = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const daysPt = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

    switch (this.translateService.currentLang) {
      case 'pt':
        return daysPt[day];
      case 'fr':
        return daysFr[day];
      default:
        return daysEn[day];
    }
  }

  eventTypesLabel(ev: EventTypes) {
    switch (this.translateService.currentLang) {
      case 'pt':
        return ev.pt;
      case 'fr':
        return ev.fr;
      default:
        return ev.en;
    }
  }

  reccurenceLabel(rec: RecurrenceTypes) {
    switch (this.translateService.currentLang) {
      case 'pt':
        return rec.pt;
      case 'fr':
        return rec.fr;
      default:
        return rec.en;
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  invitationsChanged($event: PublicProfile[]) {
    this.attendees = [];
    for (const profile of $event) {
      const attendee: PiiccoEventAttendee = {
        attendee: profile,
        attendeeId: profile.id,
        answer: 'maybe',
      };
      this.attendees.push(attendee);
    }
  }

  generateAppointment(startDate: Date, endDate: Date): PiiccoEvent {
    const appt: PiiccoEvent = {
      id: nanoid(12),
      calendarId: this.data.calendarId,
      text: this.form.controls['title'].value,
      description: this.form.controls['description'].value,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      recurrenceRule: '',
      location: this.form.controls['location'].value,
      eventType: this.form.controls['eventType'].value,
      attendees: this.attendees,
    };

    return appt;
  }

  async generateSingleAppointment() {
    const appt: PiiccoEvent = this.generateAppointment(
      this.form.controls['startDate'].value,
      this.form.controls['endDate'].value
    );
    const apptRes: PiiccoEvent = await this.calendarService.createEvent(appt, this.form.controls['organisationId'].value);
  }

  async generateDailyAppointments() {
    let startDate: Date = this.form.controls['startDate'].value;
    let endDate: Date = this.form.controls['endDate'].value;

    let startDateNoTime = new Date(startDate);
    startDateNoTime.setHours(0, 0, 0, 0);

    const until: Date = new Date(this.form.controls['repeatUntil'].value);
    until.setHours(0, 0, 0, 0);
    do {
      const appt: PiiccoEvent = this.generateAppointment(startDate, endDate);
      const apptRes: PiiccoEvent = await this.calendarService.createEvent(appt, this.form.controls['organisationId'].value);
      startDate = moment(startDate).add(1, 'days').toDate();
      startDateNoTime = new Date(startDate);
      startDateNoTime.setHours(0, 0, 0, 0);
      endDate = moment(endDate).add(1, 'days').toDate();
    } while (startDateNoTime <= until);
  }

  async generateWeeklyAppointments() {
    let startDate: Date = this.form.controls['startDate'].value;
    let endDate: Date = this.form.controls['endDate'].value;

    let startDateNoTime = new Date(startDate);
    startDateNoTime.setHours(0, 0, 0, 0);

    const until: Date = new Date(this.form.controls['repeatUntil'].value);
    until.setHours(0, 0, 0, 0);
    do {
      const appt: PiiccoEvent = this.generateAppointment(startDate, endDate);
      const apptRes: PiiccoEvent = await this.calendarService.createEvent(appt, this.form.controls['organisationId'].value);
      startDate = moment(startDate).add(1, 'weeks').toDate();
      startDateNoTime = new Date(startDate);
      startDateNoTime.setHours(0, 0, 0, 0);
      endDate = moment(endDate).add(1, 'weeks').toDate();
    } while (startDateNoTime <= until);
  }

  async generateMonthlyAppointments() {
    let startDate: Date = this.form.controls['startDate'].value;
    let endDate: Date = this.form.controls['endDate'].value;

    let startDateNoTime = new Date(startDate);
    startDateNoTime.setHours(0, 0, 0, 0);

    const until: Date = new Date(this.form.controls['repeatUntil'].value);
    until.setHours(0, 0, 0, 0);
    do {
      const appt: PiiccoEvent = this.generateAppointment(startDate, endDate);
      const apptRes: PiiccoEvent = await this.calendarService.createEvent(appt, this.form.controls['organisationId'].value);
      startDate = moment(startDate).add(1, 'months').toDate();
      startDateNoTime = new Date(startDate);
      startDateNoTime.setHours(0, 0, 0, 0);
      endDate = moment(endDate).add(1, 'months').toDate();
    } while (startDateNoTime <= until);
  }

  async update() {
    const appt = JSON.parse(JSON.stringify(this.data.selectedEvent));

    appt.text = this.form.controls['title'].value;
    appt.description = this.form.controls['description'].value;
    appt.startDate = new Date(this.form.controls['startDate'].value).toISOString();
    appt.endDate = new Date(this.form.controls['endDate'].value).toISOString();
    appt.recurrenceRule = '';
    appt.location = this.form.controls['location'].value;
    appt.eventType = this.form.controls['eventType'].value;
    appt.attendees = this.attendees;
    const apptRes: PiiccoEvent = await this.calendarService.updateEvent(appt);
  }

  async save() {
    this.saving = true;
    if (this.data.selectedEvent) {
      await this.update();
    } else {
      switch (this.form.controls['recurrence'].value) {
        case 'None':
          await this.generateSingleAppointment();
          break;
        case 'Daily':
          await this.generateDailyAppointments();
          break;
        case 'Weekly':
          await this.generateWeeklyAppointments();
          break;
        case 'Monthly':
          await this.generateMonthlyAppointments();
          break;
      }
    }
    this.saving = false;
    this.dialogRef.close(true);
  }

  calcEndDate($event: any) {
    if ($event.source._selected) {
      const endDate = new Date(this.form.controls['startDate'].value);
      endDate.setTime(endDate.getTime() + $event.source.value * 60 * 60 * 1000);
      this.form.controls['endDate'].setValue(endDate);
      console.log('calcEndDate', this.form.controls['endDate'].value);
    }
  }

  private getDuration(date: Date, date2: Date) {
    const hours = Math.abs(date2.getTime() - date.getTime()) / 36e5;
    console.log('getDuration', hours);
    return hours;
  }

  calcEndDateFromStart($event: MatDatetimePickerInputEvent<unknown>) {
    console.log('calcEndDateFromStart', $event);
    const endDate = new Date(this.form.controls['startDate'].value);
    endDate.setTime(endDate.getTime() + this.form.controls['duration'].value * 60 * 60 * 1000);
    this.form.controls['endDate'].setValue(endDate);
    console.log('calcEndDate', this.form.controls['endDate'].value);
  }
}
