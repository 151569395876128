<div
    fxLayout="row wrap"
    (click)="opened = !opened"
    class="piicco-expansion-panel"
>
    <div class="clickable-flat" fxFlex="100" class="mt-3" fxLayout="row wrap">
        
        <h2 fxFlex="85" fxFlexOffset="5" class="piicco-expansion-panel-title" [ngClass]="{'active' : opened}">{{title}}</h2>

        <mat-icon fxFlex="10" [color]="opened ? 'white' : 'accent'">{{opened ? 'arrow_drop_down' : 'arrow_right'}}</mat-icon>
    </div>

    <div fxFlex="100" style="transition-duration: 2s;" *ngIf="opened">
        <ng-content></ng-content>
    </div>

    <mat-divider class="mt-3" fxFlex="100"></mat-divider>
</div>