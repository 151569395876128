import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'firebase';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TermsAndConditions } from 'src/app/models/terms';
import { AuthenticationService } from 'src/app/auth/services/authentication-service/authentication.service';
import { TermsService } from 'src/app/services/terms/terms.service';
import { userSelector } from 'src/app/store/auth/auth.selectors';
import { SetLanguageAction } from 'src/app/store/global/global.actions';

@Component({
  selector: 'vex-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.scss']
})
export class TermsDialogComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  loading = false;

  user: User;
  terms: TermsAndConditions;

  form = new FormGroup({
    terms: new FormControl(false)
  });

  constructor(
    private translate: TranslateService,
    private store: Store,
    private sanitizer: DomSanitizer,
    private termsService: TermsService,
    @Inject(MAT_DIALOG_DATA) public data: TermsAndConditions,
    private dialogRef: MatDialogRef<TermsDialogComponent>) { }

  get checked() { return this.form.get('terms') }

  get content() {
    return this.sanitizer.bypassSecurityTrustHtml(this.data?.content?.[this.translate.currentLang]);
  }

  get date() {
    if (!this?.data?.date) { return ''; }
    const date = new Date(this.data?.date);
    return new Intl.DateTimeFormat('en-CA').format(date);
  }

  ngOnInit() {
    this.store.select(userSelector).pipe(
      takeUntil(this.destroy$),
      map(user => this.user = user)
    ).subscribe();
  }

  submit() {
    this.loading = true;
    this.termsService.putTerms(
      this.user.uid,
      this.data.id
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      response => {
        console.log('response', response);
        this.loading = false;
        this.dialogRef.close(true);
      },
      error => {
        console.log(error);
        this.dialogRef.close(false);
      }
    );
  }

  setLanguage(language: string) {
    this.store.dispatch(SetLanguageAction({ language }));
  }

  closeDialog(val = false) {
    this.dialogRef.close(val);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
