import { Foot, Gender } from 'src/app/models/piiccoProfile';
import { TextValueData } from '../models/text-value-data.model';

export const SPORT_TYPE_GENDER: TextValueData<string>[] = [
  {
    text: 'sport.gender.m',
    value: Gender.male,
  },
  {
    text: 'sport.gender.f',
    value: Gender.female,
  },
];

export const GENDERS: TextValueData<string>[] = [
  {
    text: 'pages.profile.gender.man',
    value: Gender.male,
  },
  {
    text: 'pages.profile.gender.women',
    value: Gender.female,
  },
];

export const FEET: TextValueData<Foot>[] = [
  {
    text: 'common.left',
    value: Foot.left,
  },
  {
    text: 'common.right',
    value: Foot.right,
  },
  {
    text: 'common.both',
    value: Foot.both,
  },
];
