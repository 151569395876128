import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  CreateFamilyMember,
  FamilyMember,
  Gender,
  PicccoSoccerProfile,
  PiiccoCurrentOrganization,
  PiiccoProfile,
  PiiccoSoccerCoachHistory,
  PiiccoSoccerPlayHistory,
  PiiccoUserPreferenceRights,
  PiiccoUserPreferences,
  UpdatePiiccoProfile,
} from '../../models/piiccoProfile';
import { catchError, map, share, take, tap } from 'rxjs/operators';
import { TermsAndConditions } from 'src/app/models/terms';
import UserCredential = firebase.auth.UserCredential;
import { Organization } from 'src/app/organization/models/organization';
import {
  SetGlobalErrorMessageAction,
  SetGlobalSuccessMessageAction,
  SetLanguageAction,
} from 'src/app/store/global/global.actions';
import HTTP_STATUS_CODES from 'http-status-enum';
import { Store } from '@ngrx/store';
import { userIdSelector } from 'src/app/store/auth/auth.selectors';
import { APP_DEFAULT_LANG, APP_LOCAL_STORAGE_LANG_KEY } from 'src/app/core/config/application.config';
import { SearchOptions, SearchResult } from 'src/app/shared/models/search.model';
import { ProfileSearchResult } from 'src/app/organization/models/organization-seach.model';
import { RightsService } from 'src/app/core/services/rights.service';
import { Roles } from 'src/app/core/models/roles.enum';
import { DEFAULT_SEARCH_OPTIONS } from 'src/app/core/config/pagination.config';
import { InitProfile } from 'src/app/profile/models/profile.model';
import { OrganizationProfileSearch } from 'src/app/profile/models/profile-search.model';
import { ConvertEmptyValuesToNull } from '../utils/nullable-api-values.util';
import { LogoutAction } from 'src/app/store/auth/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  private count = 0;
  private profileCache: PiiccoProfile | null = null;
  private otherProfileCache: { [id: string]: PiiccoProfile } = {};
  private orgCache: Organization[] = null;
  private orgObservableCache: Observable<Organization[]> | undefined;
  private profileFamilyMembersCache: FamilyMember[] | null = null;
  familyUpdated = new BehaviorSubject<FamilyMember[] | null>([]);
  familyFeatureStateChanged = new BehaviorSubject<boolean>(false);
  profileLoaded = new BehaviorSubject<boolean>(false);

  isFetchingProfile = false;
  profileApiPromise;

  constructor(
    private http: HttpClient,
    private store: Store,
    @Inject('Window') private window: Window,
    private rightsService: RightsService
  ) {}

  clearCache() {
    this.profileCache = null;
    this.otherProfileCache = {};

    this.orgCache = null;

    this.orgObservableCache = null;
    this.profileFamilyMembersCache = null;
  }

  clearProfileOrgCache() {
    this.orgCache = null;
    this.orgObservableCache = undefined;
  }

  clearProfileCache() {
    this.profileCache = null;
  }

  saveProfile(profile: PiiccoProfile): Observable<any> {
    this.clearCache();

    return this.http.put(`${environment.apiGateway}profiles`, profile).pipe(
      map((response) => response as any),
      share()
    );
  }

  setForcePasswordChange(newVal: boolean) {
    this.profileCache.userPreferences.forcePasswordChange = false;
  }

  async searchOrganizationProfile(
    organizationId: string,
    searchQuery: OrganizationProfileSearch,
    searchOptions: SearchOptions = DEFAULT_SEARCH_OPTIONS
  ) {
    return new Promise<SearchResult<PiiccoProfile>>((res, rej) => {
      if (!searchQuery) {
        return res({
          pageCount: 0,
          recordCount: 0,
          records: [],
        });
      }

      return this.http
        .post<SearchResult<PiiccoProfile>>(
          `${environment.apiGateway}profiles/search/${organizationId}?page=${searchOptions.pageNumber}&pageSize=${searchOptions.pageSize}`,
          searchQuery
        )
        .subscribe(
          (result) => res(result),
          () =>
            res({
              pageCount: 0,
              recordCount: 0,
              records: [],
            })
        );
    });
  }

  searchPublicProfile(search: string | null, pageSize = 15) {
    return new Promise<PiiccoProfile[]>((res, rej) => {
      if (!search || search === '') {
        return res([]);
      }

      const formattedSearch = search.replace(' ', '%20');

      return this.http
        .get<SearchResult<PiiccoProfile>>(
          `${environment.apiGateway}profiles/public/list/${formattedSearch}?page=1&pageSize=${pageSize}`
        )
        .subscribe(
          (result) => res(result.records),
          () => res([])
        );
    });
  }

  searchFriendsProfile(search: string | null) {
    return new Promise<ProfileSearchResult[]>((res, rej) => {
      if (!search || search === '') {
        return res([]);
      }

      const formattedSearch = search.replace(' ', '%20');

      return this.http
        .get<SearchResult<ProfileSearchResult>>(
          `${environment.apiGateway}profiles/public/list/${formattedSearch}?page=1&pageSize=15&friendsOnly=true`
        )
        .take(1)
        .subscribe(
          (result) => res(result.records),
          () => res([])
        );
    });
  }

  updateProfileCache(profileData: PiiccoProfile) {
    this.profileCache = profileData;
  }

  getUserLang() {
    return this.window.localStorage.getItem('piicco-language') || APP_DEFAULT_LANG;
  }

  getUserId() {
    let id;
    this.store
      .select(userIdSelector)
      .take(1)
      .subscribe((i) => (id = i));

    return id;
  }

  getOrganizations(): Observable<Organization[]> {
    if (this.orgCache) {
      return of(this.orgCache);
    }

    if (!!this.orgObservableCache) {
      return this.orgObservableCache;
    }

    this.orgObservableCache = this.http
      .get<Organization[]>(`${environment.apiGateway}profiles/organizations`)
      .pipe(
        tap((r) => (this.orgCache = r)),
        share()
      )
      .take(1);

    return this.orgObservableCache;
  }

  getOrganizationAndChilds(organizationId: string) {
    return new Promise<Organization[]>((res, rej) => {
      this.getOrganizations().subscribe((organizationList: Organization[]) => {
        const organization = this.findOrganizationInHiearchy(organizationId, organizationList);

        if (!organization) {
          return [];
        }

        res(this.getFlattenedUserOrganizations([organization]));
      });
    });
  }

  deleteAccount() {
    return this.http.delete(`${environment.apiGateway}profiles`).pipe(
      take(1),
      map((res) => res)
    );
  }

  async refreshOrganizations() {
    return new Promise<Organization[]>((res, rej) => {
      this.http.get<Organization[]>(`${environment.apiGateway}profiles/organizations`).subscribe((r) => {
        this.orgCache = r;
        res(r);
      });
    });
  }

  isProfileLoaded() {
    return !!this.profileCache;
  }

  async getProfile(id?: string, byPassCache = false): Promise<PiiccoProfile> {
    if (!id || id === this.getUserId()) {
      return await this.getCurrentProfile(byPassCache);
    }

    if (!!this.otherProfileCache[id] && !byPassCache) {
      return this.otherProfileCache[id];
    }

    return new Promise((res, rej) => {
      this.http
        .get<PiiccoProfile>(`${environment.apiGateway}profiles/${id}`)
        .take(1)
        .pipe(map((x) => ConvertEmptyValuesToNull(x)))
        .subscribe((r) => {
          this.otherProfileCache[id] = r;
          res(r);
        });
    });
  }

  updateProfile(
    profile: UpdatePiiccoProfile,
    successMessage = 'pages.profile.edit-success'
  ): Observable<PiiccoProfile | undefined> {
    return this.http
      .put<PiiccoProfile>(`${environment.apiGateway}profiles`, profile)
      .take(1)
      .pipe(
        map((x) => ConvertEmptyValuesToNull(x)),
        tap((profile) => (this.profileCache = profile)),
        tap((p) => this.store.dispatch(SetGlobalSuccessMessageAction({ translationKey: successMessage }))),
        catchError((e) => {
          this.handleError(e);
          throw throwError(e);
        })
      );
  }

  activateFamilyFeature() {
    return new Promise((res, rej) => {
      return this.http
        .put<PiiccoProfile>(`${environment.apiGateway}profiles/family/activate`, {})
        .take(1)
        .pipe(
          map((x) => ConvertEmptyValuesToNull(x)),
          tap((profile) => (this.profileCache = profile)),
          tap((x) => this.familyFeatureStateChanged.next(true)),
          tap((p) =>
            this.store.dispatch(
              SetGlobalSuccessMessageAction({
                translationKey: 'application-settings-dialog.preferences-updated-success',
              })
            )
          )
        )
        .subscribe(
          (response) => {
            res(response);
          },
          (error) => {
            this.handleError(error);
            rej(error);
          }
        );
    });
  }

  updateChildRights(id: string, rights: PiiccoUserPreferenceRights) {
    return new Promise((res, rej) => {
      return this.http
        .put<PiiccoProfile>(`${environment.apiGateway}profiles/family/${id}`, rights)
        .take(1)
        .pipe(
          tap((x) => {
            this.profileFamilyMembersCache = this.profileFamilyMembersCache.map((i) => {
              if (i.profile.id === id) {
                return {
                  profile: x,
                  unprocessedNotifications: i.unprocessedNotifications,
                };
              } else {
                return i;
              }
            });
            this.familyUpdated.next(this.profileFamilyMembersCache);
          }),
          tap((p) =>
            this.store.dispatch(
              SetGlobalSuccessMessageAction({
                translationKey: 'edit-child-rights-dialog.updated-success',
              })
            )
          )
        )
        .subscribe(
          (response) => {
            res(response);
          },
          (error) => {
            this.handleError(error);
            rej(error);
          }
        );
    });
  }

  deactivateFamilyFeature() {
    return new Promise((res, rej) => {
      return this.http
        .put<PiiccoProfile>(`${environment.apiGateway}profiles/family/deactivate`, {})
        .take(1)
        .pipe(
          map((x) => ConvertEmptyValuesToNull(x)),
          tap((profile) => (this.profileCache = profile)),
          tap((x) => this.familyFeatureStateChanged.next(false)),
          tap((p) =>
            this.store.dispatch(
              SetGlobalSuccessMessageAction({
                translationKey: 'application-settings-dialog.preferences-updated-success',
              })
            )
          )
        )
        .subscribe(
          (response) => {
            res(response);
          },
          (error) => {
            if (error instanceof HttpErrorResponse && error.status === HTTP_STATUS_CODES.BAD_REQUEST) {
              this.store.dispatch(
                SetGlobalErrorMessageAction({
                  translationKey: 'application-settings-dialog.disable-family-feature-bad-request',
                })
              );
            } else {
              this.handleError(error);
            }
            rej(error);
          }
        );
    });
  }

  updateProfilePreferences(id: string, preferences: PiiccoUserPreferences): Observable<PiiccoProfile | undefined> {
    return this.http
      .put<PiiccoProfile>(`${environment.apiGateway}profiles`, {
        id: id,
        userPreferences: preferences,
      })
      .take(1)
      .pipe(
        map((x) => ConvertEmptyValuesToNull(x)),
        tap((profile) => (this.profileCache = profile)),
        catchError((e) => {
          throw throwError(e);
        })
      );
  }

  uploadPhoto64(image: string, mimeType?: string) {
    const imageData = image.slice(image.indexOf(',') + 1);
    return this.http
      .put(`${environment.apiGateway}profiles/photo`, { imageData: imageData })
      .pipe(map((r) => r as PiiccoProfile));
  }

  getTerms(): Observable<TermsAndConditions> {
    return this.http.get(`${environment.apiGateway}documents/terms`).pipe(map((r) => r as any));
  }

  putTerms(profileId: string, documentId: string): Observable<any> {
    this.clearCache();
    return this.http
      .put(
        `${environment.apiGateway}documents/terms/accept/${profileId}/${documentId}`,
        {} // body?
      )
      .pipe(map((r) => r as any));
  }

  initProfile(data: InitProfile) {
    this.clearCache();
    return this.http.put(`${environment.apiGateway}profiles/init/`, data).pipe(map((r) => r as any));
  }

  async hasEditRights(organizationId: string) {
    return new Promise<boolean>((res, rej) => {
      if (!this.orgCache) {
        this.getOrganizations().subscribe((response) => {
          const userFlattenedOrganizationList = this.getFlattenedUserOrganizations(response);

          const foundOrganization = userFlattenedOrganizationList.find((o) => o.id === organizationId);

          if (!foundOrganization) {
            res(false);
            return;
          }

          const hasRole = this.rightsService.hasOrganizationRole(foundOrganization, [Roles.Admin, Roles.SuperAdmin]);

          return res(hasRole);
        });
      } else {
        const userFlattenedOrganizationList = this.getFlattenedUserOrganizations(this.orgCache);

        const foundOrganization = userFlattenedOrganizationList.find((o) => o.id === organizationId);

        if (!foundOrganization) {
          res(false);
          return;
        }

        const hasRole = this.rightsService.hasOrganizationRole(foundOrganization, [Roles.Admin, Roles.SuperAdmin]);

        return res(hasRole);
      }
    });
  }

  hasEditRightsOnProfile(profileId: string) {
    return this.store.select(userIdSelector).pipe(map((id) => profileId === id && !!id));
  }

  getProfileSoccerProfile(profile: PiiccoProfile): PicccoSoccerProfile {
    if (!profile || !profile.sports || !profile.sports.soccer) {
      return this.initSoccerProfile();
    }
    return profile.sports.soccer;
  }

  getProfileJerseyNumber(profile?: PiiccoProfile) {
    if (!profile) {
      return null;
    }

    const currentOrganization = this.getProfileFirstCurrentPlayHistory(profile);

    if (!!currentOrganization && !!currentOrganization.jerseyNumber) {
      return currentOrganization.jerseyNumber;
    }

    return null;
  }

  getProfilePlayHistory(profile: PiiccoProfile): PiiccoSoccerPlayHistory[] {
    const soccerProfile = this.getProfileSoccerProfile(profile);

    if (!soccerProfile || !soccerProfile.playingOrganizationHistory) {
      return [];
    }

    return soccerProfile.playingOrganizationHistory;
  }

  getProfileCoachingHistory(profile: PiiccoProfile): PiiccoSoccerCoachHistory[] {
    const soccerProfile = this.getProfileSoccerProfile(profile);

    if (!soccerProfile || !soccerProfile.coachingOrganizationHistory) {
      return [];
    }

    return soccerProfile.coachingOrganizationHistory;
  }

  getProfileFirstCurrentPlayHistory(profile: PiiccoProfile): PiiccoCurrentOrganization | undefined {
    const profileCurrent = this.getProfileCurrentOrganization(profile);

    if (!profileCurrent) {
      return undefined;
    }

    return profileCurrent;
  }

  getProfileCertifications(profile: PiiccoProfile) {
    const soccerProfile = this.getProfileSoccerProfile(profile);

    if (!soccerProfile || !soccerProfile.certifications || soccerProfile.certifications.length === 0) {
      return [];
    }

    return soccerProfile.certifications;
  }

  getProfileSpecializedCamps(profile: PiiccoProfile) {
    const soccerProfile = this.getProfileSoccerProfile(profile);

    if (!soccerProfile || !soccerProfile.specializedCamps || soccerProfile.specializedCamps.length === 0) {
      return [];
    }

    return soccerProfile.specializedCamps;
  }

  getProfileEducation(profile: PiiccoProfile) {
    if (!profile.educationDiplomas || profile.educationDiplomas.length === 0) {
      return [];
    }

    return profile.educationDiplomas;
  }

  getProfileCurrentOrganization(profile?: PiiccoProfile): PiiccoCurrentOrganization | null {
    if (!profile || !profile.sports?.soccer?.current) {
      return null;
    }

    return profile.sports.soccer.current;
  }

  getProfileWorkExperience(profile: PiiccoProfile) {
    const soccerProfile = this.getProfileSoccerProfile(profile);

    if (!soccerProfile || !soccerProfile.workExperience || soccerProfile.workExperience.length === 0) {
      return [];
    }

    return soccerProfile.workExperience;
  }

  getProfileFullName(profile: PiiccoProfile) {
    if (!profile || !profile.firstName || !profile.lastName) {
      return '';
    }

    return `${profile.firstName} ${profile.lastName}`;
  }

  initSoccerProfile(): PicccoSoccerProfile {
    return {
      bestPosition: null,
      certifications: null,
      foot: null,
      otherPositions: null,
      passportId: null,
      pnce: null,
      coachingOrganizationHistory: null,
      playingOrganizationHistory: null,
      specializedCamps: null,
      workExperience: null,
      current: null,
    };
  }

  async getProfileFamily(parentToken = '', byPassCache = false) {
    if (this.profileFamilyMembersCache && !byPassCache) {
      return this.profileFamilyMembersCache;
    }

    return new Promise<FamilyMember[]>((res, rej) => {
      return this.http
        .get<FamilyMember[]>(`${environment.apiGateway}profiles/family`, {
          headers: {
            useParentToken: parentToken,
          },
        })
        .take(1)
        .pipe(
          tap((x) => (this.profileFamilyMembersCache = x)),
          tap((x) => this.familyUpdated.next(x))
        )
        .subscribe(
          (result) => {
            res(result);
          },
          (error) => {
            this.profileFamilyMembersCache = [];
            res([]);
          }
        );
    });
  }

  async deleteFamilyMember(id: string) {
    return new Promise<void>((res, rej) => {
      return this.http
        .put<void>(`${environment.apiGateway}profiles/family/${id}/delete`, {})
        .take(1)
        .subscribe(
          (result) => {
            this.profileFamilyMembersCache = this.profileFamilyMembersCache.filter((i) => i.profile.id !== id);
            this.store.dispatch(
              SetGlobalSuccessMessageAction({
                translationKey: 'application-settings-dialog.family-member-deleted-success',
              })
            );
            this.familyUpdated.next(this.profileFamilyMembersCache);
            res();
          },
          (error) => {
            if (error instanceof HttpErrorResponse && error.status === HTTP_STATUS_CODES.BAD_REQUEST) {
              this.store.dispatch(
                SetGlobalErrorMessageAction({
                  translationKey: 'application-settings-dialog.delete-family-member-bad-request',
                })
              );
            } else {
              this.handleError(error);
            }
            rej();
          }
        );
    });
  }

  async emancipateFamilyMember(id: string) {
    return new Promise<void>((res, rej) => {
      return this.http
        .put<void>(`${environment.apiGateway}profiles/family/${id}/emancipate`, {})
        .take(1)
        .subscribe(
          (result) => {
            this.profileFamilyMembersCache = this.profileFamilyMembersCache.filter((i) => i.profile.id !== id);
            this.store.dispatch(
              SetGlobalSuccessMessageAction({
                translationKey: 'application-settings-dialog.family-member-emancipated-success',
              })
            );
            this.familyUpdated.next(this.profileFamilyMembersCache);
            res();
          },
          (error) => {
            if (error instanceof HttpErrorResponse && error.status === HTTP_STATUS_CODES.BAD_REQUEST) {
              this.store.dispatch(
                SetGlobalErrorMessageAction({
                  translationKey: 'application-settings-dialog.family-member-emancipated-bad-request',
                })
              );
            } else {
              this.handleError(error);
            }
            rej();
          }
        );
    });
  }

  async impersonnateFamilyMember(id: string, parentToken = '') {
    return new Promise<string>((res, rej) => {
      return this.http
        .put<string>(
          `${environment.apiGateway}profiles/family/${id}/impersonate`,
          {},
          {
            headers: {
              useParentToken: parentToken,
            },
          }
        )
        .take(1)
        .subscribe(
          (result) => {
            res(result);
          },
          (error) => {
            rej();
          }
        );
    });
  }

  async createFamilyMember(data: CreateFamilyMember) {
    return new Promise<PiiccoProfile>((res, rej) => {
      return this.http
        .post<PiiccoProfile>(`${environment.apiGateway}profiles/family`, data)
        .take(1)
        .pipe(map((x) => ConvertEmptyValuesToNull(x)))
        .subscribe(
          (result) => {
            this.store.dispatch(SetGlobalSuccessMessageAction({ translationKey: 'create-child-dialog.created-success' }));

            this.profileFamilyMembersCache.unshift({
              profile: result,
              unprocessedNotifications: 0,
            });
            this.familyUpdated.next(this.profileFamilyMembersCache);
            res(result);
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              let errorMessage = '';

              switch (error.status) {
                case HTTP_STATUS_CODES.BAD_REQUEST:
                  errorMessage = 'create-child-dialog.bad-request-error';
                  break;
                default:
                  errorMessage = 'common.error-occured';
                  break;
              }
              this.store.dispatch(SetGlobalErrorMessageAction({ translationKey: errorMessage }));
            } else {
              this.store.dispatch(SetGlobalErrorMessageAction({ translationKey: 'common.error-occured' }));
            }
            rej();
          }
        );
    });
  }

  async hasRightsOnOrganization(organizationId: string, roles: Roles[]) {
    const userOrganizations = await new Promise<Organization[]>((res, rej) => {
      this.getOrganizations().subscribe(
        (response) => res(response),
        (error) => res([])
      );
    });

    if (!userOrganizations || userOrganizations.length === 0) {
      return false;
    }

    const foundOrganization = userOrganizations.find((i) => i.id === organizationId);
    if (!foundOrganization) {
      return false;
    }

    const foundRole = (foundOrganization.roles as Roles[]).find((i) => roles.includes(i));
    return !!foundRole;
  }

  getFlattenedUserOrganizations(organizationList: any[]) {
    return organizationList.reduce<Organization[]>((r, { childOrgs, ...rest }) => {
      if (childOrgs) {
        r.push({ ...rest, childOrgs: (childOrgs as Organization[]).map((i) => i.id) });
      } else {
        r.push({ ...rest, childOrgs: [] });
      }

      if (childOrgs && childOrgs.length > 0) {
        r.push(...this.getFlattenedUserOrganizations(childOrgs as Organization[]));
      }
      return r;
    }, []);
  }

  private findOrganizationInHiearchy(lookingForId: string, organizationList: Organization[]): Organization | undefined {
    for (let organization of organizationList) {
      if (organization.id === lookingForId) {
        return organization;
      }

      if (organization.childOrgs && organization.childOrgs.length > 0) {
        const found = this.findOrganizationInHiearchy(lookingForId, organization.childOrgs as Organization[]);

        if (found) {
          return found;
        }
      }
    }
  }

  private async getCurrentProfile(byPassCache = false): Promise<PiiccoProfile> {
    if (!!this.profileCache && !byPassCache) {
      return this.profileCache;
    }

    if (this.isFetchingProfile) {
      return this.profileApiPromise;
    }

    this.isFetchingProfile = true;

    this.profileApiPromise = new Promise((res, rej) => {
      this.http
        .get<PiiccoProfile>(`${environment.apiGateway}profiles`)
        .take(1)
        .pipe(map((x) => ConvertEmptyValuesToNull(x)))
        .subscribe(
          (r) => {
            this.profileCache = r;

            this.store.dispatch(SetLanguageAction({ language: this.profileCache.userPreferences?.language || APP_DEFAULT_LANG }));
            this.isFetchingProfile = false;
            res(r);
          },
          (error) => {
            this.isFetchingProfile = false;
            this.store.dispatch(LogoutAction());
          }
        );
    });

    return this.profileApiPromise;
  }

  private handleError(error) {
    if (error instanceof HttpErrorResponse) {
      let errorMessage = '';

      switch (error.status) {
        case HTTP_STATUS_CODES.NOT_FOUND:
          errorMessage = 'pages.profile.not-found';
          break;
        case HTTP_STATUS_CODES.FORBIDDEN:
          errorMessage = 'common.forbidden';
          break;
        default:
          errorMessage = 'common.error-occured';
          break;
      }
      this.store.dispatch(SetGlobalErrorMessageAction({ translationKey: errorMessage }));
    } else {
      this.store.dispatch(SetGlobalErrorMessageAction({ translationKey: 'common.error-occured' }));
    }
  }
}
