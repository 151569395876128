import { Evaluation } from 'src/app/evaluation/models/evaluation.model';
import { PiiccoEvent } from 'src/app/models/calendar';
import { PiiccoProfile, PublicProfile } from 'src/app/models/piiccoProfile';
import { Post } from 'src/app/network/models/timeline.model';
import { Organization } from 'src/app/organization/models/organization';
import { Roles } from './roles.enum';

export enum NotificationType {
  EvaluationInvite = 'EvaluationInvite',
  OrganizationInvite = 'OrganizationInvite',
  ProfileInvite = 'ProfileInvite',
  OrgToOrgInvite = 'OrganizationToOrganizationInvite',
  OrganizationPost = 'OrganizationPost',
  PlatformInvite = 'PlatformInvite',
  EventInvite = 'EventInvite',
  EventDelete = 'EventDelete',
  EventModify = 'EventModify',
  PostComment = 'PostComment',
  PostLike = 'PostLike',
  StartersUpdate = 'StartersUpdate',
  UnreadChatMessage = 'UnreadChatMessage',
  UnprocessedChildNotification = 'UnprocessedChildNotification',
  ChildShareInvite = 'ChildShareInvite',
  ChildDeleted = 'ChildDeleted',
  ChildEmancipated = 'ChildEmancipated',
  ProfileEmancipated = 'ProfileEmancipated',
  ProfileTransfered = 'ProfileTransfered'
}

export type PiiccoNotificationItem =
  | PiiccoNotification<Evaluation>
  | PiiccoNotification<Roles[]>
  | PiiccoNotification<Post>
  | PiiccoNotification<PiiccoProfile>
  | PiiccoNotification<PiiccoEvent>;

export interface PiiccoNotification<T> {
  id: string;
  communications: PublicProfile[];
  inviterOrganization: Organization;
  inviteeOrganization?: Organization;
  inviterProfile: PiiccoProfile;
  inviteeProfile: PiiccoProfile;
  owners: string[];
  reference: T;
  type: NotificationType;
}
