import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { getDefaultImage, getProfileImage } from 'src/app/core/utils/profile-photo.util';
import { TargetedEntity } from '../../models/dialog.model';

@Component({
  selector: 'vex-club-list-item',
  templateUrl: './club-list-item.component.html',
  styleUrls: ['./club-list-item.component.scss'],
})
export class ClubListItemComponent {
  private destroy$ = new Subject();
  
  @Output() clicked: EventEmitter<string> = new EventEmitter();
  @Input() id: string;
  @Input() title: string;
  @Input() image: string;
  @Input() subtitle: string = "";
  @Input() clickable = true;

  getDefaultImage = getDefaultImage;
  getProfileImage = getProfileImage;

  photoWidth = "60px";

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe(`(max-width: 600px)`).pipe(
      takeUntil(this.destroy$),
      tap((x) => {
        if(x.breakpoints["(max-width: 600px)"]) {
          this.photoWidth = "40px"

        }
    })
    ).subscribe()
  }
  
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get logo() {
    return getProfileImage(this.title, TargetedEntity.Organization, this.image);
  }

  onClubListItemClicked() {
    if(this.clickable) {
      this.clicked.emit(this.id);
    }
  }

  getDefaultLogo($event) {
    getDefaultImage($event, this.title, TargetedEntity.Organization);
  }
}
