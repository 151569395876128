import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { getDefaultImage, getProfileImage } from 'src/app/core/utils/profile-photo.util';
import { PiiccoProfile } from 'src/app/models/piiccoProfile';
import { NetworksService } from 'src/app/network/services/networks.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData, TargetedEntity } from 'src/app/shared/models/dialog.model';

@Component({
  selector: 'vex-organization-card',
  templateUrl: './organization-card.component.html',
  styleUrls: ['./organization-card.component.scss']
})
export class OrganizationCardComponent implements OnInit {
  @Input() id: string;
  @Input() name: string;
  @Input() image: string;
  @Input() city = '';
  @Input() clickable = true;
  @Input() lowerCaseName = false;
  @Input() ngContentWidth = 70;
  @Input() ltSmNgContentWidth = 100;
  @Input() removeNgContentPadding = false;
  @Input() showNetworkButtons = true;
  @Output() selected = new EventEmitter<string>();

  isAdmin = false;
  isSuperAdmin = false;
  profileData: PiiccoProfile;
  unfollowDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  getDefaultImage = getDefaultImage;
  getProfileImage = getProfileImage;

  constructor(
    public translate: TranslateService,
    private profilesService: ProfilesService,
    private networkService: NetworksService,
    private dialog: MatDialog
    ) { }
 
  async ngOnInit() {
    this.profileData = await this.profilesService.getProfile();
  }

  get organizationImage() {
    return getProfileImage(
      this.name, 
      TargetedEntity.Organization, 
      this.image
    );
  }

  getDefaultLogo($event) {
    getDefaultImage(
      $event,
      this.name, 
      TargetedEntity.Organization
    );
  }

  topCornerButtonClick(event) {
    event.stopPropagation();
  }

  isOrganizationFollowed() {
    // Check if the logged in profile is following the current organization
    return (this.profileData?.orgsFollowed?.indexOf(this.id) >= 0);
  }

  async followOrganization(event) {
    // Follow the organization
    event.stopPropagation();
    if (this.profileData && this.id){
      const updatedUser = await this.networkService.followOrganization(this.id);
      this.profileData = updatedUser;
      this.profilesService.updateProfileCache(updatedUser);
    }
  }

  unfollowOrganization(event) {
    // Unfollow the current organization
    event.stopPropagation();
    const dialogData: ConfirmationDialogData = {      
      text: this.translate.instant('components.profile-card.unfollow-text'),
      title: this.translate.instant('components.profile-card.unfollow-title'),
      icon: 'unpublished'
    };

    this.unfollowDialogRef = this.dialog.open(
      ConfirmationDialogComponent, {
        width: '400px',
        data: dialogData
      }
    );

    this.unfollowDialogRef.componentInstance.okCaption = 'common.yes';
    this.unfollowDialogRef.componentInstance.cancelCaption = 'common.no';
    this.unfollowDialogRef.componentInstance.accepted.subscribe(async () => {
      try {

        const updatedUser = await this.networkService.unfollowOrganization(this.id);
        this.profileData = updatedUser;
        this.profilesService.updateProfileCache(updatedUser);
        
        this.unfollowDialogRef.close();
      } catch (error) {
        this.unfollowDialogRef.close();
      }
    });
  }
}
