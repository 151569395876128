<div>
  <h1 *ngIf="showTitle">{{ title }}</h1>
  <div fxLayout="row wrap">
    <div class="m-3" fxFlex="100" *ngIf="showOrganizationSelectors">
      <mat-checkbox
        class="ml-3 mr-3"
        *ngFor="let org of organisations"
        [checked]="org.checked"
        (change)="onChangeFilter($event, org)"
      >
        <span [style.color]="org.color" class="check-item">{{ org.name }}</span>
      </mat-checkbox>
    </div>
  </div>
  <div *ngIf="!readonly && organisations && organisations.length" fxLayout="row wrap" class="mt-2 mb-2">
    <button mat-raised-button color="primary" (click)="openAddDialog()">{{ 'common.add' | translate }}</button>
  </div>
  <dx-scheduler
    #scheduler
    id="scheduler"
    [showAllDayPanel]="true"
    [views]="['day', 'week', 'month', 'agenda']"
    [adaptivityEnabled]="true"
    [currentView]="'month'"
    [dataSource]="appointments"
    (currentDateChange)="currentDateChange($event)"
    (onAppointmentAdding)="onAppointmentAdding($event)"
    (onAppointmentUpdating)="onAppointmentUpdating($event)"
    (onAppointmentAdded)="onAppointmentAdded($event)"
    (onAppointmentDeleting)="onAppointmentDeleting($event)"
    (onAppointmentDeleted)="onAppointmentDeleted($event)"
    (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
    (onAppointmentUpdated)="onAppointmentUpdated($event)"
    [editing]="!readonly"
    appointmentTooltipTemplate="tooltip-template"
    appointmentTemplate="appointment-template"
    [ngClass]="getCalendarTypeClass()"
    (keyup.escape)="closeTooltip()"
  >
    <dxi-resource
      fieldExpr="organisationId"
      displayExpr="name"
      label="Organisation"
      required="true"
      [allowMultiple]="false"
      [useColorAsDefault]="true"
      [dataSource]="organisations"
    >
    </dxi-resource>
    <div *dxTemplate="let model of 'appointment-template'" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
      <div fxLayoutAlign="flex-start center">
        <div class="material-icons" [ngClass]="getIcon(model.appointmentData.eventType)"></div>
        <div>{{ model.appointmentData.text }}</div>
      </div>
    </div>

    <div *dxTemplate="let model of 'tooltip-template'" fxLayout="column" class="tooltip">
      <mat-card class="mat-elevation-z0" style="background-color: transparent !important" fxFlex="100">
        <mat-card-content style="position: relative">
          <div fxLayout="row wrap">
            <h2>
              <mat-icon [inline]="true">{{ getIcon(model.appointmentData.eventType) }}</mat-icon
              >&nbsp; {{ model.appointmentData.text }}
            </h2>

            <label class="mt-4" style="line-height: 1rem" fxFlex="100">{{
              'calendar-event-response.description' | translate
            }}</label>
            <p>{{ model.appointmentData.description || '-' }}</p>
            <label class="mt-2" style="line-height: 1rem" fxFlex="100">{{ 'calendar-event-response.when' | translate }}</label>
            <span fxFlex="100"
              >{{ 'calendar-event-response.from' | translate }} <b>{{ getStartDate(model.appointmentData) }}</b>
              {{ 'calendar-event-response.to' | translate }} <b>{{ getEndDate(model.appointmentData) }}</b></span
            >

            <label class="mt-2" style="line-height: 1rem" fxFlex="100">{{ 'calendar-event-response.type' | translate }}</label>
            <span fxFlex="100">{{ getEventType(model.appointmentData.eventType) || '-' }} </span>

            <label class="mt-2" style="line-height: 1rem" fxFlex="100">{{ 'calendar-event-response.where' | translate }}</label>
            <div>
              <a
                fxFlex="100"
                (click)="$event.stopPropagation()"
                *ngIf="validURL(model.appointmentData.location)"
                [href]="model.appointmentData.location"
                target="_blank"
              >
                {{ model.appointmentData.location }}</a
              >
              <span fxFlex="100" *ngIf="!validURL(model.appointmentData.location)">{{
                model.appointmentData.location || '-'
              }}</span>
            </div>

            <label class="mt-2" style="line-height: 1rem" fxFlex="100">{{ 'calendar-event-response.guests' | translate }}</label>
            <div fxLayout="row wrap">
              <div *ngIf="model.appointmentData.attendees.length === 0">-</div>
              <div *ngFor="let attendee of model.appointmentData.attendees">
                <div class="chip" [ngClass]="attendee.answer">
                  {{ attendee.attendee.firstName }} {{ attendee.attendee.lastName }}
                </div>
              </div>
            </div>

            <button
              class="calendar-event-delete-button"
              *ngIf="!readonly"
              mat-icon-button
              type="button"
              (click)="deleteAppointment($event, model)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-card-content>
        <mat-card-actions *ngIf="personal">
          <div fxFlex="100" fxLayoutAlign="end center" fxLayout="row wrap">
            <vex-children-rights-info
              class="mb-1"
              fxFlex="100"
              [currentFeatureRight]="UserRights.EventRSVP"
              [text]="translate.instant('children-rights-info.rsvp-rights-info-text')"
            ></vex-children-rights-info>
            <button
              *ngIf="hasEventRSVPAccess"
              class="rounded-button cancel-button"
              disableRipple
              mat-button
              (click)="reject(model.appointmentData)"
              [disabled]="isSaving"
            >
              {{ 'calendar-event-response.refuse' | translate }}
            </button>

            <button
              *ngIf="hasEventRSVPAccess"
              mat-stroked-button
              #submitButton
              class="dialog-save-button rounded-button"
              mat-button
              (click)="accept(model.appointmentData)"
              [disabled]="isSaving"
            >
              {{ 'calendar-event-response.accept' | translate }}
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </dx-scheduler>
</div>
