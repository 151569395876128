<div *ngIf="config$ | async as setting" class="config-panel">
  <h2 class="headline mb-4">
    <ic-icon [icon]="icSettings" class="mr-3" inline="true"></ic-icon>
    <span>Configuration</span>
  </h2>

  <div class="section">
    <h5 class="subheading">THEME BASE</h5>

    <div (click)="selectedStyle === Style.dark ? disableDarkMode() : enableDarkMode()"
         *ngIf="selectedStyle$ | async as selectedStyle"
         class="rounded-full mt-2 flex items-center cursor-pointer relative bg-contrast-black text-contrast-white"
         matRipple>
      <div class="m-2 h-6 w-6 rounded-full">
        <ic-icon *ngIf="selectedStyle === Style.dark" [icon]="icCheck" size="24px"></ic-icon>
        <ic-icon *ngIf="selectedStyle !== Style.dark" [icon]="icClose" size="24px"></ic-icon>
      </div>
      <p class="ml-1 font-medium text-sm">DARK MODE</p>
    </div>
  </div>


</div>
