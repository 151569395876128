import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import firebase, { User } from 'firebase';
import { Store } from '@ngrx/store';
import { ClearAuthInfoAction, CredentialInfo, SetAuthInfoAction } from 'src/app/store/auth/auth.actions';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { SetGlobalErrorMessageAction } from 'src/app/store/global/global.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  auth$ = this.afa.authState
    .pipe(
      map((next) => {
        if (!next) {
          this.store.dispatch(ClearAuthInfoAction());
        } else {
          if (!next?.emailVerified) {
            // next.sendEmailVerification();
          }
          next.getIdToken().then((token) => {
            return this.store.dispatch(
              SetAuthInfoAction({ authInfo: { user: JSON.parse(JSON.stringify(next)), idToken: token } })
            );
          });
        }
      })
    )
    .subscribe();

  constructor(
    private store: Store,
    private profileService: ProfilesService,
    private http: HttpClient,
    private afa: AngularFireAuth
  ) {}

  get authState() {
    return this.afa.authState;
  }

  async getUser() {
    return new Promise((res, rej) => {
      this.afa.currentUser.then((user) => res(user));
    });
  }

  getIdToken() {
    return this.authState.pipe(
      filter((user) => !!user),
      switchMap((user) => from(user.getIdToken()))
    );
  }

  login() {
    return new Promise<void>((res, rej) => {
      return this.http
        .put<void>(`${environment.apiGateway}profiles/login`, {})
        .take(1)
        .subscribe(
          (result) => res(),
          (err) => {
            res();
          }
        );
    });
  }

  createUser(email: string, password: string, lastName: string, firstName: string, redirectUrl?: string): Observable<any> {
    return this.http
      .put(`${environment.apiGateway}/profiles/createandinit`, {
        firstName,
        lastName,
        email,
        password,
      })
      .take(1)
      .pipe(
        tap(async () => {
          await this.afa.signOut();
          this.profileService.clearCache();
        }),
        map((response) => {
          return { userCredentials: JSON.parse(JSON.stringify(response)), lastName, firstName, redirectUrl };
        })
      );
  }

  async loginUser(email: string, password: string) {
    try {
      const trusted = localStorage.getItem('trustBrowser') === 'true';

      if (trusted) {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      } else {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
      }

      const response = await this.afa.signInWithEmailAndPassword(email, password);
      return response.user;
    } catch (error) {
      let errorMessage = '';
      switch (error.code) {
        case 'auth/wrong-password':
          errorMessage = 'pages.auth.login.bad-password';
          break;
        default:
          errorMessage = 'common.error-occured';
          break;
      }
      this.store.dispatch(SetGlobalErrorMessageAction({ translationKey: errorMessage }));
      throw new Error();
    }
  }

  async loginAsUser(customToken: string) {
    try {
      const trusted = localStorage.getItem('trustBrowser') === 'true';

      if (trusted) {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      } else {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
      }

      const response = await this.afa.signInWithCustomToken(customToken);
      return response.user;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      switch (error.code) {
        case 'auth/wrong-password':
          errorMessage = 'pages.auth.login.bad-password';
          break;
        default:
          errorMessage = 'common.error-occured';
          break;
      }
      this.store.dispatch(SetGlobalErrorMessageAction({ translationKey: errorMessage }));
      throw new Error();
    }
  }

  async logout() {
    localStorage.removeItem('trustBrowser');

    return await this.afa.signOut();
  }

  resetUserPassword(email: string) {
    return this.afa.sendPasswordResetEmail(email);
  }
}
