import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { NotificationType, PiiccoNotificationItem } from 'src/app/core/models/notification.model';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { PiiccoCurrentOrganization, PiiccoProfile, UpdatePiiccoProfilePhoto } from 'src/app/models/piiccoProfile';
import { NetworksService } from 'src/app/network/services/networks.service';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import {
  ConfirmationDialogData,
  ProfileCoverCurrentDialogData,
  ProfilePictureDialogData,
  TargetedEntity,
} from '../../models/dialog.model';
import { ProfileCoverClub, ProfileType } from '../../models/profile-cover.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ProfilePictureDialogComponent } from '../profile-picture-dialog/profile-picture-dialog.component';
import { ProfileCoverCurrentDialogComponent } from '../profile-cover-current-dialog/profile-cover-current-dialog.component';
import { getDefaultImage, getProfileImage } from 'src/app/core/utils/profile-photo.util';
import { RightsService } from 'src/app/core/services/rights.service';
import { UserRights } from 'src/app/core/models/user-rights.model';

@Component({
  selector: 'vex-profile-cover',
  templateUrl: './profile-cover.component.html',
  styleUrls: ['./profile-cover.component.scss'],
})
export class ProfileCoverComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  @Input() title: string | undefined;
  @Input() image: string | undefined | null;
  @Input() coverImage: string | undefined = '/assets/img/bg/profile-cover-image.jpg';
  @Input() subtitle: string | undefined;
  @Input() textOne: string | undefined;
  @Input() textTwo: string | undefined;
  @Input() editable: boolean = false;
  @Input() photoEditable: boolean = false;
  @Input() hasEditRights: boolean = false;
  @Input() jerseyNumber: string | undefined | null = null;
  @Input() entityId: string;
  @Input() targetedEntity: TargetedEntity;
  @Input() club: ProfileCoverClub;
  @Input() borderColorClass = 'piicco-default-border';
  @Input() updateSucceeded: Observable<boolean> = of(false);
  @Input() isSaving: boolean;
  @Input() fullWidth = false;
  //@Input() profileData: PiiccoProfile;

  @Output() updatePhoto = new EventEmitter<string>();
  @Output() updateUserCurrent = new EventEmitter<ProfileCoverCurrentDialogData | null>();

  dialogRef: MatDialogRef<ProfilePictureDialogComponent>;
  currentOrgDialogRef: MatDialogRef<ProfileCoverCurrentDialogComponent>;
  disconnectDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  profileData: PiiccoProfile;
  pendingInvitations: PiiccoNotificationItem[];
  hasNetworkFeatureAccess = true;

  UserRights = UserRights;

  getProfileImage = getProfileImage;
  getDefaultImage = getDefaultImage;

  constructor(
    private store: Store,
    private router: Router,
    public translate: TranslateService,
    private profilesService: ProfilesService,
    private notificationsService: NotificationsService,
    private networkService: NetworksService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private rightsService: RightsService
  ) {}

  get profilePicture() {
    return getProfileImage(this.title, this.targetedEntity, this.image);
  }

  editProfilePicture() {
    const dialogData: ProfilePictureDialogData = {
      entityId: this.entityId,
      targetedEntity: this.targetedEntity,
      image: this.image,
      name: this.title,
      isSaving: false,
    };

    this.dialogRef = this.dialog.open(ProfilePictureDialogComponent, {
      width: '600px',
      data: dialogData,
    });

    this.dialogRef.componentInstance.updatePhoto.subscribe((data) => {
      this.dialogRef.componentInstance.data.isSaving = true;
      this.updatePhoto.emit(data);
    });
  }

  editProfileCurrent() {
    const dialogData: ProfileCoverCurrentDialogData = {
      userId: this.entityId,
      firstName: this.profileData.firstName || null,
      lastName: this.profileData.lastName || null,
      currentOrganization: this.profileData.sports?.soccer?.current || null,
      isSaving: false,
    };

    this.currentOrgDialogRef = this.dialog.open(ProfileCoverCurrentDialogComponent, {
      width: '600px',
      data: dialogData,
    });

    this.currentOrgDialogRef.componentInstance.updateUserCurrent.subscribe((data) => {
      this.currentOrgDialogRef.componentInstance.data.isSaving = true;
      this.updateUserCurrent.emit(data);
    });
  }

  profilePictureError($event) {
    $event.target.src = getDefaultImage(this.title, this.targetedEntity, null);
  }

  trackByClubId(index, item) {
    if (!item) {
      return null;
    }

    return item.id;
  }

  clubClicked(organizationId: string) {
    this.router.navigate([`organization/${organizationId}`]);
  }

  async ngOnInit() {
    this.profileData = await this.profilesService.getProfile();
    this.hasNetworkFeatureAccess = this.rightsService.hasFeatureAccess(
      await this.profilesService.getProfile(),
      UserRights.NetworkRelations
    );

    this.pendingInvitations = await this.notificationsService.getPendingNotificationList();
    this.updateSucceeded
      .pipe(
        takeUntil(this.destroy$),
        tap(async (isSuccess) => {
          if (!!this.dialogRef) {
            this.dialogRef.componentInstance.data.isSaving = false;

            if (isSuccess) {
              this.dialogRef.componentInstance.close();
            }
          }

          if (!!this.currentOrgDialogRef) {
            this.currentOrgDialogRef.componentInstance.data.isSaving = false;

            if (isSuccess) {
              this.currentOrgDialogRef.componentInstance.close();
            }
          }
          this.profileData = await this.profilesService.getProfile();
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isProfileMyself() {
    // Check the current profile is not the logged in profile
    return !this.entityId || this.entityId == this.profileData?.id;
  }

  isProfileFriend() {
    // Check if the logged in profile is friend with the current profile
    return this.profileData?.friends && this.profileData?.friends.indexOf(this.entityId) >= 0;
  }

  isOrganizationFollowed() {
    // Check if the logged in profile is following the current organization
    return this.profileData?.orgsFollowed && this.profileData?.orgsFollowed.indexOf(this.entityId) >= 0;
  }

  isInvitationPending() {
    // Check if there is an already existing invitation for this profile pending acceptation
    return (
      (this.pendingInvitations &&
        this.pendingInvitations.some(
          (i) => i.type === NotificationType.ProfileInvite && i.inviteeProfile.id === this.entityId
        )) ||
      this.notificationsService.userHasPendingInvite(this.entityId)
    );
  }

  async connectToProfile() {
    // Create an invitation to connect and send it to the other user
    if (this.profileData && this.entityId) {
      await this.notificationsService.inviteProfile(this.profileData?.id, this.entityId);
    }
  }

  async followOrganization() {
    // Follow the organization
    if (this.profileData && this.entityId) {
      const updatedUser = await this.networkService.followOrganization(this.entityId);
      this.profileData = updatedUser;
      this.profilesService.updateProfileCache(updatedUser);
    }
  }

  disconnectFromProfile() {
    // Disconnect an existing relation
    const dialogData: ConfirmationDialogData = {
      text: this.translate.instant('components.profile-card.disconnect-text'),
      title: this.translate.instant('components.profile-card.disconnect-title'),
      icon: 'person_remove',
    };

    this.disconnectDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    this.disconnectDialogRef.componentInstance.okCaption = 'common.yes';
    this.disconnectDialogRef.componentInstance.cancelCaption = 'common.no';
    this.disconnectDialogRef.componentInstance.accepted.subscribe(async () => {
      try {
        const updatedUser = await this.networkService.friendDisconnect(this.entityId);
        this.profileData = updatedUser;
        this.profilesService.updateProfileCache(updatedUser);

        this.disconnectDialogRef.close();
      } catch (error) {
        this.disconnectDialogRef.close();
      }
    });

    this.disconnectDialogRef.componentInstance.refused.subscribe(() => {
      this.dialogRef.close();
    });

    this.disconnectDialogRef.afterClosed().subscribe(() => {
      this.dialogRef = undefined;
    });
  }

  unfollowOrganization() {
    // Unfollow the current organization
    const dialogData: ConfirmationDialogData = {
      text: this.translate.instant('components.profile-card.unfollow-text'),
      title: this.translate.instant('components.profile-card.unfollow-title'),
      icon: 'unpublished',
    };

    this.disconnectDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    this.disconnectDialogRef.componentInstance.okCaption = 'common.yes';
    this.disconnectDialogRef.componentInstance.cancelCaption = 'common.no';
    this.disconnectDialogRef.componentInstance.accepted.subscribe(async () => {
      try {
        const updatedUser = await this.networkService.unfollowOrganization(this.entityId);
        this.profileData = updatedUser;
        this.profilesService.updateProfileCache(updatedUser);

        this.disconnectDialogRef.close();
      } catch (error) {
        this.disconnectDialogRef.close();
      }
    });

    this.disconnectDialogRef.componentInstance.refused.subscribe(() => {
      this.dialogRef.close();
    });

    this.disconnectDialogRef.afterClosed().subscribe(() => {
      this.dialogRef = undefined;
    });
  }

  launchChat() {
    this.router.navigateByUrl(`/chat/${this.entityId}`);
  }
}
