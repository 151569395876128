<vex-background-cover [borderColorClass]="borderColorClass">
  <div
    class="profile-cover-content"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-sm="start center"
    fxLayout.lt-md="row wrap"
  >
    <div fxFlex="25" fxFlex.lt-sm="100" fxFlex.sm="160px" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="center start">
      <div class="profile-picture-container">
        <img mat-card-avatar class="profile-cover-avatar" [src]="profilePicture" alt="" (error)="profilePictureError($event)" />
        <button class="profile-picture-button" *ngIf="hasEditRights" mat-mini-fab (click)="editProfilePicture()">
          <mat-icon>add_a_photo</mat-icon>
        </button>
      </div>
    </div>

    <div
      class="profile-cover-text"
      [fxFlex]="club ? 70 : 37.25"
      fxFlex.lt-sm="100"
      fxFlex.sm="65"
      fxLayout="column"
      fxLayout.lt-sm="column"
      fxLayoutAlign.lt-sm="center center"
      fxLayoutAlign="center start"
      *ngIf="!!title"
    >
      <h1>{{ title }}</h1>
      <h2>{{ subtitle || ' ' }}</h2>
      <h3 *ngIf="!!textOne && textOne !== ''" class="profile-h3">{{ textOne }}</h3>
      <h4 [class.profile-h4]="!textOne || textOne == ''">{{ textTwo || ' ' }}</h4>
    </div>

    <div
      fxLayout="column"
      fxLayout.lt-md="row wrap"
      class="profile-cover-club-list"
      fxFlex="38.25"
      fxFlex.lt-md="100"
      fxFlex.lt-sm="100"
      fxLayoutAlign="end start"
      fxLayoutAlign.lt-sm="center center"
      *ngIf="club"
    >
      <vex-club-list-item
        (clicked)="clubClicked($event)"
        [title]="club.name"
        [subtitle]="club.city"
        [image]="club.logo"
        [id]="club.id"
        [clickable]="!!club.id"
        class="club-list-item"
      ></vex-club-list-item>
    </div>

    <div *ngIf="jerseyNumber" class="profile-cover-jersey-number">#{{ jerseyNumber }}</div>

    <button
      (click)="editProfileCurrent()"
      class="section-action-button"
      mat-icon-button
      *ngIf="hasEditRights && targetedEntity === 'Profile'"
    >
      <mat-icon class="icon purple"> create </mat-icon>
    </button>

    <div fxFlexAlign="center" fxLayout="row wrap" fxHide.gt-sm class="profile-cover-action-buttons">
      <div fxLayout="row" fxFlex.sm="100" *ngIf="targetedEntity == 'Profile' && !isProfileMyself() && hasNetworkFeatureAccess">
        <button
          *ngIf="!isProfileFriend() && !isInvitationPending()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="connectToProfile()"
          mat-button
          type="button"
        >
          <mat-icon inline="true">person_add</mat-icon>
        </button>
        <button
          disabled
          *ngIf="!isProfileFriend() && isInvitationPending()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          mat-button
          type="button"
        >
          <mat-icon inline="true">person_add</mat-icon>
        </button>
        <button
          *ngIf="isProfileFriend()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="disconnectFromProfile()"
          mat-button
          type="button"
        >
          <mat-icon inline="true">check</mat-icon>
        </button>
        <button
          *ngIf="isProfileFriend()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="launchChat()"
          mat-button
          type="button"
        >
          <mat-icon inline="true">chat_bubble_outline</mat-icon>
        </button>
      </div>
      <div *ngIf="targetedEntity == 'Organization'">
        <button
          *ngIf="!isOrganizationFollowed()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="followOrganization()"
          mat-button
          type="button"
        >
          <mat-icon inline="true">add_task</mat-icon>
        </button>
        <button
          *ngIf="isOrganizationFollowed()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="unfollowOrganization()"
          mat-button
          type="button"
        >
          <mat-icon inline="true">check_circle</mat-icon>
        </button>
      </div>
    </div>

    <div fxHide.lt-sm class="profile-cover-action-buttons">
      <div *ngIf="targetedEntity == 'Profile' && !isProfileMyself() && hasNetworkFeatureAccess">
        <button
          *ngIf="!isProfileFriend() && !isInvitationPending()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="connectToProfile()"
          mat-button
          type="button"
        >
          <mat-icon inline="true">person_add</mat-icon>&nbsp;{{ 'components.profile-card.connect' | translate }}
        </button>
        <button
          disabled
          *ngIf="!isProfileFriend() && isInvitationPending()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          mat-button
          type="button"
        >
          <mat-icon inline="true">person_add</mat-icon>&nbsp;{{ 'components.profile-card.pending' | translate }}
        </button>
        <button
          *ngIf="isProfileFriend()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="disconnectFromProfile()"
          mat-button
          type="button"
        >
          <mat-icon inline="true">check</mat-icon>&nbsp;{{ 'components.profile-card.connected' | translate }}
        </button>
        <button
          *ngIf="isProfileFriend()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="launchChat()"
          mat-button
          type="button"
        >
          <mat-icon inline="true">chat_bubble_outline</mat-icon>&nbsp;{{ 'components.chat.chat' | translate }}
        </button>
      </div>
      <div *ngIf="targetedEntity == 'Organization'">
        <button
          *ngIf="!isOrganizationFollowed()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="followOrganization()"
          mat-button
          type="button"
        >
          <mat-icon inline="true">add_task</mat-icon>&nbsp;{{ 'components.profile-card.follow' | translate }}
        </button>
        <button
          *ngIf="isOrganizationFollowed()"
          mat-stroked-button
          class="rounded-text-button icon-text"
          (click)="unfollowOrganization()"
          mat-button
          type="button"
        >
          <mat-icon inline="true">check_circle</mat-icon>&nbsp;{{ 'components.profile-card.followed' | translate }}
        </button>
      </div>
    </div>
  </div>
</vex-background-cover>
