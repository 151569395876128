import { MultiLangItem } from "src/app/core/models/configuration.model";
import { Roles } from "src/app/core/models/roles.enum";
export interface Configurations {
  additionalProp1: string|null;
  additionalProp2: string|null;
  additionalProp3: string|null;
}

export interface Feature {
  tag: string|null;
  configurations: Configurations;
}

export interface OrganizationContactInfos {
  name: string|null;
  phoneNumber: string|null;
  email: string|null;
  streetName: string|null;
  civicNumber: string|null;
  city: string|null;
  state: string|null;
  postalCode: string|null;
  country: string|null;
  website: string|null;
}

export interface OrganizationAccomplishment {
  title: string|null;
  city: string|null;
  description: string|null;
  date: Date;
  organizationName?: string|null;
}

export enum OrganizationPeopleType {
  Athlete="athlete",
  Evaluator="evaluator",
  People="people"
}
export interface Organization {
  id: string|null;
  name: string|null;
  logo: string|null;
  sport: string|null;
  parentOrg: string|null;
  childOrgs: string[]|Organization[];
  tagLine: string|null;
  bio: string|null;
  city: string|null;
  contactInfos: OrganizationContactInfos;
  accomplishments: OrganizationAccomplishment[];
  level: string|null;
  gender: string|null;
  organizationClass?: string|null;
  features?: Feature[];
  roles?: OrganizationRole[]|Roles[];
  titles?: OrganizationTitles[]|null;
  deleted: boolean;

}
export interface OrganizationTitles {
  category: MultiLangItem;
  profileId: string;
  title: MultiLangItem;
}

export interface OrganizationRole {
  tag: Roles;
  members: string[];
}
export interface UpdateOrganizationContactInfos {

}

export interface UpdateOrganization {
  id: string|null;
  name?: string|null;
  sport?: string|null;
  parentOrg?: string|null;
  childOrgs?: string[];
  tagLine?: string|null;
  bio?: string|null;
  city?: string|null;
  contactInfos?: Partial<OrganizationContactInfos>;
  accomplishments?: OrganizationAccomplishment[];
  level?: string|null;
  gender?: string|null;
  organizationClass?: string|null;
}


export interface OrganizationMigration {
  sourceOrganizationId: string;
    destinationOrganizationId: string;
    profileIds: string[]; 
}