import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { MultiLangItem } from '../../../core/models/configuration.model';

@Component({
  selector: 'vex-list-field',
  templateUrl: './list-field.component.html',
  styleUrls: ['./list-field.component.scss']
})
export class ListFieldComponent implements OnInit {
  @Input() data = []; 
  @Input() returnHasString: boolean = false;
  @Input() delimiter: string = ",";
  @Input() title: string = "";
  @Input() availableData = [];
  @Input() imagePath: string = "";
  @Output() dataChange = new EventEmitter<MultiLangItem[]>();

  @ViewChild("input") inputData: ElementRef;

  selectedValue: MultiLangItem|null = null;

  constructor(private profilesService: ProfilesService) { }

  ngOnInit(): void {}

  get filteredAvailableData() {
    return this.availableData.filter((i) => !this.data.find((d) => d.en === i.en && d.fr === i.fr));
  }

  buildImagePath(dataItem) {
    if (this.imagePath != "" && dataItem["code"] != null){
      return this.imagePath + "\\" + dataItem["code"] + ".png";
    }
    else{
      return "";
    }
  }

  getImageDataItems(dataItems){
    const imageDataItems = dataItems.map((i) => this.availableData.find((d) => d.en === i.en && d.fr === i.fr));
    return imageDataItems.filter((i) => !!i);
  }

  addElementToArray(event: MatSelectChange) {
    if(this.isNotEmpty(event.value)) {
      this.dataChange.emit([
        ...this.data,
        event.value
      ]);
    }
  
      this.selectedValue = null;    
  }

  get userLang() {
    return this.profilesService.getUserLang();
  }
  
  removeElement(value: MultiLangItem) {
    this.dataChange.emit(this.data.filter((i) => i.en !== value.en && i.fr !== value.fr));
  }


  private isNotEmpty(val: MultiLangItem|null|undefined|string) {
    return !!val && !!(val as MultiLangItem).en;
  }



}
