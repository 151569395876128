<vex-dialog-header
  title="{{ 'application-settings-dialog.title' | translate }}"
  titleIcon="settings"
  iconColor="accent"
></vex-dialog-header>

<mat-dialog-content>
  <mat-tab-group fxFlex="100" class="pb-2">
    <mat-tab label="{{ 'application-settings-dialog.notifications' | translate }}">
      <form fxLayout="row wrap" [formGroup]="notificationsForm">
        <mat-slide-toggle fxFlex="100" class="mt-4" formControlName="pushNotificationsEnabled">{{
          'application-settings-dialog.email-notifications' | translate
        }}</mat-slide-toggle>
        <mat-slide-toggle fxFlex="100" class="mt-4" formControlName="emailNotificationsEnabled">
          {{ 'application-settings-dialog.push-notifications' | translate }}</mat-slide-toggle
        >
        <vex-loader fxFlex="100" [isLoading]="isLoading"></vex-loader>
      </form>
    </mat-tab>
    <mat-tab label="{{ 'application-settings-dialog.family' | translate: { count: getFamilyMemberCount() } }}">
      <div fxLayout="row wrap">
        <vex-loader [isLoading]="isLoading"></vex-loader>
        <div fxFlex="100">
          <mat-slide-toggle
            [disabled]="getDisableSwitch()"
            class="mt-4"
            [(ngModel)]="familyFeatureActive"
            (change)="saveSettings()"
            >{{ getSwitchLabel() }}</mat-slide-toggle
          >
        </div>
        <span class="label" style="font-size: 0.8rem" *ngIf="getDisableSwitch()">
          {{ 'application-settings-dialog.disable-family-feature-tip' | translate }}
        </span>
        <div class="fx-break"></div>

        <div class="mt-2 pb-8" fxFlex="100" *ngIf="!familyFeatureActive">
          <span class="application-settings-dialog-info">{{ 'application-settings-dialog.family-info-text' | translate }}</span>
        </div>
        <div class="fx-break"></div>

        <div fxFlex="100" fxLayout="row wrap" class="mt-2" *ngIf="familyFeatureActive" style="margin: 7px">
          <div
            fxFlex="70"
            fxFlex.lt-sm="100"
            class="application-settings-dialog-add-profile"
            (click)="openCreateFamilyMemberDialog()"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
          >
            <div fxFlex="100">
              <button class="application-settings-dialog-add-profile-button" disableRipple mat-mini-fab color="#242b3e">
                <mat-icon color="accent">add</mat-icon>
              </button>
              {{ 'application-settings-dialog.add-profile' | translate }}
            </div>
          </div>
        </div>
        <div class="fx-break"></div>

        <div fxFlex="100" fxLayout="row wrap" *ngIf="familyFeatureActive">
          <div fxFlex="100" *ngFor="let familyMember of userFamily" fxLayout="row wrap">
            <vex-profile-card
              fxFlex="70"
              fxFlex.lt-sm="100"
              [lowerCaseName]="true"
              [id]="familyMember.profile.id"
              [firstName]="familyMember.profile.firstName"
              [lastName]="familyMember.profile.lastName"
              [currentProfileOrganization]="getProfileCurrentOrganization(familyMember.profile)"
              [image]="familyMember.profile.photo"
              [ngContentWidth]="20"
              [showNetworkButtons]="false"
              [badgeContent]="familyMember.unprocessedNotifications"
              [hideBadge]="familyMember.unprocessedNotifications === 0"
            >
            </vex-profile-card>
            <div fxFlex="30" fxFlex.lt-sm="100" fxLayout="row wrap" fxFlexAlign="center">
              <button (click)="editFamilyMemberRights(familyMember.profile)" mat-icon-button color="accent">
                <mat-icon>admin_panel_settings</mat-icon>
              </button>
              <button (click)="addParent(familyMember.profile)" mat-icon-button color="accent">
                <mat-icon>person_add</mat-icon>
              </button>
              <button (click)="deleteFamilyMember(familyMember.profile.id)" mat-icon-button color="accent">
                <mat-icon>delete</mat-icon>
              </button>
              <button (click)="emancipateFamilyMember(familyMember.profile.id)" mat-icon-button color="accent">
                <mat-icon>link_off</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{ 'application-settings-dialog.account' | translate }}">
      <div fxLayout="row wrap">
        <label flex="100">{{ 'application-settings-dialog.delete-my-account.label' | translate }}</label>
        <div fxFlex="100">
          <button (click)="deleteAccount()" mat-raised-button disableRipple class="delete-account-button">
            {{ 'application-settings-dialog.delete-my-account.button-label' | translate }}
          </button>
        </div>

        <vex-loader [isLoading]="isLoading"></vex-loader>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<vex-dialog-actions
  [acceptButtonText]="translate.instant('application-settings-dialog.save')"
  [cancelButtonText]="translate.instant('application-settings-dialog.close')"
  [hideSaveButton]="true"
  [cancelDisabled]="isLoading"
  (cancel)="dialogRef.close()"
  (accept)="saveSettings()"
></vex-dialog-actions>
