import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Gender, UpdatePiiccoProfile } from 'src/app/models/piiccoProfile';
import { MultiLangItem } from 'src/app/core/models/configuration.model';
import { DatesService } from 'src/app/core/services/dates.service';
import { FEET, GENDERS } from 'src/app/shared/static-data/profile.data';
import { ProfileOverviewDialogComponent } from '../profile-overview-dialog/profile-overview-dialog.component';
import { ProfilesService } from 'src/app/core/services/profiles.service';
import { ProfileOverviewDialogData } from 'src/app/profile/models/dialog.model';

@Component({
  selector: 'vex-profile-overview',
  templateUrl: './profile-overview.component.html',
  styleUrls: ['./profile-overview.component.scss']
})
export class ProfileOverviewComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  
  @Input() entityId: string;
  @Input() hideSectionTitle = false;
  @Input() hasEditRights: boolean = false;
  @Input() height: number|null = null;
  @Input() heightInches: number|null = null;
  @Input() weight: number|null = null;
  @Input() gender: Gender|null = null;
  @Input() foot: string|null = null;
  @Input() birthDate: string|null = null;
  @Input() yearOfBirth: number|null = null;
  @Input() bestPosition: MultiLangItem|null = null;
  @Input() otherPositions: MultiLangItem[]|null = null;
  @Input() isSaving: boolean;
  @Output() updateUser = new EventEmitter<UpdatePiiccoProfile>();
  @Input() updateSucceeded: Observable<boolean> = of(false);

  dialogRef: MatDialogRef<ProfileOverviewDialogComponent>;

  constructor(
    public translate: TranslateService,
    private dialog: MatDialog,
    private profilesService: ProfilesService,
    public datesService: DatesService
  ) { }

  ngOnInit(): void {
    this.updateSucceeded.pipe(
      takeUntil(this.destroy$),
      tap((isSuccess) => {
        if(!!this.dialogRef) {
          this.dialogRef.componentInstance.data.isSaving = false;

          if(isSuccess) {
            this.dialogRef.componentInstance.close();
          }
        }
      })
    ).subscribe();
  }

  get concatenatedOtherPositions() {
    if(!this.otherPositions) {
      return null;
    }

    return this.otherPositions.map((i) => i[this.userLang]).join(", ");
  }

  get userLang() { //TODO: 
    return this.profilesService.getUserLang();
  }

  get getBestPosition() {
    if(!this.bestPosition) {
      return null;
    }

    return this.bestPosition[this.userLang];
  }

  get profileGender() {
    if(!this.gender) {
      return "-";
    }
    return this.translate.instant(GENDERS.find((g) => g.value === this.gender).text) || "-";
  }

  get profileFoot() {
    if(!this.foot || !FEET.find((f) => f.value === this.foot)) {
      return "-";
    }
    return this.translate.instant(FEET.find((f) => f.value === this.foot).text);
  }

  get fullHeight(){
    if(!this.height) {
      return "-";
    }
    if (!this.heightInches){
      return `${this.height}'`;
    }
    else{
      return `${this.height}' ${this.heightInches}''`;
    }
  }

  editUserInformations() {
    const dialogData: ProfileOverviewDialogData = {
      entityId: this.entityId,
      isSaving: false,
      birthDate: this.birthDate,
      bestPosition: this.bestPosition,
      foot: this.foot,
      gender: this.gender,
      height: this.height,
      heightInches: this.heightInches,
      otherPositions: this.otherPositions,
      weight: this.weight
    };

    this.dialogRef = this.dialog.open(
      ProfileOverviewDialogComponent,{
        width: '600px',
        data: dialogData
      }
    );

    this.dialogRef.componentInstance.updateUser.subscribe((data) => {
      this.dialogRef.componentInstance.data.isSaving = true;
      this.updateUser.emit(data);
    });
  }
  
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
