import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './layout/custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { LoggedInGuard } from './auth/services/authentication-service/logged-in-guard/logged-in.guard';
import { NotLoggedInGuard } from './auth/services/authentication-service/not-logged-in-guard/not-logged-in.guard';

const routes: VexRoutes = [
  {
    path: 'login',
    canActivate: [NotLoggedInGuard],
    loadChildren: () => import('./auth/pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    canActivate: [NotLoggedInGuard],
    loadChildren: () => import('./auth/pages/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    canActivate: [NotLoggedInGuard],
    loadChildren: () => import('./auth/pages/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then((m) => m.ComingSoonModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: 'chat',
        loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
      },
      {
        path: 'organization',
        loadChildren: () => import('./organization/organization.module').then((m) => m.OrganizationModule),
      },
      {
        path: 'evaluation',
        loadChildren: () => import('./evaluation/evaluation.module').then((m) => m.EvaluationModule),
      },
      {
        path: 'network',
        loadChildren: () => import('./network/network.module').then((m) => m.NetworkModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'invite',
        loadChildren: () => import('./invite/invite.module').then((m) => m.InviteModule),
      },
      {
        path: 'redirect',
        loadChildren: () => import('./redirect/redirect.module').then((m) => m.RedirectModule),
      },
      {
        path: '',
        redirectTo: '/network/timeline',
        pathMatch: 'full',
      },
      {
        path: 'apps',
        children: [
          {
            path: 'evaluations',
            loadChildren: () => import('./evaluation/core/evaluation.module').then((m) => m.EvaluationModule),
            data: {
              toolbarShadowEnabled: true,
            },
          },
        ],
      },
      {
        path: 'pages',
        children: [
          {
            path: 'error-404',
            loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then((m) => m.Error404Module),
          },
          {
            path: 'error-500',
            loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then((m) => m.Error500Module),
          },
        ],
      },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then((m) => m.Error404Module),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
