<mat-card [ngClass]="customCardClass" [ngClass]="{ 'mt-2 mb-2': hideContent }" fxLayout="row wrap" class="piicco-card">
  <mat-card-title *ngIf="!hideTitle" class="section-title" fxFlex="row wrap" fxFlex="100">
    <mat-icon [ngClass]="customIconClass" class="section-title-icon">{{ icon }}</mat-icon>
    <span [ngClass]="customTitleClass" class="title" [fxFlex]="subtitle ? '45' : '90'" style="margin-top: 5px">
      {{ title }}
    </span>
    <div *ngIf="subtitle" fxFlex="45">
      <span style="float: right; margin-top: 5px" class="subtitle">
        {{ subtitle }}
      </span>
    </div>

    <button (click)="actionClicked()" class="section-action-button" mat-icon-button *ngIf="hasEditRights">
      <mat-icon [ngClass]="actionButtonClass" class="icon">
        {{ actionButtonIcon }}
      </mat-icon>
    </button>

    <ng-content select="[action]"></ng-content>
  </mat-card-title>
  <mat-card-content fxFlex="100%" [ngClass]="{ 'piicco-card-content': !hideContent }" class="piicco-card-content">
    <ng-content *ngIf="!hideContent"></ng-content>
  </mat-card-content>
</mat-card>
