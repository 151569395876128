import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { PublicProfile } from 'src/app/models/piiccoProfile';
import { Organization, OrganizationAccomplishment, OrganizationContactInfos } from '../models/organization';

export const CLEAR_ORGANIZATION_STATE = "[Organization] - Clear state";
export const FETCH_ORGANIZATION = '[Organization] - Fetch Organization By Id';
export const FETCH_ORGANIZATION_SUCCESS = '[Organization] - SUCCESS - Fetch Organization By Id';
export const FETCH_ORGANIZATION_ERROR = '[Organization] - FAILED - Fetch Organization By Id';
export const FETCH_ORGANIZATION_CHILD = '[Organization] - Fetch Organization children';
export const FETCH_ORGANIZATION_CHILD_ERROR = '[Organization] - FAILED - Fetch Organization children';
export const FETCH_ORGANIZATION_CHILD_SUCCESS = '[Organization] - SUCCESS - Fetch Organization children';
export const FETCH_ORGANIZATION_PEOPLE = '[Organization] - Fetch Organization people';
export const FETCH_ORGANIZATION_PEOPLE_ERROR = '[Organization] - FAILED - Fetch Organization people';
export const FETCH_ORGANIZATION_PEOPLE_SUCCESS = '[Organization] - SUCCESS - Fetch Organization people';
export const UPDATE_ORGANIZATION_ABOUT = '[Organization] - Update Organization about';
export const UPDATE_ORGANIZATION_SUCCESS = '[Organization] - SUCCESS - Update Organization';
export const UPDATE_ORGANIZATION_ERROR = '[Organization] - ERROR - Update Organization';
export const CREATE_ORGANIZATION_ACCOMPLISHMENT = "[Organization] - Create Organization accomplishment";
export const UDPATE_ORGANIZATION_ACCOMPLISHMENT = "[Organization] - Update Organization accomplishment";
export const DELETE_ORGANIZATION_ACCOMPLISHMENT = "[Organization] - Delete Organization accomplishment";
export const UPDATE_ORGANIZATION_CONTACT = '[Organization] - Update Organization contact';
export const UPDATE_ORGANIZATION_LOGO = '[Organization] - Update Organization logo';

export const ClearOrganizationStateAction = createAction(
  CLEAR_ORGANIZATION_STATE
);

export const FetchOrganizationByIdAction = createAction(
  FETCH_ORGANIZATION,
  props<{ organizationId: string }>()
);
export const FetchOrganizationByIdSuccessAction = createAction(
  FETCH_ORGANIZATION_SUCCESS,
  props<{ response: Organization }>()
);
export const FetchOrganizationByIdErrorAction = createAction(
  FETCH_ORGANIZATION_ERROR,
  props<{ error: HttpErrorResponse }>()
);


export const FetchOrganizationChildrenAction = createAction(
  FETCH_ORGANIZATION_CHILD,
  props<{ organizationId: string }>()
);
export const FetchOrganizationChildrenSuccessAction = createAction(
  FETCH_ORGANIZATION_CHILD_SUCCESS,
  props<{ response: Organization[], organizationId: string }>()
);
export const FetchOrganizationChildrenErrorAction = createAction(
  FETCH_ORGANIZATION_CHILD_ERROR,
  props<{ error: HttpErrorResponse }>()
);

export const FetchOrganizationPeopleAction = createAction(
  FETCH_ORGANIZATION_PEOPLE,
  props<{ organizationId: string }>()
);
export const FetchOrganizationPeopleSuccessAction = createAction(
  FETCH_ORGANIZATION_PEOPLE_SUCCESS,
  props<{ response: PublicProfile[], organizationId: string }>()
);
export const FetchOrganizationPeopleErrorAction = createAction(
  FETCH_ORGANIZATION_PEOPLE_ERROR,
  props<{ error: HttpErrorResponse }>()
);


export const UpdateOrganizationAboutAction = createAction(
  UPDATE_ORGANIZATION_ABOUT,
  props<{ organizationId: string, aboutText: string }>()
);
export const UpdateOrganizationSuccessAction = createAction(
  UPDATE_ORGANIZATION_SUCCESS,
  props<{ response: Organization }>()
);
export const UpdateOrganizationErrorAction = createAction(
  UPDATE_ORGANIZATION_ERROR,
  props<{ error: HttpErrorResponse }>()
);


export const AddOrganizationAccomplishmentAction = createAction(
  CREATE_ORGANIZATION_ACCOMPLISHMENT,
  props<{ organizationId: string, accomplishmentList: OrganizationAccomplishment[]}>()
);

export const UpdateOrganizationAccomplishmentAction = createAction(
  UDPATE_ORGANIZATION_ACCOMPLISHMENT,
  props<{ organizationId: string, accomplishmentList: OrganizationAccomplishment[]}>()
);

export const DeleteOrganizationAccomplishmentAction = createAction(
  DELETE_ORGANIZATION_ACCOMPLISHMENT,
  props<{ organizationId: string, accomplishmentList: OrganizationAccomplishment[]}>()
);

export const UpdateOrganizationContactAction = createAction(
  UPDATE_ORGANIZATION_CONTACT,
  props<{ organizationId: string, contactInfo: OrganizationContactInfos}>()
);

export const UpdateOrganizationLogoAction = createAction(
  UPDATE_ORGANIZATION_LOGO,
  props<{ organizationId: string, imageData:string}>()
);