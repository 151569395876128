<div fxLayout="row wrap"> 
    <label fxFill="100%" >{{title}}</label>
    <div 
        *ngFor="let dataItem of _data; index as i"
        fxFlex="100%"
    >
        <div fxFlex="100%" class="list-field-item-text">
            <span >{{dataItem}}</span>
            
        </div>
        <div>
            <button
                class="list-clear-button" 
                mat-mini-fab
                type="button"
                (click)="removeElement(i)"
            >
            <mat-icon>clear</mat-icon>
        </button>
        </div>
    </div>


    <mat-form-field fxFlex="100%" appearance="outline">
        <mat-label>{{ 'common.add' | translate }} + </mat-label>
        <input id="string" #input (keyup.enter)="addElementToArray($event)" matInput [disabled]="disabled" />
    </mat-form-field>

</div>