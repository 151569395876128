
<vex-dialog-header
[title]="translate.instant('create-child-dialog.title')"
[hideIcon]="true"
></vex-dialog-header>
<mat-dialog-content >
    <form [formGroup]="form" (keyup.enter)="send($event)" fxLayout="column">
        <div fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'pages.auth.register.form.firstname.label' | translate }}</mat-label>
            <input id="firstName" formControlName="firstname" matInput>
          </mat-form-field>
  
          <mat-form-field appearance="outline">
            <mat-label>{{ 'pages.auth.register.form.name.label' | translate }}</mat-label>
            <input id="lastName" formControlName="lastname" matInput>
          </mat-form-field>
  
          <mat-form-field appearance="outline">
            <mat-label>{{ 'pages.auth.register.form.email.label' | translate }}</mat-label>
            <input id="email" formControlName="email" matInput>
            <mat-error *ngIf="form.get('email').hasError('pattern')">{{ 'validations.email' | translate }}</mat-error>
          </mat-form-field>

        </div>

        <vex-loader [isLoading]="isLoading"></vex-loader>
    </form>

    <vex-dialog-actions
        (cancel)="dialogRef.close()"
        (accept)="send()"
        [saving]="isLoading"
    ></vex-dialog-actions>

  </mat-dialog-content>
  