<div [ngClass]="{'clickable-card': clickable}" class="card-margin">
    <mat-card 
        (click)="selected.emit(id)" 
        class="no-border-radius" 
        style="position:relative" 
        fxLayout="row wrap"
        fxLayoutAlign="center center"
    >
        <div 
            [fxFlex]="ref.children.length === 0 ? 100 : (100 - this.ngContentWidth)" 
            [fxFlex.lt-sm]="ref.children.length === 0 ? 100 : (100 - this.ltSmNgContentWidth)" 
        >
            <div fxLayout="row">
                <img 
                    mat-card-avatar 
                    class="organization-card-avatar" 
                    [src]="organizationImage" 
                    (error)="getDefaultLogo($event)"
                />
                <div class="organization-card-title title">
                    <span [ngClass]="{'organization-card-name': !lowerCaseName}">{{name}}</span> <br/>
                    <div class="organization-card-subtitle">
                        <div>{{city}}</div>
                    </div>
                </div>
            </div>                        
        </div>

        <div *ngIf="showNetworkButtons" class="organization-card-action-buttons">
            <button 
                *ngIf="!isOrganizationFollowed()"
                mat-stroked-button
                class="rounded-text-button icon-text"
                (click)="followOrganization($event)"
                mat-button 
                type="button">
                <mat-icon inline=true>add_task</mat-icon>
            </button>
            <button 
                *ngIf="isOrganizationFollowed()"
                mat-stroked-button
                class="rounded-text-button icon-text"
                (click)="unfollowOrganization($event)"
                mat-button 
                type="button">
                <mat-icon inline=true>check_circle</mat-icon>
            </button>
        </div>

        <div
            #ref 
            [fxFlex]="ref.children.length === 0 ? 0 : this.ngContentWidth"
            [fxFlex.lt-sm]="ref.children.length === 0 ? 0 : this.ltSmNgContentWidth"
            [ngClass]="{'lt-sm-padding-top' : !removeNgContentPadding}"
        >
            <ng-content></ng-content>
        </div>
    </mat-card>
</div>

